import { ProcessWithdrawalStatus } from "app/store/models/request";
import React from "react";
import PendingWithdrawalModalDetails from "./pending-modal";
import ProcessedWithdrawalModalDetails from "./processed-modal";

const WithdrawalDetailsModal = ({ data, onClose }: any) => {
  return (
    <React.Fragment>
      {data?.status === "pending" ||
      data?.status === ProcessWithdrawalStatus.processing ? (
        <PendingWithdrawalModalDetails data={data} onClose={onClose} />
      ) : (
        <ProcessedWithdrawalModalDetails data={data} onClose={onClose} />
      )}
    </React.Fragment>
  );
};

export default WithdrawalDetailsModal;
