import { Doughnut } from "react-chartjs-2";
import { useAppSelector } from "app/hooks";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const colors = [
  "rgba(58, 96, 110, 1)",
  "rgba(252, 81, 48, 1)",
  "rgba(62, 81, 122, 1)",
  "rgba(50, 128, 57, 1)",
  "rgba(54, 162, 235, 1)",
  "rgba(255, 206, 86, 1)",
  "rgba(75, 192, 192, 1)",
  "rgba(19, 7, 53, 1)",
  "rgba(255, 159, 64, 1)",
  "rgba(156, 181, 209, 1)",
  "rgba(25, 42, 81, 1)",
  "rgba(037, 109, 123)",
  "rgba(255, 99, 132, 1)",
  "rgba(153, 102, 255, 1)"
];

const WalletChart = () => {
  const raw = useAppSelector(
    (state) => state.summary?.data?.walletPieChartData
  );

  const labels: any[] = [];
  const dataset: any[] = [];

  {
    raw?.forEach((item) => {
      labels.push(item.currency);
      dataset.push(item.walletSum);
    });
  }

  const data = {
    labels,
    datasets: [
      {
        spacing: 6,
        data: dataset,
        borderRadius: 4,
        borderColor: colors,
        label: "# of Votes",
        backgroundColor: colors,
        hoverOffset: 6,
        borderWidth: 1
      }
    ]
  };

  return (
    <Doughnut
      data={data}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: { display: false },
          legend: { position: "right" },
          subtitle: { display: false }
        }
      }}
      style={{ maxWidth: "100%", width: "100%", height: "100%" }}
    />
  );
};

export default WalletChart;
