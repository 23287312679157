import {
  Td,
  Th,
  Tr,
  Box,
  Text,
  Table,
  Tbody,
  Thead,
  Stack,
  Modal,
  Switch,
  Button,
  Select,
  Divider,
  ModalBody,
  FormLabel,
  InputGroup,
  ModalHeader,
  FormControl,
  ModalFooter,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  TableContainer,
  ModalCloseButton
} from "@chakra-ui/react";
import React from "react";
import { BaseModalProps } from "../models";
import NoData from "views/components/no-data";
import { useSearchParams } from "react-router-dom";
import getSymbolFromCurrency from "currency-symbol-map";
import { TransactionBadge } from "views/components/badge";
import { useAppDispatch, useAppSelector } from "app/hooks";
import TransactionSkeletion from "views/containers/transactions/tables/skeleton";
import { formatFullDateToUsersLocale } from "data/utils/date-formatter-users-locale";

import {
  subscribeUser,
  getAllFiatWallet,
  getSubscriptions,
  cancelSubscription
} from "app/store";

const duration = ["monthly", "yearly"];
const plans = ["freelancer", "premium"];

const UserSubscriptions = () => {
  const dispatch = useAppDispatch();
  const [id, setId] = React.useState(0);
  let [searchParams] = useSearchParams();
  const { isOpen, onToggle } = useDisclosure();
  const userId = +searchParams.get("user-id")!;

  const [wallets, subscriptions, cancelling] = useAppSelector((state) => [
    state.wallet.allFiatWallet,
    state.user.subscriptions.all,
    state.user.subscriptions.cancel.loading
  ]);

  const handleCancel = async (id: number, isActive: boolean) => {
    setId(id);
    if (!isActive || cancelling) return;
    const success = await dispatch(cancelSubscription(id));

    if (success) {
      setId(0);
      dispatch(getSubscriptions(userId));
    }
  };

  React.useEffect(() => {
    if (!userId) return;
    dispatch(getSubscriptions(userId));

    if (wallets?.data?.length) return;
    dispatch(getAllFiatWallet({ page: 1, perpage: 10, userId }));
  }, [userId]);

  return (
    <Box className="container mb-20">
      <Stack mt={6}>
        <Button colorScheme="primary" className="ml-auto" onClick={onToggle}>
          Subscribe User
        </Button>
      </Stack>

      <div>
        <Box
          py={[4, 6]}
          display="flex"
          bgColor="#fff"
          borderRadius="10px"
          flexDirection="column"
        >
          <Box display="flex" flexDirection="column" className="mt-2">
            <TableContainer bg="#fff" minH="calc(100vh - 10rem)">
              <Table
                fontSize="14px"
                variant="striped"
                colorScheme="gray"
                lineHeight="18.2px"
                color="brand.grayScale"
              >
                <Thead>
                  <Tr>
                    <Th
                      fontSize="16px"
                      color="gray.800"
                      lineHeight="19px"
                      fontWeight="medium"
                      textTransform="capitalize"
                    >
                      Plan
                    </Th>
                    <Th
                      fontSize="16px"
                      color="gray.800"
                      lineHeight="19px"
                      fontWeight="medium"
                      textTransform="capitalize"
                    >
                      Amount
                    </Th>
                    <Th
                      fontSize="16px"
                      color="gray.800"
                      lineHeight="19px"
                      fontWeight="medium"
                      textTransform="capitalize"
                    >
                      Auto Renew
                    </Th>

                    <Th
                      fontSize="16px"
                      color="gray.800"
                      lineHeight="19px"
                      fontWeight="medium"
                      textTransform="capitalize"
                    >
                      Cycle
                    </Th>
                    <Th
                      fontSize="16px"
                      color="gray.800"
                      lineHeight="19px"
                      fontWeight="medium"
                      textTransform="capitalize"
                    >
                      Date Created
                    </Th>
                    <Th
                      fontSize="16px"
                      color="gray.800"
                      lineHeight="19px"
                      fontWeight="medium"
                      textTransform="capitalize"
                    >
                      Due Date
                    </Th>
                    <Th
                      fontSize="16px"
                      color="gray.800"
                      lineHeight="19px"
                      fontWeight="medium"
                      textTransform="capitalize"
                    >
                      Status
                    </Th>
                    <Th
                      fontSize="16px"
                      color="gray.800"
                      lineHeight="19px"
                      fontWeight="medium"
                      textTransform="capitalize"
                    >
                      Action
                    </Th>
                  </Tr>
                </Thead>
                <Divider />
                <Tbody>
                  {subscriptions?.loading ? <TransactionSkeletion /> : null}
                  {!!subscriptions?.data?.length && !subscriptions?.loading
                    ? subscriptions?.data?.map((el, idx) => {
                        const wallet = wallets?.data?.find(
                          (w) => +w.id === +el.walletId
                        );

                        const currency = wallet?.currency
                          ? getSymbolFromCurrency(wallet?.currency)
                          : "";
                        return (
                          <Tr key={idx}>
                            <Td textTransform="capitalize">{el.plan}</Td>

                            <Td textTransform="capitalize">
                              {currency} {el?.amountPaid?.toLocaleString()}
                            </Td>

                            <Td textTransform="capitalize">
                              <Switch
                                disabled
                                colorScheme="green"
                                isChecked={el.autoRenew}
                              />
                            </Td>

                            <Td textTransform="capitalize">
                              <Text
                                fontSize="14px"
                                lineHeight="1.2"
                                fontWeight="medium"
                              >
                                {el.duration}
                              </Text>
                            </Td>

                            <Td textTransform="capitalize" color="gray.500">
                              {el.createdAt
                                ? formatFullDateToUsersLocale(el.createdAt)
                                : "--"}
                            </Td>

                            <Td textTransform="capitalize" color="gray.500">
                              {el.durationDate
                                ? formatFullDateToUsersLocale(el.durationDate)
                                : "--"}
                            </Td>

                            <Td textTransform="capitalize">
                              <TransactionBadge
                                variant={el.isActive ? "success" : "failed"}
                              >
                                {el?.isActive ? "Active" : "Inactive"}
                              </TransactionBadge>
                            </Td>

                            <Td>
                              <Button
                                size="sm"
                                colorScheme="red"
                                disabled={!el.isActive || cancelling}
                                isLoading={cancelling && id === el.id}
                                onClick={() => handleCancel(el.id, el.isActive)}
                              >
                                Cancel
                              </Button>
                            </Td>
                          </Tr>
                        );
                      })
                    : null}
                  {!subscriptions?.data?.length && !subscriptions?.loading ? (
                    <Tr>
                      <Td colSpan={7} background="transparent">
                        <NoData text="No subscriptions Yet" />
                      </Td>
                    </Tr>
                  ) : null}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </div>

      <SubscribeUserModal isOpen={isOpen} onClose={onToggle} userId={userId} />
    </Box>
  );
};

type Props = BaseModalProps & { userId: number };

const initialState = {
  plan: "",
  currency: "",
  durationType: ""
};

const SubscribeUserModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const [state, setState] = React.useState(initialState);
  const [user, allFiatWallet, loading] = useAppSelector((state) => [
    state.user.singleUser?.data,
    state.wallet.allFiatWallet,
    state.user.subscriptions.subscribe.loading
  ]);

  const isDisabled =
    !state.plan ||
    !user?.email ||
    !props.userId ||
    !state.currency ||
    !state.durationType;

  const handleClose = () => {
    setState(initialState);
    props.onClose();
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubscribe = async () => {
    if (isDisabled || loading) return;

    const success = await dispatch(
      subscribeUser({
        email: user?.email,
        userId: props.userId,
        currency: state.currency,
        plan: state.plan as any,
        durationType: state.durationType as any,
        duration: state.durationType === "monthly" ? 12 : 1
      })
    );

    if (success) {
      dispatch(getSubscriptions(props.userId));
      handleClose();
    }
  };

  return (
    <Modal
      isCentered
      isOpen={props.isOpen}
      onClose={handleClose}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textTransform="capitalize">Subscribe User</ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody>
          <FormControl py="2">
            <FormLabel color="gray.600" fontSize="sm">
              Plan
            </FormLabel>
            <InputGroup>
              <Select
                name="plan"
                value={state.plan}
                colorScheme="primary"
                className="capitalize"
                placeholder="Select Plan"
                onChange={handleInputChange}
              >
                {plans?.map((el) => (
                  <option key={el} value={el} className="capitalize">
                    {el}
                  </option>
                ))}
              </Select>
            </InputGroup>
          </FormControl>

          <FormControl py="2">
            <FormLabel color="gray.600" fontSize="sm">
              Duration
            </FormLabel>
            <InputGroup>
              <Select
                name="durationType"
                colorScheme="primary"
                className="capitalize"
                value={state.durationType}
                onChange={handleInputChange}
                placeholder="Select Duration"
              >
                {duration?.map((el) => (
                  <option key={el} value={el} className="capitalize">
                    {el}
                  </option>
                ))}
              </Select>
            </InputGroup>
          </FormControl>

          <FormControl py="2">
            <FormLabel color="gray.600" fontSize="sm">
              Wallet
            </FormLabel>
            <InputGroup>
              <Select
                name="currency"
                value={state.currency}
                placeholder="Select Wallet"
                onChange={handleInputChange}
              >
                {allFiatWallet?.data?.map((el) => (
                  <option key={el.id} value={el.currency}>
                    {el?.currency?.toUpperCase()}
                  </option>
                ))}
              </Select>
            </InputGroup>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            px="6"
            w="full"
            size="lg"
            isLoading={loading}
            colorScheme="error"
            onClick={handleSubscribe}
            disabled={isDisabled || loading}
          >
            Subscribe
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UserSubscriptions;
