import { Icon } from "@chakra-ui/react";
import Badge from "views/components/badge";
import { ReactComponent as BellIcon } from "app/assets/svg/bell.svg";

const HeaderBellIcon = () => (
  <Badge>
    <div className="bg-gray-100 rounded-2xl	w-10 h-10 flex items-center justify-center">
      <Icon as={BellIcon} width={6} height={6} />
    </div>
  </Badge>
);

export default HeaderBellIcon;
