import { createIcon } from "@chakra-ui/icon";

export const MoneyIcon = createIcon({
  displayName: "MoneyIcon",
  viewBox: "0 0 24 24",
  path: [
    <path
      d="M7.5 4H17.5C18.9234 4 20.0331 4.35591 20.7848 5.0539C21.5297 5.74565 22 6.8453 22 8.5V15.5C22 17.1547 21.5297 18.2543 20.7848 18.9461C20.0331 19.6441 18.9234 20 17.5 20H7.5C6.07665 20 4.9669 19.6441 4.21523 18.9461C3.47026 18.2543 3 17.1547 3 15.5V8.5C3 6.8453 3.47026 5.74565 4.21523 5.0539C4.9669 4.35591 6.07665 4 7.5 4ZM7.5 4.75C5.43386 4.75 3.75 6.43386 3.75 8.5V10C3.75 10.6861 4.31386 11.25 5 11.25C5.68614 11.25 6.25 10.6861 6.25 10V8.5C6.25 7.81614 6.81614 7.25 7.5 7.25H9C9.68614 7.25 10.25 6.68614 10.25 6C10.25 5.31386 9.68614 4.75 9 4.75H7.5ZM7.5 19.25H9C9.68614 19.25 10.25 18.6861 10.25 18C10.25 17.3139 9.68614 16.75 9 16.75H7.5C6.81614 16.75 6.25 16.1839 6.25 15.5V14C6.25 13.3139 5.68614 12.75 5 12.75C4.31386 12.75 3.75 13.3139 3.75 14V15.5C3.75 17.5661 5.43386 19.25 7.5 19.25ZM9 12C9 13.9361 10.5639 15.5 12.5 15.5C14.4361 15.5 16 13.9361 16 12C16 10.0639 14.4361 8.5 12.5 8.5C10.5639 8.5 9 10.0639 9 12ZM17.5 19.25C19.5661 19.25 21.25 17.5661 21.25 15.5V14C21.25 13.3139 20.6861 12.75 20 12.75C19.3139 12.75 18.75 13.3139 18.75 14V15.5C18.75 16.1839 18.1839 16.75 17.5 16.75H16C15.3139 16.75 14.75 17.3139 14.75 18C14.75 18.6861 15.3139 19.25 16 19.25H17.5ZM20 11.25C20.6861 11.25 21.25 10.6861 21.25 10V8.5C21.25 6.43386 19.5661 4.75 17.5 4.75H16C15.3139 4.75 14.75 5.31386 14.75 6C14.75 6.68614 15.3139 7.25 16 7.25H17.5C18.1839 7.25 18.75 7.81614 18.75 8.5V10C18.75 10.6861 19.3139 11.25 20 11.25Z"
      fill="#7959F5"
      stroke="#7959F5"
    />
  ]
});
