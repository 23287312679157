import { createIcon } from "@chakra-ui/icon";

export const CardSolidIcon = createIcon({
  viewBox: "0 0 24 24",
  displayName: "CardSolidIcon",
  path: [
    <path
      d="M21.5 21.25C21.5 21.3839 21.3839 21.5 21.25 21.5H2.75C2.61614 21.5 2.5 21.3839 2.5 21.25C2.5 21.1161 2.61614 21 2.75 21H21.25C21.3839 21 21.5 21.1161 21.5 21.25Z"
      fill="white"
      stroke="white"
    />,
    <path
      d="M3.53789 14.9106L3.39073 14.7598H3.38729C2.32974 13.5478 2.37714 11.7097 3.52356 10.5633L10.6736 3.41332C11.8783 2.20858 13.8217 2.20858 15.0264 3.41332L15.0263 3.41343L15.0354 3.42204C15.2364 3.61324 15.243 3.95965 15.0364 4.16621L4.29645 14.9062C4.07926 15.1234 3.73929 15.117 3.53789 14.9106Z"
      fill="white"
      stroke="white"
    />,
    <path
      d="M20.4664 8.84387L20.4677 8.84512C21.6805 10.0494 21.6806 11.9999 20.459 13.2043L20.4562 13.207L13.3177 20.3555C13.3175 20.3558 13.3172 20.356 13.3169 20.3563C12.1121 21.5516 10.168 21.5517 8.96316 20.3564C8.96287 20.3561 8.96258 20.3558 8.96229 20.3555L5.91413 17.2973L5.91356 17.2968C5.69637 17.0796 5.70273 16.7396 5.90916 16.5382L5.90919 16.5382L5.91356 16.5339L16.6536 5.79387L16.6536 5.79389L16.6579 5.78947C16.8593 5.58304 17.1993 5.57668 17.4164 5.79387L20.4664 8.84387ZM11.9036 19.0939L11.905 19.0924L13.1113 17.8761C13.4659 17.5313 13.5361 16.9991 13.3403 16.5731C13.7633 16.7658 14.2823 16.7008 14.6363 16.361L14.6364 16.3611L14.6443 16.3531L17.0836 13.9039C17.0836 13.9038 17.0837 13.9037 17.0838 13.9036C17.5326 13.4546 17.5227 12.7333 17.0904 12.2838L17.0835 12.2765L17.0763 12.2696C16.6368 11.8478 15.9056 11.8376 15.4664 12.2768L13.0164 14.7268L13.0164 14.7267L13.0096 14.7338C12.6722 15.0846 12.5921 15.601 12.7866 16.0243C12.3646 15.8343 11.8407 15.9025 11.4964 16.2468L10.2764 17.4668L10.2764 17.4667L10.2696 17.4738C9.83721 17.9234 9.82745 18.6449 10.2764 19.0939L10.2764 19.0939L10.2834 19.1007C10.7331 19.5331 11.4546 19.5429 11.9036 19.0939Z"
      fill="white"
      stroke="white"
    />
  ]
});
