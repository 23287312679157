import {
  IBank,
  WithdrawBody,
  IBitmamaMerchant,
  IMerchantTransaction,
  GetAllMerchantTransactionsParams,
  GetAllMerchantaParams,
  IMerchantWithdrawalTransaction
} from "app/store/models/bitmama-merchant";

import config from "data/config";
import { AxiosResponse } from "axios";
import instance from "data/axios-setup";
import { PaginationState } from "app/store/models/wallets";

class BitmamaMerchantService {
  async getAllMerchants(
    params: GetAllMerchantaParams
  ): Promise<
    AxiosResponse<{ data: IBitmamaMerchant[]; pagination: PaginationState }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          "admin/users?role=bitmama-merchant",
          { params }
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getAllMerchantWithdrawalTransactions(
    params: GetAllMerchantTransactionsParams
  ): Promise<
    AxiosResponse<{
      data: IMerchantWithdrawalTransaction[];
      pagination: PaginationState;
    }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        params.sourceWalletId = params.walletId;
        const response = await instance.get(
          "/admin/withdrawals?isBitmama=true",
          { params }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getAllMerchantPaymentTransactions(
    params: GetAllMerchantTransactionsParams
  ): Promise<
    AxiosResponse<{ data: IMerchantTransaction[]; pagination: PaginationState }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          "/admin/transactions?isBitmama=true",
          { baseURL: config.serverV4, params }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getBanks(
    code: string = "NG"
  ): Promise<AxiosResponse<{ data: IBank[]; pagination: PaginationState }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(`/misc/banks/${code}`);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async withdraw(
    id: number,
    body: WithdrawBody
  ): Promise<AxiosResponse<{ data: IBank[]; message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post(
          `/admin/bitmama-merchants/withdrawals/${id}`,
          body
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async resolveBankName(body: {
    bankCode: string;
    accountNumber: string;
  }): Promise<AxiosResponse<{ data: IBank[]; pagination: PaginationState }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post(
          "/misc/resolve-bank-account",
          body
        );

        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }
}

export default new BitmamaMerchantService();
