import { AxiosResponse } from "axios";
import instance from "data/axios-setup";
import { ISummary } from "app/store/models/summary";

class SummaryService {
  async getSummary(): Promise<
    AxiosResponse<{ data: ISummary; message: string }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get("admin/summary");
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }
}

export default new SummaryService();
