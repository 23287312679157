import { AxiosError } from "axios";
import toast from "react-hot-toast";
import { Dispatch } from "@reduxjs/toolkit";
import { actions } from "../reducers/fx.reducer";
import fxService from "data/services/fx.service";
import { IFXStatus, IGetAllFxParams } from "app/store/models/fx";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";

export const getAllFx = (params: IGetAllFxParams) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getAllFxBegin());
      const res = await fxService.getAllFx(params);
      dispatch(actions.getAllFxSuccess(res.data));
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.getAllFxFailed(msg));
    }
  };
};

export const getSingleFx = (id: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getSingleFxBegin());
      const res = await fxService.getSingleFx(id);
      dispatch(actions.getSingleFxSuccess(res.data.data));
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.getAllFxFailed(msg));
    }
  };
};

export const updateFxStatus = (id: number, status: IFXStatus) => {
  return async (dispatch: Dispatch) => {
    try {
      toast.loading("Submitting FX Request");
      dispatch(actions.updateFxStatusBegin());
      const res = await fxService.updateFxStatus(id, status);
      toast.dismiss();
      dispatch(actions.updateFxStatusSuccess());
      toast.success("FX request submitted successfully");
      return res.data.message;
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.updateFxStatusFxFailed(msg));
    }
  };
};

export const deleteFX = (id: number) => {
  return async (dispatch: Dispatch) => {
    try {
      toast.loading("Deleting FX Request");
      dispatch(actions.updateFxStatusBegin());
      const res = await fxService.deleteFX(id);
      toast.dismiss();
      dispatch(actions.updateFxStatusSuccess());
      toast.success("FX request deleted successfully");
      return res.data.message;
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.updateFxStatusFxFailed(msg));
    }
  };
};
