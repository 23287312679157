import {
  PaginationState,
  VirtualCardStatus,
  VirtualCardResponse
} from "app/store/models/virtual-cards";
import { AxiosResponse } from "axios";
import instance from "data/axios-setup";

class VirtualCardsService {
  async getAllVirtualCards(
    params: {
      page: number;
      perpage: number;
      q?: string;
      userId?: number;
    },
    signal?: AbortSignal
  ): Promise<
    AxiosResponse<{
      data: VirtualCardResponse[];
      pagination: PaginationState;
    }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get(`/admin/virtual-cards`, {
          params,
          signal
        });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async updateVirtualCardStatus(
    id: number,
    status: VirtualCardStatus
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.patch(`/admin/virtual-cards/${id}/status`, {
          status
        });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }
}

export default new VirtualCardsService();
