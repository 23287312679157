import {
  Box,
  Icon,
  Stack,
  Divider,
  Heading,
  useDisclosure
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import TabLink from "views/components/links/tab-link";
import { TbFilter, TbRefresh } from "react-icons/tb";
import TransactionsTableContainer from "./transactions-table-container";
import { ChangeraTransactionType } from "app/store/models/transactions";
import { useAppSelector } from "app/hooks";
import TransactionFilter from "./filter";
import { usePagination } from "@ajna/pagination";

const TransactionsTable = () => {
  // tab and url controllers
  let [searchParams, setSearchParams] = useSearchParams();
  let transactionType = searchParams.get("transaction-type");
  const [count, allTransactions] = useAppSelector((state) => [
    state.transactions.allTransactions.pagination?.total,
    state.transactions.allTransactions
  ]);

  const [status, setStatus] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleReset = () => {
    setStatus("");
    setCurrentPage(1);
  };

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, offset } =
    usePagination({
      initialState: { currentPage: 1, pageSize },
      total: allTransactions.pagination.total,
      limits: {
        inner: 2,
        outer: 1
      }
    });

  useEffect(() => {
    if (!transactionType) {
      setSearchParams({ "transaction-type": "withdrawal" });
    }
  }, []);

  return (
    <>
      <Box bg="#fff" borderRadius="16px" padding={"1rem 1rem 24px"}>
        <Stack direction="row" alignItems="center">
          <Heading
            as={"h3"}
            fontSize="24px"
            fontWeight={"medium"}
            lineHeight="28px"
            py="4"
            px="2"
          >
            Transactions
          </Heading>

          <Stack direction={"row"} color="gray.600">
            <Icon
              as={TbRefresh}
              w={"5"}
              h={"5"}
              cursor="pointer"
              onClick={handleReset}
            />
            <Icon
              as={TbFilter}
              w={"5"}
              h={"5"}
              cursor="pointer"
              onClick={onOpen}
            />
          </Stack>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          px="2"
          py="2"
          overflowX={"scroll"}
        >
          <Stack spacing={"5"} direction="row">
            <TabLink
              urlParamKey={"transaction-type"}
              urlParamValue={ChangeraTransactionType.WITHDRAWAL}
              count={count}
            >
              Withdrawal
            </TabLink>
            <TabLink
              urlParamKey={"transaction-type"}
              urlParamValue={ChangeraTransactionType.SEND_MONEY}
              count={count}
            >
              Sent
            </TabLink>
            <TabLink
              urlParamKey={"transaction-type"}
              urlParamValue={ChangeraTransactionType.FUNDING_WALLET}
              count={count}
            >
              Fiat Funding
            </TabLink>
            <TabLink
              urlParamKey={"transaction-type"}
              urlParamValue={ChangeraTransactionType.FUNDING_CRYPTO_WALLET}
              count={count}
            >
              Crypto Funding
            </TabLink>
            <TabLink
              urlParamKey={"transaction-type"}
              urlParamValue={ChangeraTransactionType.REQUEST_MONEY}
              count={count}
            >
              Request Money
            </TabLink>
            <TabLink
              urlParamKey={"transaction-type"}
              urlParamValue={ChangeraTransactionType.AIRTIME_VTU}
              count={count}
            >
              Airtime
            </TabLink>
            <TabLink
              urlParamKey={"transaction-type"}
              urlParamValue={ChangeraTransactionType.DATA_VTU}
              count={count}
            >
              Data
            </TabLink>
            <TabLink
              urlParamKey={"transaction-type"}
              urlParamValue={ChangeraTransactionType.CONVERSION}
              count={count}
            >
              Conversion
            </TabLink>
            <TabLink
              urlParamKey={"transaction-type"}
              urlParamValue={ChangeraTransactionType.FIAT_CONVERSION}
              count={count}
            >
              Fiat Conversion
            </TabLink>
            <TabLink
              urlParamKey={"transaction-type"}
              urlParamValue={ChangeraTransactionType.CRYPTO_CONVERSION}
              count={count}
            >
              Crypto Conversion
            </TabLink>
            <TabLink
              urlParamKey={"transaction-type"}
              urlParamValue={ChangeraTransactionType.REFERRAL}
              count={count}
            >
              Referral
            </TabLink>
            <TabLink
              urlParamKey={"transaction-type"}
              urlParamValue={ChangeraTransactionType.EARNING}
              count={count}
            >
              Earning
            </TabLink>
            <TabLink
              urlParamKey={"transaction-type"}
              urlParamValue={ChangeraTransactionType.FAUCET}
              count={count}
            >
              Faucet
            </TabLink>
            <TabLink
              urlParamKey={"transaction-type"}
              urlParamValue={ChangeraTransactionType.REWARD}
              count={count}
            >
              Reward
            </TabLink>
            <TabLink
              urlParamKey={"transaction-type"}
              urlParamValue={ChangeraTransactionType.INTER_TRANSFER}
              count={count}
            >
              Inter Transfer
            </TabLink>
            <TabLink
              urlParamKey={"transaction-type"}
              urlParamValue={ChangeraTransactionType.CARD}
              count={count}
            >
              Virtual Card
            </TabLink>
            <TabLink
              urlParamKey={"transaction-type"}
              urlParamValue={ChangeraTransactionType.PHYSICAL_CARD}
              count={count}
            >
              Physical Card
            </TabLink>
            <TabLink
              urlParamKey={"transaction-type"}
              urlParamValue={ChangeraTransactionType.SUBSCRIPTION}
              count={count}
            >
              Subscription
            </TabLink>
          </Stack>
        </Stack>
        <Divider />
        <TransactionsTableContainer
          pages={pages}
          status={status}
          offset={offset}
          pageSize={pageSize}
          pagesCount={pagesCount}
          isDisabled={isDisabled}
          setPageSize={setPageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Box>

      <TransactionFilter
        isOpen={isOpen}
        onClose={onClose}
        setStatus={setStatus}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
};

export default TransactionsTable;
