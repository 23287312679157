import {
  Box,
  Text,
  Modal,
  Select,
  Button,
  Divider,
  Textarea,
  ModalBody,
  FormLabel,
  ModalHeader,
  FormControl,
  ModalFooter,
  ModalOverlay,
  ModalContent,
  ModalCloseButton
} from "@chakra-ui/react";
import React from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { processingWithdrawals, processWithdrawals } from "app/store";
import { ProcessWithdrawalStatus } from "app/store/models/request";

const PendingWithdrawalModalDetails = ({ data, onClose }: any) => {
  const dispatch: any = useAppDispatch();

  const loadingStatus = useAppSelector(
    (state) => state.request.status.processWithdrawal
  );

  const isLoading = loadingStatus === "loading";

  const [reason, setReason] = React.useState("");
  const [status, setStatus] = React.useState<any>(
    ProcessWithdrawalStatus.processing
  );

  const handleSubmit = () => {
    if (
      data?.status === ProcessWithdrawalStatus.approve ||
      data?.status === ProcessWithdrawalStatus.deny ||
      isLoading
    )
      return;
    dispatch(processWithdrawals(data.id, { status, reason }, onClose));
  };

  const processingWithdrawalsHandler = () => {
    if (
      data?.status === ProcessWithdrawalStatus.approve ||
      data?.status === ProcessWithdrawalStatus.deny ||
      isLoading
    )
      return;
    dispatch(processingWithdrawals(data.id, { status }, onClose));
  };

  return (
    <Modal isOpen={Boolean(data)} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          fontSize={24}
          lineHeight="28px"
          fontWeight={700}
          fontFamily="Karla"
          color="primary.500"
        >
          {status === ProcessWithdrawalStatus.processing
            ? "Processing"
            : status === ProcessWithdrawalStatus.approve
            ? "Approve"
            : "Decline"}{" "}
          Withdrawal
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text color="gray.500" lineHeight="19px" mt={4}>
            Request
          </Text>
          <Text
            color={
              status === ProcessWithdrawalStatus.processing
                ? "warning.500"
                : status === ProcessWithdrawalStatus.approve
                ? "success.500"
                : "error.500"
            }
            lineHeight="19px"
            fontWeight={500}
            mt={2}
          >
            {status === ProcessWithdrawalStatus.processing
              ? "Processing"
              : status === ProcessWithdrawalStatus.approve
              ? "Approve"
              : "Decline"}{" "}
            Withdrawal
          </Text>

          <Box mt={6} display="flex" gap={4} flexDirection="column">
            <FormControl>
              <FormLabel
                mb={1}
                color="gray.600"
                lineHeight="24px"
                fontSize={14}
                fontWeight={400}
              >
                Status
              </FormLabel>
              <Select
                height="44px"
                value={status}
                colorScheme="primary.500"
                focusBorderColor="primary.500"
                disabled={
                  data?.status === ProcessWithdrawalStatus.approve ||
                  data?.status === ProcessWithdrawalStatus.deny
                }
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value={ProcessWithdrawalStatus.processing}>
                  Processing
                </option>
                <option value={ProcessWithdrawalStatus.approve}>Approve</option>
                <option value={ProcessWithdrawalStatus.deny}>Decline</option>
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel
                mb={1}
                fontSize={14}
                color="gray.600"
                lineHeight="24px"
                fontWeight={400}
              >
                Please provide more information
              </FormLabel>
              <Textarea
                value={reason}
                minHeight="120px"
                focusBorderColor="primary.500"
                onChange={(e) => setReason(e.target.value)}
                disabled={status !== ProcessWithdrawalStatus.deny}
              />
            </FormControl>
          </Box>
        </ModalBody>

        <Divider mt={4} mb={1} />

        <ModalFooter>
          {status === ProcessWithdrawalStatus.processing ? (
            <Button
              mr={3}
              width="full"
              height="56px"
              isLoading={isLoading}
              colorScheme="warning"
              disabled={
                data?.status === ProcessWithdrawalStatus.approve ||
                data?.status === ProcessWithdrawalStatus.deny ||
                isLoading
              }
              onClick={processingWithdrawalsHandler}
            >
              Processing Withdrawal
            </Button>
          ) : status === ProcessWithdrawalStatus.deny ? (
            <Button
              mr={3}
              width="full"
              height="56px"
              colorScheme="error"
              isLoading={isLoading}
              onClick={handleSubmit}
              disabled={
                data?.status === ProcessWithdrawalStatus.approve ||
                data?.status === ProcessWithdrawalStatus.deny ||
                isLoading
              }
            >
              Decline Withdrawal
            </Button>
          ) : (
            <Button
              mr={3}
              width="full"
              height="56px"
              colorScheme="success"
              onClick={handleSubmit}
              isLoading={isLoading}
              disabled={
                data?.status === ProcessWithdrawalStatus.approve ||
                data?.status === ProcessWithdrawalStatus.deny ||
                isLoading
              }
            >
              Approve Withdrawal
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PendingWithdrawalModalDetails;
