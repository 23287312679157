import { createIcon } from "@chakra-ui/icon";

export const ProfileCheckOutlineIcon = createIcon({
  viewBox: "0 0 24 24",
  displayName: "ProfileCheckOutlineIcon",
  path: [
    <path
      fill="transparent"
      d="M14.44 19.0498L15.96 20.5698L19 17.5298"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      fill="transparent"
      d="M12.16 10.87C12.06 10.86 11.94 10.86 11.83 10.87C9.45 10.79 7.56 8.84 7.56 6.44C7.55 3.99 9.54 2 11.99 2C14.44 2 16.43 3.99 16.43 6.44C16.43 8.84 14.53 10.79 12.16 10.87Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      fill="transparent"
      d="M11.99 21.8102C10.17 21.8102 8.36001 21.3502 6.98001 20.4302C4.56001 18.8102 4.56001 16.1702 6.98001 14.5602C9.73001 12.7202 14.24 12.7202 16.99 14.5602"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ]
});
