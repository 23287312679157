import { createIcon } from "@chakra-ui/icon";

export const FlashSolidIcon = createIcon({
  viewBox: "0 0 24 24",
  displayName: "UserSolidIcon",
  path: [
    <path
      fill="#6484F6"
      d="M13.91 8.7199H10.82V1.5199C10.82 -0.160102 9.91001 -0.500103 8.80001 0.759897L8.00001 1.6699L1.23001 9.3699C0.300011 10.4199 0.690012 11.2799 2.09001 11.2799H5.18001V18.4799C5.18001 20.1599 6.09001 20.4999 7.20001 19.2399L8.00001 18.3299L14.77 10.6299C15.7 9.5799 15.31 8.7199 13.91 8.7199Z"
    />
  ]
});
