import React from "react";
import { Tr, Td, Skeleton } from "@chakra-ui/react";

const PaymentSkeleton = () => {
  return (
    <React.Fragment>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((key) => (
        <Tr key={key}>
          {[1, 2, 3, 4, 5, 6, 7].map((keyy) => (
            <Td key={keyy}>
              <Skeleton width="100%" height="30px" />
            </Td>
          ))}
        </Tr>
      ))}
    </React.Fragment>
  );
};

export default PaymentSkeleton;
