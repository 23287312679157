import {
  Td,
  Th,
  Tr,
  Box,
  Text,
  Stack,
  Table,
  Tbody,
  Thead,
  Select,
  Divider,
  Checkbox,
  TableContainer
} from "@chakra-ui/react";
import WalletSkeleton from "./skeleton";
import { useEffect, useState } from "react";
import NoData from "views/components/no-data";
import { usePagination } from "@ajna/pagination";
import { TransactionBadge } from "views/components/badge";
import { useAppDispatch, useAppSelector } from "app/hooks";
import PaginationWrapper from "views/components/PaginationWrapper/PaginationWrapper";
import { formatFullDateToUsersLocale } from "data/utils/date-formatter-users-locale";
import { getAllVirtualAccounts } from "app/store/action-creators/virtual-account.actions";

const VirtualAccountsTableContainer = () => {
  const dispatch = useAppDispatch();
  const [status, setStatus] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [currency, setCurrency] = useState("");

  const allVirtualAccounts = useAppSelector(
    (state) => state.virtualAccount.allVirtualAccounts
  );

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, offset } =
    usePagination({
      limits: { inner: 2, outer: 1 },
      initialState: { currentPage: 1, pageSize },
      total: allVirtualAccounts.pagination?.total
    });

  const handlePageChange = (nextPage: number) => {
    setCurrentPage(nextPage);
    setTimeout(() => {
      window.scrollTo({ top: 10, behavior: "smooth" });
    }, 500);
  };

  const handleSize = (e: any) => {
    setPageSize(e.target.value);
  };

  const handleStatus = (e: any) => {
    setStatus(e.target.value);
  };

  const handleCurrency = (e: any) => {
    setCurrency(e.target.value);
  };

  useEffect(() => {
    dispatch(
      getAllVirtualAccounts({
        status,
        currency,
        page: currentPage,
        perpage: pageSize
      })
    );
  }, [status, currency, currentPage, pageSize]);

  const heading = [
    "id",
    "user",
    "account details",
    "currency",
    "status",
    "is active",
    "date"
  ];

  return (
    <>
      <TableContainer bg="#fff" minH="calc(100vh - 10rem)">
        <Stack mt="0.75rem" ml={2} spacing={4} direction="row">
          <Box mb="12px">
            <Select
              width="120px"
              placeholder="Status"
              defaultValue="Status"
              onChange={handleStatus}
            >
              <option value="approved">Approved</option>
              <option value="pending">Pending</option>
            </Select>
          </Box>
          <Box mb="12px">
            <Select
              width="120px"
              placeholder="Currency"
              defaultValue="Currency"
              onChange={handleCurrency}
            >
              <option value="ngn">NGN</option>
              <option value="usd">USD</option>
              <option value="gbp">GBP</option>
              <option value="eur">EUR</option>
            </Select>
          </Box>
          <Box mb="12px">
            <Select
              width="120px"
              placeholder="Size"
              defaultValue="Size"
              onChange={handleSize}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
            </Select>
          </Box>
        </Stack>
        <Table
          fontSize="14px"
          variant="striped"
          colorScheme="gray"
          lineHeight="18.2px"
          color="brand.grayScale"
        >
          <Thead>
            <Tr>
              {heading.map((h) => (
                <Th
                  key={h}
                  fontSize="16px"
                  color="gray.800"
                  lineHeight="19px"
                  fontWeight="medium"
                  textTransform="capitalize"
                >
                  {h}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Divider />
          <Tbody>
            {allVirtualAccounts.loading ? <WalletSkeleton /> : null}
            {!!allVirtualAccounts.data.length && !allVirtualAccounts.loading
              ? allVirtualAccounts.data.map((el, idx) => (
                  <Tr key={idx}>
                    <Td textTransform="capitalize">{el.id}</Td>
                    <Td textTransform="capitalize">
                      <Stack>
                        <Text
                          fontSize="14px"
                          lineHeight={1.2}
                          fontWeight="medium"
                        >
                          {`${el.user?.firstName || "--"} ${
                            el.user?.lastName || ""
                          }`}
                        </Text>
                        <Text
                          marginTop={0}
                          fontSize="12px"
                          lineHeight="1.2"
                          color="gray.600"
                        >
                          {el.user?.email || "--"}
                        </Text>
                      </Stack>
                    </Td>
                    <Td textTransform="capitalize">
                      {el?.accountNumber ? (
                        <Stack>
                          {el.accountName && (
                            <Text
                              fontSize="14px"
                              lineHeight={1.2}
                              fontWeight="medium"
                            >
                              {el.accountName}
                            </Text>
                          )}
                          {el.accountNumber && (
                            <Text
                              marginTop={0}
                              fontSize="12px"
                              lineHeight="1.2"
                              color="gray.600"
                            >
                              {el.accountNumber}
                            </Text>
                          )}
                        </Stack>
                      ) : (
                        "-"
                      )}
                    </Td>

                    <Td textTransform="capitalize">{el.currency}</Td>

                    <Td>
                      <TransactionBadge
                        variant={el.status}
                        children={el.status}
                      />
                    </Td>

                    <Td>
                      <Checkbox
                        colorScheme="green"
                        checked={el.isActive}
                        isChecked={el.isActive}
                      />
                    </Td>
                    <Td>
                      {el.updatedAt
                        ? formatFullDateToUsersLocale(el.updatedAt)
                        : "--"}
                    </Td>
                  </Tr>
                ))
              : null}

            {!allVirtualAccounts.data.length && !allVirtualAccounts.loading ? (
              <Tr>
                <Td colSpan={7} background="transparent" h="xl">
                  <NoData text={`No Virtual Accounts Yet`} />
                </Td>
              </Tr>
            ) : null}
          </Tbody>
        </Table>
      </TableContainer>

      <PaginationWrapper
        pages={pages}
        offset={offset}
        size={pageSize}
        isDisabled={isDisabled}
        pagesCount={pagesCount}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        totalDataCount={allVirtualAccounts.pagination?.total}
      />
    </>
  );
};

export default VirtualAccountsTableContainer;
