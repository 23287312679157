import {
  Text,
  Icon,
  Modal,
  Stack,
  Button,
  Select,
  Heading,
  Divider,
  Textarea,
  ModalBody,
  FormLabel,
  InputGroup,
  ModalHeader,
  ModalFooter,
  FormControl,
  ModalOverlay,
  ModalContent,
  ModalCloseButton
} from "@chakra-ui/react";
import { format } from "date-fns";
import { AxiosError } from "axios";
import toast from "react-hot-toast";
import Input from "views/components/input";
import { useEffect, useState } from "react";
import { getAllFiatWallet } from "app/store";
import { Calendar2, Profile } from "iconsax-react";
import { SingleUserState } from "app/store/models/user";
import getSymbolFromCurrency from "currency-symbol-map";
import walletService from "data/services/wallet-service";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";
import NumberFormat, { NumberFormatValues } from "react-number-format";

interface ILien {
  amount: string;
  walletId: number;
  currency: string;
  description: string;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
  user: SingleUserState;
}

const AddLien = ({ user, isOpen, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const admin = useAppSelector((state) => state.dashboard?.userProfile);
  const allFiatWallet = useAppSelector((state) => state.wallet.allFiatWallet);

  const [lienState, setLienState] = useState<ILien>({
    amount: "",
    walletId: 0,
    currency: "",
    description: ""
  });

  //   form state
  const [loading, setLoading] = useState(false);

  const isDisabled =
    loading ||
    !lienState.amount ||
    !lienState.walletId ||
    !lienState.currency ||
    !lienState.description;

  const imposeLienHandler = async () => {
    if (isDisabled) return;

    setLoading(true);
    try {
      const res = await walletService.addLien({
        userId: +user?.data?.id!,
        amount: +lienState.amount,
        currency: lienState.currency,
        walletId: lienState.walletId,
        description: lienState.description
      });
      toast.success(res.data.message);
      onClose();
      setLoading(false);
      setLienState({ amount: "", currency: "", description: "", walletId: 0 });
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(
      getAllFiatWallet({ page: 1, perpage: 10, userId: user?.data?.id })
    );
  }, []);

  return (
    <>
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textTransform={"capitalize"}>Impose Lien</ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody>
            <Stack
              p="5"
              bg="primary.500"
              color={"gray.300"}
              rounded="lg"
              spacing={"4"}
            >
              <Stack direction={"row"} justifyContent="space-between">
                <Stack direction={"row"} alignItems="center">
                  <Icon as={Profile} h={5} w={5} />
                  <Text>Requested By</Text>
                </Stack>
                <Text>{`${admin?.firstName || ""} ${
                  admin?.lastName || ""
                }`}</Text>
              </Stack>
              <Stack direction={"row"} justifyContent="space-between">
                <Stack direction={"row"} alignItems="center">
                  <Icon as={Calendar2} h={5} w={5} />
                  <Text>Date Registered</Text>
                </Stack>
                <Text>
                  {(() => {
                    const newDate = new Date(Date.now());
                    return format(newDate, "MMM d, yyyy");
                  })()}
                </Text>
              </Stack>
            </Stack>
            <Heading
              as={"h4"}
              fontSize="24px"
              fontFamily={"karla"}
              my="5"
              fontWeight={"extrabold"}
            >
              Request Details
            </Heading>
            <FormControl py="2">
              <FormLabel color={"gray.600"} fontSize="sm">
                Currency
              </FormLabel>
              <InputGroup>
                <Select
                  placeholder="Select Currency"
                  value={lienState.currency}
                  onChange={(e) => {
                    const wallet = allFiatWallet.data.find(
                      (x) => x.currency === e.target.value
                    );

                    if (!wallet) return;
                    setLienState((prev) => ({
                      ...prev,
                      walletId: wallet.id,
                      currency: wallet.currency
                    }));
                  }}
                >
                  {allFiatWallet?.data?.map((el) => (
                    <option key={el.id} value={el.currency}>
                      {el?.currency?.toUpperCase()}
                    </option>
                  ))}
                </Select>
              </InputGroup>
            </FormControl>
            <FormControl py="2">
              <FormLabel color={"gray.600"} fontSize="sm">
                Amount to Impose
              </FormLabel>
              <InputGroup>
                <Input
                  as={NumberFormat}
                  prefix={getSymbolFromCurrency(lienState.currency)}
                  placeholder="0.00"
                  thousandSeparator={true}
                  inputMode="decimal"
                  fixedDecimalScale={false}
                  decimalScale={2}
                  allowLeadingZeros={false}
                  displayType="input"
                  name="amount"
                  value={lienState.amount}
                  onValueChange={({ value }: NumberFormatValues) =>
                    setLienState((prev) => ({ ...prev, amount: value }))
                  }
                  autoComplete="off"
                  disabled={!lienState.currency}
                />
              </InputGroup>
            </FormControl>
            <FormControl py="2">
              <FormLabel color={"gray.600"} fontSize="sm">
                Description
              </FormLabel>
              <Textarea
                value={lienState.description}
                onChange={(e) =>
                  setLienState((prev) => ({
                    ...prev,
                    description: e.target.value
                  }))
                }
                size="lg"
                height="56px"
                fontSize="16px"
                borderRadius="8px"
                backgroundColor="#f9f9fa"
                focusBorderColor="#141D3E"
                border="1px solid #cccfd6 !important"
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              px="6"
              w={"full"}
              size={"lg"}
              colorScheme="error"
              isLoading={loading}
              disabled={isDisabled}
              onClick={imposeLienHandler}
            >
              Impose Lien
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddLien;
