import {
  Menu,
  Text,
  Modal,
  Radio,
  Stack,
  Button,
  Select,
  MenuList,
  MenuItem,
  ModalBody,
  MenuButton,
  RadioGroup,
  ModalHeader,
  FormControl,
  ModalOverlay,
  ModalContent,
  StackDivider
} from "@chakra-ui/react";
import React from "react";
import { setUserFilter } from "app/store";
import { startOfMonth } from "date-fns/esm";
import startOfDay from "date-fns/startOfDay";
import { UserFilter } from "app/store/models/user";
import DatePicker from "views/components/date-picker";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { FilterIcon } from "views/components/icons/filter-icon";
import { endOfDay, endOfMonth, endOfYear, startOfYear } from "date-fns";

type RootModalProps = {
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
};

const getUserStatus = (status: any) => {
  if (status === true) return "blocked";
  if (status === false) return "active";
  if (status === undefined) return "all";
};

const countries = [
  "cameroon",
  "canada",
  "ghana",
  "ivory coast",
  "kenya",
  "nigeria",
  "senegal",
  "uganda"
];

type Props = {
  setSearchInput: React.Dispatch<React.SetStateAction<string>>;
};

const UserFilterModal: React.FC<Props> = ({ setSearchInput }) => {
  const [state, setState] = React.useState(0);
  const handleOpen = (value: number) => setState(value);

  const onClose = () => setState(0);

  const filterCallback = () => {
    setSearchInput("");
  };

  return (
    <React.Fragment>
      <Menu computePositionOnMount={true}>
        <MenuButton as={FilterIcon} cursor="pointer" />
        <MenuList>
          <MenuItem onClick={() => handleOpen(1)}>Date</MenuItem>
          <MenuItem onClick={() => handleOpen(2)}>Plan</MenuItem>
          <MenuItem onClick={() => handleOpen(3)}>Country</MenuItem>
          <MenuItem onClick={() => handleOpen(4)}>User Status</MenuItem>
          <MenuItem onClick={() => handleOpen(5)}>Advanced...</MenuItem>
        </MenuList>
      </Menu>

      <RootModal onClose={onClose} isOpen={Boolean(state)}>
        {state === 1 && (
          <DateComponent onClose={onClose} callback={filterCallback} />
        )}
        {state === 2 && <Plan onClose={onClose} callback={filterCallback} />}
        {state === 3 && <Country onClose={onClose} callback={filterCallback} />}
        {state === 4 && (
          <UserStatus onClose={onClose} callback={filterCallback} />
        )}
        {state === 5 && (
          <Advanced onClose={onClose} callback={filterCallback} />
        )}
      </RootModal>
    </React.Fragment>
  );
};

const RootModal = (props: RootModalProps) => (
  <Modal
    isCentered
    isOpen={props.isOpen}
    onClose={props.onClose}
    scrollBehavior="inside"
  >
    <ModalOverlay />
    <ModalContent py={6} px={[6]}>
      {props.children}
    </ModalContent>
  </Modal>
);

const DateComponent = (props: any) => {
  const dispatch = useAppDispatch();
  const filter = useAppSelector((state) => state.user.filter);
  const [update, setUpdate] = React.useState<Partial<UserFilter>>({
    q: "",
    endDate: filter.endDate,
    dateType: filter.dateType,
    startDate: filter.startDate,
    dateFormat: filter.dateFormat,
    showYearPicker: filter.showYearPicker,
    showMonthYearPicker: filter.showMonthYearPicker
  });

  const handleApplyChange = () => {
    dispatch(setUserFilter(update));
    props?.callback();
    props.onClose();
  };

  const handleDateTypeChange = (value: any, date?: any) => {
    const newUpdate: Partial<UserFilter> = { dateType: value };
    date = Boolean(date && new Date(date).getTime())
      ? new Date(date)
      : new Date();

    if (!value || value === "day") {
      newUpdate.showYearPicker = false;
      newUpdate.dateFormat = "dd/MM/yyyy";
      newUpdate.showMonthYearPicker = false;
      newUpdate.endDate = endOfDay(date).toISOString();
      newUpdate.startDate = startOfDay(date).toISOString();
    }

    if (value === "month") {
      newUpdate.dateFormat = "MM/yyyy";
      newUpdate.showYearPicker = false;
      newUpdate.showMonthYearPicker = true;
      newUpdate.endDate = endOfMonth(date).toISOString();
      newUpdate.startDate = startOfMonth(date).toISOString();
    }

    if (value === "year") {
      newUpdate.dateFormat = "yyyy";
      newUpdate.showYearPicker = true;
      newUpdate.showMonthYearPicker = false;
      newUpdate.endDate = endOfYear(date).toISOString();
      newUpdate.startDate = startOfYear(date).toISOString();
    }

    setUpdate({ ...update, ...newUpdate });
  };

  const handleDateChange = (date: Date) => {
    handleDateTypeChange(update.dateType, date);
  };

  React.useEffect(() => {
    if (update?.startDate && update?.endDate) return;

    setUpdate((prev) => ({
      ...prev,
      endDate: endOfDay(new Date()),
      startDate: startOfDay(new Date())
    }));
  }, []);

  return (
    <React.Fragment>
      <ModalHeader p={0} mb={4} fontSize={16} color="#333843" fontWeight={600}>
        Please Select a date
      </ModalHeader>
      <ModalBody
        p={1}
        mb={8}
        color="#444B59"
        overflow="unset"
        textAlign="center"
      >
        <Stack spacing={6}>
          <Select
            value={update.dateType}
            onChange={(e) => handleDateTypeChange(e.target.value)}
          >
            <option value="day">Day</option>
            <option value="month">Month</option>
            <option value="year">Year</option>
          </Select>

          <FormControl>
            <DatePicker
              onChange={handleDateChange}
              dateFormat={update.dateFormat}
              showYearPicker={update.showYearPicker}
              showMonthYearPicker={update.showMonthYearPicker}
              selected={
                update?.startDate ? new Date(update.startDate) : undefined
              }
            />
          </FormControl>
        </Stack>
      </ModalBody>

      <Button size="lg" colorScheme="primary" onClick={handleApplyChange}>
        Apply
      </Button>
    </React.Fragment>
  );
};

const UserStatus = (props: any) => {
  const dispatch = useAppDispatch();
  const filter = useAppSelector((state) => state.user.filter);
  const [update, setUpdate] = React.useState<Partial<UserFilter>>({
    q: "",
    status: filter.status
  });

  const handleApplyChange = () => {
    dispatch(setUserFilter(update));
    props?.callback();
    props.onClose();
  };

  const handleChange = (status: any) => {
    if (status === "blocked") status = true;
    if (status === "active") status = false;
    if (status === "all") status = undefined;
    setUpdate({ ...update, status });
  };

  return (
    <React.Fragment>
      <ModalHeader p={0} mb={4} fontSize={16} color="#333843" fontWeight={600}>
        Select Status
      </ModalHeader>

      <ModalBody p={1} textAlign="center" mb={8} color="#444B59">
        <RadioGroup
          colorScheme="primary"
          onChange={handleChange}
          value={getUserStatus(update.status)}
        >
          <Stack spacing={5} divider={<StackDivider borderColor="#F0F1F3" />}>
            <Radio value="all">All</Radio>
            <Radio value="active">Active</Radio>
            <Radio value="blocked">Blocked</Radio>
          </Stack>
        </RadioGroup>
      </ModalBody>

      <Button size="lg" colorScheme="primary" onClick={handleApplyChange}>
        Apply
      </Button>
    </React.Fragment>
  );
};

const Country = (props: any) => {
  const dispatch = useAppDispatch();
  const filter = useAppSelector((state) => state.user.filter);
  const [update, setUpdate] = React.useState<Partial<UserFilter>>({
    q: "",
    country: filter.country
  });

  const handleApplyChange = () => {
    dispatch(setUserFilter(update));
    props?.callback();
    props.onClose();
  };

  const handleChange = (country?: string) => {
    if (country === "all") country = undefined;
    setUpdate({ ...update, country });
  };

  return (
    <React.Fragment>
      <ModalHeader p={0} mb={4} fontSize={16} color="#333843" fontWeight={600}>
        Select Country
      </ModalHeader>

      <ModalBody p={1} textAlign="center" mb={8} color="#444B59">
        <RadioGroup
          colorScheme="primary"
          onChange={handleChange}
          value={update.country ? update.country : "all"}
        >
          <Stack spacing={5} divider={<StackDivider borderColor="#F0F1F3" />}>
            <Radio value="all">All</Radio>
            {countries.map((country) => (
              <Radio key={country} value={country} className="capitalize">
                <span className="capitalize">{country}</span>
              </Radio>
            ))}
          </Stack>
        </RadioGroup>
      </ModalBody>

      <Button size="lg" colorScheme="primary" onClick={handleApplyChange}>
        Apply
      </Button>
    </React.Fragment>
  );
};

const Plan = (props: any) => {
  const dispatch = useAppDispatch();
  const filter = useAppSelector((state) => state.user.filter);
  const [update, setUpdate] = React.useState<Partial<UserFilter>>({
    q: "",
    plan: filter.plan
  });

  const handleApplyChange = () => {
    dispatch(setUserFilter(update));
    props?.callback();
    props.onClose();
  };

  const handleChange = (plan: any) => {
    if (plan === "all") plan = undefined;
    setUpdate({ ...update, plan });
  };

  return (
    <React.Fragment>
      <ModalHeader p={0} mb={4} fontSize={16} color="#333843" fontWeight={600}>
        Select Plan
      </ModalHeader>

      <ModalBody p={1} textAlign="center" mb={8} color="#444B59">
        <RadioGroup
          colorScheme="primary"
          onChange={handleChange}
          value={update.plan ? update.plan : "all"}
        >
          <Stack spacing={5} divider={<StackDivider borderColor="#F0F1F3" />}>
            <Radio value="all">All</Radio>
            <Radio value="freemium">Freemium</Radio>
            <Radio value="freelancer">Freelancer</Radio>
            <Radio value="premium">Premium</Radio>
          </Stack>
        </RadioGroup>
      </ModalBody>

      <Button size="lg" colorScheme="primary" onClick={handleApplyChange}>
        Apply
      </Button>
    </React.Fragment>
  );
};

const Advanced = (props: any) => {
  const dispatch = useAppDispatch();
  const filter = useAppSelector((state) => state.user.filter);
  const [update, setUpdate] = React.useState<Partial<UserFilter>>({
    q: "",
    plan: filter?.plan,
    status: filter.status,
    endDate: filter.endDate,
    country: filter.country,
    dateType: filter.dateType,
    startDate: filter.startDate,
    dateFormat: filter.dateFormat,
    showYearPicker: filter.showYearPicker,
    showMonthYearPicker: filter.showMonthYearPicker
  });

  const handleApplyChange = () => {
    dispatch(setUserFilter(update));
    props?.callback();
    props.onClose();
  };

  const handleDateTypeChange = (value: any, date?: any) => {
    const newUpdate: Partial<UserFilter> = { dateType: value };

    date = Boolean(date && new Date(date).getTime())
      ? new Date(date)
      : new Date();
    newUpdate.startDate = startOfDay(new Date(date));

    if (!value || value === "day") {
      newUpdate.showYearPicker = false;
      newUpdate.dateFormat = "dd/MM/yyyy";
      newUpdate.showMonthYearPicker = false;
      newUpdate.endDate = endOfDay(date).toISOString();
      newUpdate.startDate = startOfDay(date).toISOString();
    }

    if (value === "month") {
      newUpdate.dateFormat = "MM/yyyy";
      newUpdate.showYearPicker = false;
      newUpdate.showMonthYearPicker = true;
      newUpdate.endDate = endOfMonth(date).toISOString();
      newUpdate.startDate = startOfMonth(date).toISOString();
    }

    if (value === "year") {
      newUpdate.dateFormat = "yyyy";
      newUpdate.showYearPicker = true;
      newUpdate.showMonthYearPicker = false;
      newUpdate.endDate = endOfYear(date).toISOString();
      newUpdate.startDate = startOfYear(date).toISOString();
    }

    setUpdate({ ...update, ...newUpdate });
  };

  const handleDateChange = (date: Date) => {
    handleDateTypeChange(update.dateType, date);
  };

  const handlePlanChange = (plan: any) => {
    if (plan === "all") plan = undefined;
    setUpdate({ ...update, plan });
  };

  const handleCountryChange = (country?: string) => {
    if (country === "all") country = undefined;
    setUpdate({ ...update, country });
  };

  const handleStatusChange = (status: any) => {
    if (status === "active") status = false;
    if (status === "blocked") status = true;
    if (status === "all") status = undefined;
    setUpdate({ ...update, status });
  };

  return (
    <React.Fragment>
      <ModalBody p={1} mb={6} color="#444B59">
        <Stack
          spacing={8}
          direction="column"
          divider={<StackDivider borderColor="#F0F1F3" />}
        >
          <React.Fragment>
            <Text
              mb={6}
              fontSize={16}
              color="#333843"
              fontWeight={600}
              colorScheme="primary"
            >
              Date
            </Text>
            <Stack spacing={6}>
              <Select
                value={update.dateType}
                onChange={(e) => handleDateTypeChange(e.target.value)}
              >
                <option value="day">Day</option>
                <option value="month">Month</option>
                <option value="year">Year</option>
              </Select>

              <FormControl>
                <DatePicker
                  onChange={handleDateChange}
                  dateFormat={update.dateFormat}
                  showYearPicker={update.showYearPicker}
                  showMonthYearPicker={update.showMonthYearPicker}
                  selected={
                    new Date(update.startDate || startOfDay(new Date()))
                  }
                />
              </FormControl>
            </Stack>
          </React.Fragment>

          <React.Fragment>
            <Text
              mb={6}
              fontSize={16}
              color="#333843"
              fontWeight={600}
              colorScheme="primary"
            >
              Plan
            </Text>
            <Stack spacing={6}>
              <RadioGroup
                colorScheme="primary"
                onChange={handlePlanChange}
                value={update.plan ? update?.plan : "all"}
              >
                <Stack
                  spacing={5}
                  divider={<StackDivider borderColor="#F0F1F3" />}
                >
                  <Radio value="all">All</Radio>
                  <Radio value="freemium">Freemium</Radio>
                  <Radio value="freelancer">Freelancer</Radio>
                  <Radio value="premium">Premium</Radio>
                </Stack>
              </RadioGroup>
            </Stack>
          </React.Fragment>

          <React.Fragment>
            <Text
              mb={6}
              fontSize={16}
              color="#333843"
              fontWeight={600}
              colorScheme="primary"
            >
              Country
            </Text>
            <Stack spacing={6}>
              <RadioGroup
                colorScheme="primary"
                onChange={handleCountryChange}
                value={update.country ? update.country : "all"}
              >
                <Stack
                  spacing={5}
                  divider={<StackDivider borderColor="#F0F1F3" />}
                >
                  <Radio value="all">All</Radio>
                  {countries.map((country) => (
                    <Radio key={country} value={country} className="capitalize">
                      <span className="capitalize">{country}</span>
                    </Radio>
                  ))}
                </Stack>
              </RadioGroup>
            </Stack>
          </React.Fragment>

          <React.Fragment>
            <Text
              mb={6}
              fontSize={16}
              color="#333843"
              fontWeight={600}
              colorScheme="primary"
            >
              Status
            </Text>
            <RadioGroup
              colorScheme="primary"
              onChange={handleStatusChange}
              value={getUserStatus(update.status)}
            >
              <Stack
                spacing={5}
                divider={<StackDivider borderColor="#F0F1F3" />}
              >
                <Radio value="all">All</Radio>
                <Radio value="active">Active</Radio>
                <Radio value="blocked">Blocked</Radio>
              </Stack>
            </RadioGroup>
          </React.Fragment>
        </Stack>
      </ModalBody>

      <Stack direction="row" spacing={4}>
        <Button
          size="lg"
          width="100%"
          variant="outline"
          colorScheme="primary"
          onClick={props.onClose}
        >
          Cancel
        </Button>
        <Button
          size="lg"
          width="100%"
          colorScheme="primary"
          onClick={handleApplyChange}
        >
          Apply
        </Button>
      </Stack>
    </React.Fragment>
  );
};

export default UserFilterModal;
