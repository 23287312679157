import { createIcon } from "@chakra-ui/icon";

export const MessageSolidIcon = createIcon({
  viewBox: "0 0 24 24",
  displayName: "MessageSolidIcon",
  path: [
    <path
      d="M9.69928 19.039L9.69931 19.039L9.69564 19.0343C9.55281 18.8494 9.35354 18.7065 9.16111 18.6103C8.96956 18.5145 8.73469 18.44 8.5 18.44H7C4.51418 18.44 2.5 16.4319 2.5 13.96V12.96V6.98C2.5 4.5081 4.51418 2.5 7 2.5H17C19.4858 2.5 21.5 4.5081 21.5 6.98V13.96C21.5 16.4319 19.4858 18.44 17 18.44H15.5C15.0314 18.44 14.585 18.6649 14.3019 19.0375L14.3007 19.039L12.8007 21.029L12.8 21.03C12.5506 21.3626 12.2559 21.49 12 21.49C11.7441 21.49 11.4494 21.3626 11.2 21.03L11.1993 21.029L9.69928 19.039ZM7 14.25H13C13.6861 14.25 14.25 13.6861 14.25 13C14.25 12.3139 13.6861 11.75 13 11.75H7C6.31386 11.75 5.75 12.3139 5.75 13C5.75 13.6861 6.31386 14.25 7 14.25ZM7 9.25H17C17.6861 9.25 18.25 8.68614 18.25 8C18.25 7.31386 17.6861 6.75 17 6.75H7C6.31386 6.75 5.75 7.31386 5.75 8C5.75 8.68614 6.31386 9.25 7 9.25Z"
      fill="white"
      stroke="white"
    />
  ]
});
