/* AWS S3 config options */
/* Highly recommended to declare the config object in an external file import it when needed */

/* s3Config.ts */

export const s3Config = {
  bucketName: `${process.env.REACT_APP_DO_BUCKET_NAME}`,
  region: `${process.env.REACT_APP_DO_SPACES_REGION}`,
  accessKeyId: `${process.env.REACT_APP_DO_SPACES_ACCESS_KEY}`,
  secretAccessKey: `${process.env.REACT_APP_DO_SPACES_SECRET_ACCESS_KEY}`,
  s3Url: `${process.env.REACT_APP_DO_SPACES_URL}` /* Optional */
};

/* End of s3Config.ts */
