import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Stack,
  Text,
  Icon,
  Heading,
  Divider,
  Flex,
  Skeleton,
  Spinner
} from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import { BaseModalProps } from "views/containers/user-information/models";
import { ProfileCheckOutlineIcon } from "views/components/icons";
import { IoCalendarOutline } from "react-icons/io5";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useEffect, useState } from "react";
import { formatDateToUTC } from "data/utils/formatDateToUTC";
import { VerificationDetailParam } from "../models";
import { getAllVerification, getSingleVerification } from "app/store";
import verificationService from "data/services/verification-service";
import { VerificationStatus } from "app/store/models/verification";
import toast from "react-hot-toast";
import { AxiosError } from "axios";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";

const ApproveVerification = ({ isOpen, onClose }: BaseModalProps) => {
  const dispatch = useAppDispatch();
  const [userDetails, requestor, currentPage, pageSize] = useAppSelector(
    (state) => [
      state.verification.singleVerification,
      state.dashboard.userProfile,
      state.verification.allVerification.pagination?.currentPage,
      state.verification.allVerification.pagination?.pageSize
    ]
  );

  // url params
  let [searchParams, setSearchParams] = useSearchParams();
  const verificationDetailParamValue = searchParams.get("verification-detail");
  const userId = searchParams.get("verification-id");
  const verificationStatus = searchParams.get(
    "verification-status"
  ) as VerificationStatus;

  useEffect(() => {
    if (!!userId && !verificationDetailParamValue) {
      searchParams.set(
        "verification-detail",
        VerificationDetailParam.ATTACHMENTS
      );
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  //   form state
  const [loading, setLoading] = useState(false);

  const approveVerificationHandler = async () => {
    if (!userId || !currentPage || !pageSize || !verificationStatus) return;
    setLoading(true);
    try {
      const res = await verificationService.approveVerification(+userId);
      if (res.status === 200 || 201 || 202) {
        dispatch(getSingleVerification(+userId));
        dispatch(
          getAllVerification({
            page: currentPage,
            perpage: pageSize,
            status: verificationStatus
          })
        );
        toast.success(`Verification Successfully Approved`);
        onClose();
      }
      setLoading(false);
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      setLoading(false);
    }
  };

  // fetch data
  useEffect(() => {
    if (!userId) return;
    dispatch(getSingleVerification(+userId));
  }, [userId]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textTransform={"capitalize"}>
            Approve Verification
          </ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody>
            {userDetails.loading ? (
              <Flex
                p="5"
                bg="primary.500"
                color={"gray.300"}
                rounded="lg"
                direction="column"
                gap={"4"}
              >
                <Skeleton h="6" />
                <Skeleton h="6" />
                <Skeleton h="6" />
              </Flex>
            ) : null}
            {!!userDetails.data && !userDetails.loading ? (
              <Stack
                p="5"
                bg="primary.500"
                color={"gray.300"}
                rounded="lg"
                spacing={"4"}
              >
                <Stack direction={"row"} justifyContent="space-between">
                  <Stack direction={"row"} alignItems="center">
                    <Icon as={ProfileCheckOutlineIcon} h={5} w={5} />
                    <Text>Approved By</Text>
                  </Stack>
                  <Text>
                    {requestor
                      ? `${requestor.firstName} ${requestor.lastName}`
                      : "--"}
                  </Text>
                </Stack>
                <Stack direction={"row"} justifyContent="space-between">
                  <Stack direction={"row"} alignItems="center">
                    <Icon as={IoCalendarOutline} h={5} w={5} />
                    <Text>Date Registered</Text>
                  </Stack>
                  <Text>
                    {userDetails.data?.createdAt
                      ? formatDateToUTC(userDetails.data.createdAt)
                      : "--"}
                  </Text>
                </Stack>
              </Stack>
            ) : null}
            <Heading
              as={"h4"}
              fontSize="24px"
              fontFamily={"karla"}
              my="5"
              fontWeight={"extrabold"}
            >
              Request Details
            </Heading>
            <Text color={"gray.600"}>Request</Text>
            <Text
              fontWeight={"medium"}
              color={"success.500"}
              textTransform="capitalize"
            >
              Approve Verification
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button
              size={"lg"}
              colorScheme="green"
              px="6"
              w={"full"}
              onClick={approveVerificationHandler}
            >
              {loading ? <Spinner /> : "Approve Verification"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ApproveVerification;
