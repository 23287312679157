import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Stack,
  Text,
  Icon,
  Heading,
  FormControl,
  FormLabel,
  Textarea,
  Divider,
  Flex,
  Skeleton,
  Spinner
} from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import { BaseModalProps } from "views/containers/user-information/models";
import {
  MessageOutlineIcon,
  ProfileCheckOutlineIcon
} from "views/components/icons";
import { IoCalendarOutline } from "react-icons/io5";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useEffect, useState } from "react";
import { getAllFiatWallet, getSingleFiatWallet } from "app/store";
import { formatDateToUTC } from "data/utils/formatDateToUTC";
import walletService from "data/services/wallet-service";
import { AxiosError } from "axios";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";
import toast from "react-hot-toast";

const BanWalletActions = ({ isOpen, onClose }: BaseModalProps) => {
  const dispatch = useAppDispatch();
  const [fiatWallet, requestor, currentPage, pageSize] = useAppSelector(
    (state) => [
      state.wallet.singleFiatWallet,
      state.dashboard.userProfile,
      state.wallet.allFiatWallet.pagination?.currentPage,
      state.wallet.allFiatWallet.pagination?.pageSize
    ]
  );

  // tab and url controllers
  let [searchParams] = useSearchParams();
  let banAction = searchParams.get("ban-wallet-action");
  const walletId = searchParams.get("wallet-id");
  const userId = searchParams.get("user-id");

  // form state
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);

  const toggleFiatWalletHandler = async () => {
    if (!walletId || !currentPage || !pageSize || !fiatWallet.data) return;
    setLoading(true);
    const status = !fiatWallet.data.active ? "activate" : "deactivate";
    try {
      const res = await walletService.toggleFiatWallet(
        +walletId,
        {
          reason: reason
        },
        status
      );
      if (res.status === 200 || 201 || 202) {
        dispatch(
          getAllFiatWallet({
            page: currentPage,
            perpage: pageSize,
            ...(userId && { userId: +userId })
          })
        );
        toast.success(`Wallet Successfully ${status}d`);
        onClose();
      }
      setLoading(false);
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      setLoading(false);
    }
  };

  // get wallet details
  useEffect(() => {
    if (!walletId) return;
    dispatch(getSingleFiatWallet(+walletId));
  }, [walletId]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="outside">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textTransform={"capitalize"}>
            {banAction} Wallet
          </ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody>
            {fiatWallet.loading ? (
              <Flex
                p="5"
                bg="primary.500"
                color={"gray.300"}
                rounded="lg"
                direction="column"
                gap={"4"}
              >
                <Skeleton h="6" />
                <Skeleton h="6" />
                <Skeleton h="6" />
              </Flex>
            ) : null}
            {!!fiatWallet.data && !fiatWallet.loading ? (
              <Stack
                p="5"
                bg="primary.500"
                color={"gray.300"}
                rounded="lg"
                spacing={"4"}
              >
                <Stack direction={"row"} justifyContent="space-between">
                  <Stack direction={"row"} alignItems="center">
                    <Icon as={ProfileCheckOutlineIcon} h={5} w={5} />
                    <Text>Requestor</Text>
                  </Stack>
                  <Text>
                    {requestor
                      ? `${requestor.firstName} ${requestor.lastName}`
                      : "--"}
                  </Text>
                </Stack>
                <Stack direction={"row"} justifyContent="space-between">
                  <Stack direction={"row"} alignItems="center">
                    <Icon as={MessageOutlineIcon} h={5} w={5} />
                    <Text>Wallet Id</Text>
                  </Stack>
                  <Text>{walletId}</Text>
                </Stack>
                <Stack direction={"row"} justifyContent="space-between">
                  <Stack direction={"row"} alignItems="center">
                    <Icon as={IoCalendarOutline} h={5} w={5} />
                    <Text>Date Requested</Text>
                  </Stack>
                  <Text>
                    {fiatWallet.data?.createdAt
                      ? formatDateToUTC(fiatWallet.data.createdAt)
                      : "--"}
                  </Text>
                </Stack>
              </Stack>
            ) : null}
            <Heading
              as={"h4"}
              fontSize="24px"
              fontFamily={"karla"}
              my="5"
              fontWeight={"extrabold"}
            >
              Request Details
            </Heading>
            <Text color={"gray.600"}>Request</Text>
            <Text
              fontWeight={"medium"}
              color={banAction === "disable" ? "error.500" : "success.500"}
              textTransform="capitalize"
            >
              {banAction} Wallet
            </Text>
            <Stack my="5" spacing={"4"}>
              <FormControl>
                <FormLabel color={"gray.600"} fontWeight="400">
                  Reason for Deactivation
                </FormLabel>
                <Textarea
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                />
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter>
            {banAction === "disable" ? (
              <Button
                size={"lg"}
                colorScheme="red"
                px="6"
                w={"full"}
                onClick={toggleFiatWalletHandler}
              >
                {loading ? <Spinner /> : banAction}
              </Button>
            ) : (
              <Button
                size={"lg"}
                colorScheme="green"
                px="6"
                w={"full"}
                onClick={toggleFiatWalletHandler}
              >
                {loading ? <Spinner /> : banAction}
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default BanWalletActions;
