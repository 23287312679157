import React from "react";
import WalletChart from "./wallets-chart";
import { useAppSelector } from "app/hooks";
import TransactionChart from "./transaction-chart";
import { ISummary, TransactionTime } from "app/store/models/summary";
import { Box, Grid, GridItem, Heading, Stack } from "@chakra-ui/react";

const Graphs = () => {
  const [label, setLabel] = React.useState<any>([]);
  const [dataset, setDataset] = React.useState<any>([]);
  const [variant, setVariant] = React.useState(TransactionTime.yearly);
  const raw: ISummary = useAppSelector((state) => state.summary?.data) as any;

  const handleVariantChange = (value: TransactionTime) => {
    const newData: any = [];
    const newLabel: any = [];
    const dt = raw ? raw[value] : [];

    dt?.forEach((data) => {
      newData.push(data.total);

      if (value === TransactionTime.daily) newLabel.push(data.day);
      if (value === TransactionTime.yearly) newLabel.push(data.year);
      if (value === TransactionTime.weekly) newLabel.push(data.Week);
      if (value === TransactionTime.monthly) newLabel.push(data.month);
    });

    setVariant(value);
    setDataset(newData);
    setLabel(newLabel);
  };

  return (
    <Grid
      mb={6}
      gap={6}
      templateColumns={["repeat(1, 1fr)", "repear(1, 1fr)", "55fr 45fr"]}
    >
      <GridItem>
        <Box
          p={[4, 6]}
          display="flex"
          bgColor="#fff"
          borderRadius="10px"
          flexDirection="column"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Heading
              color="gray.900"
              fontWeight={700}
              lineHeight="28px"
              fontFamily="Karla"
              fontSize={[20, 24]}
            >
              Transactions
            </Heading>

            <ToggleButtons variant={variant} onChange={handleVariantChange} />
          </Box>
          <Box height="300px" display="flex" flexDirection="column">
            <TransactionChart data={dataset} labels={label} />
          </Box>
        </Box>
      </GridItem>
      <GridItem>
        <Box
          p={[4, 6]}
          display="flex"
          bgColor="#fff"
          borderRadius="10px"
          flexDirection="column"
        >
          <Box>
            <Heading
              color="gray.900"
              fontWeight={700}
              lineHeight="28px"
              fontFamily="Karla"
              fontSize={[20, 24]}
            >
              Created Wallets
            </Heading>
          </Box>
          <Box height="300px" py="10px" display="flex" flexDirection="column">
            <WalletChart />
          </Box>
        </Box>
      </GridItem>
    </Grid>
  );
};

const ToggleButtons = ({ variant, onChange }: any) => (
  <Stack direction="row">
    <ToggleButton
      active={variant === TransactionTime.daily}
      onChange={() => onChange(TransactionTime.daily)}
    >
      1D
    </ToggleButton>
    <ToggleButton
      active={variant === TransactionTime.weekly}
      onChange={() => onChange(TransactionTime.weekly)}
    >
      1W
    </ToggleButton>
    <ToggleButton
      active={variant === TransactionTime.monthly}
      onChange={() => onChange(TransactionTime.monthly)}
    >
      1M
    </ToggleButton>
    <ToggleButton
      active={variant === TransactionTime.yearly}
      onChange={() => onChange(TransactionTime.yearly)}
    >
      1Y
    </ToggleButton>
  </Stack>
);

const ToggleButton = ({ active, ...props }: any) => (
  <Box
    px="8px"
    py="4px"
    width="31px"
    height="22px"
    fontSize={12}
    flexShrink={0}
    display="flex"
    cursor="pointer"
    borderRadius="4px"
    alignItems="center"
    justifyContent="center"
    onClick={props.onChange}
    color={active ? "gray.200" : "#808899"}
    bgColor={active ? "success.600" : "transparent"}
    boxShadow={active ? "0px 0px 16px rgba(8, 12, 26, 0.15)" : null}
    {...props}
  />
);

export default Graphs;
