import {
  IWishList,
  IWishListItem,
  IWishListState,
  IValentineWishlistItem,
  IValentineWishlist,
  IValentineWishListUsers
} from "../models/wishlist";
import { IPagination } from "data/models/base";
import { PaginationState } from "../models/fx";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const pagination: PaginationState = {
  next: 0,
  total: 0,
  prevPage: 0,
  lastPage: 0,
  pageSize: 10,
  hasNext: false,
  currentPage: 1,
  hasPrevious: false
};

const initialState: IWishListState = {
  denyWishList: { loading: false, error: "" },
  approveWishList: { loading: false, error: "" },
  getSingleWishList: { loading: false, error: "" },
  updateWishListItems: { loading: false, error: "" },
  createWishListItems: { loading: false, error: "" },
  getWishList: { data: [], pagination, loading: false, error: "" },
  getWishListItems: {
    data: [],
    pagination,
    loading: false,
    error: "",
    oldUsers: [],
    newUsers: []
  },

  addValentineWishListItems: { loading: false, error: "" },
  updateValentineWishListItem: { loading: false, error: "" },
  deleteValentineWishListItem: { loading: false, error: "" },
  approveValentineWishListWinner: { loading: false, error: "" },
  getValentineWishList: { loading: false, error: "", data: null },
  getValentineWishListWinners: {
    loading: false,
    error: "",
    data: [],
    pagination
  },
  getValentineWishListTopUsers: {
    loading: false,
    error: "",
    data: [],
    pagination
  },
  getValentineWishListItems: {
    loading: false,
    error: "",
    data: [],
    pagination
  }
};

const WishListSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {
    getWishListBegin: (state) => {
      state.getWishList.loading = true;
      state.getWishList.error = "";
      state.getWishList.data = [];
    },
    getWishListSuccess: (
      state,
      action: PayloadAction<{ data: IWishList[]; pagination: IPagination }>
    ) => {
      state.getWishList.data = action.payload.data;
      state.getWishList.pagination = action.payload.pagination;
      state.getWishList.loading = false;
      state.getWishList.error = "";
    },
    getWishListFailed: (state, action: PayloadAction<string>) => {
      state.getWishList.loading = false;
      state.getWishList.error = action.payload;
    },
    getWishListItemsBegin: (state) => {
      state.getWishListItems.loading = true;
      state.getWishListItems.error = "";
      state.getWishListItems.data = [];
    },
    getWishListListItemsSuccess: (
      state,
      action: PayloadAction<{
        userType: string;
        data: IWishListItem[];
        pagination: IPagination;
      }>
    ) => {
      const { data, userType, pagination } = action.payload;
      state.getWishListItems.data = data;
      state.getWishListItems.pagination = pagination;
      state.getWishListItems.loading = false;
      state.getWishListItems.error = "";
      if (userType === "OLD_USER") state.getWishListItems.oldUsers = data;
      if (userType === "NEW_USER") state.getWishListItems.newUsers = data;
    },
    getWishListItemsFailed: (state, action: PayloadAction<string>) => {
      state.getWishListItems.loading = false;
      state.getWishListItems.error = action.payload;
    },
    getSingleWishListBegin: (state) => {
      state.getSingleWishList.error = "";
      state.getSingleWishList.loading = true;
      state.getSingleWishList.data = undefined;
    },
    getSingleWishListSuccess: (state, action: PayloadAction<IWishList>) => {
      state.getSingleWishList.error = "";
      state.getSingleWishList.loading = false;
      state.getSingleWishList.data = action.payload;
    },
    getSingleWishListFailed: (state, action: PayloadAction<string>) => {
      state.getSingleWishList.loading = false;
      state.getSingleWishList.error = action.payload;
    },
    createWishListItemsBegin: (state) => {
      state.createWishListItems.error = "";
      state.createWishListItems.loading = true;
    },
    createWishListItemsSuccess: (state) => {
      state.createWishListItems.loading = false;
      state.createWishListItems.error = "";
    },
    createWishListItemsFailed: (state, action: PayloadAction<string>) => {
      state.createWishListItems.loading = false;
      state.createWishListItems.error = action.payload;
    },
    updateWishListItemsBegin: (state) => {
      state.updateWishListItems.error = "";
      state.updateWishListItems.loading = true;
    },
    updateWishListItemsSuccess: (state) => {
      state.updateWishListItems.loading = false;
      state.updateWishListItems.error = "";
    },
    updateWishListItemsFailed: (state, action: PayloadAction<string>) => {
      state.updateWishListItems.loading = false;
      state.updateWishListItems.error = action.payload;
    },
    approveWishListBegin: (state) => {
      state.approveWishList.error = "";
      state.approveWishList.loading = true;
    },
    approveWishListSuccess: (state) => {
      state.approveWishList.loading = false;
      state.approveWishList.error = "";
    },
    approveWishListFailed: (state, action: PayloadAction<string>) => {
      state.approveWishList.loading = false;
      state.approveWishList.error = action.payload;
    },
    denyWishListBegin: (state) => {
      state.denyWishList.error = "";
      state.denyWishList.loading = true;
    },
    denyWishListSuccess: (state) => {
      state.denyWishList.loading = false;
      state.denyWishList.error = "";
    },
    denyWishListFailed: (state, action: PayloadAction<string>) => {
      state.denyWishList.loading = false;
      state.denyWishList.error = action.payload;
    },
    getValentineWishListBegin: (state) => {
      state.getValentineWishList.error = "";
      state.getValentineWishList.loading = true;
    },
    getValentineWishListSuccess: (
      state,
      action: PayloadAction<{ data: IValentineWishlist }>
    ) => {
      state.getValentineWishList.error = "";
      state.getValentineWishList.loading = false;
      state.getValentineWishList.data = action.payload.data;
    },
    getValentineWishListFailed: (state, action: PayloadAction<string>) => {
      state.getValentineWishList.loading = false;
      state.getValentineWishList.error = action.payload;
    },
    updateValentineWishListItemsBegin: (state) => {
      state.updateValentineWishListItem.error = "";
      state.updateValentineWishListItem.loading = true;
    },
    updateValentineWishListItemsSuccess: (state) => {
      state.updateValentineWishListItem.loading = false;
      state.updateValentineWishListItem.error = "";
    },
    updateValentineWishListItemsFailed: (
      state,
      action: PayloadAction<string>
    ) => {
      state.updateValentineWishListItem.loading = false;
      state.updateValentineWishListItem.error = action.payload;
    },
    getValentineWishListItemsBegin: (state) => {
      state.getValentineWishListItems.loading = true;
      state.getValentineWishListItems.error = "";
      state.getValentineWishListItems.data = [];
    },
    getValentineWishListListItemsSuccess: (
      state,
      action: PayloadAction<{
        pagination: IPagination;
        data: IValentineWishlistItem[];
      }>
    ) => {
      const { data, pagination } = action.payload;
      state.getValentineWishListItems.error = "";
      state.getValentineWishListItems.data = data;
      state.getValentineWishListItems.loading = false;
      state.getValentineWishListItems.pagination = pagination;
    },
    getValentineWishListItemsFailed: (state, action: PayloadAction<string>) => {
      state.getValentineWishListItems.loading = false;
      state.getValentineWishListItems.error = action.payload;
    },
    addValentineWishListItemsBegin: (state) => {
      state.addValentineWishListItems.error = "";
      state.addValentineWishListItems.loading = true;
    },
    addValentineWishListItemsSuccess: (state) => {
      state.addValentineWishListItems.loading = false;
      state.addValentineWishListItems.error = "";
    },
    addValentineWishListItemsFailed: (state, action: PayloadAction<string>) => {
      state.addValentineWishListItems.loading = false;
      state.addValentineWishListItems.error = action.payload;
    },
    selectValentineWishListWinnerBegin: (state) => {
      state.approveValentineWishListWinner.error = "";
      state.approveValentineWishListWinner.loading = true;
    },
    selectValentineWishListWinnerSuccess: (state) => {
      state.approveValentineWishListWinner.loading = false;
      state.approveValentineWishListWinner.error = "";
    },
    selectValentineWishListWinnerFailed: (
      state,
      action: PayloadAction<string>
    ) => {
      state.approveValentineWishListWinner.loading = false;
      state.approveValentineWishListWinner.error = action.payload;
    },
    getValentineWishListTopUsersBegin: (state) => {
      state.getValentineWishListTopUsers.loading = true;
      state.getValentineWishListTopUsers.error = "";
      state.getValentineWishListTopUsers.data = [];
    },
    getValentineWishListListTopUsersSuccess: (
      state,
      action: PayloadAction<{
        pagination: IPagination;
        data: IValentineWishListUsers[];
      }>
    ) => {
      const { data, pagination } = action.payload;
      state.getValentineWishListTopUsers.error = "";
      state.getValentineWishListTopUsers.data = data;
      state.getValentineWishListTopUsers.loading = false;
      state.getValentineWishListTopUsers.pagination = pagination;
    },
    getValentineWishListTopUsersFailed: (
      state,
      action: PayloadAction<string>
    ) => {
      state.getValentineWishListTopUsers.loading = false;
      state.getValentineWishListTopUsers.error = action.payload;
    },
    getValentineWishListSelectedWinnersBegin: (state) => {
      state.getValentineWishListWinners.loading = true;
      state.getValentineWishListWinners.error = "";
    },
    getValentineWishListListWinnersSuccess: (
      state,
      action: PayloadAction<{
        pagination: IPagination;
        data: IValentineWishListUsers[];
      }>
    ) => {
      const { data, pagination } = action.payload;
      state.getValentineWishListWinners.error = "";
      state.getValentineWishListWinners.data = data;
      state.getValentineWishListWinners.loading = false;
      state.getValentineWishListWinners.pagination = pagination;
    },
    getValentineWishListWinnersFailed: (
      state,
      action: PayloadAction<string>
    ) => {
      state.getValentineWishListWinners.loading = false;
      state.getValentineWishListWinners.error = action.payload;
    }
  }
});

export const actions = WishListSlice.actions;

export default WishListSlice.reducer;
