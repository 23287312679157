import React from "react";
import {
  Box,
  Text,
  Modal,
  Select,
  Button,
  Divider,
  Textarea,
  ModalBody,
  FormLabel,
  ModalHeader,
  FormControl,
  ModalFooter,
  ModalOverlay,
  ModalContent,
  ModalCloseButton
} from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { processSendMoney } from "app/store";
import { ProcessSendMoneyStatus } from "app/store/models/request";

const SendMoneyModalDetails = ({ data, onClose }: any) => {
  const dispatch: any = useAppDispatch();
  const [reason, setReason] = React.useState("");
  const [status, setStatus] = React.useState<any>(ProcessSendMoneyStatus.deny);
  const [sendMoneyData, loading] = useAppSelector((state) => [
    state.request.sendMoney,
    state.request.status.processSendMoney
  ]);

  const handleSubmit = () => {
    if (data.status !== "pending" && data.status !== "processing") return;
    dispatch(
      processSendMoney(data.id, { status, reason }, sendMoneyData, dispatch)
    );
    onClose();
  };

  return (
    <Modal isOpen={Boolean(data)} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          fontSize={24}
          lineHeight="28px"
          fontWeight={700}
          fontFamily="Karla"
          color="primary.500"
        >
          {status === ProcessSendMoneyStatus.approve ? "Approve" : "Decline"}{" "}
          Withdrawal
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text color="gray.500" lineHeight="19px" mt={4}>
            Request
          </Text>
          <Text
            color={
              status === ProcessSendMoneyStatus.approve
                ? "success.500"
                : "error.500"
            }
            lineHeight="19px"
            fontWeight={500}
            mt={2}
          >
            {status === ProcessSendMoneyStatus.approve ? "Approve" : "Decline"}{" "}
            Withdrawal
          </Text>

          <Box mt={6} display="flex" gap={4} flexDirection="column">
            <FormControl>
              <FormLabel
                mb={1}
                color="gray.600"
                lineHeight="24px"
                fontSize={14}
                fontWeight={400}
              >
                Status
              </FormLabel>
              <Select
                height="44px"
                value={status}
                colorScheme="primary.500"
                focusBorderColor="primary.500"
                onChange={(e) => setStatus(e.target.value)}
                disabled={
                  data?.status !== "pending" && data?.status !== "processing"
                }
              >
                <option value={ProcessSendMoneyStatus.approve}>Approve</option>
                <option value={ProcessSendMoneyStatus.deny}>Decline</option>
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel
                mb={1}
                fontSize={14}
                color="gray.600"
                lineHeight="24px"
                fontWeight={400}
              >
                Please provide more information
              </FormLabel>
              <Textarea
                value={reason}
                minHeight="120px"
                focusBorderColor="primary.500"
                onChange={(e) => setReason(e.target.value)}
                disabled={status === ProcessSendMoneyStatus.approve}
              />
            </FormControl>
          </Box>
        </ModalBody>

        <Divider mt={4} mb={1} />

        <ModalFooter>
          {status === ProcessSendMoneyStatus.deny ? (
            <Button
              mr={3}
              width="full"
              height="56px"
              colorScheme="error"
              onClick={handleSubmit}
              disabled={
                data?.status !== "pending" && data?.status !== "processing"
              }
              isLoading={loading === "loading"}
            >
              Decline Withdrawal
            </Button>
          ) : (
            <Button
              mr={3}
              width="full"
              height="56px"
              colorScheme="success"
              onClick={handleSubmit}
              disabled={
                data?.status !== "pending" && data?.status !== "processing"
              }
              isLoading={loading === "loading"}
            >
              Approve Withdrawal
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SendMoneyModalDetails;
