import AllMerchantsTable from "./all-merchants-table";
import { Box, Tabs, TabList, Tab, TabPanel, TabPanels } from "@chakra-ui/react";
import { useAppSelector } from "app/hooks";

const InformationTable = () => {
  const total = useAppSelector(
    (state) => state.bitmamaMerchants.allMerchants.pagination?.total
  );

  return (
    <Tabs colorScheme="primary.500">
      <TabList>
        <Tab>
          <span>All Merchants</span>
          <Box
            ml={2}
            fontSize={12}
            color="blue.500"
            display="inline"
            fontWeight={400}
            lineHeight="14px"
          >
            <span>{total || 0}</span>
          </Box>
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel>
          <AllMerchantsTable />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default InformationTable;
