import {
  IBank,
  IBitmamaMerchant,
  IBitmamaMerchantState,
  IMerchantTransaction,
  IMerchantWithdrawalTransaction
} from "../models/bitmama-merchant";
import { PaginationState } from "../models/wallets";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const pagination = {
  next: 2,
  total: 0,
  prevPage: 0,
  lastPage: 0,
  pageSize: 10,
  hasNext: true,
  currentPage: 1,
  hasPrevious: false
};

const initialState: IBitmamaMerchantState = {
  withdraw: { loading: false },
  banks: { data: [], loading: false, pagination },
  allMerchants: { data: [], loading: false, pagination },
  paymentTransactions: { data: [], loading: false, pagination },
  withdrawalTransactions: { data: [], loading: false, pagination },

  resolvedBank: {
    pagination,
    loading: false,
    data: { account_name: "", account_number: "" }
  }
};

export const BitmamaMerchantSlice = createSlice({
  initialState,
  name: "bitmama-merchant",
  reducers: {
    getBanksLoading: (state) => {
      state.banks.loading = true;
    },

    withdrawLoading: (state) => {
      state.withdraw.loading = true;
    },

    getAllMerchantsLoading: (state) => {
      state.allMerchants.loading = true;
    },

    resolveBankNameLoading: (state) => {
      state.resolvedBank.loading = true;
    },

    getAllMerchantPaymentTransactionsLoading: (state) => {
      state.paymentTransactions.loading = true;
    },

    getAllMerchantWithdrawalTransactionsLoading: (state) => {
      state.withdrawalTransactions.loading = true;
    },

    withdrawFailed: (state) => {
      state.withdraw.loading = false;
    },

    getBanksFailed: (state) => {
      state.resolvedBank.loading = false;
    },

    getAllMerchantsFailed: (state) => {
      state.allMerchants.loading = false;
    },

    resolveBankNameFailed: (state) => {
      state.resolvedBank.loading = false;
      state.resolvedBank.data = { account_name: "", account_number: "" };
    },

    getAllMerchantPaymentTransctionsFailed: (state) => {
      state.paymentTransactions.loading = false;
    },

    getAllMerchantWithdrawalTransactionsFailed: (state) => {
      state.withdrawalTransactions.loading = false;
    },

    withdrawSuccess: (state) => {
      state.withdraw.loading = false;
    },

    getBankSuccess: (state, action: PayloadAction<{ data: IBank[] }>) => {
      state.banks.loading = false;
      state.banks.data = action.payload.data;
    },

    resolveBankNameSuccess: (state, action) => {
      state.resolvedBank.loading = false;
      state.resolvedBank.data = action.payload.data;
    },

    getAllMerchantsSuccess: (
      state,
      action: PayloadAction<{
        data: IBitmamaMerchant[];
        pagination: PaginationState;
      }>
    ) => {
      state.allMerchants.loading = false;
      state.allMerchants.data = action.payload.data;
      state.allMerchants.pagination = action.payload.pagination;
    },

    getAllMerchantPaymentTransactionsSuccess: (
      state,
      action: PayloadAction<{
        pagination: PaginationState;
        data: IMerchantTransaction[];
      }>
    ) => {
      state.paymentTransactions.loading = false;
      state.paymentTransactions.data = action.payload.data;
      state.paymentTransactions.pagination = action.payload.pagination;
    },

    getAllMerchantWithdrawalTransactionsSuccess: (
      state,
      action: PayloadAction<{
        pagination: PaginationState;
        data: IMerchantWithdrawalTransaction[];
      }>
    ) => {
      state.withdrawalTransactions.loading = false;
      state.withdrawalTransactions.data = action.payload.data;
      state.withdrawalTransactions.pagination = action.payload.pagination;
    }
  }
});

export const actions = BitmamaMerchantSlice.actions;

export default BitmamaMerchantSlice.reducer;
