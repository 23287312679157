import {
  Avatar,
  Box,
  Button,
  Heading,
  Stack,
  StackDivider,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Text,
  Tr,
  Select,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Input,
  useDisclosure,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuItem,
  MenuList,
  MenuButton,
  IconButton
} from "@chakra-ui/react";
import { ReactComponent as Success } from "app/assets/svg/success.svg";
import { debounce } from "lodash";
import { Tabs, TabList, Tab } from "@chakra-ui/react";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { ReactComponent as ProfileDeleteIcon } from "app/assets/svg/profile-delete.svg";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  unassignTeamMember,
  deactivateTeamMember,
  getAllRoles,
  getAllTeams,
  updateRole,
  resetCreateTeamSuccess
} from "app/store/action-creators/team-management.actions";
import React, { useEffect, useMemo, useState } from "react";
import NoData from "views/components/no-data";

import TeamTableSkeletion from "./skeleton";
import { usePagination } from "@ajna/pagination";
import { FiSearch } from "react-icons/fi";
import PaginationWrapper from "views/components/PaginationWrapper/PaginationWrapper";
import AddMember from "./add-member";
import { formatFullDateToUsersLocale } from "data/utils/date-formatter-users-locale";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { TeamManagementResponse } from "app/store/models/team-management";
import { ProfileRemove } from "iconsax-react";

const head = ["Members", "Role", "Date Joined", "Status", "Action"];

const TeamManagementContainer = () => {
  const { isOpen, onOpen, onClose: onCloseModal } = useDisclosure();
  const {
    isOpen: isDeactivateOpen,
    onOpen: isDeactivatOnOpen,
    onClose: isDeactivateOnClose
  } = useDisclosure();
  const {
    isOpen: isAddOpen,
    onOpen: onAddOpen,
    onClose: onAddClose
  } = useDisclosure();
  const [user, setUser] = React.useState<null | TeamManagementResponse>(null);
  const allTeams = useAppSelector((state) => state.teamManagement.allTeams);
  const userId = useAppSelector((state) => state.dashboard.userProfile?.id);
  const roles = useAppSelector((state) => state.teamManagement.roles);
  const creatingTeam = useAppSelector(
    (state) => state.teamManagement.creatingTeamMember
  );
  const loading = useAppSelector((state) => state.teamManagement.loading);
  const createdTeamMember = useAppSelector(
    (state) => state.teamManagement.createdTeamMember
  );
  const [pageSize, setPageSize] = useState(10);
  const dispatch = useAppDispatch();
  const [values, setValues] = useState({
    email: "",
    role: "user"
  });
  const handleChange =
    (name: any) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      setValues({ ...values, [name]: e.target.value });
    };

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, offset } =
    usePagination({
      initialState: { currentPage: 1, pageSize },
      total: allTeams.pagination?.total,
      limits: {
        inner: 2,
        outer: 1
      }
    });

  const handleSize = (e: any) => {
    setPageSize(e.target.value);
  };

  const handleCloseAddSuccessModal = () => {
    onAddClose();
    setUser(null);
    onCloseModal();
    setValues({ email: "", role: "user" });
    dispatch(resetCreateTeamSuccess());
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;

    if (query.length >= 3) {
      setSearchString(query);
      setCurrentPage(1);
    } else {
      setSearchString("");
      setCurrentPage(1);
    }
  };

  const debouncedChangeHandler = useMemo(() => {
    return debounce(handleSearch, 1000);
  }, []);

  const [searchString, setSearchString] = useState("");
  const handlePageChange = (nextPage: number) => {
    setCurrentPage(nextPage);
    setTimeout(() => {
      window.scrollTo({ top: 10, behavior: "smooth" });
    }, 500);
  };

  const handleOpenDeactivateModal = (currentUser: TeamManagementResponse) => {
    setUser(currentUser);
    isDeactivatOnOpen();
  };

  const handleOpenUnassignModal = (currentUser: TeamManagementResponse) => {
    setUser(currentUser);
    onOpen();
  };

  const handleCloseDeactivateModal = () => {
    setUser(null);
    isDeactivateOnClose();
    dispatch(resetCreateTeamSuccess());
  };

  const handleCloseUnassignModal = () => {
    setUser(null);
    onCloseModal();
    dispatch(resetCreateTeamSuccess());
  };

  useEffect(() => {
    dispatch(
      getAllTeams({ page: currentPage, perpage: pageSize, q: searchString })
    );
  }, [currentPage, pageSize, searchString]);
  useEffect(() => {
    dispatch(getAllRoles());
  }, []);
  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const email = !!user ? user?.email : values.email;
    const body = { ...values, email };
    dispatch(unassignTeamMember(body, dispatch));
  };

  const deactivateTeamMate = async (id: string) => {
    const callback = () => {
      isDeactivateOnClose();
      setUser(null);
    };
    dispatch(deactivateTeamMember(id, dispatch, callback));
  };

  return (
    <Box>
      <Breadcrumb mb={4}>
        <BreadcrumbItem>
          <BreadcrumbLink href="/">Home</BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink href="/team-management">Team Settings</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Box
        p={[4, 6]}
        display="flex"
        bgColor="#fff"
        borderRadius="10px"
        flexDirection="column"
      >
        <Box mb={6}>
          <div className="flex items-center justify-between mt-3">
            <Stack
              spacing={2}
              mb={[4, 4, 4, 0]}
              direction="row"
              alignItems="center"
              divider={<StackDivider borderColor="#F0F1F3" />}
            >
              <div>
                <Heading
                  color="gray.900"
                  fontWeight={700}
                  lineHeight="28px"
                  fontFamily="Karla"
                  fontSize={[20, 24]}
                >
                  Team Settings
                </Heading>

                <p className="text-gray-700">
                  Create as many roles and assign to users, but there can only
                  be one Super Admin - You.
                </p>
              </div>
            </Stack>
            <Stack direction={"row"}>
              <Button
                className="flex items-center bg-primary-600 mdx:p-6"
                textColor="#fff"
                colorScheme="primary"
                border="1px"
                padding={3}
                onClick={onAddOpen}
              >
                Add Member
              </Button>
              <Button
                className="flex items-center bg-primary-600 mdx:p-6"
                textColor="#fff"
                colorScheme="primary"
                border="1px"
                padding={3}
                onClick={onOpen}
              >
                Unassign Member
              </Button>
            </Stack>
          </div>
        </Box>

        <Box className="mt-3">
          <Tabs colorScheme="primary.500">
            <TabList>
              <Tab>
                <span>Members</span>
              </Tab>
              <Tab>
                <span>Permission Groups</span>
              </Tab>
            </TabList>
          </Tabs>
        </Box>

        <Box display="flex" flexDirection="column" className="mt-2">
          <TableContainer>
            <Stack mt="0.75rem" ml="2" spacing="4" direction={"row"}>
              <Box mb="16px">
                <InputGroup width="256px">
                  <Input
                    width="256px"
                    paddingLeft="40px"
                    minH="40px"
                    placeholder={
                      "Search by reference or groupt transaction reference"
                    }
                    border="1px solid #EDEBF9"
                    onChange={debouncedChangeHandler}
                    borderRadius="10px"
                    fontSize="12px"
                    _focus={{
                      border: "1px solid #EDEBF9"
                    }}
                    _hover={{
                      border: "1px solid #EDEBF9"
                    }}
                  />

                  <InputLeftElement
                    h="100%"
                    mr="12px"
                    children={<FiSearch color="#A0A3BD" />}
                  />
                </InputGroup>
              </Box>
              <Box mb="12px">
                <Select
                  width="120px"
                  placeholder="Size"
                  defaultValue={"Size"}
                  onChange={handleSize}
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                </Select>
              </Box>
            </Stack>
            <Table variant="striped" colorScheme="gray">
              <Thead>
                <Tr>
                  {head.map((data) => (
                    <Th
                      key={data}
                      fontSize={16}
                      color="gray.800"
                      fontWeight={500}
                      lineHeight="19px"
                      letterSpacing="0px"
                      textTransform="unset"
                    >
                      {data}
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {loading ? <TeamTableSkeletion /> : null}
                {!!allTeams.data.length && !loading
                  ? allTeams.data.map((el, idx) => (
                      <Tr key={idx}>
                        <Td>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={3}
                          >
                            <Avatar
                              size="xl"
                              width="32px"
                              height="32px"
                              showBorder={false}
                              borderRadius="12px"
                              src="https://bit.ly/kent-c-dodds"
                            />

                            <Stack>
                              <Text color="#212327">
                                {el.firstName} {""} {el.lastName}
                              </Text>
                              <Text
                                fontSize={"12px"}
                                lineHeight={"1.2"}
                                color="gray.600"
                                marginTop={0}
                              >
                                {el.email}
                              </Text>
                            </Stack>
                          </Stack>
                        </Td>
                        <Td>
                          <Menu>
                            <MenuButton
                              as={Button}
                              className="!bg-transparent !font-normal"
                              rightIcon={<ChevronDownIcon boxSize={5} />}
                            >
                              {el.role}
                            </MenuButton>
                            <MenuList>
                              {roles &&
                                roles.map((role: any) => (
                                  <MenuItem
                                    key={role}
                                    value={role}
                                    className="capitalize"
                                    onClick={(e: any) => {
                                      dispatch(
                                        updateRole(
                                          {
                                            email: el.email,
                                            role: e.target.value
                                          },
                                          dispatch
                                        )
                                      );
                                    }}
                                  >
                                    {role}
                                  </MenuItem>
                                ))}
                            </MenuList>
                          </Menu>
                        </Td>

                        <Td color="gray.500" lineHeight="18px" fontSize={14}>
                          {formatFullDateToUsersLocale(el.createdAt)}
                        </Td>

                        <Td>-</Td>

                        <Td>
                          <Menu>
                            <MenuButton
                              as={IconButton}
                              cursor="pointer"
                              variant="outline"
                              aria-label="Options"
                              icon={<ProfileDeleteIcon />}
                              className="!border-0 !bg-transparent"
                            />
                            <MenuList>
                              <MenuItem
                                icon={<ProfileRemove color="red" />}
                                onClick={() => handleOpenUnassignModal(el)}
                              >
                                Unassign
                              </MenuItem>
                              <MenuItem
                                icon={<ProfileDeleteIcon />}
                                onClick={() => handleOpenDeactivateModal(el)}
                              >
                                Deactivate
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </Td>
                      </Tr>
                    ))
                  : null}
                {!allTeams.data.length && !loading ? (
                  <Tr>
                    <Td colSpan={7} background="transparent" h="xl">
                      <NoData text={`No Team Mambers added Yet`} />
                    </Td>
                  </Tr>
                ) : null}
              </Tbody>
            </Table>
          </TableContainer>
          <PaginationWrapper
            handlePageChange={handlePageChange}
            totalDataCount={allTeams.pagination?.total}
            currentPage={currentPage}
            isDisabled={isDisabled}
            pagesCount={pagesCount}
            offset={offset}
            pages={pages}
            size={pageSize}
          />
        </Box>
      </Box>

      <Modal
        isOpen={isOpen}
        onClose={handleCloseUnassignModal}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent px={2} pt={2} pb={4}>
          <ModalHeader
            fontSize={"16px"}
            lineHeight={"1.2"}
            fontWeight={"600"}
            color="#333843"
          >
            Unassign Member
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody mb={2} color="#444B59">
            <Divider />
            {!createdTeamMember ? (
              <div>
                <div className="my-4">
                  <label className="text-gray-600 ">
                    Email Address/ Username
                  </label>
                  <span>{user?.email}</span>
                  <Input
                    isRequired
                    type="email"
                    className="mt-2"
                    focusBorderColor="none"
                    value={!!user ? user.email : values.email}
                    disabled={!!user}
                    onChange={!!user ? () => {} : handleChange("email")}
                  />
                </div>
                {/* <div className="my-4">
                  <label className="text-gray-600 ">Select Role</label>
                  <Select
                    value={values.role}
                    isRequired
                    focusBorderColor="none"
                    className="mt-2"
                    onChange={handleChange("role")}
                  >
                    <option>Select Role</option>
                    {roles &&
                      roles.map((role: any) => (
                        <option className="capitalize" value={role} key={role}>
                          {role}
                        </option>
                      ))}
                  </Select>
                </div> */}

                <Stack gap={2} direction="row">
                  <Button
                    size="md"
                    width="full"
                    variant="outline"
                    colorScheme="primary"
                    onClick={handleCloseUnassignModal}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="md"
                    width="full"
                    colorScheme="primary"
                    isLoading={creatingTeam}
                    onClick={handleSubmit}
                  >
                    Unassign User
                  </Button>
                </Stack>
              </div>
            ) : (
              <>
                {" "}
                {/* Invite Send Content */}
                <div className="p-6">
                  <div className="flex items-center justify-center my-2">
                    <Success />
                  </div>
                  <p className="mt-5 text-center text-gray-900 text-heading100 mdx:text-heading100">
                    Member has been unassigned
                  </p>
                  <p className="mt-1 text-center text-gray-700">
                    You have unassigned {!!user ? user?.email : values.email}{" "}
                    from the role of a/an{" "}
                    <span className="capitalize">{values.role}</span>
                  </p>

                  <div className="mt-8 mdx:mt-12">
                    <Button
                      onClick={handleCloseUnassignModal}
                      className="bg-primary-600"
                      textColor="white"
                      bgColor="#111834"
                      w="full"
                      padding="6"
                      _hover={{ bgColor: "#3B435E" }}
                    >
                      {" "}
                      OK
                    </Button>
                  </div>
                </div>
              </>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        scrollBehavior="inside"
        onClose={handleCloseDeactivateModal}
        isOpen={isDeactivateOpen && !!user}
      >
        <ModalOverlay />
        <ModalContent px={2} pt={2} pb={4}>
          <ModalHeader
            fontSize={"16px"}
            lineHeight={"1.2"}
            fontWeight={"600"}
            color="#333843"
          >
            Deactivate Member
          </ModalHeader>

          <ModalBody mb={2} color="#444B59">
            <Divider />
            <div>
              <div className="my-4">
                <p>Are you sure you want to deactivate {user?.email} ?</p>
              </div>

              <Stack gap={2} direction="row">
                <Button
                  width="full"
                  size="md"
                  colorScheme="primary"
                  onClick={handleCloseDeactivateModal}
                  variant="outline"
                >
                  Cancel
                </Button>
                <Button
                  isDisabled={user?.id! === userId}
                  isLoading={creatingTeam}
                  size="md"
                  colorScheme="primary"
                  width="full"
                  onClick={() => deactivateTeamMate(user?.id!)}
                >
                  Deactivate User
                </Button>
              </Stack>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>

      <AddMember isOpen={isAddOpen} onClose={handleCloseAddSuccessModal} />
    </Box>
  );
};

export default TeamManagementContainer;
