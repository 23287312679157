import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Stack,
  Icon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink
} from "@chakra-ui/react";
import { useBreadCrumbs } from "app/hooks";
import { BsArrowsAngleExpand } from "react-icons/bs";
import VCardTxTableContainer from "../transactions/tables/virtual-cards-transactions";
import { BaseModalProps } from "../wallets/models";

interface Props extends BaseModalProps {
  id: number;
}

const VirtualCardTransactionsModal = ({ isOpen, onClose, id }: Props) => {
  const breadCrumbs = useBreadCrumbs();
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize={"14px"} lineHeight={"1.2"} fontWeight={"normal"}>
          <Stack spacing={"4"} direction="row" alignItems={"center"}>
            <Icon as={BsArrowsAngleExpand} />
            <Breadcrumb mb={"5"}>
              {breadCrumbs.map((el, idx) => (
                <BreadcrumbItem key={idx}>
                  <BreadcrumbLink href={el.to}>{el.title}</BreadcrumbLink>
                </BreadcrumbItem>
              ))}
              <BreadcrumbItem>
                <BreadcrumbLink isCurrentPage={true}>{id}</BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
          </Stack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VCardTxTableContainer id={id} />
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="red" w="full" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default VirtualCardTransactionsModal;
