import { Th, Tr, Thead } from "@chakra-ui/react";

type Props = { data: any[] };

const TransactionHead = ({ data }: Props) => (
  <Thead>
    <Tr>
      {data.map((dt) => (
        <Item key={dt.name} children={dt.name} />
      ))}
    </Tr>
  </Thead>
);

const Item = (props: any) => <Th color="#333843" {...props} />;

export default TransactionHead;
