import {
  Alert,
  AlertIcon,
  Button,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text
} from "@chakra-ui/react";
import { UilEnvelope } from "@iconscout/react-unicons";
import { useNavigate } from "react-router-dom";
import { ReactComponent as RoundIcon } from "app/assets/svg/roundIcon.svg";
import { useAppDispatch, useAppSelector, useCopyToClipboard } from "app/hooks";
import { generate2FA } from "app/store";
import { IoMdCopy } from "react-icons/io";
import { setEmail } from "app/store/reducers/auth.reducer";

export const Generate2FA = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, twoFactorState, step, email] = useAppSelector((state) => [
    state.auth.loading,
    state.auth.twoFactor.state,
    state.auth.twoFactor.step,
    state.auth.twoFactor.email
  ]);

  //copy handler
  const copyHandler = useCopyToClipboard();

  // steps
  const steps = [
    {
      element: (
        <div className="my-4">
          <InputGroup className="items-center">
            <InputLeftElement
              top="auto"
              pointerEvents="none"
              children={<UilEnvelope color="#B1B4BF" />}
            />
            <Input
              focusBorderColor="none"
              type="email"
              placeholder="Business Email"
              size="lg"
              color="#444B59"
              value={email}
              onChange={(e) => dispatch(setEmail(e.target.value))}
              name="email"
              isRequired
              _placeholder={{ opacity: 1, color: "#667085" }}
            />
          </InputGroup>
        </div>
      ),
      buttonText: "Generate Code",
      alert: "Enter your email to generate a QRCode and a secret key"
    },
    {
      element: (
        <Stack alignItems={"center"}>
          <Image src={twoFactorState.url} boxSize="44" />
          <Stack
            direction={"row"}
            spacing="2"
            alignItems={"center"}
            cursor={"pointer"}
            w="full"
            wordBreak={"break-word"}
            onClick={() =>
              copyHandler(twoFactorState.secret || "", "Secret Key")
            }
          >
            <Text>{twoFactorState.secret}</Text>
            <Icon as={IoMdCopy} w={5} h={5} color="success.300" />
          </Stack>
        </Stack>
      ),
      buttonText: "Proceed to Login",
      alert:
        "Scan the QRCode or copy the secret key to generate your login code"
    }
  ];

  //
  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (step === 0) {
      dispatch(generate2FA(email));
    } else {
      navigate("/auth/login");
    }
  };

  return (
    <div className="w-11/12 p-6 bg-gray-100 mdx:w-5/12 sm:w-9/12">
      <p className="text-gray-900 text-heading100 mdx:text-heading300">
        Welcome !
      </p>
      <Alert status="info">
        <AlertIcon />
        {steps[step].alert}
      </Alert>
      <div className="mt-6 mdx:mt-10 authForm">
        <form onSubmit={handleSubmit}>
          {steps[step].element}
          <div className="mt-8 mdx:mt-12">
            <Button
              isDisabled={!email}
              isLoading={isLoading}
              className="mdx:p-6 bg-primary-600"
              textColor="white"
              type="submit"
              bgColor="#111834"
              w="full"
              _hover={{ bgColor: "#3B435E" }}
            >
              <div className="flex items-center">
                <p>{steps[step].buttonText}</p>
                <RoundIcon className="ml-2" />
              </div>
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
