import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CryptoWalletData,
  FiatWalletData,
  LienData,
  PaginationState,
  WalletsState
} from "../models/wallets";

const initialPagination: PaginationState = {
  hasPrevious: false,
  prevPage: 0,
  hasNext: true,
  next: 2,
  currentPage: 1,
  pageSize: 10,
  lastPage: 0,
  total: 0
};

const initialState: WalletsState = {
  allFiatWallet: {
    loading: false,
    data: [],
    pagination: initialPagination
  },
  allCryptoWallet: {
    loading: false,
    data: [],
    pagination: initialPagination
  },
  singleFiatWallet: {
    loading: false,
    data: null
  },
  singleCryptoWallet: {
    loading: false,
    data: null
  },
  lien: {
    data: [],
    loading: false,
    pagination: initialPagination
  }
};

export const WalletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    getAllFiatWalletPending: (state) => {
      state.allFiatWallet.loading = true;
    },
    getAllFiatWalletFulfilled: (
      state,
      action: PayloadAction<{
        data: FiatWalletData[];
        pagination: PaginationState;
      }>
    ) => {
      state.allFiatWallet.data = action.payload.data;
      state.allFiatWallet.pagination = action.payload.pagination;
      state.allFiatWallet.loading = false;
    },
    getAllTFiatWalletFailed: (state) => {
      state.allFiatWallet.loading = false;
    },
    getAllCryptoWalletPending: (state) => {
      state.allCryptoWallet.loading = true;
    },
    getAllCryptoWalletFulfilled: (
      state,
      action: PayloadAction<{
        data: CryptoWalletData[];
        pagination: PaginationState;
      }>
    ) => {
      state.allCryptoWallet.data = action.payload.data;
      state.allCryptoWallet.pagination = action.payload.pagination;
      state.allCryptoWallet.loading = false;
    },
    getAllTCryptoWalletFailed: (state) => {
      state.allCryptoWallet.loading = false;
    },
    getSingleFiatWalletPending: (state) => {
      state.singleFiatWallet.loading = true;
    },
    getSingleFiatWalletSuccess: (
      state,
      action: PayloadAction<{
        data: FiatWalletData;
      }>
    ) => {
      state.singleFiatWallet.data = action.payload.data;
      state.singleFiatWallet.loading = false;
    },
    getSingleFiatWalletFailed: (state) => {
      state.singleFiatWallet.loading = false;
    },
    getSingleCryptoWalletPending: (state) => {
      state.singleCryptoWallet.loading = true;
    },
    getSingleCryptoWalletSuccess: (
      state,
      action: PayloadAction<{
        data: CryptoWalletData;
      }>
    ) => {
      state.singleCryptoWallet.data = action.payload.data;
      state.singleCryptoWallet.loading = false;
    },
    getSingleCryptoWalletFailed: (state) => {
      state.singleCryptoWallet.loading = false;
    },
    getAllLienBegin: (state) => {
      state.lien.loading = true;
    },
    getAllLienFailed: (state) => {
      state.lien.loading = false;
    },
    getAllLienSuccess: (
      state,
      actions: PayloadAction<{ data: LienData[]; pagination: PaginationState }>
    ) => {
      state.lien.loading = false;
      state.lien.data = actions.payload.data;
      state.lien.pagination = actions.payload.pagination;
    }
  }
});

export const actions = WalletSlice.actions;

export default WalletSlice.reducer;
