import {
  Stack,
  FormLabel,
  InputGroup,
  forwardRef,
  FormControl,
  Input as ChakraInput,
  InputProps as ChakraInputProps
} from "@chakra-ui/react";
import React from "react";

export interface InputProps extends ChakraInputProps {
  disabled?: boolean;
  leftElement?: React.ReactNode;
  rightElement?: React.ReactNode;
  label?: string | React.ReactNode;
}

const Label = ({ label }: any) =>
  Boolean(label) ? (
    <FormLabel w="full">
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        {typeof label === "string" && label}
        {typeof label !== "string" ? <span>{label}</span> : <></>}
      </Stack>
    </FormLabel>
  ) : (
    <></>
  );

const Input = forwardRef<InputProps, "input">(
  (
    {
      h = "56px",
      disabled = false,
      leftElement,
      rightElement,
      onChange = () => {},
      ...props
    },
    ref
  ) => {
    return (
      <FormControl>
        <Label label={props.label} />
        <InputGroup h={h}>
          {leftElement}
          <ChakraInput
            w="full"
            ref={ref}
            size="lg"
            height="inherit"
            fontSize="16px"
            borderRadius="8px"
            onChange={onChange}
            disabled={disabled}
            backgroundColor="#f9f9fa"
            focusBorderColor="#141D3E"
            border="1px solid #cccfd6 !important"
            {...props}
          />
          {rightElement}
        </InputGroup>
      </FormControl>
    );
  }
);

export default Input;
