import {
  CampaignData,
  PaginationState,
  CreateCampaignBody,
  GetAllCampaignsParam
} from "app/store/models/campaign";
import { AxiosResponse } from "axios";
import instance from "data/axios-setup";

class CampaignService {
  async getAllCampaign(
    params: GetAllCampaignsParam
  ): Promise<
    AxiosResponse<{ data: CampaignData[]; pagination: PaginationState }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get(`/admin/campaigns`, { params });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async addCampaign(
    body: CreateCampaignBody
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.post(`/admin/campaigns`, body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }
}

export default new CampaignService();
