import {
  PaginationState,
  ExchangeRateData,
  ExchangeRateState
} from "../models/exchange-rate";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: ExchangeRateState = {
  allExchangeRate: {
    data: [],
    loading: false,
    pagination: {
      next: 0,
      total: 10,
      prevPage: 0,
      lastPage: 0,
      pageSize: 10,
      hasNext: true,
      currentPage: 1,
      hasPrevious: false
    }
  }
};

export const ExchangeRateSlice = createSlice({
  name: "exchange-rate",
  initialState,
  reducers: {
    getAllExchangeRatePending: (state) => {
      state.allExchangeRate.loading = true;
    },
    getAllExchangeRateSuccess: (
      state,
      action: PayloadAction<{
        data: ExchangeRateData[];
        pagination?: PaginationState;
      }>
    ) => {
      state.allExchangeRate.loading = false;
      state.allExchangeRate.data = action.payload.data;
      if (action.payload.pagination) {
        state.allExchangeRate.pagination = action?.payload?.pagination;
      }
    },
    getAllExchangeRateFailed: (state) => {
      state.allExchangeRate.loading = false;
    }
  }
});

export const actions = ExchangeRateSlice.actions;

export default ExchangeRateSlice.reducer;
