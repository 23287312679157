import {
  Td,
  Th,
  Tr,
  Box,
  Text,
  Table,
  Tbody,
  Thead,
  Badge,
  Divider,
  TableContainer
} from "@chakra-ui/react";
import { useEffect } from "react";
import { getAllLiens } from "app/store";
import NoData from "views/components/no-data";
import { usePagination } from "@ajna/pagination";
import { useSearchParams } from "react-router-dom";
import getSymbolFromCurrency from "currency-symbol-map";
import { useAppDispatch, useAppSelector } from "app/hooks";
import TransactionSkeletion from "views/containers/transactions/tables/skeleton";
import PaginationWrapper from "views/components/PaginationWrapper/PaginationWrapper";
import { formatFullDateToUsersLocale } from "data/utils/date-formatter-users-locale";

const UserLiens = () => {
  const dispatch = useAppDispatch();
  const liens = useAppSelector((state) => state.wallet.lien);

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, offset } =
    usePagination({
      total: liens.pagination?.total,
      limits: { inner: 2, outer: 1 },
      initialState: { currentPage: 1, pageSize: 10 }
    });

  const handlePageChange = (nextPage: number) => {
    setCurrentPage(nextPage);
    setTimeout(() => {
      window.scrollTo({ top: 10, behavior: "smooth" });
    }, 500);
  };

  let [searchParams] = useSearchParams();
  let userId = searchParams.get("user-id");

  useEffect(() => {
    if (!userId) return;
    dispatch(getAllLiens({ page: currentPage, perpage: 10, userId: +userId }));
  }, [currentPage, 10, userId]);

  return (
    <Box className="container">
      <div>
        <Box
          display="flex"
          bgColor="#fff"
          borderRadius="10px"
          flexDirection="column"
        >
          <Box display="flex" flexDirection="column" className="mt-2">
            <TableContainer bg="#fff" minH="calc(100vh - 10rem)">
              <Table
                color="brand.grayScale"
                fontSize="14px"
                lineHeight="18.2px"
                colorScheme={"gray"}
                variant="striped"
              >
                <Thead>
                  <Tr>
                    <Th
                      color="gray.800"
                      fontSize={"16px"}
                      lineHeight={"19px"}
                      fontWeight={"medium"}
                      textTransform={"capitalize"}
                    >
                      Currency
                    </Th>
                    <Th
                      color="gray.800"
                      fontSize={"16px"}
                      lineHeight={"19px"}
                      fontWeight={"medium"}
                      textTransform={"capitalize"}
                    >
                      Balance
                    </Th>
                    <Th
                      textTransform={"capitalize"}
                      color="gray.800"
                      fontSize={"16px"}
                      lineHeight={"19px"}
                      fontWeight={"medium"}
                    >
                      Last Deposit
                    </Th>

                    <Th
                      textTransform={"capitalize"}
                      color="gray.800"
                      fontSize={"16px"}
                      lineHeight={"19px"}
                      fontWeight={"medium"}
                    >
                      Last Withdrawal
                    </Th>
                    <Th
                      textTransform={"capitalize"}
                      color="gray.800"
                      fontSize={"16px"}
                      lineHeight={"19px"}
                      fontWeight={"medium"}
                    >
                      Description
                    </Th>

                    <Th
                      textTransform={"capitalize"}
                      color="gray.800"
                      fontSize={"16px"}
                      lineHeight={"19px"}
                      fontWeight={"medium"}
                    >
                      Status
                    </Th>
                    <Th
                      textTransform={"capitalize"}
                      color="gray.800"
                      fontSize={"16px"}
                      lineHeight={"19px"}
                      fontWeight={"medium"}
                    >
                      Date Created
                    </Th>
                  </Tr>
                </Thead>
                <Divider />
                <Tbody>
                  {liens.loading ? <TransactionSkeletion /> : null}
                  {!!liens.data.length && !liens.loading
                    ? liens.data.map((el, idx) => (
                        <Tr key={idx}>
                          <Td textTransform={"uppercase"}>
                            {el.currency || "--"}
                          </Td>

                          <Td>
                            {getSymbolFromCurrency(el?.currency || "")}{" "}
                            {el?.balance?.toLocaleString() || "--"}
                          </Td>

                          <Td>
                            {getSymbolFromCurrency(el?.currency || "")}{" "}
                            {el?.lastDeposit?.toLocaleString() || "--"}
                          </Td>

                          <Td>
                            {getSymbolFromCurrency(el?.currency || "")}{" "}
                            {el?.lastWithdrawal?.toLocaleString() || "--"}
                          </Td>

                          <Td>{el.description || "--"}</Td>

                          <Td textTransform={"capitalize"}>
                            <Badge
                              px="3"
                              py="1"
                              gap={"2"}
                              rounded={"2xl"}
                              display={"flex"}
                              color="orage.500"
                              alignItems="center"
                              fontWeight="normal"
                              colorScheme="orange"
                              textTransform="capitalize"
                              justifyContent={"space-between"}
                            >
                              <Text>{el.status}</Text>
                            </Badge>
                          </Td>
                          <Td textTransform={"capitalize"} color="gray.500">
                            {el.createdAt
                              ? formatFullDateToUsersLocale(el.createdAt)
                              : "--"}
                          </Td>
                        </Tr>
                      ))
                    : null}
                  {!liens.data.length && !liens.loading ? (
                    <Tr>
                      <Td colSpan={7} background="transparent" h="xl">
                        <NoData text={`No liens Yet`} />
                      </Td>
                    </Tr>
                  ) : null}
                </Tbody>
              </Table>
            </TableContainer>

            <PaginationWrapper
              size={10}
              pages={pages}
              offset={offset}
              isDisabled={isDisabled}
              pagesCount={pagesCount}
              currentPage={currentPage}
              handlePageChange={handlePageChange}
              totalDataCount={liens.pagination?.total}
            />
          </Box>
        </Box>
      </div>
    </Box>
  );
};

export default UserLiens;
