import { Text } from "@chakra-ui/react";
import userService from "data/services/user-service";
import { useState } from "react";
import AsyncSelect from "react-select/async";

export default () => {
  const [dataOptions, setDataOptions] = useState<Option[]>([]);

  const promiseOptions = (inputValue: string) =>
    new Promise<Option[]>(async (resolve, reject) => {
      try {
        const res = await userService.getAllUsers({
          q: inputValue,
          page: 1,
          perpage: 10
        });
        const data: Option[] = res.data.data.map((el) => ({
          label: el.email || "",
          value: el.email || ""
        }));
        resolve(
          data.filter((i) =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
          )
        );
      } catch (err) {
        reject(err);
      }
    });

  const onChange: (newValue: any) => void = (newValue) => {
    setDataOptions(newValue as Option[]);
  };

  return {
    AsyncInput: (
      <AsyncSelect
        isMulti
        defaultOptions
        loadOptions={promiseOptions}
        placeholder={<Text fontSize={"xs"}>Search by email</Text>}
        onChange={onChange}
      />
    ),
    data: dataOptions
  };
};

export interface Option {
  value: any;
  label: string;
}
