import { usePagination } from "@ajna/pagination";
import {
  Box,
  Button,
  Divider,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { getAllFiatWallet } from "app/store";
import { useEffect, useMemo, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { useSearchParams } from "react-router-dom";
import NoData from "views/components/no-data";
import PaginationWrapper from "views/components/PaginationWrapper/PaginationWrapper";
import BanWalletActions from "../action-modals/ban-wallet-modal";
import FundWalletAction from "../action-modals/fund-wallet";
import ViewWalletModal from "../action-modals/view-wallet-modal";
import WalletSkeleton from "./skeleton";
import { debounce } from "lodash";
import WithdrawAction from "../action-modals/withdraw-modal";

const FiatWalletsTableContainer = () => {
  const allFiatWallet = useAppSelector((state) => state.wallet.allFiatWallet);
  const dispatch = useAppDispatch();
  // tab and url controllers
  let [searchParams, setSearchParams] = useSearchParams();
  let userId = searchParams.get("user-id");
  let txAction = searchParams.get("fund-wallet") as "fund" | "withdraw";

  // Wallet Modal controller
  const onDisableWallet = (id: number) => {
    searchParams.set("wallet-id", id.toString());
    searchParams.set("ban-wallet-action", "disable");
    setSearchParams(searchParams);
  };

  const onEnableWallet = (id: number) => {
    searchParams.set("wallet-id", id.toString());
    searchParams.set("ban-wallet-action", "enable");
    setSearchParams(searchParams);
  };

  const onCloseBanWalletModal = () => {
    searchParams.delete("wallet-id");
    searchParams.delete("ban-wallet-action");
    searchParams.delete("transaction-type");
    setSearchParams(searchParams);
  };

  const onViewWallet = (id: number) => {
    searchParams.set("wallet-id", id.toString());
    searchParams.set("view-wallet", "true");
    setSearchParams(searchParams);
  };

  const onCloseViewWalletModal = () => {
    searchParams.delete("wallet-id");
    searchParams.delete("view-wallet");
    searchParams.delete("transaction-type");
    setSearchParams(searchParams);
  };

  const onFundWallet = (id: number) => {
    searchParams.set("fund-wallet", "fund");
    searchParams.set("wallet-id", id.toString());
    setSearchParams(searchParams);
  };

  const onWithdraw = (id: number) => {
    searchParams.set("fund-wallet", "withdraw");
    searchParams.set("wallet-id", id.toString());
    setSearchParams(searchParams);
  };

  const onCloseFundWalletModal = () => {
    searchParams.delete("wallet-id");
    searchParams.delete("fund-wallet");
    searchParams.delete("transaction-type");
    setSearchParams(searchParams);
  };

  // pagination and search
  const [pageSize, setPageSize] = useState(10);
  const [searchString, setSearchString] = useState("");

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, offset } =
    usePagination({
      initialState: { currentPage: 1, pageSize },
      total: allFiatWallet.pagination?.total,
      limits: {
        inner: 2,
        outer: 1
      }
    });

  const handlePageChange = (nextPage: number) => {
    setCurrentPage(nextPage);
    setTimeout(() => {
      window.scrollTo({ top: 10, behavior: "smooth" });
    }, 500);
  };

  const handleSize = (e: any) => {
    setPageSize(e.target.value);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;

    if (query.length >= 3) {
      setSearchString(query);
      setCurrentPage(1);
    } else {
      setSearchString("");
      setCurrentPage(1);
    }
  };

  const debouncedChangeHandler = useMemo(() => {
    return debounce(handleSearch, 1000);
  }, []);

  useEffect(() => {
    dispatch(
      getAllFiatWallet({
        page: currentPage,
        perpage: pageSize,
        q: searchString,
        ...(!!userId && { userId: +userId })
      })
    );
  }, [currentPage, pageSize, searchString]);

  return (
    <>
      <TableContainer bg="#fff" minH="calc(100vh - 10rem)">
        <Stack mt="0.75rem" ml="2" spacing="4" direction={"row"}>
          <Box mb="16px">
            <InputGroup width="256px">
              <Input
                width="256px"
                paddingLeft="40px"
                minH="40px"
                placeholder={"Search"}
                border="1px solid #EDEBF9"
                onChange={debouncedChangeHandler}
                borderRadius="10px"
                fontSize="12px"
                _focus={{
                  border: "1px solid #EDEBF9"
                }}
                _hover={{
                  border: "1px solid #EDEBF9"
                }}
              />

              <InputLeftElement
                h="100%"
                mr="12px"
                children={<FiSearch color="#A0A3BD" />}
              />
            </InputGroup>
          </Box>
          <Box mb="12px">
            <Select
              width="120px"
              defaultValue={"Size"}
              placeholder="Size"
              onChange={handleSize}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
            </Select>
          </Box>
        </Stack>
        <Table
          color="brand.grayScale"
          fontSize="14px"
          lineHeight="18.2px"
          colorScheme={"gray"}
          variant="striped"
        >
          <Thead>
            {!userId ? (
              <Tr>
                <Th
                  textTransform={"capitalize"}
                  color="gray.800"
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"medium"}
                >
                  id
                </Th>
                <Th
                  textTransform={"capitalize"}
                  color="gray.800"
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"medium"}
                >
                  Full Name
                </Th>
                <Th
                  textTransform={"capitalize"}
                  color="gray.800"
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"medium"}
                >
                  currency
                </Th>
                <Th
                  textTransform={"capitalize"}
                  color="gray.800"
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"medium"}
                >
                  last deposit
                </Th>
                <Th
                  textTransform={"capitalize"}
                  color="gray.800"
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"medium"}
                >
                  last withdrawal
                </Th>
                <Th
                  textTransform={"capitalize"}
                  color="gray.800"
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"medium"}
                >
                  balance
                </Th>
                <Th
                  textTransform={"capitalize"}
                  color="gray.800"
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"medium"}
                >
                  Ledger Balance
                </Th>
                <Th
                  textTransform={"capitalize"}
                  color="gray.800"
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"medium"}
                >
                  Locked Balance
                </Th>
                <Th
                  textTransform={"capitalize"}
                  color="gray.800"
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"medium"}
                >
                  action
                </Th>
              </Tr>
            ) : null}
            {!!userId ? (
              <Tr>
                <Th
                  textTransform={"capitalize"}
                  color="gray.800"
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"medium"}
                >
                  id
                </Th>
                <Th
                  textTransform={"capitalize"}
                  color="gray.800"
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"medium"}
                >
                  currency
                </Th>
                <Th
                  textTransform={"capitalize"}
                  color="gray.800"
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"medium"}
                >
                  last deposit
                </Th>
                <Th
                  textTransform={"capitalize"}
                  color="gray.800"
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"medium"}
                >
                  last withdrawal
                </Th>
                <Th
                  textTransform={"capitalize"}
                  color="gray.800"
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"medium"}
                >
                  balance
                </Th>
                <Th
                  textTransform={"capitalize"}
                  color="gray.800"
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"medium"}
                >
                  Ledger Balance
                </Th>
                <Th
                  textTransform={"capitalize"}
                  color="gray.800"
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"medium"}
                >
                  Locked Balance
                </Th>
                <Th
                  textTransform={"capitalize"}
                  color="gray.800"
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"medium"}
                >
                  action
                </Th>
              </Tr>
            ) : null}
          </Thead>
          <Divider />
          <Tbody>
            {allFiatWallet.loading ? <WalletSkeleton /> : null}
            {!!allFiatWallet.data.length && !allFiatWallet.loading && !userId
              ? allFiatWallet.data.map((el, idx) => (
                  <Tr key={idx}>
                    <Td textTransform={"capitalize"}>{el.id}</Td>
                    <Td textTransform={"capitalize"}>
                      <Stack>
                        <Text
                          fontSize={"14px"}
                          fontWeight="medium"
                          lineHeight={"1.2"}
                        >
                          {`${el.user?.firstName || "--"} ${
                            el.user?.lastName || ""
                          }`}
                        </Text>
                        <Text
                          fontSize={"12px"}
                          lineHeight={"1.2"}
                          color="gray.600"
                          marginTop={0}
                        >
                          {el.user?.email || "--"}
                        </Text>
                      </Stack>
                    </Td>
                    <Td textTransform={"capitalize"}>
                      {el.currencies
                        ? `${el.currencies.code.toUpperCase()}`
                        : "--"}
                    </Td>
                    <Td textTransform={"capitalize"} fontWeight={500}>
                      {el.lastDeposit ? el.lastDeposit.toLocaleString() : "0"}
                    </Td>
                    <Td textTransform={"capitalize"} fontWeight={500}>
                      {el.lastWithdrawal?.toLocaleString() || "0"}
                    </Td>
                    <Td textTransform={"capitalize"} fontWeight={500}>
                      {el.balance?.toLocaleString() || "0"}
                    </Td>
                    <Td textTransform={"capitalize"} fontWeight={500}>
                      {el.ledgerBalance?.toLocaleString() || "0"}
                    </Td>
                    <Td textTransform={"capitalize"} fontWeight={500}>
                      {el.lockedBalance?.toLocaleString() || "0"}
                    </Td>
                    <Td>
                      <Button
                        size={"sm"}
                        borderColor="gray.400"
                        colorScheme="gray"
                        _hover={{ background: "primary.100" }}
                        variant="outline"
                        px="6"
                        ml="1"
                        onClick={() => onViewWallet(el.id)}
                      >
                        view
                      </Button>
                    </Td>
                  </Tr>
                ))
              : null}
            {!!allFiatWallet.data.length && !allFiatWallet.loading && !!userId
              ? allFiatWallet.data.map((el, idx) => (
                  <Tr key={idx}>
                    <Td textTransform={"capitalize"}>{el.id}</Td>
                    <Td textTransform={"capitalize"}>
                      {el.currencies ? el.currencies.code.toUpperCase() : "--"}
                    </Td>
                    <Td textTransform={"capitalize"} fontWeight={500}>
                      {el.lastDeposit ? el.lastDeposit.toLocaleString() : "0"}
                    </Td>
                    <Td textTransform={"capitalize"} fontWeight={500}>
                      {el.lastWithdrawal?.toLocaleString() || "0"}
                    </Td>
                    <Td textTransform={"capitalize"} fontWeight={500}>
                      {el.balance?.toLocaleString() || "0"}
                    </Td>
                    <Td textTransform={"capitalize"} fontWeight={500}>
                      {el?.ledgerBalance?.toLocaleString() || "0"}
                    </Td>
                    <Td textTransform={"capitalize"} fontWeight={500}>
                      {el?.lockedBalance?.toLocaleString() || "0"}
                    </Td>
                    <Td>
                      <Button
                        size={"sm"}
                        colorScheme={!!el.active ? "red" : "green"}
                        px="6"
                        mr={"1"}
                        onClick={() =>
                          !!el.active
                            ? onDisableWallet(el.id)
                            : onEnableWallet(el.id)
                        }
                      >
                        {!!el.active ? "disable" : "enable"}
                      </Button>
                      <Button
                        size={"sm"}
                        borderColor="gray.400"
                        colorScheme="gray"
                        _hover={{ background: "primary.100" }}
                        variant="outline"
                        px="6"
                        ml="1"
                        onClick={() => onFundWallet(el.id)}
                      >
                        fund
                      </Button>
                      <Button
                        size={"sm"}
                        borderColor="gray.400"
                        colorScheme="gray"
                        _hover={{ background: "primary.100" }}
                        variant="outline"
                        px="6"
                        ml="1"
                        onClick={() => onWithdraw(el.id)}
                      >
                        withdraw
                      </Button>
                    </Td>
                  </Tr>
                ))
              : null}
            {!allFiatWallet.data.length && !allFiatWallet.loading ? (
              <Tr>
                <Td colSpan={7} background="transparent" h="xl">
                  <NoData text={`No Fiat Wallets Yet`} />
                </Td>
              </Tr>
            ) : null}
          </Tbody>
        </Table>
      </TableContainer>
      <PaginationWrapper
        handlePageChange={handlePageChange}
        totalDataCount={allFiatWallet.pagination?.total}
        currentPage={currentPage}
        isDisabled={isDisabled}
        pagesCount={pagesCount}
        offset={offset}
        pages={pages}
        size={pageSize}
      />
      <BanWalletActions
        isOpen={
          !!searchParams.get("wallet-id") &&
          !!searchParams.get("ban-wallet-action")
        }
        onClose={onCloseBanWalletModal}
      />
      <ViewWalletModal
        isOpen={
          !!searchParams.get("wallet-id") && !!searchParams.get("view-wallet")
        }
        onClose={onCloseViewWalletModal}
      />
      <FundWalletAction
        isOpen={!!searchParams.get("wallet-id") && txAction === "fund"}
        onClose={onCloseFundWalletModal}
      />
      <WithdrawAction
        isOpen={!!searchParams.get("wallet-id") && txAction === "withdraw"}
        onClose={onCloseFundWalletModal}
      />
    </>
  );
};

export default FiatWalletsTableContainer;
