import { createSlice } from "@reduxjs/toolkit";

interface UIState {
  isCollapsed: boolean;
}

const initialState: UIState = {
  isCollapsed: false
};

export const UISlice = createSlice({
  name: "UI",
  initialState,
  reducers: {
    toggleShow: (state: UIState) => {
      state.isCollapsed = !state.isCollapsed;
    },

    onHide: (state: UIState) => {
      state.isCollapsed = false;
    }
  }
});

export const { toggleShow, onHide } = UISlice.actions;

export default UISlice.reducer;
