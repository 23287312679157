import { AxiosResponse } from "axios";
import instance from "data/axios-setup";
import {
  PaginationState,
  TeamManagementResponse
} from "app/store/models/team-management";

class TeamManagementService {
  async getAllTeams(params: {
    page: number;
    perpage: number;
    q?: string;
  }): Promise<
    AxiosResponse<{
      data: TeamManagementResponse[];
      pagination: PaginationState;
    }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get(`/admin/roles/users`, { params });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async addTeam(body: { email: string; role: string }): Promise<
    AxiosResponse<{
      message: string;
    }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.put(`/admin/roles`, body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }
}

export default new TeamManagementService();
