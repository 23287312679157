import { Dispatch } from "@reduxjs/toolkit";
import { toggleShow, onHide } from "../reducers/ui.reducer";

export const toggleSideBar = () => {
  return (dispatch: Dispatch) => {
    dispatch(toggleShow());
  };
};

export const expandSideBar = () => {
  return (dispatch: Dispatch) => {
    dispatch(onHide());
  };
};
