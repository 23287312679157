import React from "react";
import Content from "./content";
import { Show } from "@chakra-ui/react";
import SidebarDesktop from "./view/sidebar.desktop";

const Sidebar = () => (
  <React.Fragment>
    <Show above="lg">
      <SidebarDesktop>
        <Content />
      </SidebarDesktop>
    </Show>
  </React.Fragment>
);

export default Sidebar;
