import {
  UsersIcon,
  AppsSolidIcon,
  AppsOutlineIcon,
  CardOutlineIcon,
  StatusOutlineIcon,
  WalletOutlineIcon,
  MessageOutlineIcon,
  SettingsOutlineIcon,
  ProfileCheckOutlineIcon,
  WalletSolidIcon,
  MessageSolidIcon,
  CardSolidIcon,
  SettingsSolidIcon
} from "views/components/icons";
import LogoutItem from "./components/logout";
import ListItem from "./components/list-item";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Stack, Icon } from "@chakra-ui/react";
import { UserSolidIcon } from "views/components/icons/user-solid-icon";
import { ReactComponent as LogoutIcon } from "app/assets/svg/logout.svg";
import { ReactComponent as ChangeraIcon } from "app/assets/svg/changera.svg";
import { UsersSolidIcon } from "views/components/icons/users-solid-icon";
import { ReportSolidIcon } from "views/components/icons/report-solid-icon";
import { BsCurrencyExchange } from "react-icons/bs";
import { ReactComponent as RoundIcon } from "app/assets/svg/roundIcon.svg";
import { MdFeaturedPlayList, MdOutlineFeaturedPlayList } from "react-icons/md";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useLayoutEffect } from "react";
import { expandSideBar } from "app/store/action-creators/ui.action";
import {
  Bank
  //Airplane,
  // Shop,
  //Notification,
  //MoneySend
  // Star,
  // Lovely
} from "iconsax-react";

const list = [
  {
    url: "/dashboard",
    text: "Dashboard",
    icon2: AppsSolidIcon,
    icon: AppsOutlineIcon
  },
  {
    url: "/requests",
    text: "Requests",
    icon: MessageOutlineIcon,
    icon2: MessageSolidIcon
  },
  {
    url: "/user-information",
    text: "User Information",
    icon: UsersIcon,
    icon2: UsersSolidIcon
  },
  {
    url: "/verification",
    text: "Verification",
    icon: ProfileCheckOutlineIcon,
    icon2: UserSolidIcon
  },
  {
    url: "/wallets",
    text: "Wallets",
    icon: WalletOutlineIcon,
    icon2: WalletSolidIcon
  },
  {
    icon: Bank,
    icon2: Bank,
    url: "/virtual-accounts",
    text: "Virtual Accounts"
  },
  {
    url: "/transactions",
    text: "Transactions",
    icon: CardOutlineIcon,
    icon2: CardSolidIcon
  },
  // {
  //   url: "/flights",
  //   text: "Flights",
  //   icon: Airplane,
  //   icon2: Airplane
  // },
  // {
  //   icon: Shop,
  //   icon2: Shop,
  //   url: "/bitmama-merchants",
  //   text: "Bitmama Merchants"
  // },
  // {
  //   icon: Star,
  //   icon2: Star,
  //   url: "/wishlist",
  //   text: "Christmas Wishlist"
  // },
  // {
  //   icon: Lovely,
  //   icon2: Lovely,
  //   url: "/valentine-wishlist",
  //   text: "Valentine Wishlist"
  // },
  // {
  //   url: "/fx",
  //   text: "FX",
  //   icon: MoneySend,
  //   icon2: MoneySend
  // },
  {
    url: "/reports",
    text: "Reports",
    icon: StatusOutlineIcon,
    icon2: ReportSolidIcon
  },
  {
    url: "/virtual-cards",
    text: "Virtual Cards",
    icon: WalletOutlineIcon,
    icon2: WalletSolidIcon
  },
  {
    url: "/exchange-rates",
    text: "Exchange Rates",
    icon: BsCurrencyExchange,
    icon2: BsCurrencyExchange
  },
  {
    url: "/team-management",
    text: "Team Settings",
    icon: SettingsOutlineIcon,
    icon2: SettingsSolidIcon
  },
  {
    url: "/feature",
    text: "Feature",
    icon: MdOutlineFeaturedPlayList,
    icon2: MdFeaturedPlayList
  }
  // {
  //   url: "/campaign",
  //   text: "Campaigns",
  //   icon: Notification,
  //   icon2: Notification
  // }
];
// logout function from hooks

export interface CloseSideBar {
  onCloseSideBar?: () => void;
}

const Content = ({ onCloseSideBar }: CloseSideBar) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const closeSideNav = () => {
    if (!onCloseSideBar) return;
    onCloseSideBar();
  };

  const isCollapsed = useAppSelector((state) => state.UI.isCollapsed);

  const navigate = useNavigate();

  const goToHome = () => {
    navigate("/");
  };

  useLayoutEffect(() => {
    const expandDesktopNav = () => {
      if (window.innerWidth <= 992) {
        dispatch(expandSideBar());
      }
    };
    window.addEventListener("resize", expandDesktopNav);

    return () => {
      window.removeEventListener("resize", expandDesktopNav);
    };
  }, []);

  return (
    <Box p={8} py={16} height="100%" overflowY="auto">
      <Stack direction="column" justifyContent="space-between" height="100%">
        <Box>
          {!isCollapsed ? (
            <Icon
              mb={12}
              width="180px"
              height="16"
              as={ChangeraIcon}
              onClick={goToHome}
              cursor="pointer"
            />
          ) : (
            <Icon
              mb={12}
              boxSize="16"
              as={RoundIcon}
              p="4"
              onClick={goToHome}
              cursor="pointer"
            />
          )}

          {list.map((props) => (
            <ListItem
              {...props}
              key={props.text}
              active={pathname === props.url}
              onClick={closeSideNav}
              isCollapsed={isCollapsed}
            />
          ))}
        </Box>

        <Box>
          <LogoutItem
            key="logout"
            icon={LogoutIcon}
            text="Logout"
            isCollapsed={isCollapsed}
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default Content;
