import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPagination } from "data/models/base";
import { IFlightData, IFlightState } from "data/models/fligts";

const initialPagination = {
  hasPrevious: false,
  prevPage: 0,
  hasNext: true,
  next: 2,
  currentPage: 1,
  pageSize: 10,
  lastPage: 0,
  total: 0
};

const initialState: IFlightState = {
  allFlightOrders: {
    data: [],
    loading: false,
    error: "",
    pagination: initialPagination
  }
};

export const FlightSlice = createSlice({
  name: "flight",
  initialState,
  reducers: {
    getAllFlightOrdersPending: (state) => {
      state.allFlightOrders.loading = true;
    },
    getAllFlightOrdersSuccess: (
      state,
      action: PayloadAction<{
        data: IFlightData[];
        pagination: IPagination;
      }>
    ) => {
      state.allFlightOrders.data = action.payload.data;
      state.allFlightOrders.pagination = action.payload.pagination;
      state.allFlightOrders.loading = false;
    },
    getAllFlightOrdersFailed: (state) => {
      state.allFlightOrders.loading = false;
    }
  }
});

export const actions = FlightSlice.actions;

export default FlightSlice.reducer;
