import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Center,
  Text
} from "@chakra-ui/react";
import { SingleUserState } from "app/store/models/user";
import { AxiosError } from "axios";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";
import notificationService from "data/services/notification.service";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { BaseModalProps } from "../models";

export default function PushNotificationSingle({
  isOpen,
  onClose,
  user
}: IPushNotificationSingle) {
  const [state, setState] = useState({
    title: "",
    message: "",
    email: user.data?.email as string
  });

  const onChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    setState((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const isDisabled = () => !state.message || !state.title || !state.email;

  //
  const [loading, setLoading] = useState(false);

  const submitHandler = async () => {
    const body = {
      ...state
    };

    setLoading(true);
    try {
      const res = await notificationService.sendNotificationsSingleUser(body);
      toast.success(res.data.message || "Push Notification sent");
      onClose();
      setLoading(false);
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Push Notification</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl pb="2">
            <FormLabel color={"gray.600"} fontSize={"sm"} fontWeight="normal">
              User Email Address
            </FormLabel>
            <Input
              type="email"
              placeholder="----"
              value={state.email}
              name="email"
              onChange={onChangeHandler}
              disabled
            />
          </FormControl>

          <FormControl py="5">
            <FormLabel color={"gray.600"} fontSize={"sm"} fontWeight="normal">
              Message Title
            </FormLabel>
            <Input
              type="text"
              placeholder="----"
              value={state.title}
              name="title"
              onChange={onChangeHandler}
            />
          </FormControl>

          <FormControl py="5">
            <FormLabel color={"gray.600"} fontSize={"sm"} fontWeight="normal">
              <Center justifyContent={"space-between"}>
                <Text>Message Title</Text>
                <Text>{state.message.length}/200</Text>
              </Center>
            </FormLabel>
            <Textarea
              placeholder="Type message here"
              value={state.message}
              name="message"
              onChange={onChangeHandler}
            />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme={"primary"}
            onClick={submitHandler}
            disabled={isDisabled()}
            isLoading={loading}
          >
            Send Message
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

interface IPushNotificationSingle extends BaseModalProps {
  user: SingleUserState;
}
