import {
  PaginationState,
  TransactionsResponse,
  ChangeraTransactionType
} from "app/store/models/transactions";
import { AxiosResponse } from "axios";
import instance from "data/axios-setup";

class TransactionService {
  async getAllTransactions(
    params: {
      page: number;
      perpage: number;
      q?: string;
      changeraTransactionType?: ChangeraTransactionType;
      walletId?: number;
      userId?: number;
      isBitmama?: boolean;
      virtualCardId?: number;
      status?: string;
    },
    signal?: AbortSignal
  ): Promise<
    AxiosResponse<{ data: TransactionsResponse[]; pagination: PaginationState }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get(`/admin/transactions`, {
          params,
          signal
        });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }
}

export default new TransactionService();
