import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  TeamManagementState,
  TeamManagementResponse,
  PaginationState
} from "../models/team-management";

const initialState: TeamManagementState = {
  allTeams: {
    pagination: {
      hasPrevious: false,
      prevPage: 0,
      hasNext: true,
      next: 2,
      currentPage: 1,
      pageSize: 10,
      lastPage: 0,
      total: 62
    },
    data: []
  },
  roles: [],
  loading: false,
  creatingTeamMember: false,
  createdTeamMember: false,
  togglingUser: false,
  toggledUser: false
};

export const TeamManagementSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    getAllTeamsBegin: (state: TeamManagementState) => {
      state.loading = true;
    },
    getAllTeamsSuccess: (
      state: TeamManagementState,
      actions: PayloadAction<{
        data: TeamManagementResponse[];
        pagination: PaginationState;
      }>
    ) => {
      state.allTeams.data = actions.payload.data;
      state.allTeams.pagination = actions.payload.pagination;
      state.loading = false;
    },
    gottenRoles: (
      state: TeamManagementState,
      actions: PayloadAction<{
        data: [];
      }>
    ) => {
      state.roles = actions.payload.data;
    },
    getAllTeamsFailed: (state: TeamManagementState) => {
      state.loading = false;
    },
    loadingBegin: (state: TeamManagementState) => {
      state.creatingTeamMember = true;
    },
    error: (state: TeamManagementState) => {
      state.creatingTeamMember = false;
      state.togglingUser = false;
    },
    teamMemberCreatedSuccesful: (state: TeamManagementState) => {
      state.createdTeamMember = true;
      state.creatingTeamMember = false;
    },
    togglingUser: (state: TeamManagementState) => {
      state.togglingUser = true;
    },
    toggledUser: (state: TeamManagementState) => {
      state.toggledUser = true;
    },
    resetCreateTeamSuccess: (state: TeamManagementState) => {
      state.createdTeamMember = false;
    }
  }
});

export const actions = TeamManagementSlice.actions;

export default TeamManagementSlice.reducer;
