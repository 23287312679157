export interface BaseModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export interface DetailProps {
  title: string;
  text: string;
}

export enum VerificationDetailParam {
  PERSONAL_DETAILS = "personal-details",
  ATTACHMENTS = "attachments",
  VERIFICATIONS = "verifications"
}

export enum VerificationAction {
  DECLINE = "decline",
  APPROVE = "approve"
}
