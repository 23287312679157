import { Link } from "react-router-dom";
import { Box, Text, Heading, Stack, StackDivider } from "@chakra-ui/react";
import { useAppSelector } from "app/hooks";

const TransactionToolbar = () => {
  const recentTransactionsLength = useAppSelector(
    (state) => state.summary.data?.recentTransactions.length
  );

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Stack
        direction="row"
        alignItems="center"
        divider={<StackDivider borderColor="gray.200" />}
      >
        <Heading
          fontSize={18}
          fontWeight={500}
          color="gray.900"
          lineHeight="21px"
        >
          Recent Transactions
          <Box
            ml={2}
            fontSize={12}
            color="blue.500"
            display="inline"
            fontWeight={400}
            lineHeight="14px"
          >
            <sub>{recentTransactionsLength || "-"}</sub>
          </Box>
        </Heading>
      </Stack>

      <Link to="/transactions">
        <Text>See All</Text>
      </Link>
    </Box>
  );
};

export default TransactionToolbar;
