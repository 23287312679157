import React from "react";
import { Box, Stack, BadgeProps } from "@chakra-ui/react";

type Props = { children?: React.ReactNode };
type BadgeVariant =
  | "active"
  | "failed"
  | "denied"
  | "success"
  | "pending"
  | "approved"
  | "successfull"
  | "successful"
  | "cancelled"
  | "accepted"
  | "processing"
  | "terminated";

interface BadgePropsss extends BadgeProps {
  variant: BadgeVariant;
  children?: React.ReactNode;
}

const Badge = ({ children }: Props) => (
  <div className="relative">
    {children}
    <div className="absolute w-3 h-3 bg-[#DB2438] top-0 right-0 origin-[100%_0%] scale-100 rounded-full" />
  </div>
);

export default Badge;

export const TransactionBadge = (props: BadgePropsss) => (
  <Box
    height="32px"
    width="112px"
    display="flex"
    fontSize="12px"
    borderWidth="1px"
    borderStyle="solid"
    borderRadius="16px"
    alignItems="center"
    justifyContent="center"
    {...getBadgeStyles(props.variant).style}
    {...props}
  >
    <Stack direction="row" alignItems="center" justifyContent="center">
      <span className="capitalize">
        {props.children || getBadgeStyles(props.variant).text}
      </span>
      <Box
        width="8px"
        height="8px"
        borderRadius="50%"
        backgroundColor={getBadgeStyles(props.variant).style.color}
      />
    </Stack>
  </Box>
);

const getBadgeStyles = (variant: BadgeVariant) => {
  switch (variant) {
    case "success":
    case "active":
    case "accepted":
    case "successful":
    case "successfull":
      return {
        text: "Success",
        style: {
          color: "#1B9B48",
          borderColor: "#1B9B48",
          backgroundColor: "#D1EBDA"
        }
      };

    case "approved":
      return {
        text: "Approved",
        style: {
          color: "#1B9B48",
          borderColor: "#1B9B48",
          backgroundColor: "#D1EBDA"
        }
      };

    case "processing":
      return {
        text: "In Progress",
        style: {
          color: "gray.600",
          borderColor: "gray.600",
          backgroundColor: "gray.200"
        }
      };

    case "failed":
      return {
        text: "Failed",
        style: {
          color: "#DB2438",
          borderColor: "#DB2438",
          backgroundColor: "#F8D3D7"
        }
      };

    case "denied":
      return {
        text: "Denied",
        style: {
          color: "#DB2438",
          borderColor: "#DB2438",
          backgroundColor: "#F8D3D7"
        }
      };

    case "cancelled":
    case "terminated":
      return {
        text: "Cancelled",
        style: {
          color: "#DB2438",
          borderColor: "#DB2438",
          backgroundColor: "#F8D3D7"
        }
      };

    case "pending":
    default:
      return {
        text: "Pending",
        style: {
          color: "#FFCC04",
          borderColor: "#FFCC04",
          backgroundColor: "#FFF5CD"
        }
      };
  }
};
