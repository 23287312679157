import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import UserDetails from "views/containers/user-information/user-details/index";

export const useUserDetails = () => {
  let [searchParams, setSearchParams] = useSearchParams();

  // User details Modal controller
  const isUserDetailsOpen = useMemo(
    () => !!searchParams.get("user-id"),
    [searchParams]
  );

  const onCloseUserDetails = () => {
    searchParams.delete("user-id");
    searchParams.delete("user-detail");
    searchParams.delete("wallet-category");
    searchParams.delete("transaction-type");
    searchParams.delete("verification-detail");
    setSearchParams(searchParams);
  };

  const onOpenUserDetails = (id: number) => {
    searchParams.set("user-id", id.toString());
    setSearchParams(searchParams);
  };

  return {
    UserDetail: isUserDetailsOpen ? (
      <UserDetails isOpen={isUserDetailsOpen} onClose={onCloseUserDetails} />
    ) : null,
    onOpenUserDetails,
    onCloseUserDetails,
    isUserDetailsOpen
  };
};
