export const parseJSON = (json: any) => {
  let parsed = json;

  try {
    parsed = JSON.parse(json);
  } catch (e) {}

  return parsed;
};

export const formatter = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});
