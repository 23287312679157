import {
  Icon,
  Modal,
  Stack,
  Button,
  Heading,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  ModalContent
} from "@chakra-ui/react";
import { FC } from "react";
import { BsArrowsAngleExpand } from "react-icons/bs";
import getSymbolFromCurrency from "currency-symbol-map";
import { TEuroGBPAccount } from "app/store/models/user";
import { formatFullDateToUsersLocale } from "data/utils/date-formatter-users-locale";

interface Props {
  onClose: any;
  isOpen: any;
  selectedAccount?: TEuroGBPAccount;
}
const VirtualAccountDetails: FC<Props> = ({
  selectedAccount,
  isOpen,
  onClose
}) => {
  if (!selectedAccount) return null;

  return (
    <div>
      <Modal isCentered isOpen={isOpen} onClose={onClose} size={"4xl"}>
        <ModalOverlay />
        <ModalContent px={2} pt={2} pb={4}>
          <ModalHeader m={0} fontSize={18} color="#333843" fontWeight={600}>
            <Stack spacing={"4"} direction="row" alignItems={"center"}>
              <Icon as={BsArrowsAngleExpand} />
              <Heading
                as={"h6"}
                fontSize="24px"
                lineHeight={"1.1"}
                fontWeight="700"
                textTransform={"capitalize"}
                fontFamily="karla"
              >
                Virtual Account Details
              </Heading>
            </Stack>
          </ModalHeader>

          <ModalBody mb={2} color="#444B59">
            <div className="flex justify-between my-4">
              <Heading
                fontSize="20px"
                lineHeight={"1.1"}
                fontWeight="600"
                textTransform={"capitalize"}
                fontFamily="karla"
              >
                Account Name: {selectedAccount.accountName}
              </Heading>
              <Heading
                fontSize="20px"
                lineHeight={"1.1"}
                color="primary.900"
                fontWeight="600"
                textTransform={"capitalize"}
                fontFamily="karla"
              >
                Balance: {getSymbolFromCurrency(selectedAccount?.currency)}
                {selectedAccount?.balance?.toLocaleString()}
              </Heading>
            </div>
            <hr />
            <div className="grid grid-cols-1 gap-5 my-8 lg:grid-cols-3">
              <p className="text-gray-900">
                Account Name:{" "}
                <span className="text-gray-600">
                  {selectedAccount?.accountName || "--"}
                </span>
              </p>
              <p className="text-gray-900">
                Account Number:{" "}
                <span className="text-gray-600">
                  {selectedAccount?.accountNumber || "--"}
                </span>
              </p>
              <p className="text-gray-900">
                Bank Name:{" "}
                <span className="text-gray-600">
                  {selectedAccount?.bankName || "--"}
                </span>
              </p>
              <p className="text-gray-900">
                Bank Code:{" "}
                <span className="text-gray-600">
                  {selectedAccount?.bankCode || "--"}
                </span>
              </p>
              <p className="text-gray-900">
                Bank Address:{" "}
                <span className="text-gray-600">
                  {selectedAccount?.bankAddress || "--"}
                </span>
              </p>
              <p className="text-gray-900">
                Bank Swift Code:{" "}
                <span className="text-gray-600">
                  {selectedAccount?.bankSwiftCode || "--"}
                </span>
              </p>
              <p className="text-gray-900">
                IBAN:{" "}
                <span className="text-gray-600">
                  {selectedAccount?.iban || "--"}
                </span>
              </p>
              <p className="text-gray-900">
                BIC:{" "}
                <span className="text-gray-600">
                  {selectedAccount?.bic || "--"}
                </span>
              </p>
              <p className="text-gray-900">
                Sort Code:{" "}
                <span className="text-gray-600">
                  {selectedAccount?.sortCode || "--"}
                </span>
              </p>
              <p className="text-gray-900">
                Issued Country Code:{" "}
                <span className="text-gray-600">
                  {selectedAccount?.issuedCountryCode || "--"}
                </span>
              </p>
              <p className="text-gray-900">
                Document Number:{" "}
                <span className="text-gray-600">
                  {selectedAccount?.documentNumber || "--"}
                </span>
              </p>
              <p className="text-gray-900">
                Issued By:{" "}
                <span className="text-gray-600">
                  {selectedAccount?.issuedBy || "--"}
                </span>
              </p>{" "}
              <p className="text-gray-900">
                Issued Date:{" "}
                <span className="text-gray-600">
                  {selectedAccount?.issuedDate
                    ? formatFullDateToUsersLocale(selectedAccount?.issuedDate)
                    : "--"}
                </span>
              </p>{" "}
              <p className="text-gray-900">
                Document Number:{" "}
                <span className="text-gray-600">
                  {selectedAccount?.documentNumber || "--"}
                </span>
              </p>
              <p className="text-gray-900">
                Expiration Date:{" "}
                <span className="text-gray-600">
                  {selectedAccount?.expirationDate
                    ? formatFullDateToUsersLocale(
                        selectedAccount?.expirationDate
                      )
                    : "--"}
                </span>
              </p>
              <p className="text-gray-900">
                Currency:{" "}
                <span className="text-gray-600">
                  {selectedAccount?.currency || "--"}
                </span>
              </p>
              <p className="text-gray-900">
                FirstName:{" "}
                <span className="text-gray-600">
                  {selectedAccount?.firstName || "--"}
                </span>
              </p>
              <p className="text-gray-900">
                LastName:{" "}
                <span className="text-gray-600">
                  {selectedAccount?.lastName || "--"}
                </span>
              </p>
              <p className="text-gray-900">
                Birth Country:{" "}
                <span className="text-gray-600">
                  {selectedAccount?.birthCountry || "--"}
                </span>
              </p>
              <p className="text-gray-900">
                Tax Country:{" "}
                <span className="text-gray-600">
                  {selectedAccount?.taxCountry || "--"}
                </span>
              </p>
              <p className="text-gray-900">
                Tax Number:{" "}
                <span className="text-gray-600">
                  {selectedAccount?.taxNumber || "--"}
                </span>
              </p>
              <p className="text-gray-900">
                Phone:{" "}
                <span className="text-gray-600">
                  {selectedAccount?.phone || "--"}
                </span>
              </p>
              <p className="text-gray-900">
                Email:{" "}
                <span className="text-gray-600">
                  {selectedAccount?.email || "--"}
                </span>
              </p>
              <p className="text-gray-900">
                Birth Date:{" "}
                <span className="text-gray-600">
                  {selectedAccount?.birthDate
                    ? formatFullDateToUsersLocale(selectedAccount?.birthDate)
                    : "--"}
                </span>
              </p>{" "}
              <p className="text-gray-900">
                Birth Place:{" "}
                <span className="text-gray-600">
                  {selectedAccount?.birthPlace || "--"}
                </span>
              </p>{" "}
              <p className="text-gray-900">
                Country:{" "}
                <span className="text-gray-600">
                  {selectedAccount?.country || "--"}
                </span>
              </p>{" "}
              <p className="text-gray-900">
                City:{" "}
                <span className="text-gray-600">
                  {selectedAccount?.city || "--"}
                </span>
              </p>{" "}
              <p className="text-gray-900">
                Zip Code:{" "}
                <span className="text-gray-600">
                  {selectedAccount?.zip || "--"}
                </span>
              </p>{" "}
              <p className="text-gray-900">
                Street:{" "}
                <span className="text-gray-600">
                  {selectedAccount?.street || "--"}
                </span>
              </p>{" "}
            </div>

            <Stack gap={2} direction="row" className="mt-6">
              <Button
                width="full"
                size="md"
                colorScheme="primary"
                onClick={onClose}
                variant="outline"
              >
                Close
              </Button>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default VirtualAccountDetails;
