import { FeatureData, PaginationState } from "app/store/models/feature";
import { AxiosResponse } from "axios";
import instance from "data/axios-setup";

class FeatureService {
  async getAllFeatures(params: {
    page: number;
    perpage: number;
  }): Promise<
    AxiosResponse<{ data: FeatureData[]; pagination: PaginationState }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get(`/admin/feature`, { params });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async addFeature(body: {
    feature: string;
    active: boolean;
  }): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.post(`/admin/feature`, body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async processFeature(
    id: number,
    body: {
      active: boolean;
      sendMessage: boolean;
      title: string;
      message: string;
    }
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.put(`/admin/feature/${id}`, body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }
}

export default new FeatureService();
