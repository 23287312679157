import { logOut } from "app/store";
import { AxiosError } from "axios";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";
import authService from "data/services/auth-service";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "./useReduxState";

export const useLogoutFunction = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const logoutHandler = async () => {
    try {
      await authService.logout();
      dispatch(logOut(navigate));
    } catch (err) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
    }
  };

  return logoutHandler;
};
