import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Divider,
  Spinner
} from "@chakra-ui/react";
import { useAppDispatch } from "app/hooks";
import { getAllExchangeRates } from "app/store";
import { AxiosError } from "axios";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";
import exchangeRateService from "data/services/exchange-rate-service";
import { useState } from "react";
import toast from "react-hot-toast";
import { ExchangeRateModal } from "../models";

const EditExchangeRate = ({ isOpen, onClose, id, data }: ExchangeRateModal) => {
  if (!data) return null;

  //
  const dispatch = useAppDispatch();

  //
  const [loading, setLoading] = useState(false);

  const [state, setState] = useState({
    buyRate: data.buyRate.toString(),
    sellRate: data.sellRate.toString()
  });

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setState((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  //
  const updateExchangeRate = async () => {
    if (!id) return;
    setLoading(true);
    try {
      const res = await exchangeRateService.updateExchangeRate(+id, {
        ...state,
        buyRate: parseFloat(state.buyRate),
        sellRate: parseFloat(state.sellRate)
      });
      if (res.status === 200 || 201 || 202) {
        dispatch(getAllExchangeRates());
        toast.success(res.data.message);
        setState({
          sellRate: "",
          buyRate: ""
        });
        onClose();
      }
      setLoading(false);
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textTransform={"capitalize"}>
          Update Exchange Rate for {data.currencyCode.toUpperCase()}
        </ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody>
          <Stack my="5" spacing={"4"}>
            <FormControl>
              <FormLabel color={"gray.600"} fontWeight="400">
                Buy Rate
              </FormLabel>
              <Input
                type="number"
                value={state.buyRate}
                name="buyRate"
                onChange={onChangeHandler}
              />
            </FormControl>
            <FormControl>
              <FormLabel color={"gray.600"} fontWeight="400">
                Sell Rate
              </FormLabel>
              <Input
                type="number"
                value={state.sellRate}
                name="sellRate"
                onChange={onChangeHandler}
              />
            </FormControl>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme={"primary"}
            variant={"outline"}
            onClick={onClose}
            mr="3"
            w="full"
          >
            Cancel
          </Button>
          <Button
            colorScheme={"primary"}
            w="full"
            onClick={updateExchangeRate}
            disabled={loading || !state.buyRate || !state.sellRate}
          >
            {loading ? <Spinner /> : "Submit"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditExchangeRate;
