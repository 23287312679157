import { AxiosError } from "axios";
import toast from "react-hot-toast";
import { Dispatch } from "@reduxjs/toolkit";
import { actions } from "../reducers/wallet.reducer";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";
import walletService from "data/services/wallet-service";

export const getAllFiatWallet = (params: {
  page: number;
  perpage: number;
  q?: string;
  userId?: number;
}) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getAllFiatWalletPending());
      const res = await walletService.getAllFiatWallets(params);
      if (res.status === 200 || 201 || 202) {
        dispatch(
          actions.getAllFiatWalletFulfilled({
            data: res.data.data,
            pagination: res.data.pagination
          })
        );
      }
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.getAllTFiatWalletFailed());
      toast.error(msg);
    }
  };
};

export const getAllCryptoWallet = (params: {
  page: number;
  perpage: number;
  q?: string;
  userId?: number;
}) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getAllCryptoWalletPending());
      const res = await walletService.getAllCryptoWallets(params);
      if (res.status === 200 || 201 || 202) {
        dispatch(
          actions.getAllCryptoWalletFulfilled({
            data: res.data.data,
            pagination: res.data.pagination
          })
        );
      }
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.getAllTCryptoWalletFailed());
      toast.error(msg);
    }
  };
};

export const getSingleFiatWallet = (id: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getSingleFiatWalletPending());
      const res = await walletService.getSingleFiatWallet(id);
      if (res.status === 200 || 201 || 202) {
        dispatch(
          actions.getSingleFiatWalletSuccess({
            data: res.data.data
          })
        );
      }
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.getSingleFiatWalletFailed());
      toast.error(msg);
    }
  };
};

export const getSingleCryptoWallet = (id: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getSingleCryptoWalletPending());
      const res = await walletService.getSingleCryptoWallet(id);
      if (res.status === 200 || 201 || 202) {
        dispatch(
          actions.getSingleCryptoWalletSuccess({
            data: res.data.data
          })
        );
      }
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.getSingleCryptoWalletFailed());
      toast.error(msg);
    }
  };
};

export const getAllLiens = (params: {
  page: number;
  perpage: number;
  userId?: number;
}) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getAllLienBegin());
      const res = await walletService.getAllLien(params);
      if (res.status === 200 || 201 || 202) {
        dispatch(
          actions.getAllLienSuccess({
            data: res.data.data,
            pagination: res.data.pagination
          })
        );
      }
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.getAllLienFailed());
      toast.error(msg);
    }
  };
};
