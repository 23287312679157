import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack
} from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { getAllTeams } from "app/store";
import { AxiosError } from "axios";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";
import teamManagementService from "data/services/team-management-service";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { BaseModalProps } from "../feature/models";

const AddMember = ({ isOpen, onClose }: BaseModalProps) => {
  const [roles, page, perpage] = useAppSelector((state) => [
    state.teamManagement.roles,
    state.teamManagement.allTeams.pagination.currentPage,
    state.teamManagement.allTeams.pagination.pageSize
  ]);

  //
  const dispatch = useAppDispatch();

  //
  const [isLoading, setLoading] = useState(false);

  const [state, setState] = useState({
    email: "",
    role: ""
  });

  const onChangedHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    setState((prev) => ({ ...prev, [name]: value }));
  };

  //
  const AddMemberHandler = async () => {
    setLoading(true);
    try {
      const res = await teamManagementService.addTeam(state);
      if (res.status === 200 || 201 || 202) {
        dispatch(getAllTeams({ page, perpage }));
        toast.success(res.data.message);
        setState({
          email: "",
          role: ""
        });
        onClose();
      }
      setLoading(false);
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent px={2} pt={2} pb={4}>
        <ModalHeader
          fontSize={"16px"}
          lineHeight={"1.2"}
          fontWeight={"600"}
          color="#333843"
        >
          Add Member
        </ModalHeader>

        <ModalBody mb={2} color="#444B59">
          <Divider />
          <Stack>
            <FormControl py="2">
              <FormLabel>Email Address</FormLabel>
              <Input
                type={"email"}
                name="email"
                value={state.email}
                onChange={onChangedHandler}
              />
            </FormControl>

            <FormControl py="2">
              <FormLabel>Role</FormLabel>
              <Select
                name="role"
                value={state.role}
                onChange={onChangedHandler}
                placeholder="Select Role"
              >
                {roles.map((role: string) => (
                  <option key={role} value={role}>
                    {role}
                  </option>
                ))}
              </Select>
            </FormControl>

            <Stack gap={2} direction="row">
              <Button
                width="full"
                size="md"
                colorScheme="primary"
                variant="outline"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                isLoading={isLoading}
                size="md"
                colorScheme="primary"
                width="full"
                onClick={AddMemberHandler}
              >
                Add User
              </Button>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddMember;
