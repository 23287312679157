import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Stack,
  Text,
  Icon,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Divider,
  Flex,
  Skeleton,
  Spinner
} from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import { BaseModalProps } from "views/containers/user-information/models";
import {
  MessageOutlineIcon,
  ProfileCheckOutlineIcon
} from "views/components/icons";
import { IoCalendarOutline } from "react-icons/io5";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { getAllFiatWallet, getSingleFiatWallet } from "app/store";
import { formatDateToUTC } from "data/utils/formatDateToUTC";
import NumberFormat from "react-number-format";
import walletService from "data/services/wallet-service";
import toast from "react-hot-toast";
import { AxiosError } from "axios";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";

const FundWalletAction = ({ isOpen, onClose }: BaseModalProps) => {
  const dispatch = useAppDispatch();
  const [fiatWallet, requestor, currentPage, pageSize] = useAppSelector(
    (state) => [
      state.wallet.singleFiatWallet,
      state.dashboard.userProfile,
      state.wallet.allFiatWallet.pagination?.currentPage,
      state.wallet.allFiatWallet.pagination?.pageSize
    ]
  );

  // tab and url controllers
  let [searchParams] = useSearchParams();
  const walletId = searchParams.get("wallet-id");
  const userId = searchParams.get("user-id");

  // form state
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    amount: "0",
    reference: "",
    description: ""
  });
  const manualFundFiatWallet = async () => {
    if (!walletId || !currentPage || !pageSize || !fiatWallet.data) return;
    setLoading(true);
    try {
      const res = await walletService.manualFundFiatWallet(+walletId, {
        ...state,
        amount: parseFloat(state.amount)
      });
      if (res.status === 200 || 201 || 202) {
        dispatch(
          getAllFiatWallet({
            page: currentPage,
            perpage: pageSize,
            ...(userId && { userId: +userId })
          })
        );
        toast.success(
          `Wallet Funded Successfully with ${
            fiatWallet.data.currencies?.symbol
          }${parseFloat(state.amount).toLocaleString()}`
        );
        setState({
          amount: "",
          reference: "",
          description: ""
        });
        onClose();
      }
      setLoading(false);
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      setLoading(false);
    }
  };

  // get wallet details
  useEffect(() => {
    if (!walletId) return;
    dispatch(getSingleFiatWallet(+walletId));
  }, [walletId]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="outside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textTransform={"capitalize"}>Fund Wallet</ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody>
          {fiatWallet.loading ? (
            <Flex
              p="5"
              bg="primary.500"
              color={"gray.300"}
              rounded="lg"
              direction="column"
              gap={"4"}
            >
              <Skeleton h="6" />
              <Skeleton h="6" />
              <Skeleton h="6" />
            </Flex>
          ) : null}
          {!!fiatWallet.data && !fiatWallet.loading ? (
            <Stack
              p="5"
              bg="primary.500"
              color={"gray.300"}
              rounded="lg"
              spacing={"4"}
            >
              <Stack direction={"row"} justifyContent="space-between">
                <Stack direction={"row"} alignItems="center">
                  <Icon as={ProfileCheckOutlineIcon} h={5} w={5} />
                  <Text>Requestor</Text>
                </Stack>
                <Text>
                  {requestor
                    ? `${requestor.firstName} ${requestor.lastName}`
                    : "--"}
                </Text>
              </Stack>
              <Stack direction={"row"} justifyContent="space-between">
                <Stack direction={"row"} alignItems="center">
                  <Icon as={MessageOutlineIcon} h={5} w={5} />
                  <Text>Wallet Id</Text>
                </Stack>
                <Text>{walletId}</Text>
              </Stack>
              <Stack direction={"row"} justifyContent="space-between">
                <Stack direction={"row"} alignItems="center">
                  <Icon as={IoCalendarOutline} h={5} w={5} />
                  <Text>Date Requested</Text>
                </Stack>
                <Text>
                  {fiatWallet.data?.createdAt
                    ? formatDateToUTC(fiatWallet.data.createdAt)
                    : "--"}
                </Text>
              </Stack>
            </Stack>
          ) : null}
          <Heading
            as={"h4"}
            fontSize="24px"
            fontFamily={"karla"}
            my="5"
            fontWeight={"extrabold"}
          >
            Transaction Details
          </Heading>
          <Stack my="5" spacing={"4"}>
            <FormControl>
              <FormLabel color={"gray.600"} fontWeight="400">
                Amount to Fund
              </FormLabel>
              <NumberFormat
                placeholder="0.00"
                prefix={fiatWallet.data?.currencies?.symbol || ""}
                thousandSeparator={true}
                inputMode="decimal"
                fixedDecimalScale={false}
                decimalScale={2}
                allowLeadingZeros={false}
                displayType="input"
                defaultValue={"0.00"}
                name="amount"
                value={state.amount}
                onValueChange={({ value }) =>
                  setState((prevState) => ({ ...prevState, amount: value }))
                }
                className="border-gray-200 outline-1 outline-gray-200 rounded-md border-[2px] w-full text-gray-800 py-2 px-4 shadow-[#6584f6] focus-visible:border-[#6484F6] focus-visible:shadow-[0_0_0_1px_#6484f6]"
              />
            </FormControl>
            <FormControl>
              <FormLabel color={"gray.600"} fontWeight="400">
                Reference
              </FormLabel>
              <Input
                type="text"
                value={state.reference}
                onChange={(e) =>
                  setState((prevState) => ({
                    ...prevState,
                    reference: e.target.value
                  }))
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel color={"gray.600"} fontWeight="400">
                Description
              </FormLabel>
              <Textarea
                value={state.description}
                onChange={(e) =>
                  setState((prevState) => ({
                    ...prevState,
                    description: e.target.value
                  }))
                }
              />
            </FormControl>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme={"primary"}
            variant={"outline"}
            onClick={onClose}
            mr="3"
            w="full"
          >
            Cancel
          </Button>
          <Button
            colorScheme={"primary"}
            w="full"
            onClick={manualFundFiatWallet}
            disabled={
              loading || !state.amount || !state.description || !state.reference
            }
          >
            {loading ? <Spinner /> : "Submit"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FundWalletAction;
