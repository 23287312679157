import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Divider,
  Spinner,
  Textarea,
  Checkbox
} from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { getAllFeatures } from "app/store";
import { AxiosError } from "axios";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";
import featureService from "data/services/feature-service";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { ProcessFeatureModal } from "../models";

const ProcessFeature = ({ isOpen, onClose, data }: ProcessFeatureModal) => {
  if (!data) return null;

  //
  const dispatch = useAppDispatch();
  const pagination = useAppSelector(
    (state) => state.feature.allFeatures.pagination
  );

  //
  const [loading, setLoading] = useState(false);

  const [state, setState] = useState({
    active: !data.active,
    sendMessage: false,
    title: "",
    message: ""
  });

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setState((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  useEffect(() => {
    if (!state.sendMessage)
      setState((prev) => ({ ...prev, title: "", message: "" }));
  }, [state.sendMessage]);

  //
  const processFeatureHandler = async () => {
    setLoading(true);
    try {
      const res = await featureService.processFeature(data.id, state);
      if (res.status === 200 || 201 || 202) {
        dispatch(
          getAllFeatures({
            page: pagination.currentPage,
            perpage: pagination.pageSize
          })
        );
        toast.success(res.data.message);
        onClose();
      }
      setLoading(false);
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textTransform={"capitalize"}>{data.feature}</ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody>
          <Stack my="5" spacing={"4"}>
            <FormControl>
              <Checkbox
                checked={state.sendMessage}
                onChange={(e) =>
                  setState((prev) => ({
                    ...prev,
                    sendMessage: e.target.checked
                  }))
                }
              >
                Send Message
              </Checkbox>
            </FormControl>
            {state.sendMessage ? (
              <>
                <FormControl>
                  <FormLabel color={"gray.600"} fontWeight="400">
                    Title
                  </FormLabel>
                  <Input
                    type="text"
                    value={state.title}
                    name="title"
                    onChange={onChangeHandler}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel color={"gray.600"} fontWeight="400">
                    Message
                  </FormLabel>
                  <Textarea
                    value={state.message}
                    name="message"
                    onChange={(e) =>
                      setState((prev) => ({ ...prev, message: e.target.value }))
                    }
                  />
                </FormControl>
              </>
            ) : null}
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme={"primary"}
            variant={"outline"}
            onClick={onClose}
            mr="3"
            w="full"
          >
            Cancel
          </Button>
          <Button
            colorScheme={data.active ? "red" : "green"}
            w="full"
            onClick={processFeatureHandler}
            disabled={loading}
          >
            {loading ? <Spinner /> : data.active ? "Deactivate" : "Activate"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ProcessFeature;
