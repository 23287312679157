import React from "react";
import { Box, Button } from "@chakra-ui/react";
import { FiMoreHorizontal } from "react-icons/fi";
import { useValentineWishList } from "app/hooks/use-wishlist";
import { Menu, MenuList, MenuItem, MenuButton } from "@chakra-ui/react";

const Item = ({ type, handleEdit }: any) => (
  <div className="w-56">
    <div className="bg-[#CBD6FC] p-4 rounded-lg">
      <div className="bg-white p-4 rounded-lg">
        <img src="/images/wishlist.png" />
      </div>
    </div>

    <div className="mt-2 flex items-center justify-between">
      <p className="font-medium">{type}</p>

      <Menu>
        <MenuButton
          p={2}
          size="sm"
          as={Button}
          minWidth={0}
          minHeight={0}
          height="unset"
        >
          <FiMoreHorizontal className="cursor-pointer" />
        </MenuButton>
        <MenuList>
          <MenuItem onClick={handleEdit}>Edit Wishlist</MenuItem>
        </MenuList>
      </Menu>
    </div>
  </div>
);

type Props = { showUpdateWishList: () => void };

const WishLists = ({ showUpdateWishList }: Props) => {
  const { valentineWishListItems, dispatchValentineWishListItems } =
    useValentineWishList();

  React.useEffect(() => {
    dispatchValentineWishListItems({});
  }, []);

  return (
    <Box mb={8} className="flex items-center gap-16">
      {!!valentineWishListItems.data.length && (
        <Item
          type="Valentine Wish List"
          handleEdit={() => showUpdateWishList()}
        />
      )}
    </Box>
  );
};

export default WishLists;
