import { createIcon } from "@chakra-ui/icon";

export const StarIcon = createIcon({
  displayName: "StarIcon",
  viewBox: "0 0 20 20",
  path: (
    <path
      d="M11.4417 2.9252L12.9084 5.85853C13.1084 6.26686 13.6417 6.65853 14.0917 6.73353L16.75 7.1752C18.45 7.45853 18.85 8.69186 17.625 9.90853L15.5584 11.9752C15.2084 12.3252 15.0167 13.0002 15.125 13.4835L15.7167 16.0419C16.1834 18.0669 15.1084 18.8502 13.3167 17.7919L10.825 16.3169C10.375 16.0502 9.63338 16.0502 9.17505 16.3169L6.68338 17.7919C4.90005 18.8502 3.81671 18.0585 4.28338 16.0419L4.87505 13.4835C4.98338 13.0002 4.79171 12.3252 4.44171 11.9752L2.37505 9.90853C1.15838 8.69186 1.55005 7.45853 3.25005 7.1752L5.90838 6.73353C6.35005 6.65853 6.88338 6.26686 7.08338 5.85853L8.55005 2.9252C9.35005 1.33353 10.65 1.33353 11.4417 2.9252Z"
      fill="transparent"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  )
});
