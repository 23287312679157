import {
  ISendMoney,
  IWithdrawal,
  IFiatWallet,
  IRequestState,
  IRequestError,
  ICryptoWallet,
  TRequestLoading,
  PaginationState,
  IRequestSuccess,
  ProcessWithdrawalStatus
} from "../models/request";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const pagination = {
  next: 0,
  total: 0,
  prevPage: 0,
  lastPage: 0,
  pageSize: 10,
  hasNext: false,
  currentPage: 1,
  hasPrevious: false
};

const initialState: IRequestState = {
  error: null,
  sendMoney: { data: [], pagination, status: "" },
  fiatWallet: { data: [], pagination },
  crytoWallet: { data: [], pagination },
  withdrawal: { data: [], pagination, selected: {}, status: "" },
  status: {
    getAllSendMoney: "idle",
    processSendMoney: "idle",
    toggleFiatWallet: "idle",
    getAllFiatWallets: "idle",
    processWithdrawal: "idle",
    getAllWithdrawals: "idle",
    getAllCryptoWallets: "idle"
  }
};

export const RequestsSlice = createSlice({
  initialState,
  name: "requests",
  reducers: {
    loading: (state: IRequestState, action: PayloadAction<TRequestLoading>) => {
      state.status[action.payload] = "loading";
    },
    error: (state: IRequestState, action: PayloadAction<IRequestError>) => {
      state.error = action.payload.msg;
      state.status[action.payload.for] = "failed";
    },
    success: (state: IRequestState, action: PayloadAction<IRequestSuccess>) => {
      state.status[action.payload.for] = "idle";
    },
    setSendMoney: (
      state: IRequestState,
      action: PayloadAction<{ data: ISendMoney[]; pagination: PaginationState }>
    ) => {
      state.sendMoney.data = action.payload.data;
      state.status.getAllSendMoney = "succeeded";
      state.sendMoney.pagination = action.payload.pagination;
    },
    setSendMoneyStatus: (
      state,
      action: PayloadAction<ProcessWithdrawalStatus | "">
    ) => {
      state.sendMoney.status = action.payload;
    },
    setFiatWallets: (
      state: IRequestState,
      action: PayloadAction<{
        data: IFiatWallet[];
        pagination: PaginationState;
      }>
    ) => {
      state.fiatWallet.data = action.payload.data;
      state.status.getAllFiatWallets = "succeeded";
      state.fiatWallet.pagination = action.payload.pagination;
    },
    setCryptoWallets: (
      state: IRequestState,
      action: PayloadAction<{
        data: ICryptoWallet[];
        pagination: PaginationState;
      }>
    ) => {
      state.crytoWallet.data = action.payload.data;
      state.status.getAllCryptoWallets = "succeeded";
      state.crytoWallet.pagination = action.payload.pagination;
    },
    setWithdrawals: (
      state: IRequestState,
      action: PayloadAction<{
        data: IWithdrawal[];
        pagination: PaginationState;
      }>
    ) => {
      state.withdrawal.data = action.payload.data;
      state.status.getAllWithdrawals = "succeeded";
      state.withdrawal.pagination = action.payload.pagination;
    },
    setWithdrawalStatus: (
      state,
      action: PayloadAction<ProcessWithdrawalStatus | "">
    ) => {
      state.withdrawal.status = action.payload;
    },
    setProcessedWithdrawal: (
      state: IRequestState,
      action: PayloadAction<{
        id: number;
        status: ProcessWithdrawalStatus;
      }>
    ) => {
      state.status.processWithdrawal = "idle";
      const itemIndex = state.withdrawal.data.findIndex(
        (e) => e.id === action.payload.id
      );

      const newState = [...state.withdrawal.data];
      newState[itemIndex].status = action.payload.status;
      state.withdrawal.data = newState;
    },
    setProcessedSendMoney: (state: IRequestState) => {
      state.status.processSendMoney = "idle";
    },
    setToggleFiatWallet: (
      state: IRequestState,
      action: PayloadAction<{
        id: number;
      }>
    ) => {
      state.status.toggleFiatWallet = "idle";
      const newState = state.fiatWallet.data.filter(
        (el) => el.id !== action.payload.id
      );

      state.fiatWallet.data = newState;
      const total = state.fiatWallet.pagination?.total ?? 1;
      state.fiatWallet.pagination.total = total - 1;
    }
  }
});

export const actions = RequestsSlice.actions;

export default RequestsSlice.reducer;
