import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input
} from "@chakra-ui/react";

import { useEffect, useState } from "react";
import { BaseModalProps } from "../models";
import { EditUserRequestBody } from "app/store/models/user";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useSearchParams } from "react-router-dom";
import userService from "data/services/user-service";
import { AxiosError } from "axios";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";
import toast from "react-hot-toast";
import { getAllUsers, getSingleUser } from "app/store";

const EditUserModal = ({ isOpen, onClose }: BaseModalProps) => {
  const controller = new AbortController();
  const dispatch = useAppDispatch();
  //
  const [userDetails, allUsers] = useAppSelector((state) => [
    state.user.singleUser,
    state.user.allUsers
  ]);
  const [state, setState] = useState<EditUserRequestBody>({
    firstName: "",
    lastName: "",
    phone: "",
    email: ""
  });

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setState((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  useEffect(() => {
    if (!userDetails.data) return;

    setState((prev) => ({
      ...prev,
      firstName: userDetails.data?.firstName as string,
      lastName: userDetails.data?.lastName as string,
      email: userDetails.data?.email as string,
      phone: userDetails.data?.phone as string
    }));
  }, [userDetails]);

  //
  const [loading, setLoading] = useState(false);

  // url serch params
  let [searchParams] = useSearchParams();
  const id = searchParams.get("user-id");

  //
  const editUserHandler = async () => {
    if (!id) return;
    setLoading(true);

    const initialState: any = userDetails?.data || {};

    const data = Object.entries(state)
      .filter(([key, value]) => value !== "" && value !== initialState?.[key])
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

    if (!Object.keys(data)?.length) {
      setLoading(false);
      return onClose();
    }

    try {
      const res = await userService.editUser(+id, data as any);
      if (res.status === 200 || 201 || 202) {
        dispatch(getSingleUser(+id, controller.signal));

        dispatch(
          getAllUsers({
            page: allUsers.pagination.currentPage,
            perpage: allUsers.pagination.pageSize,
            blocked: false
          })
        );

        toast.success(res.data.message);
        onClose();
      }
      setLoading(false);
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      setLoading(false);
    }
  };

  useEffect(() => {
    return () => controller.abort();
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit User</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>First Name</FormLabel>
            <Input
              name="firstName"
              value={state.firstName}
              onChange={onChangeHandler}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Last Name</FormLabel>
            <Input
              name="lastName"
              value={state.lastName}
              onChange={onChangeHandler}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Phone</FormLabel>
            <Input
              name="phone"
              value={state.phone}
              onChange={onChangeHandler}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Email</FormLabel>
            <Input
              name="email"
              value={state.email}
              onChange={onChangeHandler}
            />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            w={"full"}
            colorScheme="red"
            mr={3}
            variant="outline"
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            w="full"
            colorScheme={"primary"}
            isLoading={loading}
            disabled={!state.firstName || !state.lastName || !state.phone}
            onClick={editUserHandler}
          >
            Edit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditUserModal;
