import { HStack, Icon, Text } from "@chakra-ui/react";
import {
  Pagination,
  PaginationNext,
  PaginationPage,
  PaginationPrevious,
  PaginationContainer,
  PaginationPageGroup,
  PaginationSeparator
} from "@ajna/pagination";
import { ChevronRightIcon, ChevronLeftIcon } from "@chakra-ui/icons";
import { clamp } from "data/utils/clamp";

const PaginationWrapper = ({
  handlePageChange,
  totalDataCount,
  currentPage,
  isDisabled,
  pagesCount,
  offset,
  pages,
  size
}: any) => {
  return (
    <>
      <HStack
        mt={["20px", "20px", "0"]}
        // bg={["transparent", "transparent", "white"]}
        p="10px 20px"
        justifyContent="space-between"
        borderRadius="0px 0px 16px 16px"
      >
        <Text>
          Showing {offset + 1} to{" "}
          {clamp(offset + Number(size), Number(size), totalDataCount)} of{" "}
          {totalDataCount}
        </Text>
        <HStack>
          <Pagination
            pagesCount={pagesCount}
            currentPage={currentPage}
            isDisabled={isDisabled}
            onPageChange={handlePageChange}
          >
            <PaginationContainer
              align="center"
              justify="space-between"
              p={4}
              w="full"
            >
              <PaginationPrevious
                mr="8px"
                boxSize="44px"
                _focus={{ shadow: "0 0 0 3px var(--focusColor)" }}
                _active={{ bg: "transparent" }}
                _hover={{
                  bg: "transparent"
                }}
                bg="transparent"
                border={"2px"}
                size={"sm"}
              >
                <Icon as={ChevronLeftIcon} w={5} h={5} />
              </PaginationPrevious>

              <PaginationPageGroup
                isInline
                align="center"
                separator={
                  <PaginationSeparator
                    border="1px solid #DFE3E8"
                    color="brand.grayScale"
                    bg="transparent"
                    fontSize="xs"
                    boxSize="36px"
                    jumpSize={4}
                    _active={{ bg: "transparent" }}
                    _hover={{
                      bg: "transparent"
                    }}
                    _focus={{
                      borderColor: "transparent",
                      shadow: "0 0 0 3px var(--focusColor)"
                    }}
                  />
                }
              >
                {pages.map((page: any) => (
                  <PaginationPage
                    boxSize="44px"
                    bg="gray.100"
                    border="2px"
                    key={`pagination_page_${page}`}
                    page={page}
                    fontSize="xs"
                    color="brand.grayScale"
                    _active={{ bg: "primary.500", color: "white" }}
                    _hover={{
                      borderColor: "primary.500",
                      bg: "primary.500",
                      color: "white"
                    }}
                    _focus={{
                      borderColor: "primary.500",
                      background: "primary.500",
                      shadow: "0 0 0 3px var(--focusColor)"
                    }}
                    _current={{
                      borderColor: "primary.500",
                      bg: "primary.500",
                      fontSize: "xs",
                      boxSize: "44px",
                      color: "#fff",
                      _hover: {
                        borderColor: "#DFE3E8",
                        bg: "transparent",
                        color: "black"
                      }
                    }}
                  />
                ))}
              </PaginationPageGroup>

              <PaginationNext
                size={"sm"}
                ml="8px"
                boxSize={"44px"}
                _active={{ bg: "transparent" }}
                _focus={{ shadow: "0 0 0 3px var(--focusColor)" }}
                _hover={{
                  bg: "transparent"
                }}
                bg="transparent"
                border={"2px"}
                p="0"
              >
                <Icon as={ChevronRightIcon} w={5} h={5} />
              </PaginationNext>
            </PaginationContainer>
          </Pagination>
        </HStack>
      </HStack>
    </>
  );
};

export default PaginationWrapper;
