import { AxiosError } from "axios";
import toast from "react-hot-toast";
import instance from "data/axios-setup";
import { Dispatch } from "@reduxjs/toolkit";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";
import { actions } from "../reducers/virtual-cards.reducer";
import { VirtualCardStatus } from "../models/virtual-cards";
import reportsService from "data/services/virtual-cards-service";

export const getAllVirtualCards = (
  params: {
    page: number;
    perpage: number;
    q?: string;
    userId?: number;
  },
  signal?: AbortSignal
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getAllVirtualCardBegin());
      const res = await reportsService.getAllVirtualCards(params, signal);
      if (res.status === 200 || 201 || 202) {
        dispatch(
          actions.getAllVirtualCardSuccess({
            data: res.data.data,
            pagination: res.data.pagination
          })
        );
      }
    } catch (err: any) {
      if (err?.code === "ERR_CANCELED") return;
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.error());
      toast.error(msg);
    }
  };
};

export const updateVirtualCardStatus = (
  id: number,
  status: VirtualCardStatus,
  callback?: () => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.updateVirtualCardStatusBegin());
      const res = await reportsService.updateVirtualCardStatus(id, status);
      dispatch(actions.updateVirtualCardStatusSuccess());
      toast.success(res.data.message);
      if (callback) callback();
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.updateVirtualCardStatusFailed(msg));
      toast.error(msg);
    }
  };
};

export const getMonoCardDetails = (id: string, signal?: AbortSignal) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.gettingCardDetails());
      const res = await instance.get(`/admin/virtual-cards/${id}/mono`, {
        signal
      });
      if (res.status == 201 || 200 || 202) {
        dispatch(
          actions.gottenCardDetails({
            data: res.data.data
          })
        );
      }
    } catch (err: any) {
      if (err?.code === "ERR_CANCELED") return;
      dispatch(actions.error());
    }
  };
};

export const getUnionCardDetails = (id: string, signal?: AbortSignal) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.gettingCardDetails());
      const res = await instance.get(`/admin/virtual-cards/${id}`, { signal });
      if (res.status == 201 || 200 || 202) {
        dispatch(
          actions.gottenCardDetails({
            data: res.data.data
          })
        );
      }
    } catch (err: any) {
      if (err?.code === "ERR_CANCELED") return;
      dispatch(actions.error());
    }
  };
};
