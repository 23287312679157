import React from "react";
import SendMoneyModalDetails from "./send-money-modal";

const SendMoneyDetailsModal = ({ data, onClose }: any) => {
  return (
    <React.Fragment>
      <SendMoneyModalDetails data={data} onClose={onClose} />
    </React.Fragment>
  );
};

export default SendMoneyDetailsModal;
