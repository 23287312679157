import {
  IWishList,
  IWishListItem,
  IGetWishListParams,
  IValentineWishlist,
  IGetWishListItemParams,
  ICreateWishListItemsBody,
  IValentineWishListUsers,
  IUpdateWishListItemsBody,
  IValentineWishlistItem,
  IAddValentineWishListItemsBody,
  IGetValentineWishListItemParams,
  ISelectValentineWishListWinnerBody,
  IGetValentineWishListTopUsersParams
} from "app/store/models/wishlist";
import { AxiosResponse } from "axios";
import instance from "data/axios-setup";
import { IPagination } from "data/models/base";

class WishListService {
  async getWishList(
    params: IGetWishListParams
  ): Promise<AxiosResponse<{ data: IWishList[]; pagination: IPagination }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get("/admin/wishlist", {
          params
        });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getWishListItems(
    params: IGetWishListItemParams
  ): Promise<
    AxiosResponse<{ data: IWishListItem[]; pagination: IPagination }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get("/admin/wishlist-item", {
          params
        });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async createWishListitems(
    body: ICreateWishListItemsBody
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.post("/admin/wishlist-item/many", body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async updateWishListitems(
    body: IUpdateWishListItemsBody
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.put("/admin/wishlist-item/many", body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async approveWishList(
    id: number
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.put(
          "/admin/wishlist/approve-user-wishlist",
          { userId: id }
        );
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async denyWishList(
    id: number,
    reason?: string
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.put("/admin/wishlist/deny-user-wishlist", {
          userId: id,
          reason
        });

        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getSingleWishList(
    id: number
  ): Promise<AxiosResponse<{ data: IWishList }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get(`/admin/wishlist/single-wishlist/${id}`);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getValentineWishList(): Promise<
    AxiosResponse<{ data: IValentineWishlist }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get("/admin/valantine-campaign/wishlist");
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async updateValentineWishListitems(
    id: number,
    body: { item: string }
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.put(
          `/admin/valantine-campaign/wishlist-item/${id}`,
          body
        );
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getValentineWishListItems(
    params: IGetValentineWishListItemParams
  ): Promise<
    AxiosResponse<{ data: IValentineWishlistItem[]; pagination: IPagination }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get(
          "/admin/valantine-campaign/wishlist-item",
          {
            params
          }
        );
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async addValentineWishListitems(
    body: IAddValentineWishListItemsBody
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.post(
          "/admin/valantine-campaign/wishlist-item",
          body
        );
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async selectValentineWishListWinner(
    body: ISelectValentineWishListWinnerBody
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.post(
          "/admin/valantine-campaign/select-winner",
          body
        );
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getValentineWishListTopUsers(
    params: IGetValentineWishListTopUsersParams
  ): Promise<
    AxiosResponse<{ data: IValentineWishListUsers[]; pagination: IPagination }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get(
          "/admin/valantine-campaign/weekly-winners",
          {
            params
          }
        );
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getValentineWishListWinners(
    params: IGetValentineWishListTopUsersParams
  ): Promise<
    AxiosResponse<{ data: IValentineWishListUsers[]; pagination: IPagination }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get(
          "/admin/valantine-campaign/selected-winners",
          { params }
        );
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }
}

export default new WishListService();
