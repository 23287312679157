import { Dispatch } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";
import userService from "data/services/user-service";
import toast from "react-hot-toast";
import { actions } from "../reducers/user.reducer";
import { SubscribeUserBody, UserFilter } from "../models/user";

export const getAllUsers = (
  params: {
    q?: string;
    page: number;
    plan?: string;
    perpage: number;
    blocked?: boolean;
    country?: string;
    endDate?: Date | string;
    startDate?: Date | string;
  },
  signal?: AbortSignal
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getAllUsersPending());
      const res = await userService.getAllUsers(params, signal);
      if (res.status === 200 || 201 || 202) {
        dispatch(
          actions.getAllUsersSuccess({
            data: res.data.data,
            pagination: res.data.pagination
          })
        );
      }
    } catch (err: any) {
      if (err?.code === "ERR_CANCELED") return;
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.getAllUsersFailed());
      console.log(err);
      toast.error(msg);
    }
  };
};

export const getBannedUsers = (params: {
  page?: number;
  perpage?: number;
  q?: string;
  type?: string;
  userId?: number;
}) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getBannedUsersPending());
      const res = await userService.getBannedUsers(params);
      if (res.status === 200 || 201 || 202) {
        dispatch(
          actions.getBannedUsersSuccess({
            data: res.data.data,
            pagination: res.data.pagination
          })
        );
      }
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.getBannedUsersFailed());
      toast.error(msg);
    }
  };
};

export const getSingleUser = (id: number, signal?: AbortSignal) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getSingleUserPending());
      const res = await userService.getSingleUser(id, signal);
      if (res.status === 200 || 201 || 202) {
        dispatch(
          actions.getSingleUserSuccess({
            data: res.data.data
          })
        );
      }
    } catch (err: any) {
      if (err?.code === "ERR_CANCELED") return;
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.getSingleUserFailed());
      toast.error(msg);
    }
  };
};

export const getBanHistory = (userId: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getBanHistoryPending());
      const res = await userService.getBanHistory(userId);
      if (res.status === 200 || 201 || 202) {
        dispatch(
          actions.getBanHistorySuccess({
            data: res.data.data,
            pagination: res.data.pagination
          })
        );
      }
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.getBanHistoryFailed());
      toast.error(msg);
    }
  };
};

export const setUserFilter = (filter: UserFilter) => {
  return async (dispatch: Dispatch) => {
    dispatch(actions.setUserFilter(filter));
  };
};

export const getSubscriptions = (userId: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getSubscriptionsLoading());
      const res = await userService.getSubscriptions(userId);
      if (res.status === 200 || 201 || 202) {
        dispatch(
          actions.getSubscriptionsSuccess({
            data: res.data.data,
            pagination: res.data.pagination
          })
        );
      }
    } catch (err: any) {
      if (err?.code === "ERR_CANCELED") return;
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.getSubscriptionsFailed(msg));
      toast.error(msg);
    }
  };
};

export const cancelSubscription = (id: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.cancelSubscriptionPending());
      const res = await userService.cancelSubscriptions(id);
      if (res.status === 200 || 201 || 202) {
        dispatch(actions.cancelSubscriptionSuccess());
        toast.success(res.data?.message || "Success");
        return true;
      }
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.cancelSubscriptionFailed(msg));
      toast.error(msg);
    }
  };
};

export const subscribeUser = (body: SubscribeUserBody) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.subscribeUserPending());
      const res = await userService.subscribeUser(body);
      if (res.status === 200 || 201 || 202) {
        dispatch(actions.subscribeUserSuccess());
        toast.success(res.data?.message || "Success");
        return true;
      }
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.subscribeUserFailed(msg));
      toast.error(msg);
    }
  };
};

export const recoverAccount = (userId: number, reason: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.recoverAccountPending());
      const res = await userService.recoverAccount(userId, reason);
      if (res.status === 200 || 201 || 202) {
        dispatch(actions.recoverAccountSuccess());
        toast.success(res.data?.message || "Success");
        return true;
      }
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.recoverAccountFailed(msg));
      toast.error(msg);
    }
  };
};
