import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Divider,
  Flex,
  Stack,
  Avatar,
  Heading,
  Badge,
  Icon,
  Text,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  useDisclosure
} from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import { BaseModalProps } from "../models";
import { BsArrowsAngleExpand, BsLightningChargeFill } from "react-icons/bs";
import TabLink from "../../../components/links/tab-link";
import {
  WalletOutlineIcon,
  ProfileCheckOutlineIcon
} from "views/components/icons";
import { CardOutlineIcon } from "views/components/icons/card-outline-icon";
import PersonalInformation from "./personal-information";
import { useEffect } from "react";
import UserWallets from "./user-wallets";
import UserTransactions from "./user-transactions";
import { useAppDispatch, useAppSelector, useBreadCrumbs } from "app/hooks";
import { getSingleUser } from "app/store";
import { formatDateOnlyToDateString } from "data/utils/formatDateToUTC";
import ToggleUser from "./toggle-user";
import EditUserModal from "./edit-user-modal";
import { IoCardOutline } from "react-icons/io5";
import UserVirtualCards from "./user-vcards";
import PushNotificationSingle from "../modals/push-notification-single";
import Verification from "./verification";
import {
  Repeat,
  WalletMoney,
  ProfileDelete,
  MoneyForbidden
} from "iconsax-react";
import UserVirtualAccounts from "./user-virtual-accounts";
import AddLien from "../lien/add-lien";
import UserLiens from "./user-lien";
import BanHistory from "./ban-history";
import UserSubscriptions from "./subscriptions";

const UserDetails = ({ isOpen, onClose }: BaseModalProps) => {
  const [user] = useAppSelector((state) => [state.user.singleUser]);
  const dispatch = useAppDispatch();

  // url serch params
  let [searchParams, setSearchParams] = useSearchParams();
  const userDetailParamValue = searchParams.get("user-detail");
  const userId = searchParams.get("user-id");

  useEffect(() => {
    if (!!userId && !userDetailParamValue) {
      searchParams.set("user-detail", "personal-information");
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  //
  const onToggleUserAction = (status: string) => {
    searchParams.set("ban-user-action", status);
    setSearchParams(searchParams);
  };

  const onCloseToggleUserModal = () => {
    searchParams.delete("ban-user-action");
    setSearchParams(searchParams);
  };

  // fetch data
  useEffect(() => {
    if (!userId) return;
    const controller = new AbortController();
    dispatch(getSingleUser(+userId, controller.signal));

    return () => controller.abort();
  }, [userId]);

  // bread crumbs
  const breadCrumbs = useBreadCrumbs();

  //
  const {
    isOpen: isEditOpen,
    onClose: onEditClose,
    onOpen: onEditOpen
  } = useDisclosure();

  const {
    isOpen: isPushOpen,
    onOpen: onPushOpen,
    onClose: onPushClose
  } = useDisclosure();

  const { isOpen: isLienOpen, onToggle: onToggleLien } = useDisclosure();

  return (
    <>
      <Modal
        size={"6xl"}
        onClose={onClose}
        scrollBehavior="outside"
        isOpen={!user.loading && isOpen}
      >
        <ModalOverlay />
        <ModalContent minH={"calc(100vh - 8rem)"}>
          <ModalHeader
            fontSize={"14px"}
            lineHeight={"1.2"}
            fontWeight={"normal"}
          >
            <Stack spacing={"4"} direction="row" alignItems={"center"}>
              <Icon as={BsArrowsAngleExpand} />
              <Breadcrumb mb={"5"}>
                {breadCrumbs.map((el, idx) => (
                  <BreadcrumbItem key={idx}>
                    <BreadcrumbLink href={el.to}>{el.title}</BreadcrumbLink>
                  </BreadcrumbItem>
                ))}
                <BreadcrumbItem>
                  <BreadcrumbLink isCurrentPage={true}>{userId}</BreadcrumbLink>
                </BreadcrumbItem>
              </Breadcrumb>
            </Stack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Divider />
            <Flex
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
              my={"4"}
            >
              <Stack direction={"row"} alignItems="center">
                <Avatar size={"lg"} rounded={"2xl"} />
                <Stack>
                  <Stack direction="row">
                    <Heading
                      as={"h6"}
                      fontSize="24px"
                      lineHeight={"1.1"}
                      fontWeight="700"
                      textTransform={"capitalize"}
                      fontFamily="karla"
                    >
                      {user.data?.firstName || "--"}
                    </Heading>

                    {(user.data?.isDeleted || user.data?.isSuspended) && (
                      <span>
                        <Badge variant="solid" colorScheme="red">
                          {user?.data?.isDeleted ? "Deleted" : "Suspended"}
                        </Badge>
                      </span>
                    )}
                  </Stack>
                  <Badge
                    variant="outline"
                    colorScheme="gray"
                    rounded={"xl"}
                    py="2"
                    px="4"
                    textTransform={"capitalize"}
                  >
                    {user.data?.plan || "--"}
                    <Icon
                      as={BsLightningChargeFill}
                      ml="2"
                      w={"4"}
                      h={"4"}
                      color="yellow.400"
                    />
                  </Badge>
                </Stack>
              </Stack>
              <Stack direction={"row"} spacing="4">
                <Button
                  colorScheme={"primary"}
                  size={["sm", "md"]}
                  onClick={onPushOpen}
                >
                  Push Notification
                </Button>
                <Button
                  colorScheme="primary"
                  variant="outline"
                  onClick={onEditOpen}
                >
                  Edit
                </Button>
                <Button
                  size={["sm", "md"]}
                  colorScheme={"primary"}
                  onClick={onToggleLien}
                >
                  Add Lien
                </Button>
                <Button
                  colorScheme={user.data?.blocked ? "green" : "red"}
                  size={"md"}
                  onClick={() =>
                    onToggleUserAction(
                      user.data?.blocked ? "activate" : "deactivate"
                    )
                  }
                >
                  {user.data?.blocked ? "Activate" : "Deactivate"}
                </Button>
              </Stack>
            </Flex>
            <Divider />
            <Stack mt={"4"}>
              <Flex
                direction={"row"}
                justifyContent="space-between"
                alignItems={"center"}
                mb={"2"}
              >
                <Heading
                  as={"h6"}
                  fontSize="20px"
                  lineHeight={"1.1"}
                  fontWeight="700"
                  textTransform={"capitalize"}
                  fontFamily="karla"
                >
                  User Details
                </Heading>
                <Text color={"gray.600"} fontStyle="italic">
                  Registered:{" "}
                  {user.data
                    ? formatDateOnlyToDateString(user.data.createdAt)
                    : "--"}
                </Text>
              </Flex>
              <Stack
                spacing={"5"}
                direction="row"
                pt={"3"}
                overflowX="scroll"
                className="no-scrollbar"
              >
                <TabLink
                  urlParamKey={"user-detail"}
                  urlParamValue={"personal-information"}
                >
                  <Stack direction={"row"}>
                    <Icon as={ProfileCheckOutlineIcon} w={"5"} h={"5"} />
                    <Text>Personal Information</Text>
                  </Stack>
                </TabLink>
                <TabLink
                  urlParamKey={"user-detail"}
                  urlParamValue={"verification"}
                >
                  <Stack direction={"row"}>
                    <Icon as={ProfileCheckOutlineIcon} w={"5"} h={"5"} />
                    <Text>Verification</Text>
                  </Stack>
                </TabLink>
                <TabLink urlParamKey={"user-detail"} urlParamValue={"wallets"}>
                  <Stack direction={"row"}>
                    <Icon as={WalletOutlineIcon} w={"5"} h={"5"} />
                    <Text>Wallet</Text>
                  </Stack>
                </TabLink>
                <TabLink
                  urlParamKey={"user-detail"}
                  urlParamValue={"transactions"}
                >
                  <Stack direction={"row"}>
                    <Icon as={CardOutlineIcon} w={"5"} h={"5"} />
                    <Text>Transactions</Text>
                  </Stack>
                </TabLink>
                <TabLink
                  urlParamKey={"user-detail"}
                  urlParamValue={"virtual-cards"}
                >
                  <Stack direction={"row"}>
                    <Icon as={IoCardOutline} w={"5"} h={"5"} />
                    <Text>Virtual Cards</Text>
                  </Stack>
                </TabLink>
                <TabLink
                  urlParamKey={"user-detail"}
                  urlParamValue={"virtual-accounts"}
                >
                  <Stack direction={"row"}>
                    <Icon as={WalletMoney} w={"5"} h={"5"} />
                    <Text>Virtual Accounts</Text>
                  </Stack>
                </TabLink>
                <TabLink
                  urlParamKey={"user-detail"}
                  urlParamValue={"subscriptions"}
                >
                  <Stack direction={"row"}>
                    <Icon as={Repeat} w={"5"} h={"5"} />
                    <Text>Subscriptions</Text>
                  </Stack>
                </TabLink>
                <TabLink urlParamKey={"user-detail"} urlParamValue={"liens"}>
                  <Stack direction={"row"}>
                    <Icon as={MoneyForbidden} w={"5"} h={"5"} />
                    <Text>Liens</Text>
                  </Stack>
                </TabLink>
                <TabLink
                  urlParamKey={"user-detail"}
                  urlParamValue={"ban-history"}
                >
                  <Stack direction={"row"}>
                    <Icon as={ProfileDelete} w={"5"} h={"5"} />
                    <Text>Ban History</Text>
                  </Stack>
                </TabLink>
              </Stack>
            </Stack>
            <Divider />
            {searchParams.get("user-detail") === "personal-information" ? (
              <PersonalInformation />
            ) : searchParams.get("user-detail") === "verification" ? (
              <Verification />
            ) : searchParams.get("user-detail") === "wallets" ? (
              <UserWallets />
            ) : searchParams.get("user-detail") === "transactions" ? (
              <UserTransactions />
            ) : searchParams.get("user-detail") === "virtual-cards" ? (
              <UserVirtualCards />
            ) : searchParams.get("user-detail") === "virtual-accounts" ? (
              <UserVirtualAccounts />
            ) : searchParams.get("user-detail") === "liens" ? (
              <UserLiens />
            ) : searchParams.get("user-detail") === "ban-history" ? (
              <BanHistory />
            ) : searchParams.get("user-detail") === "subscriptions" ? (
              <UserSubscriptions />
            ) : null}
            <Divider />
          </ModalBody>

          <ModalFooter>
            <Button
              size={"lg"}
              borderColor="gray.400"
              colorScheme="gray"
              _hover={{ background: "primary.100" }}
              variant="outline"
              px="6"
              w={"full"}
              onClick={onClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {!!user.data ? (
        <EditUserModal isOpen={isEditOpen} onClose={onEditClose} />
      ) : null}
      {!!user.data ? (
        <PushNotificationSingle
          isOpen={isPushOpen}
          onClose={onPushClose}
          user={user}
        />
      ) : null}

      {!!user.data ? (
        <AddLien isOpen={isLienOpen} onClose={onToggleLien} user={user} />
      ) : null}

      <ToggleUser
        isOpen={
          !!searchParams.get("user-id") && !!searchParams.get("ban-user-action")
        }
        onClose={onCloseToggleUserModal}
      />
    </>
  );
};

export default UserDetails;
