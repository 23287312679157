import { Box, BoxProps, Stack } from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";

type Props = {
  urlParamKey: string;
  urlParamValue: string;
  count?: number;
} & BoxProps;

const TabLink = ({
  children,
  urlParamValue,
  count,
  urlParamKey,
  ...props
}: Props): JSX.Element => {
  let [searchParams, setSearchParams] = useSearchParams();
  let isActive = searchParams.get(urlParamKey) === urlParamValue;

  // to={`?${urlParamKey}=${urlParamValue}`}

  const onClick = () => {
    searchParams.set(urlParamKey, urlParamValue);
    setSearchParams(searchParams);
  };

  return (
    <Box w={"fit-content"} cursor="pointer" onClick={onClick}>
      <Box
        fontSize={"16px"}
        fontWeight={isActive ? 500 : 400}
        lineHeight={"1.2"}
        width="100%"
        flexGrow={1}
        flexBasis="50%"
        borderRadius="8px"
        alignItems="center"
        display="inline-flex"
        justifyContent="center"
        color={isActive ? "gray.900" : "gray.600"}
        position="relative"
        _after={{
          content: "''",
          width: "60%",
          height: "2px",
          backgroundColor: isActive ? "primary.500" : "transparent",
          position: "absolute",
          top: "calc(100% + 5px)",
          left: "0"
        }}
        {...props}
      >
        <Stack
          direction={"row"}
          spacing="1"
          alignItems={"baseline"}
          whiteSpace="nowrap"
        >
          <Box>{children}</Box>
          <Box
            fontSize={"12px"}
            lineHeight="1.2"
            fontWeight={"400"}
            color={isActive ? "blue.500" : "gray.600"}
          >
            {isActive ? count : ""}
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default TabLink;
