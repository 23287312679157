import {
  Td,
  Th,
  Tr,
  Table,
  Tbody,
  Thead,
  TableContainer
} from "@chakra-ui/react";
import React from "react";
import { getAllTransactions } from "app/store";
import getSymbolFromCurrency from "currency-symbol-map";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { ChangeraTransactionType } from "app/store/models/transactions";
import { TransactionBadge } from "views/components/badge";
import { usePagination } from "@ajna/pagination";
import PaginationWrapper from "views/components/PaginationWrapper/PaginationWrapper";
import { useUserDetails } from "app/hooks/use-user-details";
import { formatFullDateToUsersLocale } from "data/utils/date-formatter-users-locale";

const head = [
  "Username",
  "Amount",
  "Balance Before",
  "Balance After",
  "Rate",
  "Payment Gateway",
  "Currency / Type",
  "Wallet ID",
  "Date",
  "Status"
];

const Funding = () => {
  const dispatch = useAppDispatch();
  const funding = useAppSelector((state) => state.transactions.allTransactions);

  const {
    pages,
    offset,
    pageSize,
    pagesCount,
    isDisabled,
    currentPage,
    setCurrentPage
  } = usePagination({
    limits: { inner: 2, outer: 1 },
    total: funding?.pagination?.total,
    initialState: { currentPage: 1, pageSize: 10 }
  });

  const handlePageChange = (nextPage: number) => {
    setCurrentPage(nextPage);
    setTimeout(() => {
      window.scrollTo({ top: 10, behavior: "smooth" });
    }, 500);
  };

  React.useEffect(() => {
    dispatch(
      getAllTransactions({
        page: 1,
        perpage: 10,
        changeraTransactionType: ChangeraTransactionType.FUNDING_WALLET
      })
    );
  }, [currentPage]);

  // user details
  const { onOpenUserDetails, UserDetail } = useUserDetails();

  if (funding.loading) return <p>Loading...</p>;

  return (
    <React.Fragment>
      <TableContainer>
        <Table variant="striped" colorScheme="gray">
          <Thead>
            <Tr>
              {head.map((data) => (
                <Th
                  key={data}
                  fontSize={16}
                  color="gray.800"
                  fontWeight={500}
                  lineHeight="19px"
                  letterSpacing="0px"
                  textTransform="unset"
                >
                  {data}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {funding?.data?.map((data) => (
              <Tr key={data.id}>
                <Td
                  cursor={data.userId ? "pointer" : "not-allowed"}
                  _hover={{ color: "#B1B4BF" }}
                  onClick={() => {
                    if (!data.id) return;
                    onOpenUserDetails(data.userId);
                  }}
                >
                  {data?.user?.username || "-"}
                </Td>
                <Td>
                  {getSymbolFromCurrency(data.currency)}{" "}
                  {data && data.amount ? data.amount.toLocaleString() : ""}
                </Td>
                <Td>
                  {getSymbolFromCurrency(data.currency)}{" "}
                  {data && data.balanceBefore
                    ? data.balanceBefore.toLocaleString()
                    : "-"}
                </Td>
                <Td>
                  {getSymbolFromCurrency(data.currency)}{" "}
                  {data && data.balanceAfter
                    ? data.balanceAfter.toLocaleString()
                    : "-"}
                </Td>
                <Td>
                  {getSymbolFromCurrency(data.currency)}{" "}
                  {data && data.rate ? data.rate.toLocaleString() : "-"}
                </Td>
                <Td>{data.paymentGateway || "-"}</Td>
                <Td>{`${data.currency.toUpperCase() || "--"} / ${
                  data.currencyType || "--"
                }`}</Td>
                <Td>{data.walletId || "-"}</Td>

                <Td color="gray.500" lineHeight="18px" fontSize={14}>
                  {formatFullDateToUsersLocale(data.createdAt)}
                </Td>

                <Td>
                  <TransactionBadge children="Successful" variant="success" />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      <PaginationWrapper
        pages={pages}
        size={pageSize}
        offset={offset}
        isDisabled={isDisabled}
        pagesCount={pagesCount}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        totalDataCount={funding?.pagination?.total}
      />

      {false && UserDetail}
    </React.Fragment>
  );
};

export default Funding;
