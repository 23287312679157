import { Box, Button, Heading, Stack, useDisclosure } from "@chakra-ui/react";
import AddFeature from "../modals/add-feature";
import FeatureTable from "./feature-table";

const FeatureTableContainer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box bg="#fff" borderRadius="16px" padding={"1rem 1rem 24px"}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems="center"
      >
        <Heading
          as={"h3"}
          fontSize="24px"
          fontWeight={"medium"}
          lineHeight="28px"
          py="4"
          px="2"
        >
          Features
        </Heading>
        <Button
          size={"sm"}
          borderColor="gray.400"
          colorScheme="primary"
          _hover={{ background: "primary.700" }}
          px="6"
          py="5"
          onClick={onOpen}
        >
          Add Feature
        </Button>
      </Stack>
      <FeatureTable />
      <AddFeature isOpen={isOpen} onClose={onClose} />
    </Box>
  );
};

export default FeatureTableContainer;
