import Dashboard from "views/pages/dashboard";
import Request from "views/containers/request";
import DashboardLayout from "views/layouts/dashboard";
import { Navigate, RouteObject } from "react-router-dom";
import UserInformation from "views/pages/user-information";
import Verification from "views/pages/verification";
import Wallets from "views/pages/wallets";
import VirtualAccounts from "views/pages/virtual-accounts";
import Transactions from "views/pages/transactions";
import Reports from "views/pages/reports";
import TeamManagement from "views/pages/team-management";
import AuthLayout from "views/layouts/auth";
import Login from "views/pages/authentication/login";
import PrivateRoute from "./privateRoute";
import Unauthorized from "views/pages/unauthorized/401";
import NotFound from "views/pages/unauthorized/404";
import ExchageRates from "views/pages/exchange-rate";
import Feature from "views/pages/feature";
import WalletTransactions from "views/containers/wallets/pages/wallet-transactions";
import VirtualCards from "views/pages/virtual-cards";
// import Flights from "views/pages/flight";
import { Generate2FA } from "views/containers/auth/generate-2fa";
import BitmamaMerchants from "views/pages/bitmama-merchants";
// import Campaign from "views/pages/campaign";
// import FX from "views/pages/fx";
// import WishList from "views/pages/wishlist";
import ValentineWishList from "views/pages/valentine-wishlist";

const routes: RouteObject[] = [
  {
    element: <AuthLayout />,
    children: [
      {
        element: <Login />,
        path: "/auth/login"
      },
      {
        element: <Generate2FA />,
        path: "/auth/2fa"
      }
    ]
  },
  {
    element: (
      <PrivateRoute>
        <DashboardLayout />
      </PrivateRoute>
    ),
    children: [
      {
        element: <Navigate to="/dashboard" replace />,
        path: "/"
      },
      {
        element: <Dashboard />,
        path: "/dashboard"
      },
      {
        element: <Request />,
        path: "requests"
      },
      {
        element: <UserInformation />,
        path: "user-information"
      },
      {
        element: <Verification />,
        path: "verification"
      },
      {
        element: <Wallets />,
        path: "wallets"
      },
      {
        element: <VirtualAccounts />,
        path: "virtual-accounts"
      },
      {
        element: <WalletTransactions />,
        path: "wallets/transactions"
      },
      {
        element: <Transactions />,
        path: "transactions"
      },
      {
        element: <VirtualCards />,
        path: "virtual-cards"
      },
      {
        element: <Reports />,
        path: "reports"
      },
      {
        element: <TeamManagement />,
        path: "team-management"
      },
      {
        element: <ExchageRates />,
        path: "exchange-rates"
      },
      // {
      //   element: <FX />,
      //   path: "fx"
      // },
      // {
      //   element: <WishList />,
      //   path: "wishlist"
      // },
      {
        element: <ValentineWishList />,
        path: "valentine-wishlist"
      },
      {
        element: <Feature />,
        path: "feature"
      },
      // {
      //   element: <Flights />,
      //   path: "flights"
      // },
      // {
      //   element: <Campaign />,
      //   path: "campaign"
      // },
      {
        element: <BitmamaMerchants />,
        path: "bitmama-merchants"
      },
      { element: <Unauthorized />, path: "/unauthorised" }
    ]
  },
  {
    element: <NotFound />,
    path: "*"
  }
];

export default routes;
