import { Dispatch } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";
import exchangeRateService from "data/services/exchange-rate-service";
import toast from "react-hot-toast";
import { actions } from "../reducers/exchange-rate.reducer";
import { IGetExchangeRatesV4Params } from "../models/exchange-rate";

export const getAllExchangeRates = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getAllExchangeRatePending());
      const res = await exchangeRateService.getAllExchangeRates();
      if (res.status === 200 || 201 || 202) {
        dispatch(
          actions.getAllExchangeRateSuccess({
            data: res.data.data
          })
        );
      }
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.getAllExchangeRateFailed());
      toast.error(msg);
    }
  };
};

export const getAllExchangeRatesV4 = ({ page }: IGetExchangeRatesV4Params) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getAllExchangeRatePending());
      const res = await exchangeRateService.getAllExchangeRatesV4({ page });
      if (res.status === 200 || 201 || 202) {
        dispatch(
          actions.getAllExchangeRateSuccess({
            data: res.data.data,
            pagination: res?.data?.pagination
          })
        );
      }
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.getAllExchangeRateFailed());
      toast.error(msg);
    }
  };
};
