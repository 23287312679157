import {
  Td,
  Th,
  Tr,
  Table,
  Tbody,
  Thead,
  TableContainer,
  Stack,
  Text,
  Icon
} from "@chakra-ui/react";
import React from "react";
import WithdrawalSkeleton from "./skeleton";
import { usePagination } from "@ajna/pagination";
import { getWalletTransactions } from "app/store";
import getSymbolFromCurrency from "currency-symbol-map";
import { TransactionBadge } from "views/components/badge";
import { useAppDispatch, useAppSelector, useCopyToClipboard } from "app/hooks";
import PaginationWrapper from "views/components/PaginationWrapper/PaginationWrapper";
import { formatFullDateToUsersLocale } from "data/utils/date-formatter-users-locale";
import { ChangeraTransactionType } from "app/store/models/transactions";
import NoData from "views/components/no-data";
import { IoMdCopy } from "react-icons/io";

type Props = { walletId: number };

const head = ["Transaction Ref", "Description", "Amount", "Date", "Status"];

const Withdrawals = ({ walletId }: Props) => {
  const dispatch = useAppDispatch();
  const copyHandler = useCopyToClipboard();
  const [data, loading, pagination] = useAppSelector((state) => [
    state.transactions.walletTransactions.data,
    state.transactions.walletTransactions.loading,
    state.transactions.walletTransactions.pagination
  ]);

  const {
    pages,
    offset,
    pageSize,
    pagesCount,
    isDisabled,
    currentPage,
    setCurrentPage
  } = usePagination({
    total: pagination?.total,
    limits: { inner: 2, outer: 1 },
    initialState: { currentPage: 1, pageSize: 10 }
  });

  const handlePageChange = (nextPage: number) => {
    setCurrentPage(nextPage);
    setTimeout(() => {
      window.scrollTo({ top: 10, behavior: "smooth" });
    }, 500);
  };

  React.useEffect(() => {
    if (!walletId || loading) return;

    dispatch(
      getWalletTransactions({
        walletId,
        perpage: 10,
        isBitmama: true,
        page: currentPage,
        changeraTransactionType:
          ChangeraTransactionType.BITMAMA_MERCHANT_DEPOSIT
      })
    );
  }, [currentPage]);

  return (
    <React.Fragment>
      <TableContainer>
        <Table variant="striped" colorScheme="gray">
          <Thead>
            <Tr>
              {head.map((data) => (
                <Th
                  key={data}
                  fontSize={16}
                  color="gray.800"
                  fontWeight={500}
                  lineHeight="19px"
                  letterSpacing="0px"
                  textTransform="unset"
                >
                  {data}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {loading ? <WithdrawalSkeleton /> : null}

            {!!data && !loading
              ? data?.map((d) => (
                  <Tr key={d.id}>
                    <Td>
                      <Stack spacing="2" direction="row" alignItems="center">
                        <Text maxWidth="250px" className="truncate">
                          {d?.reference || "--"}
                        </Text>
                        {d?.reference && (
                          <Icon
                            w={5}
                            h={5}
                            as={IoMdCopy}
                            color="success.300"
                            cursor={"pointer"}
                            onClick={() =>
                              copyHandler(
                                d?.reference || "",
                                "Transaction Reference"
                              )
                            }
                          />
                        )}
                      </Stack>
                    </Td>

                    <Td>{d?.description || "-"}</Td>

                    <Td>
                      {d?.currency ? getSymbolFromCurrency(d?.currency) : ""}
                      {d?.amount?.toLocaleString() || "--"}
                    </Td>

                    <Td color="gray.500" lineHeight="18px" fontSize={14}>
                      {formatFullDateToUsersLocale(d.createdAt)}
                    </Td>

                    <Td>
                      <TransactionBadge
                        cursor="pointer"
                        children={d?.status}
                        variant={d?.status as any}
                      />
                    </Td>
                  </Tr>
                ))
              : null}

            {!data?.length && !loading ? (
              <Tr>
                <Td colSpan={7} background="transparent" h="xl">
                  <NoData text={`No Transactions Yet`} />
                </Td>
              </Tr>
            ) : null}
          </Tbody>
        </Table>
      </TableContainer>

      <PaginationWrapper
        pages={pages}
        size={pageSize}
        offset={offset}
        isDisabled={isDisabled}
        pagesCount={pagesCount}
        currentPage={currentPage}
        totalDataCount={pagination?.total}
        handlePageChange={handlePageChange}
      />
    </React.Fragment>
  );
};

export default Withdrawals;
