import Input, { InputProps } from "../input";
import { forwardRef } from "@chakra-ui/react";
import "react-datepicker/dist/react-datepicker.css";
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";

type Props = ReactDatePickerProps & { customInputProps?: InputProps };

const CustomInput = forwardRef((props: InputProps, ref) => (
  <Input ref={ref} {...props} />
));

const DatePicker = ({
  popperClassName = "hide !translate-x-0 !translate-y-0 !relative",
  customInput,
  customInputProps,
  ...props
}: Props) => {
  return (
    <ReactDatePicker
      customInput={customInput || <CustomInput {...customInputProps} />}
      wrapperClassName="w-full"
      dateFormat={props.dateFormat}
      popperClassName={popperClassName}
      {...props}
    />
  );
};

export default DatePicker;
