import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Stack,
  Button,
  Icon,
  Heading,
  Spinner,
  Badge
} from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  getAllVirtualCards,
  getMonoCardDetails,
  getUnionCardDetails,
  updateVirtualCardStatus
} from "app/store";
import getSymbolFromCurrency from "currency-symbol-map";
import { FC, useEffect } from "react";
import { BsArrowsAngleExpand } from "react-icons/bs";

interface Props {
  selectedCard: any;
  isOpen: any;
  onClose: any;
}
const CardDetails: FC<Props> = ({ selectedCard, isOpen, onClose }) => {
  const dispatch = useAppDispatch();

  const callback = () => {
    dispatch(
      getAllVirtualCards({ page: 1, userId: selectedCard?.userId, perpage: 10 })
    );
  };

  const cardDetails = useAppSelector((state) => state.virtualCards.cardDetails);
  const [loading, isUpdating] = useAppSelector((state) => [
    state.virtualCards.cardDetails.loading,
    state.virtualCards.updateCard.loading
  ]);

  const handleUpdateCardStatus = () => {
    const id = selectedCard.id as number;
    if (!id || isUpdating) return;

    const status =
      cardDetails.data?.status === "inactive" ? "active" : "inactive";

    dispatch(updateVirtualCardStatus(id, status, callback));
  };

  useEffect(() => {
    if (!selectedCard || isUpdating) return;
    const controller = new AbortController();

    if (selectedCard && selectedCard.provider === "mono") {
      dispatch(getMonoCardDetails(selectedCard.id, controller.signal));
    } else {
      dispatch(getUnionCardDetails(selectedCard.id, controller.signal));
    }

    return () => controller.abort();
  }, [isUpdating, selectedCard]);

  if (!selectedCard) return null;

  return (
    <div>
      <Modal isCentered isOpen={isOpen} onClose={onClose} size={"4xl"}>
        <ModalOverlay />
        <ModalContent px={2} pt={2} pb={4}>
          <ModalHeader m={0} fontSize={18} color="#333843" fontWeight={600}>
            <Stack spacing={"4"} direction="row" alignItems={"center"}>
              <Icon as={BsArrowsAngleExpand} />
              <Heading
                as="h6"
                fontSize="24px"
                lineHeight="1.1"
                fontWeight="700"
                fontFamily="karla"
                textTransform="capitalize"
              >
                Card Details
              </Heading>
            </Stack>
          </ModalHeader>

          <ModalBody mb={2} color="#444B59">
            {loading ? (
              <div className="flex justify-center">
                <Spinner />
              </div>
            ) : (
              <>
                {" "}
                <div className="flex justify-between my-4">
                  <Heading
                    fontSize="20px"
                    lineHeight={"1.1"}
                    fontWeight="600"
                    textTransform={"capitalize"}
                    fontFamily="karla"
                  >
                    Name on card: {cardDetails?.data?.nameOnCard || "--"}
                  </Heading>
                  <Heading
                    fontSize="20px"
                    lineHeight={"1.1"}
                    color="primary.900"
                    fontWeight="600"
                    textTransform={"capitalize"}
                    fontFamily="karla"
                  >
                    Balance:{" "}
                    {cardDetails.data
                      ? getSymbolFromCurrency(cardDetails?.data?.currency)
                      : ""}
                    {cardDetails?.data?.balance?.availableBalance?.toLocaleString()}
                  </Heading>
                </div>
                <hr />
                <div className="grid grid-cols-1 gap-3 my-8 lg:grid-cols-3">
                  <p className="text-gray-900">
                    Currency:{" "}
                    <span className="text-gray-600">
                      {cardDetails?.data?.currency || "--"}
                    </span>
                  </p>

                  <p className="text-gray-900">
                    Brand:{" "}
                    <span className="text-gray-600">
                      {cardDetails?.data?.brand || "--"}
                    </span>
                  </p>

                  <p className="text-gray-900">
                    Last 4 Digits:{" "}
                    <span className="text-gray-600">
                      {cardDetails?.data?.last4 || "--"}
                    </span>
                  </p>
                  <div className="flex items-center">
                    <p className="mr-2 text-gray-900">Card Status </p>
                    <Badge
                      colorScheme={
                        cardDetails?.data?.status === "active" ? "green" : "red"
                      }
                      px="3"
                      py="1"
                      rounded={"2xl"}
                      textTransform="capitalize"
                      display={"flex"}
                      alignItems="center"
                      justifyContent={"space-between"}
                      gap={"2"}
                      color={
                        cardDetails?.data?.status === "active"
                          ? "green.500"
                          : "red.500"
                      }
                      fontWeight={"normal"}
                    >
                      <p className="text-gray-900">
                        {cardDetails?.data?.status === "active"
                          ? "Active"
                          : "Inactive"}
                      </p>
                    </Badge>
                  </div>

                  {/* <div className="flex">
                    <p className="mr-2 text-gray-900">Can Fund ?</p>
                    <Badge
                      colorScheme={cardDetails.data?.canFund ? "green" : "red"}
                      px="3"
                      py="1"
                      rounded={"2xl"}
                      textTransform="capitalize"
                      display={"flex"}
                      alignItems="center"
                      justifyContent={"space-between"}
                      gap={"2"}
                      color={
                        cardDetails.data?.canFund ? "green.500" : "red.500"
                      }
                      fontWeight={"normal"}
                    >
                      <p className="text-gray-900">
                        {cardDetails.data?.canFund ? "True" : "False"}
                      </p>
                    </Badge>
                  </div>
                  <div className="flex">
                    <p className="mr-2 text-gray-900">Can Withdraw ?</p>
                    <Badge
                      colorScheme={
                        cardDetails.data?.canWithdraw ? "green" : "red"
                      }
                      px="3"
                      py="1"
                      rounded={"2xl"}
                      textTransform="capitalize"
                      display={"flex"}
                      alignItems="center"
                      justifyContent={"space-between"}
                      gap={"2"}
                      color={
                        cardDetails.data?.canWithdraw ? "green.500" : "red.500"
                      }
                      fontWeight={"normal"}
                    >
                      <p className="text-gray-900">
                        {cardDetails.data?.canWithdraw ? "True" : "False"}
                      </p>
                    </Badge>
                  </div> */}
                </div>
                <Stack gap={2} direction="row" className="mt-6">
                  <Button
                    size="md"
                    colorScheme={
                      cardDetails?.data?.status === "inactive" ? "green" : "red"
                    }
                    width="full"
                    onClick={handleUpdateCardStatus}
                    isLoading={isUpdating}
                    disabled={isUpdating}
                  >
                    {cardDetails?.data?.status === "inactive"
                      ? "Activate Card"
                      : "Freeze Card"}
                  </Button>
                  <Button
                    width="full"
                    size="md"
                    colorScheme="primary"
                    onClick={onClose}
                    variant="outline"
                  >
                    Close
                  </Button>
                </Stack>
              </>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default CardDetails;
