import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Divider,
  Spinner,
  Checkbox
} from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { getAllFeatures } from "app/store";
import { AxiosError } from "axios";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";
import featureService from "data/services/feature-service";
import { useState } from "react";
import toast from "react-hot-toast";
import { BaseModalProps } from "../models";

const AddFeature = ({ isOpen, onClose }: BaseModalProps) => {
  //
  const dispatch = useAppDispatch();
  const pagination = useAppSelector(
    (state) => state.feature.allFeatures.pagination
  );

  //
  const [loading, setLoading] = useState(false);

  const [state, setState] = useState({
    feature: "",
    active: false
  });

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setState((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  //
  const processFeatureHandler = async () => {
    const formattedState = {
      ...state,
      feature: state.feature.replace(/\s+/g, " ").trim().split(" ").join("-")
    };
    setLoading(true);
    try {
      const res = await featureService.addFeature(formattedState);
      if (res.status === 200 || 201 || 202) {
        dispatch(
          getAllFeatures({
            page: pagination.currentPage,
            perpage: pagination.pageSize
          })
        );
        toast.success(res.data.message);
        onClose();
      }
      setLoading(false);
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textTransform={"capitalize"}>Add Feature</ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody>
          <Stack my="5" spacing={"4"}>
            <FormControl>
              <FormLabel color={"gray.600"} fontWeight="400">
                Title
              </FormLabel>
              <Input
                type="text"
                value={state.feature}
                name="feature"
                onChange={onChangeHandler}
              />
            </FormControl>
            <FormControl>
              <Checkbox
                checked={state.active}
                onChange={(e) =>
                  setState((prev) => ({
                    ...prev,
                    active: e.target.checked
                  }))
                }
              >
                Active
              </Checkbox>
            </FormControl>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme={"primary"}
            variant={"outline"}
            onClick={onClose}
            mr="3"
            w="full"
          >
            Cancel
          </Button>
          <Button
            colorScheme="primary"
            w="full"
            onClick={processFeatureHandler}
            disabled={loading}
          >
            {loading ? <Spinner /> : "Add Feature"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddFeature;
