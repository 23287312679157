import React from "react";
import Header from "./header";
import WishLists from "./wishlists";
import ValentineWishListTopUsersTable from "./top-users";
import ValentineWishListSelectedWinnersTable from "./selected-winners";
import { Tab, Tabs, TabList, TabPanel, TabPanels } from "@chakra-ui/react";

type Props = {
  showTableList: () => void;
  showCreatePage: () => void;
  showUpdateWishList: () => void;
};

const WishListTable = ({ showCreatePage, showUpdateWishList }: Props) => {
  return (
    <React.Fragment>
      <Header showCreatePage={showCreatePage} />
      <WishLists showUpdateWishList={showUpdateWishList} />

      <Tabs colorScheme="primary">
        <TabList>
          <Tab>Top Weekly Users</Tab>
          <Tab>Selected Winners</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <ValentineWishListTopUsersTable />
          </TabPanel>
          <TabPanel>
            <ValentineWishListSelectedWinnersTable />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </React.Fragment>
  );
};

export default WishListTable;
