import { useAppSelector } from "app/hooks";
import { Grid, GridItem } from "@chakra-ui/react";
import { MoneyIcon } from "views/components/icons";
import StatsCard from "views/components/stats-card";
import getSymbolFromCurrency from "currency-symbol-map";
import { WalletCheckSolidIcon } from "views/components/icons/wallet-check";

const Stats = () => {
  const formatter = new Intl.NumberFormat("en-US", {
    notation: "compact",
    compactDisplay: "short",
    maximumFractionDigits: 2
  });

  const summary = useAppSelector((state) => state.summary.data);

  return (
    <Grid
      templateColumns={[
        "repeat(1, 1fr)",
        "repeat(2, 1fr)",
        "repeat(2, 1fr)",
        "repeat(2, 1fr)",
        "repeat(3, 1fr)"
      ]}
      mb={6}
      gap={6}
    >
      <GridItem w="100%">
        <StatsCard
          name="Wallets Created"
          icon={WalletCheckSolidIcon}
          value={
            summary?.totalWallets ? formatter.format(summary.totalWallets) : "-"
          }
        />
      </GridItem>

      <GridItem w="100%">
        <StatsCard
          name="Transaction Volume"
          icon={MoneyIcon}
          value={
            summary?.yearlyTransactions[0].total
              ? getSymbolFromCurrency("USD") +
                formatter.format(summary?.yearlyTransactions[0].total)
              : "-"
          }
        />
      </GridItem>

      <GridItem w="100%">
        <StatsCard
          icon={WalletCheckSolidIcon}
          value={
            summary?.totalUsers ? formatter.format(summary?.totalUsers) : "-"
          }
          name="Registered Users"
        />
      </GridItem>
    </Grid>
  );
};

export default Stats;
