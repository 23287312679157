import {
  IGetWishListParams,
  IGetWishListItemParams,
  ICreateWishListItemsBody,
  IUpdateWishListItemsBody,
  IGetValentineWishListItemParams,
  IAddValentineWishListItemsBody,
  ISelectValentineWishListWinnerBody,
  IGetValentineWishListTopUsersParams
} from "app/store/models/wishlist";
import { AxiosError } from "axios";
import toast from "react-hot-toast";
import { Dispatch } from "@reduxjs/toolkit";
import { actions } from "../reducers/wishlist.reducer";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";
import wishlistService from "data/services/wishlist.service";

export const getWishList = (params: IGetWishListParams) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getWishListBegin());
      const res = await wishlistService.getWishList(params);
      dispatch(actions.getWishListSuccess(res.data));
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.getWishListFailed(msg));
    }
  };
};

export const updateWishListItems = (body: IUpdateWishListItemsBody) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.updateWishListItemsBegin());
      const res = await wishlistService.updateWishListitems(body);
      dispatch(actions.updateWishListItemsSuccess());
      toast.success(res.data.message);
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.updateWishListItemsFailed(msg));
    }
  };
};

export const getWishListItems = (params: IGetWishListItemParams) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getWishListItemsBegin());
      const res = await wishlistService.getWishListItems(params);
      dispatch(
        actions.getWishListListItemsSuccess({
          data: res.data.data,
          userType: params.userType,
          pagination: res.data.pagination
        })
      );
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.getWishListItemsFailed(msg));
    }
  };
};

export const approveWishList = (id: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.approveWishListBegin());
      const res = await wishlistService.approveWishList(id);

      if (res.data.message) {
        dispatch(actions.approveWishListSuccess());
        toast.success(res.data.message);
        return res.data.message;
      } else {
        toast.error(DEFAULT_ERROR_MESSAGE);
        dispatch(actions.approveWishListFailed(DEFAULT_ERROR_MESSAGE));
      }
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.approveWishListFailed(msg));
    }
  };
};

export const denyWishList = (id: number, reason?: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.denyWishListBegin());
      const res = await wishlistService.denyWishList(id, reason);

      if (res.data.message) {
        dispatch(actions.denyWishListSuccess());
        toast.success(res.data.message);
        return res.data.message;
      } else {
        toast.error(DEFAULT_ERROR_MESSAGE);
        dispatch(actions.denyWishListFailed(DEFAULT_ERROR_MESSAGE));
      }
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.denyWishListFailed(msg));
    }
  };
};

export const createWishListItems = (body: ICreateWishListItemsBody) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.createWishListItemsBegin());
      const res = await wishlistService.createWishListitems(body);
      dispatch(actions.createWishListItemsSuccess());
      toast.success(res.data.message);
      return res.data.message;
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.createWishListItemsFailed(msg));
    }
  };
};

export const getSingleWishList = (id: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getSingleWishListBegin());
      const res = await wishlistService.getSingleWishList(id);
      dispatch(actions.getSingleWishListSuccess(res.data.data));
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.getSingleWishListFailed(msg));
    }
  };
};

export const getValentineWishList = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getValentineWishListBegin());
      const res = await wishlistService.getValentineWishList();
      dispatch(actions.getValentineWishListSuccess(res.data));
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.getWishListFailed(msg));
    }
  };
};

export const updateValentineWishListItems = (
  id: number,
  body: { item: string }
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.updateValentineWishListItemsBegin());
      const res = await wishlistService.updateValentineWishListitems(id, body);
      dispatch(actions.updateValentineWishListItemsSuccess());
      toast.success(res.data.message);
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.updateValentineWishListItemsFailed(msg));
    }
  };
};

export const getValentineWishListItems = (
  params: IGetValentineWishListItemParams
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getValentineWishListItemsBegin());
      const res = await wishlistService.getValentineWishListItems(params);
      dispatch(
        actions.getValentineWishListListItemsSuccess({
          data: res.data.data,
          pagination: res.data.pagination
        })
      );
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.getValentineWishListFailed(msg));
    }
  };
};

export const addValentineWishListItems = (
  body: IAddValentineWishListItemsBody
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.addValentineWishListItemsBegin());
      const res = await wishlistService.addValentineWishListitems(body);
      dispatch(actions.addValentineWishListItemsSuccess());
      toast.success(res.data.message);
      return res.data.message;
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.addValentineWishListItemsFailed(msg));
    }
  };
};

export const selectValentineWishListWinner = (
  body: ISelectValentineWishListWinnerBody
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.selectValentineWishListWinnerBegin());
      const res = await wishlistService.selectValentineWishListWinner(body);
      dispatch(actions.selectValentineWishListWinnerSuccess());
      toast.success(res.data.message);
      return res.data.message;
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.selectValentineWishListWinnerFailed(msg));
    }
  };
};

export const getValentineWishListTopUsers = (
  body: IGetValentineWishListTopUsersParams
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getValentineWishListTopUsersBegin());
      const res = await wishlistService.getValentineWishListTopUsers(body);
      dispatch(actions.getValentineWishListListTopUsersSuccess(res.data));
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.selectValentineWishListWinnerFailed(msg));
    }
  };
};

export const getValentineWishListSelectedWinners = (
  body: IGetValentineWishListTopUsersParams
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getValentineWishListSelectedWinnersBegin());
      const res = await wishlistService.getValentineWishListWinners(body);
      dispatch(actions.getValentineWishListListWinnersSuccess(res.data));
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.getValentineWishListWinnersFailed(msg));
    }
  };
};
