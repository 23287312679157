import { useEffect } from "react";
import { useAppSelector } from "app/hooks";
import { Box, Spinner } from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import SingleUserVerificationTable from "views/containers/verification/verification-details/verifications";

const Verification = () => {
  const [userDetails] = useAppSelector((state) => [
    state.verification.singleVerification
  ]);

  let [searchParams, setSearchParams] = useSearchParams();

  const userParamValue = searchParams.get("user-id");
  const userDetailParamValue = searchParams.get("user-detail");
  const verificationTypeParamValue = searchParams.get("verification-detail");

  useEffect(() => {
    if (
      !!userParamValue &&
      !!userDetailParamValue &&
      !verificationTypeParamValue
    ) {
      searchParams.set("verification-detail", "verifications");
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  return (
    <>
      {userDetails?.loading ? (
        <Box display="flex" alignItems="center" justifyContent="center" mt={12}>
          <Spinner />
        </Box>
      ) : (
        <SingleUserVerificationTable />
      )}
    </>
  );
};

export default Verification;
