import { AxiosError } from "axios";
import toast from "react-hot-toast";
import { Dispatch } from "@reduxjs/toolkit";
import { actions } from "../reducers/summary.reducer";
import summaryService from "data/services/summary-service";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";

export const getSummary = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("getSummary"));
      const res = await summaryService.getSummary();
      dispatch(actions.setSummary(res.data.data));
      // toast.success(res.data.message);
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.error({ msg, for: "getSummary" }));
    }
  };
};
