import {
  Box,
  Breadcrumb,
  BreadcrumbLink,
  BreadcrumbItem
} from "@chakra-ui/react";
import React from "react";
import EditWishList from "./edit";
import WishListTable from "./table";
import PopulateWishList from "./populate";
import { useBreadCrumbs } from "app/hooks";

const ValentineWishListContainer = () => {
  const [page, setPage] = React.useState(0);

  const breadCrumbs = useBreadCrumbs();
  const showTableList = () => setPage(0);
  const showCreatePage = () => setPage(1);
  const showUpdateWishList = () => setPage(2);

  return (
    <React.Fragment>
      <Box className="mb-20">
        <Breadcrumb mb={"5"}>
          {breadCrumbs.map((el, idx) => (
            <BreadcrumbItem
              key={idx}
              isCurrentPage={idx === breadCrumbs.length - 1}
            >
              <BreadcrumbLink href={el.to}>{el.title}</BreadcrumbLink>
            </BreadcrumbItem>
          ))}
        </Breadcrumb>

        <Box bg="#fff" borderRadius="16px" padding="1rem 1rem 24px">
          {page === 0 && (
            <WishListTable
              showTableList={showTableList}
              showCreatePage={showCreatePage}
              showUpdateWishList={showUpdateWishList}
            />
          )}
          {page === 2 && <EditWishList showTableList={showTableList} />}
          {page === 1 && <PopulateWishList showTableList={showTableList} />}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default ValentineWishListContainer;
