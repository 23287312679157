import Header from "./header";
import { Box } from "@chakra-ui/react";

const DashboardHeader = () => (
  <Box
    p={6}
    top={0}
    zIndex={9}
    height={24}
    bgColor="#fff"
    display="flex"
    position="sticky"
    alignItems="center"
    borderBottomLeftRadius={10}
  >
    <Header />
  </Box>
);

export default DashboardHeader;
