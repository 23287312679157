import { format, parseISO, parse, isValid } from "date-fns";

export const formatDateToUsersLocale = (date: string) => {
  return format(parseISO(date), "MMM d, yyyy");
};

export const formatTimeToUsersLocale = (date: string) => {
  return format(parseISO(date), "h:mmaaa");
};

export const formatFullDateToUsersLocale = (date: string) => {
  return format(parseISO(date), "MMM d, yyyy. h:mmaaa");
};

export const getDate = (value: any) => {
  let date = parse(value, "dd-MM-yyyy", new Date());
  if (isValid(date)) return date;

  return new Date(value);
};
