import { Stack, Text } from "@chakra-ui/react";
import NoDataSVG from "app/assets/svg/no-data.svg";

const NoData = ({ text }: { text: string }) => (
  <Stack
    mt={24}
    spacing={8}
    width="full"
    height={"full"}
    alignItems="center"
    justifyContent={"center"}
  >
    <img alt="" src={NoDataSVG} style={{ width: "172px", height: "128px" }} />
    <Text color="#444B59">{text}</Text>
  </Stack>
);

export default NoData;
