import { TransactionBadge } from "../badge";
import getSymbolFromCurrency from "currency-symbol-map";
import { Tr, Td } from "@chakra-ui/react";
import { useUserDetails } from "app/hooks/use-user-details";
import { TransactionsResponse } from "app/store/models/transactions";
import { formatFullDateToUsersLocale } from "data/utils/date-formatter-users-locale";

const TransactionItem = (props: TransactionsResponse) => {
  const { onOpenUserDetails } = useUserDetails();

  return (
    <Tr key={props.id}>
      <Td
        fontSize="14px"
        cursor="pointer"
        _hover={{ color: "#B1B4BF" }}
        onClick={() => onOpenUserDetails(props.userId)}
      >
        {`${props.user?.firstName || "--"} ${props.user?.lastName || ""}`}
      </Td>
      <Td fontSize="14px">{props.description || "--"}</Td>
      <Td
        fontSize="14px"
        textTransform={"capitalize"}
      >{`${props.currency.toUpperCase()} / ${props.currencyType}`}</Td>
      <Td
        fontWeight={600}
        fontFamily="Rubik"
        color={props.type === "credit" ? "success.500" : "error.500"}
      >
        {props.type === "credit" ? "+" : "-"}{" "}
        {getSymbolFromCurrency(props.currency)}{" "}
        {props.amount ? props.amount.toLocaleString() : ""}
      </Td>
      <Td fontSize="14px">{props.rate || "--"}</Td>
      <Td fontSize="14px">{props.changeraTransactionType}</Td>
      <Td fontSize="14px">{props.walletId}</Td>
      <Td fontSize="14px" color="gray.500">
        {formatFullDateToUsersLocale(props.createdAt)}
      </Td>
      <Td>
        <TransactionBadge children="Successful" variant="success" />
      </Td>
    </Tr>
  );
};

export default TransactionItem;
