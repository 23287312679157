import React from "react";
import { Button, Heading } from "@chakra-ui/react";

type Props = {
  showCreatePage: () => void;
};

const Header = ({ showCreatePage }: Props) => (
  <React.Fragment>
    <div className="flex items-center justify-between mb-6">
      <Heading
        py="4"
        px="2"
        as="h3"
        fontSize="18px"
        lineHeight="28px"
        fontWeight="medium"
      >
        Wishlist
      </Heading>
      <Button variant="solid" colorScheme="primary" onClick={showCreatePage}>
        Add Wishlist Item
      </Button>
    </div>
  </React.Fragment>
);

export default Header;
