import WalletTransactionsTableContainer from "views/containers/transactions/tables/wallet-transactions";
import GenerateStatement from "../action-modals/generate-statement-modal";
import { ChangeraTransactionType } from "app/store/models/transactions";
import TabLink from "views/components/links/tab-link";
import { HiOutlineStar } from "react-icons/hi";
import { TbFilter } from "react-icons/tb";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Heading,
  Icon,
  Stack,
  useDisclosure
} from "@chakra-ui/react";
import { useAppSelector } from "app/hooks";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const WalletTransactions = () => {
  const [count] = useAppSelector((state) => [
    state.transactions.walletTransactions.pagination?.total
  ]);
  // generate statement
  const {
    isOpen: isGenerateStatementOpen,
    onOpen: onGenerateStatementOpen,
    onClose: onGenerateStatementClose
  } = useDisclosure();

  //
  let [searchParams, setSearchParams] = useSearchParams();
  let transactionType = searchParams.get("transaction-type");
  const walletId = searchParams.get("wallet-id");

  useEffect(() => {
    if (!transactionType) {
      searchParams.set("transaction-type", "withdrawal");
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  // bread crumbs

  return (
    <>
      <Breadcrumb mb={"5"}>
        <BreadcrumbItem>
          <BreadcrumbLink href="/">Home</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink href="/wallets">Wallets</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink isCurrentPage={true}>{walletId}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Stack
        direction={"row"}
        justifyContent="space-between"
        alignItems={"center"}
      >
        <Heading
          as={"h4"}
          fontSize="24px"
          fontFamily={"karla"}
          my="5"
          fontWeight={"extrabold"}
        >
          Transactions History
        </Heading>
        <Button
          colorScheme="primary"
          fontSize={"12px"}
          onClick={onGenerateStatementOpen}
        >
          Generate Statement
        </Button>
      </Stack>

      <Box background={"white"} p="2" rounded={"xl"}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          px="2"
          py="2"
          alignItems={"stretch"}
        >
          <Stack spacing={"5"} direction="row" overflowX={"scroll"} h="8">
            <TabLink
              urlParamKey={"transaction-type"}
              urlParamValue={ChangeraTransactionType.WITHDRAWAL}
              count={count}
            >
              Withdrawal
            </TabLink>
            <TabLink
              urlParamKey={"transaction-type"}
              urlParamValue={ChangeraTransactionType.SEND_MONEY}
              count={count}
            >
              Sent
            </TabLink>
            <TabLink
              urlParamKey={"transaction-type"}
              urlParamValue={ChangeraTransactionType.FUNDING_WALLET}
              count={count}
            >
              Fiat Funding
            </TabLink>
            <TabLink
              urlParamKey={"transaction-type"}
              urlParamValue={ChangeraTransactionType.FUNDING_CRYPTO_WALLET}
              count={count}
            >
              Crypto Funding
            </TabLink>
            <TabLink
              urlParamKey={"transaction-type"}
              urlParamValue={ChangeraTransactionType.AIRTIME_VTU}
              count={count}
            >
              Airtime
            </TabLink>
            <TabLink
              urlParamKey={"transaction-type"}
              urlParamValue={ChangeraTransactionType.DATA_VTU}
              count={count}
            >
              Data
            </TabLink>
            <TabLink
              urlParamKey={"transaction-type"}
              urlParamValue={ChangeraTransactionType.CONVERSION}
              count={count}
            >
              Conversion
            </TabLink>
            <TabLink
              urlParamKey={"transaction-type"}
              urlParamValue={ChangeraTransactionType.FIAT_CONVERSION}
              count={count}
            >
              Fiat Conversion
            </TabLink>
            <TabLink
              urlParamKey={"transaction-type"}
              urlParamValue={ChangeraTransactionType.CRYPTO_CONVERSION}
              count={count}
            >
              Crypto Conversion
            </TabLink>
            <TabLink
              urlParamKey={"transaction-type"}
              urlParamValue={ChangeraTransactionType.REFERRAL}
              count={count}
            >
              Referral
            </TabLink>
            <TabLink
              urlParamKey={"transaction-type"}
              urlParamValue={ChangeraTransactionType.EARNING}
              count={count}
            >
              Earning
            </TabLink>
            <TabLink
              urlParamKey={"transaction-type"}
              urlParamValue={ChangeraTransactionType.FAUCET}
              count={count}
            >
              Faucet
            </TabLink>
            <TabLink
              urlParamKey={"transaction-type"}
              urlParamValue={ChangeraTransactionType.REWARD}
              count={count}
            >
              Reward
            </TabLink>
            <TabLink
              urlParamKey={"transaction-type"}
              urlParamValue={ChangeraTransactionType.INTER_TRANSFER}
              count={count}
            >
              Inter Transfer
            </TabLink>
            <TabLink
              urlParamKey={"transaction-type"}
              urlParamValue={ChangeraTransactionType.CARD}
              count={count}
            >
              Virtual Card
            </TabLink>
            <TabLink
              urlParamKey={"transaction-type"}
              urlParamValue={ChangeraTransactionType.PHYSICAL_CARD}
              count={count}
            >
              Physical Card
            </TabLink>
            <TabLink
              urlParamKey={"transaction-type"}
              urlParamValue={ChangeraTransactionType.SUBSCRIPTION}
              count={count}
            >
              Subscription
            </TabLink>
          </Stack>
          <Stack direction={"row"} color="gray.600">
            <Icon as={HiOutlineStar} w={"5"} h={"5"} cursor="pointer" />
            <Icon as={TbFilter} w={"5"} h={"5"} cursor="pointer" />
          </Stack>
        </Stack>
        <WalletTransactionsTableContainer />
      </Box>
      <GenerateStatement
        isOpen={isGenerateStatementOpen}
        onClose={onGenerateStatementClose}
      />
    </>
  );
};

export default WalletTransactions;
