import axios from "axios";

import config from "data/config";
import { getToken, removeToken } from "./utils/localStorage";

const baseURL = config.server;

const instance = axios.create({
  baseURL: baseURL
});

export const authInstance = axios.create({
  baseURL: baseURL
});

instance.interceptors.request.use((config) => {
  const configInstance = { ...config };
  const token = getToken();
  config.headers ? (config.headers.Authorization = `${token}`) : "";
  return configInstance;
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    /**
     * Do something in case the response returns an error code [3**, 4**, 5**] etc
     * For example, on token expiration retrieve a new access token, retry a failed request etc
     */
    const { response } = error;
    const originalRequest = error.config;

    if (response && response.status === 403) {
      removeToken();
      return (window.location.href = "/auth/login");
    }
    if (response && response.status === 401) {
      return (window.location.href = "/unauthorised");
    }
    if (response && response.status === 400) {
      return Promise.reject(error);
    }
    if (response && response.status === 404) {
      return Promise.reject(error);
    }
    if (response) {
      return originalRequest;
    }
    return Promise.reject(error);
  }
);

export default instance;
