export enum VerificationStatus {
  INCOMING = "pending",
  VERIFIED = "approved",
  DECLINED = "denied"
}

export type PaginationState = {
  currentPage: number;
  hasNext?: boolean;
  hasPrevious?: boolean;
  lastPage?: number;
  next?: number;
  pageSize?: number;
  prevPage?: number;
  total?: number;
};

export interface UserModel {
  email: string | null;
  username: string | null;
  lastName: string | null;
  firstName: string | null;
  phone: string | null;
  plan: string;
  bvn: string;
  bvnPhoto?: string;
}

export interface VerificationData {
  id: number;
  userId: number;
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  country: string;
  postalCode: string;
  selfieUrl: string;
  idUrl: string;
  createdAt: string;
  updatedAt: string;
  dojahReferenceId?: string;
  generalServiceId: string | null;
  status: VerificationStatus;
  processedBy: string | null;
  state: string;
  lga: string;
  user: UserModel | null;
}

export interface SingleVerificationData {
  id: number;
  userId: number;
  firstName: string;
  lastName: string;
  selfieUrl: string;
  idUrl: string;
  dojahReferenceId?: string;
  createdAt: string;
  updatedAt: string;
  generalServiceId: string | null;
  status: VerificationStatus;
  processedBy: string | null;
  state: string;
  lga: string;
  lgaOfOrigin: string;
  stateOfOrigin: string;
  stateOfResidence: string;
  lgaOfResidence: string;
  user: UserModel | null;
  documentUrl?: string;
  utilityBill?: string;
  dateOfBirth?: string;
  issuedDate?: string;
  expiryDate?: string;
  postalCode?: string;
  address: string;
  country: string;
  street: string;
  city: string;
  occupation: string;

  documentNumber: string;
  documentType: string;
}

export interface AllVerificationState {
  pagination: PaginationState;
  data: VerificationData[];
  loading: boolean;
}

export interface SingleVerificationState {
  data: SingleVerificationData | null;
  loading: boolean;
}

export interface VerificationState {
  allVerification: AllVerificationState;
  singleVerification: SingleVerificationState;
  deleteVerifiaction: { loading: boolean; error: string };
}
