import {
  Box,
  Grid,
  GridItem,
  Heading,
  Icon,
  Spinner,
  Stack,
  Text,
  Badge,
  Button,
  useDisclosure
} from "@chakra-ui/react";
import { useAppDispatch, useAppSelector, useCopyToClipboard } from "app/hooks";
import { IoMdCopy } from "react-icons/io";
import { DetailProps } from "../models";
import { useEffect } from "react";
import { getBannedUsers } from "app/store";
import RecoverUser from "./recover-user";

const PersonalInformation = () => {
  const dispatch = useAppDispatch();
  const { isOpen, onToggle } = useDisclosure();
  const [userDetails, bannedUser, isLoadingBanned] = useAppSelector((state) => [
    state.user.singleUser,
    state.user.bannedUsers.data[0],
    state.user.bannedUsers.loading
  ]);

  const copyHandler = useCopyToClipboard();

  useEffect(() => {
    if (
      isLoadingBanned ||
      !userDetails?.data?.id ||
      !userDetails?.data.blocked
    ) {
      return;
    }

    dispatch(getBannedUsers({ userId: userDetails?.data?.id, perpage: 1 }));
  }, [userDetails.data?.id]);

  return (
    <>
      <Box py="2">
        <Grid gap={6} templateColumns={["repeat(1, 1fr)", "repeat(2, 1fr)"]}>
          <GridItem>
            <Detail
              title="Customer Name"
              text={`${userDetails.data?.firstName || "--"} ${
                userDetails.data?.lastName || ""
              }`}
            />
          </GridItem>
          <GridItem>
            <Detail
              title="UserName"
              text={userDetails.data?.username || "--"}
            />
          </GridItem>
          <GridItem>
            <Detail
              title="email address"
              text={
                <Stack direction={"row"} spacing="2" alignItems={"center"}>
                  <Text>{userDetails.data?.email || "--"}</Text>
                  <Icon
                    as={IoMdCopy}
                    w={5}
                    h={5}
                    color="success.300"
                    cursor={"pointer"}
                    onClick={() =>
                      copyHandler(userDetails.data?.email || "", "Email")
                    }
                  />
                </Stack>
              }
            />
          </GridItem>
          <GridItem>
            <Detail
              title="email verified?"
              text={userDetails.data?.emailVerified ? "Yes" : "No"}
            />
          </GridItem>
          <GridItem>
            <Detail
              title="Phone"
              text={
                <Stack direction={"row"} spacing="2" alignItems={"center"}>
                  <Text>{userDetails.data?.phone || "--"}</Text>
                  <Icon
                    as={IoMdCopy}
                    w={5}
                    h={5}
                    color="success.300"
                    cursor={"pointer"}
                    onClick={() =>
                      copyHandler(userDetails.data?.phone || "", "Phone")
                    }
                  />
                </Stack>
              }
            />
          </GridItem>
          <GridItem>
            <Detail title="Role" text={userDetails.data?.role || "--"} />
          </GridItem>
          <GridItem>
            <Detail
              title="Tier"
              text={userDetails.data?.tier?.toString() || "--"}
            />
          </GridItem>
          <GridItem>
            <Detail title="Country" text={userDetails.data?.country || "--"} />
          </GridItem>
          {userDetails?.data?.isDeleted && (
            <GridItem>
              <Detail
                title="delete reason"
                text={userDetails.data?.deleteReason || "--"}
              />
            </GridItem>
          )}
          {userDetails?.data?.blocked && (
            <GridItem>
              <Detail
                title="banned reason"
                text={isLoadingBanned ? <Spinner /> : bannedUser?.reason || "-"}
              />
            </GridItem>
          )}
          {userDetails?.data?.isDeleted && (
            <GridItem>
              <Detail
                title="Is Deleted"
                text={
                  <div className="flex items-center gap-3">
                    <Badge colorScheme="red" variant="solid">
                      Deleted
                    </Badge>
                    <Button
                      size="sm"
                      variant="solid"
                      onClick={onToggle}
                      colorScheme="primary"
                    >
                      Recover Account
                    </Button>
                  </div>
                }
              />
            </GridItem>
          )}
          {userDetails?.data?.isSuspended && (
            <GridItem>
              <Detail
                title="Is Suspended"
                text={
                  <div className="flex items-center gap-3">
                    <Badge colorScheme="red" variant="solid">
                      Suspended
                    </Badge>
                    <Button
                      size="sm"
                      variant="solid"
                      onClick={onToggle}
                      colorScheme="primary"
                    >
                      Recover Account
                    </Button>
                  </div>
                }
              />
            </GridItem>
          )}
        </Grid>
      </Box>

      <RecoverUser
        isOpen={isOpen}
        onClose={onToggle}
        userId={userDetails?.data?.id!}
      />
    </>
  );
};

const Detail = ({ title, text }: DetailProps) => (
  <Stack py="2">
    <Heading
      as={"h6"}
      fontSize="14px"
      fontWeight={"normal"}
      color="gray.600"
      textTransform={"capitalize"}
    >
      {title}
    </Heading>
    <Text
      fontSize={"18px"}
      fontWeight={"medium"}
      color="gray.900"
      textTransform={
        title === "email address" ||
        title === "delete reason" ||
        title === "banned reason"
          ? "lowercase"
          : "capitalize"
      }
    >
      {text}
    </Text>
  </Stack>
);

export default PersonalInformation;
