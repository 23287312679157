import React from "react";
import { Tr, Td, SkeletonCircle, Skeleton } from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";

const ActiveUsersSkeleton = () => {
  const [searchParams] = useSearchParams();
  let blocked = searchParams.get("blocked");

  return (
    <React.Fragment>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((key) => (
        <>
          {blocked === "false" ? (
            <Tr key={key}>
              <Td fontWeight={600}>
                <Skeleton width="100%" height="30px" />
              </Td>
              <Td fontWeight={600}>
                <Skeleton width="100%" height="30px" />
              </Td>
              <Td fontSize="14px">
                <Skeleton width="100%" height="30px" />
              </Td>
              <Td fontSize="14px">
                <Skeleton width="100%" height="30px" />
              </Td>
              <Td fontSize="14px" color="##808899">
                <Skeleton width="100%" height="30px" />
              </Td>
              <Td fontSize="14px" color="##808899">
                <Skeleton width="100%" height="30px" />
              </Td>
              <Td fontSize="14px" color="##808899">
                <Skeleton width="100%" height="30px" />
              </Td>
              <Td fontSize="14px" color="##808899">
                <Skeleton width="100%" height="30px" />
              </Td>
              <Td>
                <SkeletonCircle width="112px" height="36px" />
              </Td>
            </Tr>
          ) : null}
          {blocked === "true" ? (
            <Tr key={key}>
              <Td fontWeight={600}>
                <Skeleton width="100%" height="30px" />
              </Td>
              <Td fontWeight={600}>
                <Skeleton width="100%" height="30px" />
              </Td>
              <Td fontSize="14px">
                <Skeleton width="100%" height="30px" />
              </Td>
              <Td fontSize="14px" color="##808899">
                <Skeleton width="100%" height="30px" />
              </Td>
              <Td fontSize="14px" color="##808899">
                <Skeleton width="100%" height="30px" />
              </Td>
              <Td fontSize="14px" color="##808899">
                <Skeleton width="100%" height="30px" />
              </Td>
              <Td fontSize="14px" color="##808899">
                <Skeleton width="100%" height="30px" />
              </Td>
              <Td>
                <SkeletonCircle width="112px" height="36px" />
              </Td>
            </Tr>
          ) : null}
        </>
      ))}
    </React.Fragment>
  );
};

export default ActiveUsersSkeleton;
