import { Box, Heading } from "@chakra-ui/react";
import VirtualAccountsTableContainer from "./container";

const WalletsTable = () => {
  return (
    <>
      <Box bg="#fff" borderRadius="16px" padding={"1rem 1rem 24px"}>
        <Heading
          py={4}
          px={2}
          as="h3"
          fontSize="24px"
          lineHeight="28px"
          fontWeight="medium"
        >
          Virtual Accounts
        </Heading>

        <VirtualAccountsTableContainer />
      </Box>
    </>
  );
};

export default WalletsTable;
