import {
  Button,
  Divider,
  // FormControl,
  // FormLabel,
  // Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Spinner,
  Stack
} from "@chakra-ui/react";
import { PayloadAction } from "@reduxjs/toolkit";
import { useAppSelector, useExportToCSV } from "app/hooks";
import { useCallback, useMemo, useReducer, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { BaseModalProps, FormatType, GenerateStatementState } from "../models";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import toast from "react-hot-toast";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const initialState: GenerateStatementState = {
  format: "",
  from: "",
  to: "",
  activeStep: 0
};

const reducer = (
  state: GenerateStatementState,
  action: PayloadAction<unknown>
): GenerateStatementState => {
  let { type, payload } = action;

  switch (type) {
    case "HANDLE_RESET":
      return {
        ...initialState
      };
    case "SET_ACTIVE_STEP":
      return {
        ...state,
        activeStep: state.activeStep + 1
      };
    case "SET_FORMAT":
      return {
        ...state,
        format: payload as FormatType
      };
    case "SET_FROM":
      return {
        ...state,
        from: payload as string
      };
    case "SET_TO":
      return {
        ...state,
        to: payload as string
      };
    default:
      return { ...state };
  }
};

const GenerateStatement = ({ isOpen, onClose }: BaseModalProps) => {
  // tab and url controllers
  let [searchParams] = useSearchParams();
  let walletId = searchParams.get("wallet-id") as string;

  // state
  const [transactions] = useAppSelector((state) => [
    state.transactions.walletTransactions.data
  ]);

  //
  const generateExcel = useExportToCSV({
    apiData: transactions,
    fileName: `Transactions-${walletId}`
  });

  //
  const onCloseModal = () => {
    dispatchReducer({
      type: "HANDLE_RESET",
      payload: null
    });
    setLoading(false);
    onClose();
  };

  // form state
  const [state, dispatchReducer] = useReducer(reducer, initialState);

  // multi step handler
  const steps = useMemo(() => {
    return [
      <RadioGroup colorScheme="primary">
        <Stack spacing={5} w="full">
          <Radio
            colorScheme="primary"
            value={"pdf" as FormatType}
            name="FormatType"
            onChange={(e) =>
              dispatchReducer({
                payload: e.target.value as FormatType,
                type: "SET_FORMAT"
              })
            }
          >
            PDF
          </Radio>
          <Divider />
          <Radio
            colorScheme="primary"
            value={"excel" as FormatType}
            name="FormatType"
            onChange={(e) =>
              dispatchReducer({
                payload: e.target.value as FormatType,
                type: "SET_FORMAT"
              })
            }
          >
            Excel
          </Radio>
        </Stack>
      </RadioGroup>
    ];
  }, [state]);

  const handleNext = () => {
    return dispatchReducer({
      type: "SET_ACTIVE_STEP",
      payload: null
    });
  };

  const handleDisabled = useCallback(() => {
    if (state.activeStep === 0) {
      return !state.format;
    } else if (state.activeStep === 1) {
      return !state.from || !state.to;
    } else return false;
  }, [state]);

  const docDefinition = {
    content: [
      {
        defaultStyle: {
          lineHeight: 1.2,
          font: "Helvetica"
        },
        layout: "lightHorizontalLines",
        table: {
          headerRows: 1,
          widths: ["*", "*", "*", "*"],
          body: [
            ["Transaction Ref", "Amount", "Date Created", "Status"],
            ...transactions.map((tx) => [
              tx.reference,
              tx.amount ? tx.amount : "--",
              tx.createdAt,
              "Successful"
            ])
          ]
        }
      }
    ]
  };

  const createPdfHandler = () => {
    if (!transactions.length) {
      toast.error("No Transactions to generate PDF for");
      return;
    }
    pdfMake
      .createPdf(docDefinition)
      .download(`Transactions-${walletId}`, () => {
        setLoading(false);
        onCloseModal();
      });
  };

  //
  const [loading, setLoading] = useState(false);

  const proceedButtonHandler = async () => {
    if (state.activeStep < steps.length - 1) return handleNext();
    else if (state.format === "excel") {
      setLoading(true);
      await generateExcel();
      setLoading(false);
      return onCloseModal();
    } else {
      setLoading(true);
      return createPdfHandler();
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onCloseModal} isCentered size={"xs"}>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader
            fontSize={"16px"}
            color="#212327"
            fontWeight={500}
            fontFamily="Karla"
          >
            {state.activeStep === 0
              ? "Choose Format to Download"
              : "Please Choose Date Range to Generate"}
          </ModalHeader>
          <ModalCloseButton top={"3"} />

          <ModalBody>
            <Stack
              direction={"column"}
              width="100%"
              height="100%"
              alignItems={"start"}
              justifyContent={"center"}
              spacing={"10"}
              mb="5"
            >
              {steps[state.activeStep]}
              <Button
                size="lg"
                fontSize={14}
                width="100%"
                colorScheme="primary"
                onClick={proceedButtonHandler}
                disabled={handleDisabled()}
              >
                {loading ? (
                  <Spinner />
                ) : state.activeStep === 0 ? (
                  "Download"
                ) : (
                  "Download Statement"
                )}
              </Button>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default GenerateStatement;
