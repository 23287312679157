import React from "react";
import { Box } from "@chakra-ui/react";

type Props = { children: React.ReactNode };

const SidebarDesktop = ({ children }: Props) => (
  <Box width={"max-content"} flexShrink={0} bgColor="#FFF">
    {children}
  </Box>
);

export default SidebarDesktop;
