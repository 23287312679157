export type PaginationState = {
  currentPage: number;
  hasNext?: boolean;
  hasPrevious?: boolean;
  lastPage?: number;
  next?: number;
  pageSize?: number;
  prevPage?: number;
  total?: number;
};

export interface TransactionsResponse {
  id: number;
  userId: number;
  walletId: number | null;
  type: string;
  groupTrxReference: string;
  reference: string;
  amount: number | null;
  paymentGateway: string;
  changeraTransactionType: string;
  subType: string;
  currencyType: string;
  rate: number;
  status: string;
  balanceAfter: number;
  balanceBefore: number | null;
  createdAt: string;
  updatedAt: string;
  signedAmount: number;
  description: string;
  currency: string;
  conversionRate?: number;
  cryptoWalletId: number | null;
  virtualCardId: number | null;
  user: {
    email: string | null;
    username: string | null;
    lastName: string | null;
    firstName: string | null;
    phone: string | null;
    plan: string;
  } | null;
}

export interface AllTransactionsState {
  pagination: PaginationState;
  data: TransactionsResponse[];
  loading: boolean;
}

export interface TransactionsState {
  allTransactions: AllTransactionsState;
  userTransactions: AllTransactionsState;
  walletTransactions: AllTransactionsState;
  vCardTransactions: AllTransactionsState;
}

export enum ChangeraTransactionType {
  AIRTIME_VTU = "airtime-vtu",
  DATA_VTU = "data-vtu",
  SEND_MONEY = "send-money",
  CONVERSION = "conversion",
  CRYPTO_CONVERSION = "crypto-conversion",
  REFERRAL = "referral",
  EARNING = "earning",
  FAUCET = "faucet",
  REWARD = "reward",
  FUNDING_WALLET = "fund-wallet",
  WITHDRAWAL = "withdrawal",
  FIAT_CONVERSION = "fiat-conversion",
  INTER_TRANSFER = "wallet-to-wallet",
  CARD = "card",
  FUNDING_CRYPTO_WALLET = "fund-crypto-wallet",
  SUBSCRIPTION = "subscription",
  PHYSICAL_CARD = "physical-card",
  REQUEST_MONEY = "request-money",
  BITMAMA_MERCHANT_DEPOSIT = "bitmama-merchant-deposit"
}
