import {
  Box,
  Text,
  Modal,
  Stack,
  Button,
  ModalBody,
  ModalHeader,
  ModalFooter,
  ModalOverlay,
  ModalContent,
  ModalCloseButton
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";
import requestService from "data/services/request-service";
import { formatDateOnlyToDateString } from "data/utils/formatDateToUTC";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

const WalletRestrictionModalDetails = ({ data, onClose }: any) => {
  const [reason, setReason] = useState<any[]>([]);

  useEffect(() => {
    if (!data) return;

    (async () => {
      try {
        const res = await requestService.getBannedWallet({
          walletId: +data.id
        });
        setReason(res.data.data);
      } catch (err: any) {
        const error = err as AxiosError<{ message: string }>;
        const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
        toast.error(msg);
      }
    })();
  }, [data]);

  return (
    <Modal isOpen={Boolean(data)} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          color="primary.500"
          lineHeight="28px"
          fontSize={24}
          fontWeight={700}
          fontFamily="Karla"
        >
          Wallet
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box
            p={6}
            gap={3}
            color="#fff"
            display="flex"
            borderRadius={8}
            bgColor="primary.500"
            flexDirection="column"
          >
            <Stack direction="row" justifyContent="space-between">
              <Text color="gray.300">User</Text>
              <Text>
                {data?.user.firstName} {data?.user.lastName}
              </Text>
            </Stack>

            <Stack direction="row" justifyContent="space-between">
              <Text color="gray.300">Date Requested</Text>
              <Text>
                {data?.updatedAt
                  ? formatDateOnlyToDateString(data.updatedAt)
                  : ""}
              </Text>
            </Stack>

            <Stack direction="row" justifyContent="space-between">
              <Text color="gray.300">Wallet Id</Text>
              <Text>{data?.id}</Text>
            </Stack>

            <Stack direction="row" justifyContent="space-between">
              <Text color="gray.300">Reason</Text>
              <Text>{reason.length ? reason[0]?.reason || "N/A" : "N/A"}</Text>
            </Stack>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button
            mb={2}
            mr={3}
            width="full"
            height="56px"
            variant="outline"
            fontWeight={500}
            colorScheme="gray"
            onClick={onClose}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default WalletRestrictionModalDetails;
