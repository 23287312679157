import {
  ISummary,
  ISummaryError,
  ISummaryState,
  TSummaryLoading
} from "../models/summary";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: ISummaryState = {
  data: null,
  error: null,
  status: {
    getSummary: "idle"
  }
};

export const SummarySlice = createSlice({
  initialState,
  name: "requests",
  reducers: {
    loading: (state: ISummaryState, action: PayloadAction<TSummaryLoading>) => {
      state.status[action.payload] = "loading";
    },
    error: (state: ISummaryState, action: PayloadAction<ISummaryError>) => {
      state.error = action.payload.msg;
      state.status[action.payload.for] = "failed";
    },
    setSummary: (state: ISummaryState, action: PayloadAction<ISummary>) => {
      state.data = action.payload;
      state.status.getSummary = "succeeded";
    }
  }
});

export const actions = SummarySlice.actions;

export default SummarySlice.reducer;
