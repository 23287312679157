import {
  ButtonTab as Tab,
  ButtonTabList as TabList
} from "views/components/tabs";
import { Box, Button, Stack, useDisclosure } from "@chakra-ui/react";
import React from "react";
import PushNotificationMultiple from "views/containers/user-information/modals/push-notifcations-multiple";

const Actions = () => {
  const {
    isOpen: isPushOpen,
    onOpen: onPushOpen,
    onClose: onPushClose
  } = useDisclosure();

  return (
    <React.Fragment>
      <Stack
        alignItems="center"
        direction={["column", "row"]}
        justifyContent="space-between"
      >
        <ActionTab />

        <Button colorScheme="primary" size={["sm", "md"]} onClick={onPushOpen}>
          Push Notification
        </Button>
      </Stack>

      {isPushOpen ? (
        <PushNotificationMultiple isOpen={isPushOpen} onClose={onPushClose} />
      ) : null}
    </React.Fragment>
  );
};

const ActionTab = () => {
  return (
    <Box>
      <TabList
        p="6px !important"
        bgColor="gray.200"
        flexDirection={["column", "column", "row"]}
      >
        <Tab h="10" py="0" px="6" rounded="md" fontSize="sm" bgColor="gray.200">
          Merchant Information
        </Tab>
        <Tab py="0" px="6" rounded="md" fontSize="sm" h="10" bgColor="gray.200">
          Merchant Transactions
        </Tab>
      </TabList>
    </Box>
  );
};

export default Actions;
