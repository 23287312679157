import { IPagination } from "data/models/base";
import { PaginationState } from "../models/fx";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IFXState, IGetAllFxData } from "app/store/models/fx";

const pagination: PaginationState = {
  next: 0,
  total: 0,
  prevPage: 0,
  lastPage: 0,
  pageSize: 10,
  hasNext: false,
  currentPage: 1,
  hasPrevious: false
};

const initialState: IFXState = {
  updateFX: { loading: false, error: "" },
  singleFX: { loading: false, error: "" },
  allFX: { data: [], pagination, loading: false, error: "" }
};

const FxSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    getAllFxBegin: (state) => {
      state.allFX.loading = true;
      state.allFX.error = "";
      state.allFX.data = [];
    },
    getAllFxSuccess: (
      state,
      action: PayloadAction<{
        data: IGetAllFxData[];
        pagination: IPagination;
      }>
    ) => {
      state.allFX.data = action.payload.data;
      state.allFX.pagination = action.payload.pagination;
      state.allFX.loading = false;
      state.allFX.error = "";
    },
    getAllFxFailed: (state, action: PayloadAction<string>) => {
      state.allFX.loading = false;
      state.allFX.error = action.payload;
    },
    getSingleFxBegin: (state) => {
      state.singleFX.error = "";
      state.singleFX.loading = true;
      state.singleFX.data = undefined;
    },
    getSingleFxSuccess: (state, action: PayloadAction<IGetAllFxData>) => {
      state.singleFX.error = "";
      state.singleFX.loading = false;
      state.singleFX.data = action.payload;
    },
    getSingleFxFailed: (state, action: PayloadAction<string>) => {
      state.singleFX.loading = false;
      state.singleFX.error = action.payload;
    },
    updateFxStatusBegin: (state) => {
      state.updateFX.error = "";
      state.updateFX.loading = true;
    },
    updateFxStatusSuccess: (state) => {
      state.updateFX.error = "";
      state.updateFX.loading = false;
    },
    updateFxStatusFxFailed: (state, action: PayloadAction<string>) => {
      state.updateFX.loading = false;
      state.updateFX.error = action.payload;
    }
  }
});

export const actions = FxSlice.actions;

export default FxSlice.reducer;
