import React from "react";
import { Tr, Td, Skeleton } from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";

const TransactionSkeletion = () => {
  let [searchParams] = useSearchParams();
  let walletId = searchParams.get("wallet-id");
  let userId = searchParams.get("user-id");
  return (
    <React.Fragment>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((key) => (
        <>
          {!userId && !walletId ? (
            <Tr key={key}>
              <Td fontWeight={600}>
                <Skeleton width="100%" height="30px" />
              </Td>
              <Td fontWeight={600}>
                <Skeleton width="100%" height="30px" />
              </Td>
              <Td fontSize="14px">
                <Skeleton width="100%" height="30px" />
              </Td>
              <Td fontWeight={600}>
                <Skeleton width="100%" height="30px" />
              </Td>
              <Td fontSize="14px">
                <Skeleton width="100%" height="30px" />
              </Td>
              <Td fontSize="14px" color="##808899">
                <Skeleton width="100%" height="30px" />
              </Td>
              <Td fontSize="14px" color="##808899">
                <Skeleton width="100%" height="30px" />
              </Td>
              <Td fontSize="14px" color="##808899">
                <Skeleton width="100%" height="30px" />
              </Td>
              <Td fontSize="14px" color="##808899">
                <Skeleton width="100%" height="30px" />
              </Td>
              <Td fontSize="14px" color="##808899">
                <Skeleton width="100%" height="30px" />
              </Td>
              <Td fontSize="14px" color="##808899">
                <Skeleton width="100%" height="30px" />
              </Td>
              <Td fontSize="14px" color="##808899">
                <Skeleton width="100%" height="30px" />
              </Td>
              <Td fontSize="14px" color="##808899">
                <Skeleton width="100%" height="30px" />
              </Td>
            </Tr>
          ) : null}
          {!!userId ? (
            <Tr key={key}>
              <Td fontWeight={600}>
                <Skeleton width="100%" height="30px" />
              </Td>
              <Td fontWeight={600}>
                <Skeleton width="100%" height="30px" />
              </Td>
              <Td fontWeight={600}>
                <Skeleton width="100%" height="30px" />
              </Td>
              <Td fontWeight={600}>
                <Skeleton width="100%" height="30px" />
              </Td>
              <Td fontSize="14px">
                <Skeleton width="100%" height="30px" />
              </Td>
              <Td fontWeight={600}>
                <Skeleton width="100%" height="30px" />
              </Td>
              <Td fontWeight={600}>
                <Skeleton width="100%" height="30px" />
              </Td>
              <Td fontSize="14px">
                <Skeleton width="100%" height="30px" />
              </Td>
              <Td fontSize="14px" color="##808899">
                <Skeleton width="100%" height="30px" />
              </Td>
            </Tr>
          ) : null}
          {!!walletId ? (
            <Tr key={key}>
              <Td fontWeight={600}>
                <Skeleton width="100%" height="30px" />
              </Td>
              <Td fontWeight={600}>
                <Skeleton width="100%" height="30px" />
              </Td>
              <Td fontWeight={600}>
                <Skeleton width="100%" height="30px" />
              </Td>
              <Td fontSize="14px">
                <Skeleton width="100%" height="30px" />
              </Td>
              <Td fontWeight={600}>
                <Skeleton width="100%" height="30px" />
              </Td>
              <Td fontWeight={600}>
                <Skeleton width="100%" height="30px" />
              </Td>
              <Td fontSize="14px">
                <Skeleton width="100%" height="30px" />
              </Td>
              <Td fontSize="14px" color="##808899">
                <Skeleton width="100%" height="30px" />
              </Td>
            </Tr>
          ) : null}
        </>
      ))}
    </React.Fragment>
  );
};

export default TransactionSkeletion;
