import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Stack,
  Text,
  Icon,
  Heading,
  FormControl,
  FormLabel,
  Textarea,
  Divider,
  Flex,
  Skeleton,
  Spinner
} from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import { BaseModalProps } from "views/containers/user-information/models";
import {
  MessageOutlineIcon,
  ProfileCheckOutlineIcon
} from "views/components/icons";
import { IoCalendarOutline } from "react-icons/io5";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useEffect, useState } from "react";
import { getAllUsers, getSingleUser } from "app/store";
import { formatDateToUTC } from "data/utils/formatDateToUTC";
import { AxiosError } from "axios";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";
import toast from "react-hot-toast";
import userService from "data/services/user-service";

const ToggleUser = ({ isOpen, onClose }: BaseModalProps) => {
  const controller = new AbortController();
  const dispatch = useAppDispatch();
  const [user, requestor, currentPage, pageSize] = useAppSelector((state) => [
    state.user.singleUser,
    state.dashboard.userProfile,
    state.user.allUsers.pagination.currentPage,
    state.user.allUsers.pagination.pageSize
  ]);

  // tab and url controllers
  let [searchParams] = useSearchParams();
  let banAction = searchParams.get("ban-user-action");
  const userId = searchParams.get("user-id");

  // form state
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);

  const toggleUserHandler = async () => {
    if (!userId || !currentPage || !pageSize || !user.data) return;
    setLoading(true);
    const status = !!user.data.blocked ? "activate" : "deactivate";
    try {
      const res = await userService.toggleUser(
        +userId,
        {
          reason: reason
        },
        status
      );
      if (res.status === 200 || 201 || 202) {
        toast.success(res.data.message);
        dispatch(
          getAllUsers({
            page: currentPage,
            perpage: pageSize
          })
        );
        dispatch(getSingleUser(+userId, controller.signal));
        onClose();
      }
      setLoading(false);
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      setLoading(false);
    }
  };

  // get user details
  useEffect(() => {
    if (!userId) return;
    dispatch(getSingleUser(+userId, controller.signal));
  }, [userId]);

  useEffect(() => {
    return () => controller.abort();
  }, []);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="outside">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textTransform={"capitalize"}>
            {banAction} User
          </ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody>
            {user.loading ? (
              <Flex
                p="5"
                bg="primary.500"
                color={"gray.300"}
                rounded="lg"
                direction="column"
                gap={"4"}
              >
                <Skeleton h="6" />
                <Skeleton h="6" />
                <Skeleton h="6" />
              </Flex>
            ) : null}
            {!!user.data && !user.loading ? (
              <Stack
                p="5"
                bg="primary.500"
                color={"gray.300"}
                rounded="lg"
                spacing={"4"}
              >
                <Stack direction={"row"} justifyContent="space-between">
                  <Stack direction={"row"} alignItems="center">
                    <Icon as={ProfileCheckOutlineIcon} h={5} w={5} />
                    <Text>Requestor</Text>
                  </Stack>
                  <Text>
                    {requestor
                      ? `${requestor.firstName} ${requestor.lastName}`
                      : "--"}
                  </Text>
                </Stack>
                <Stack direction={"row"} justifyContent="space-between">
                  <Stack direction={"row"} alignItems="center">
                    <Icon as={MessageOutlineIcon} h={5} w={5} />
                    <Text>User Id</Text>
                  </Stack>
                  <Text>{userId}</Text>
                </Stack>
                <Stack direction={"row"} justifyContent="space-between">
                  <Stack direction={"row"} alignItems="center">
                    <Icon as={IoCalendarOutline} h={5} w={5} />
                    <Text>Date Created</Text>
                  </Stack>
                  <Text>
                    {user.data?.createdAt
                      ? formatDateToUTC(user.data.createdAt)
                      : "--"}
                  </Text>
                </Stack>
              </Stack>
            ) : null}
            <Heading
              as={"h4"}
              fontSize="24px"
              fontFamily={"karla"}
              my="5"
              fontWeight={"extrabold"}
            >
              Request Details
            </Heading>
            <Text color={"gray.600"}>Request</Text>
            <Text
              fontWeight={"medium"}
              color={banAction === "deactivate" ? "error.500" : "success.500"}
              textTransform="capitalize"
            >
              {banAction} User
            </Text>
            <Stack my="5" spacing={"4"}>
              <FormControl>
                <FormLabel color={"gray.600"} fontWeight="400">
                  Reason for Deactivation
                </FormLabel>
                <Textarea
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                />
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter>
            {banAction === "deactivate" ? (
              <Button
                size={"lg"}
                colorScheme="red"
                px="6"
                w={"full"}
                onClick={toggleUserHandler}
              >
                {loading ? <Spinner /> : banAction}
              </Button>
            ) : (
              <Button
                size={"lg"}
                colorScheme="green"
                px="6"
                w={"full"}
                onClick={toggleUserHandler}
              >
                {loading ? <Spinner /> : banAction}
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ToggleUser;
