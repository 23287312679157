import {
  Box,
  Button,
  Heading,
  Icon,
  Stack,
  StackDivider,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Divider,
  Input,
  InputGroup,
  InputLeftElement,
  Select
} from "@chakra-ui/react";
import { debounce } from "lodash";
import { usePagination } from "@ajna/pagination";
import { StarIcon } from "views/components/icons/star-icon";
import { FilterIcon } from "views/components/icons/filter-icon";

import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { HiOutlineDownload } from "react-icons/hi";
import { getAllReports } from "app/store";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector, useExportToCSV } from "app/hooks";
import PaginationWrapper from "views/components/PaginationWrapper/PaginationWrapper";
import TransactionSkeletion from "../transactions/tables/skeleton";
import NoData from "views/components/no-data";
import { FiSearch } from "react-icons/fi";
import { formatFullDateToUsersLocale } from "data/utils/date-formatter-users-locale";

const ReportsContainer = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(
      getAllReports({
        page: currentPage,
        perpage: pageSize
      })
    );
  }, []);
  const allReports = useAppSelector((state) => state.reports.allReports);
  const [pageSize, setPageSize] = useState(10);
  const [searchString, setSearchString] = useState("");

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, offset } =
    usePagination({
      initialState: { currentPage: 1, pageSize },
      total: allReports.pagination?.total,
      limits: {
        inner: 2,
        outer: 1
      }
    });

  const handleSize = (e: any) => {
    setPageSize(e.target.value);
  };
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;

    if (query.length >= 3) {
      setSearchString(query);
      setCurrentPage(1);
    } else {
      setSearchString("");
      setCurrentPage(1);
    }
  };

  const debouncedChangeHandler = useMemo(() => {
    return debounce(handleSearch, 1000);
  }, []);

  const handlePageChange = (nextPage: number) => {
    setCurrentPage(nextPage);
    setTimeout(() => {
      window.scrollTo({ top: 10, behavior: "smooth" });
    }, 500);
  };

  const count = useAppSelector(
    (state) => state.reports.allReports.pagination?.total
  );
  useEffect(() => {
    dispatch(
      getAllReports({
        page: currentPage,
        perpage: pageSize,
        q: searchString
      })
    );
  }, [currentPage, pageSize, searchString]);
  const generateExcel = useExportToCSV({
    apiData: allReports.data,
    fileName: "Reports Statement"
  });
  return (
    <Box className="mb-20">
      <Breadcrumb mb={4}>
        <BreadcrumbItem>
          <BreadcrumbLink href="/">Home</BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink href="/reports">Reports</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <div>
        <Box
          p={[4, 6]}
          display="flex"
          bgColor="#fff"
          borderRadius="10px"
          flexDirection="column"
        >
          <Box mb={6}>
            <div className="flex items-center justify-between mt-3">
              <Stack
                spacing={2}
                mb={[4, 4, 4, 0]}
                direction="row"
                alignItems="center"
                divider={<StackDivider borderColor="#F0F1F3" />}
              >
                <div className="flex items-center">
                  <Heading
                    color="gray.900"
                    fontWeight={700}
                    lineHeight="28px"
                    fontFamily="Karla"
                    fontSize={[20, 24]}
                  >
                    Statement
                  </Heading>
                  <sub className="block ml-2 text-sm text-blue-500">
                    {count}
                  </sub>
                </div>

                <Icon as={StarIcon} cursor="pointer" />
                <Icon as={FilterIcon} cursor="pointer" />
              </Stack>
              <div>
                <Button
                  className="flex items-center bg-white mdx:p-6"
                  textColor="#111834"
                  bgColor="#fff"
                  border="1px"
                  borderColor="#111834"
                  padding={1}
                  onClick={() => generateExcel()}
                >
                  <div className="mr-1">
                    <Icon
                      as={HiOutlineDownload}
                      w={5}
                      h={5}
                      color="primary.500"
                    />
                  </div>
                  Download Statement
                </Button>
              </div>
            </div>
          </Box>

          <Box display="flex" flexDirection="column" className="mt-2">
            <TableContainer bg="#fff" minH="calc(100vh - 10rem)">
              <Stack mt="0.75rem" ml="2" spacing="4" direction={"row"}>
                <Box mb="16px">
                  <InputGroup width="256px">
                    <Input
                      width="256px"
                      paddingLeft="40px"
                      minH="40px"
                      placeholder={
                        "Search by reference or groupt transaction reference"
                      }
                      border="1px solid #EDEBF9"
                      onChange={debouncedChangeHandler}
                      borderRadius="10px"
                      fontSize="12px"
                      _focus={{
                        border: "1px solid #EDEBF9"
                      }}
                      _hover={{
                        border: "1px solid #EDEBF9"
                      }}
                    />

                    <InputLeftElement
                      h="100%"
                      mr="12px"
                      children={<FiSearch color="#A0A3BD" />}
                    />
                  </InputGroup>
                </Box>
                <Box mb="12px">
                  <Select
                    width="120px"
                    defaultValue={"Size"}
                    placeholder="Size"
                    onChange={handleSize}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                  </Select>
                </Box>
              </Stack>
              <Table
                color="brand.grayScale"
                fontSize="14px"
                lineHeight="18.2px"
                colorScheme={"gray"}
                variant="striped"
              >
                <Thead>
                  <Tr>
                    <Th
                      textTransform={"capitalize"}
                      color="gray.800"
                      fontSize={"16px"}
                      lineHeight={"19px"}
                      fontWeight={"medium"}
                    >
                      username
                    </Th>
                    <Th
                      textTransform={"capitalize"}
                      color="gray.800"
                      fontSize={"16px"}
                      lineHeight={"19px"}
                      fontWeight={"medium"}
                    >
                      transaction ref
                    </Th>
                    <Th
                      textTransform={"capitalize"}
                      color="gray.800"
                      fontSize={"16px"}
                      lineHeight={"19px"}
                      fontWeight={"medium"}
                    >
                      recipient
                    </Th>
                    <Th
                      textTransform={"capitalize"}
                      color="gray.800"
                      fontSize={"16px"}
                      lineHeight={"19px"}
                      fontWeight={"medium"}
                    >
                      amount
                    </Th>
                    <Th
                      textTransform={"capitalize"}
                      color="gray.800"
                      fontSize={"16px"}
                      lineHeight={"19px"}
                      fontWeight={"medium"}
                    >
                      changera plan
                    </Th>
                    <Th
                      textTransform={"capitalize"}
                      color="gray.800"
                      fontSize={"16px"}
                      lineHeight={"19px"}
                      fontWeight={"medium"}
                    >
                      date created
                    </Th>
                  </Tr>
                </Thead>
                <Divider />
                <Tbody>
                  {allReports.loading ? <TransactionSkeletion /> : null}
                  {!!allReports.data.length && !allReports.loading
                    ? allReports.data.map((el, idx) => (
                        <Tr key={idx}>
                          <Td textTransform={"capitalize"}>
                            {el.user?.username || "--"}
                          </Td>
                          <Td textTransform={"capitalize"}>{el.reference}</Td>
                          <Td textTransform={"capitalize"}>
                            <Stack>
                              <Text
                                fontSize={"14px"}
                                fontWeight="medium"
                                lineHeight={"1.2"}
                              >
                                {`${el.user?.firstName || "--"} ${
                                  el.user?.lastName || ""
                                }`}
                              </Text>
                              <Text
                                fontSize={"12px"}
                                lineHeight={"1.2"}
                                color="gray.600"
                                marginTop={0}
                              >
                                {el.user?.phone || "--"}
                              </Text>
                            </Stack>
                          </Td>
                          <Td textTransform={"capitalize"} fontWeight={500}>
                            {`${el.currency} ${el.amount.toLocaleString()}`}
                          </Td>
                          <Td textTransform={"capitalize"}>
                            {el.user?.plan || "-"}
                          </Td>
                          <Td textTransform={"capitalize"} color="gray.500">
                            {el.createdAt
                              ? formatFullDateToUsersLocale(el.createdAt)
                              : "--"}
                          </Td>
                        </Tr>
                      ))
                    : null}
                  {!allReports.data.length && !allReports.loading ? (
                    <Tr>
                      <Td colSpan={7} background="transparent" h="xl">
                        <NoData text={`No Transactions Yet`} />
                      </Td>
                    </Tr>
                  ) : null}
                </Tbody>
              </Table>
            </TableContainer>

            <PaginationWrapper
              handlePageChange={handlePageChange}
              totalDataCount={allReports.pagination?.total}
              currentPage={currentPage}
              isDisabled={isDisabled}
              pagesCount={pagesCount}
              offset={offset}
              pages={pages}
              size={pageSize}
            />
          </Box>
        </Box>
      </div>
    </Box>
  );
};

export default ReportsContainer;
