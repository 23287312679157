import { format } from "date-fns";

export function formatDateToUTC(createdAt: any) {
  const newDate = new Date(createdAt);

  const result = newDate.toLocaleTimeString("en-GB", {
    year: "numeric",
    day: "2-digit",
    month: "2-digit",
    hour: "2-digit",
    minute: "2-digit"
  });

  return result;
}

export function formatDateOnlyToUTC(createdAt: any) {
  const newDate = new Date(createdAt);

  const result = newDate.toLocaleDateString("en-GB", {
    year: "numeric",
    day: "2-digit",
    month: "2-digit"
  });

  return result;
}

export function formatDateOnlyToDateString(createdAt: any) {
  const newDate = new Date(createdAt);

  const result = format(newDate, "MMM d, yyyy");

  return result;
}

export function formatTimeToUTC(createdAt: any) {
  const newDate = new Date(createdAt);

  const result = newDate.toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit"
  });

  return result;
}

export function formatDayOnylToUTC(createdAt: any) {
  const newDate = new Date(createdAt);

  const result = newDate.toLocaleTimeString("en-GB", {
    year: "numeric",
    day: "2-digit",
    month: "2-digit"
  });

  return result;
}

export function convertHMS(value: any) {
  const sec = parseInt(value, 10);
  let hours: any = Math.floor(sec / 3600);
  let minutes: any = Math.floor((sec - hours * 3600) / 60);
  let seconds: any = sec - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = "0" + hours;
  }

  if (minutes < 10) {
    minutes = "0" + minutes;
  }

  if (seconds < 10) {
    seconds = "0" + seconds;
  }

  return hours + ":" + minutes + ":" + seconds; // Return is HH : MM : SS
}
