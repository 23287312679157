import { Box } from "@chakra-ui/react";
import DashboardHeader from "../header";
import { Outlet } from "react-router-dom";
// import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

const DashboardContent = () => (
  <Box pl={6} bgColor="#F1F6FA" width="100%" overflow="auto">
    <DashboardHeader />
    <Box mt={6} pr={6} mb={6}>
      <Outlet />
    </Box>
  </Box>
);

export default DashboardContent;
