import {
  Box,
  Divider,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import { useAppDispatch, useAppSelector, useUserDetails } from "app/hooks";
import { getAllTransactions } from "app/store/action-creators/transactions.actions";
import { ChangeraTransactionType } from "app/store/models/transactions";
import { useEffect, useMemo, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { useSearchParams } from "react-router-dom";
import NoData from "views/components/no-data";
import PaginationWrapper from "views/components/PaginationWrapper/PaginationWrapper";
import TransactionSkeletion from "./skeleton";
import { debounce } from "lodash";
import getSymbolFromCurrency from "currency-symbol-map";
import { formatFullDateToUsersLocale } from "data/utils/date-formatter-users-locale";
import { TransactionBadge } from "views/components/badge";

type Props = {
  pages: any;
  offset: any;
  status?: string;
  setPageSize: any;
  pageSize: number;
  pagesCount: number;
  isDisabled: boolean;
  currentPage: number;
  setCurrentPage: any;
};

const TransactionsTableContainer = ({
  pages,
  offset,
  status,
  pageSize,
  isDisabled,
  pagesCount,
  currentPage,
  setPageSize,
  setCurrentPage
}: Props) => {
  const dispatch = useAppDispatch();

  const allTransactions = useAppSelector(
    (state) => state.transactions.allTransactions
  );

  let [searchParams] = useSearchParams();
  let changeraTransactionType = searchParams.get(
    "transaction-type"
  ) as ChangeraTransactionType;

  // pagination and search
  const [searchString, setSearchString] = useState("");

  const handlePageChange = (nextPage: number) => {
    setCurrentPage(nextPage);
    setTimeout(() => {
      window.scrollTo({ top: 10, behavior: "smooth" });
    }, 500);
  };

  const handleSize = (e: any) => {
    setPageSize(e.target.value);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;

    if (query.length >= 3) {
      setSearchString(query);
      setCurrentPage(1);
    } else {
      setSearchString("");
      setCurrentPage(1);
    }
  };

  const debouncedChangeHandler = useMemo(() => {
    return debounce(handleSearch, 1000);
  }, []);

  useEffect(() => {
    if (!changeraTransactionType) return;
    dispatch(
      getAllTransactions({
        changeraTransactionType,
        page: currentPage,
        perpage: pageSize,
        q: searchString,
        status: status || undefined
      })
    );
  }, [status, changeraTransactionType, currentPage, pageSize, searchString]);

  useEffect(() => {
    setCurrentPage(1);
  }, [changeraTransactionType]);

  //
  const { onOpenUserDetails, UserDetail } = useUserDetails();

  return (
    <>
      <TableContainer bg="#fff" minH="calc(100vh - 10rem)">
        <Stack mt="0.75rem" ml="2" spacing="4" direction={"row"}>
          <Box mb="16px">
            <InputGroup width="256px">
              <Input
                width="256px"
                paddingLeft="40px"
                minH="40px"
                placeholder={
                  "Search by reference or groupt transaction reference"
                }
                border="1px solid #EDEBF9"
                onChange={debouncedChangeHandler}
                borderRadius="10px"
                fontSize="12px"
                _focus={{
                  border: "1px solid #EDEBF9"
                }}
                _hover={{
                  border: "1px solid #EDEBF9"
                }}
              />

              <InputLeftElement
                h="100%"
                mr="12px"
                children={<FiSearch color="#A0A3BD" />}
              />
            </InputGroup>
          </Box>
          <Box mb="12px">
            <Select
              width="120px"
              defaultValue={"Size"}
              placeholder="Size"
              onChange={handleSize}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
            </Select>
          </Box>
        </Stack>
        <Table
          color="brand.grayScale"
          fontSize="14px"
          lineHeight="18.2px"
          colorScheme={"gray"}
          variant="striped"
        >
          <Thead>
            <Tr>
              <Th
                textTransform={"capitalize"}
                color="gray.800"
                fontSize={"16px"}
                lineHeight={"19px"}
                fontWeight={"medium"}
              >
                username
              </Th>
              <Th
                textTransform={"capitalize"}
                color="gray.800"
                fontSize={"16px"}
                lineHeight={"19px"}
                fontWeight={"medium"}
              >
                Description
              </Th>
              <Th
                textTransform={"capitalize"}
                color="gray.800"
                fontSize={"16px"}
                lineHeight={"19px"}
                fontWeight={"medium"}
              >
                transaction ref
              </Th>
              <Th
                textTransform={"capitalize"}
                color="gray.800"
                fontSize={"16px"}
                lineHeight={"19px"}
                fontWeight={"medium"}
              >
                recipient
              </Th>
              <Th
                textTransform={"capitalize"}
                color="gray.800"
                fontSize={"16px"}
                lineHeight={"19px"}
                fontWeight={"medium"}
              >
                currency / type
              </Th>
              <Th
                textTransform={"capitalize"}
                color="gray.800"
                fontSize={"16px"}
                lineHeight={"19px"}
                fontWeight={"medium"}
              >
                amount
              </Th>
              <Th
                textTransform={"capitalize"}
                color="gray.800"
                fontSize={"16px"}
                lineHeight={"19px"}
                fontWeight={"medium"}
              >
                rate
              </Th>

              <Th
                textTransform={"capitalize"}
                color="gray.800"
                fontSize={"16px"}
                lineHeight={"19px"}
                fontWeight={"medium"}
              >
                balance before
              </Th>
              <Th
                textTransform={"capitalize"}
                color="gray.800"
                fontSize={"16px"}
                lineHeight={"19px"}
                fontWeight={"medium"}
              >
                balance after
              </Th>
              <Th
                textTransform={"capitalize"}
                color="gray.800"
                fontSize={"16px"}
                lineHeight={"19px"}
                fontWeight={"medium"}
              >
                changera plan
              </Th>
              <Th
                textTransform={"capitalize"}
                color="gray.800"
                fontSize={"16px"}
                lineHeight={"19px"}
                fontWeight={"medium"}
              >
                date created
              </Th>
              <Th
                textTransform={"capitalize"}
                color="gray.800"
                fontSize={"16px"}
                lineHeight={"19px"}
                fontWeight={"medium"}
              >
                status
              </Th>
            </Tr>
          </Thead>
          <Divider />
          <Tbody>
            {allTransactions.loading ? <TransactionSkeletion /> : null}
            {!!allTransactions.data.length && !allTransactions.loading
              ? allTransactions.data.map((el, idx) => (
                  <Tr key={idx}>
                    <Td
                      textTransform={"capitalize"}
                      cursor="pointer"
                      _hover={{ color: "#B1B4BF" }}
                      onClick={() => onOpenUserDetails(el.userId)}
                    >
                      {el.user?.username || "--"}
                    </Td>
                    <Td textTransform={"capitalize"}>
                      {el.description || "--"}
                    </Td>
                    <Td textTransform={"capitalize"}>{el.reference}</Td>
                    <Td textTransform={"capitalize"}>
                      <Stack>
                        <Text
                          fontSize={"14px"}
                          fontWeight="medium"
                          lineHeight={"1.2"}
                        >
                          {`${el.user?.firstName || "--"} ${
                            el.user?.lastName || ""
                          }`}
                        </Text>
                        <Text
                          fontSize={"12px"}
                          lineHeight={"1.2"}
                          color="gray.600"
                          marginTop={0}
                        >
                          {el.user?.phone || "--"}
                        </Text>
                      </Stack>
                    </Td>
                    <Td textTransform={"capitalize"} fontWeight={500}>
                      {`${el.currency.toUpperCase()} / ${el.currencyType}`}
                    </Td>
                    <Td
                      textTransform={"capitalize"}
                      fontWeight={500}
                      color={el.type === "credit" ? "success.500" : "error.500"}
                    >
                      {el.type === "credit" ? "+" : "-"}{" "}
                      {getSymbolFromCurrency(el.currency)}{" "}
                      {el.amount ? el.amount.toLocaleString() : "0"}
                    </Td>
                    <Td textTransform={"capitalize"}>
                      {el.rate ||
                        (changeraTransactionType ===
                        ChangeraTransactionType.FIAT_CONVERSION
                          ? el?.conversionRate
                          : "--")}
                    </Td>

                    <Td textTransform={"capitalize"} fontWeight={500}>
                      {el.balanceBefore
                        ? el.balanceBefore.toLocaleString()
                        : "0"}
                    </Td>
                    <Td textTransform={"capitalize"} fontWeight={500}>
                      {el.balanceAfter ? el.balanceAfter.toLocaleString() : "0"}
                    </Td>
                    <Td textTransform={"capitalize"}>{el.user?.plan || ""}</Td>
                    <Td textTransform={"capitalize"} color="gray.500">
                      {el.createdAt
                        ? formatFullDateToUsersLocale(el.createdAt)
                        : "--"}
                    </Td>
                    <Td textTransform={"capitalize"}>
                      <TransactionBadge variant={el.status as any} />
                    </Td>
                  </Tr>
                ))
              : null}
            {!allTransactions.data.length && !allTransactions.loading ? (
              <Tr>
                <Td colSpan={7} background="transparent" h="xl">
                  <NoData
                    text={`No ${changeraTransactionType} Transactions Yet`}
                  />
                </Td>
              </Tr>
            ) : null}
          </Tbody>
        </Table>
      </TableContainer>
      <PaginationWrapper
        handlePageChange={handlePageChange}
        totalDataCount={allTransactions.pagination?.total}
        currentPage={currentPage}
        isDisabled={isDisabled}
        pagesCount={pagesCount}
        offset={offset}
        pages={pages}
        size={pageSize}
      />
      {UserDetail}
    </>
  );
};

export default TransactionsTableContainer;
