import {
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import TabLink from "../../components/links/tab-link";
import { HiOutlineStar } from "react-icons/hi";
import { TbFilter } from "react-icons/tb";
import { VerificationStatus } from "app/store/models/verification";
import { AiFillCaretDown } from "react-icons/ai";
import { useAppDispatch, useAppSelector } from "app/hooks";
import PaginationWrapper from "views/components/PaginationWrapper/PaginationWrapper";
import { usePagination } from "@ajna/pagination";
import { getAllVerification } from "app/store";
import VerificationSkeleton from "./skeleton";
import VerificationDetails from "./verification-details";
import DeclinedVerification from "./verification-details/decline-verification";
import { VerificationAction } from "./models";
import ApproveVerification from "./verification-details/approve-verification";
import { useUserDetails } from "app/hooks";
import { formatFullDateToUsersLocale } from "data/utils/date-formatter-users-locale";
import { FiSearch } from "react-icons/fi";
import { debounce } from "lodash";

const VerificationTable = () => {
  const [allVerification, count] = useAppSelector((state) => [
    state.verification.allVerification,
    state.verification.allVerification.pagination.total
  ]);
  const dispatch = useAppDispatch();

  // url params fns
  let [searchParams, setSearchParams] = useSearchParams();
  let verificationStatus = searchParams.get(
    "verification-status"
  ) as VerificationStatus;
  const verificationAction = searchParams.get("verification-action");

  useEffect(() => {
    if (!verificationStatus) {
      setSearchParams({ "verification-status": VerificationStatus.INCOMING });
    }
  }, []);

  // pagination
  const [pageSize] = useState(10);

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, offset } =
    usePagination({
      initialState: { currentPage: 1, pageSize },
      total: allVerification.pagination.total,
      limits: {
        inner: 2,
        outer: 1
      }
    });

  const handlePageChange = (nextPage: number) => {
    setCurrentPage(nextPage);
    setTimeout(() => {
      window.scrollTo({ top: 10, behavior: "smooth" });
    }, 500);
  };

  // Search
  const [searchString, setSearchString] = useState("");

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;

    if (query.length >= 3) {
      setSearchString(query);
      setCurrentPage(1);
    } else {
      setSearchString("");
      setCurrentPage(1);
    }
  };

  const debouncedChangeHandler = useMemo(() => {
    return debounce(handleSearch, 1000);
  }, []);

  // fetch all verification
  useEffect(() => {
    if (!verificationStatus) return;
    dispatch(
      getAllVerification({
        page: currentPage,
        perpage: pageSize,
        status: verificationStatus,
        q: searchString
      })
    );
  }, [currentPage, pageSize, verificationStatus, searchString]);

  useEffect(() => {
    setCurrentPage(1);
  }, [verificationStatus]);

  // view verification
  const onOpenVerificationDetails = (id: number) => {
    searchParams.set("verification-id", id.toString());
    setSearchParams(searchParams);
  };

  const onCloseVerificationDetails = () => {
    searchParams.delete("verification-id");
    searchParams.delete("verification-detail");
    setSearchParams(searchParams);
  };

  const onCloseVerificationAction = () => {
    searchParams.delete("verification-action");
    setSearchParams(searchParams);
  };
  // user details
  const { onOpenUserDetails, UserDetail } = useUserDetails();

  return (
    <>
      <Box bg="#fff" borderRadius="16px" padding={"1rem 1rem 24px"}>
        <Heading
          as={"h3"}
          fontSize="24px"
          fontWeight={"medium"}
          lineHeight="28px"
          py="4"
          px="2"
        >
          User Verification
        </Heading>
        <Flex direction={"row"} justifyContent={"space-between"} px="2" py="2">
          <Stack spacing={"5"} direction="row">
            <TabLink
              urlParamKey={"verification-status"}
              urlParamValue={VerificationStatus.INCOMING}
              count={count}
            >
              Incoming
            </TabLink>
            <TabLink
              urlParamKey={"verification-status"}
              urlParamValue={VerificationStatus.VERIFIED}
              count={count}
            >
              Verified
            </TabLink>
            <TabLink
              urlParamKey={"verification-status"}
              urlParamValue={VerificationStatus.DECLINED}
              count={count}
            >
              Declined
            </TabLink>
          </Stack>
          <Stack direction={"row"} color="gray.600">
            <Icon as={HiOutlineStar} w={"5"} h={"5"} cursor="pointer" />
            <Icon as={TbFilter} w={"5"} h={"5"} cursor="pointer" />
          </Stack>
        </Flex>
        <TableContainer bg="#fff" minH="calc(100vh - 10rem)">
          <Stack mt="0.75rem" ml="2" spacing="4" direction={"row"}>
            <Box mb="16px">
              <InputGroup width="256px">
                <Input
                  width="256px"
                  paddingLeft="40px"
                  minH="40px"
                  placeholder={"Search by User Id"}
                  border="1px solid #EDEBF9"
                  onChange={debouncedChangeHandler}
                  borderRadius="10px"
                  fontSize="12px"
                  _focus={{
                    border: "1px solid #EDEBF9"
                  }}
                  _hover={{
                    border: "1px solid #EDEBF9"
                  }}
                />

                <InputLeftElement
                  h="100%"
                  mr="12px"
                  children={<FiSearch color="#A0A3BD" />}
                />
              </InputGroup>
            </Box>
          </Stack>
          <Table
            color="brand.grayScale"
            fontSize="14px"
            lineHeight="18.2px"
            colorScheme={"gray"}
            variant="striped"
          >
            <Thead>
              <Tr>
                <Th
                  textTransform={"capitalize"}
                  color="gray.800"
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"medium"}
                >
                  Name
                </Th>
                <Th
                  textTransform={"capitalize"}
                  color="gray.800"
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"medium"}
                >
                  Email
                </Th>
                <Th
                  textTransform={"capitalize"}
                  color="gray.800"
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"medium"}
                >
                  Dojah Reference
                </Th>
                <Th
                  textTransform={"capitalize"}
                  color="gray.800"
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"medium"}
                >
                  Time of Verification
                </Th>
                <Th
                  textTransform={"capitalize"}
                  color="gray.800"
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"medium"}
                >
                  status
                </Th>
                <Th
                  textTransform={"capitalize"}
                  color="gray.800"
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"medium"}
                >
                  action
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {allVerification.loading ? <VerificationSkeleton /> : null}
              {!!allVerification.data.length && !allVerification.loading
                ? allVerification.data.map((el, idx) => (
                    <Tr key={idx}>
                      <Td
                        textTransform={"capitalize"}
                        cursor="pointer"
                        _hover={{ color: "#B1B4BF" }}
                        onClick={() => onOpenUserDetails(el.userId)}
                      >{`${el?.user?.firstName || el.firstName} ${
                        el?.user?.lastName || el.lastName
                      }`}</Td>
                      <Td>{el.user?.email}</Td>
                      <Td>{el?.dojahReferenceId || "--"}</Td>
                      <Td textTransform={"capitalize"} color="gray.500">
                        {el?.updatedAt
                          ? formatFullDateToUsersLocale(el?.updatedAt)
                          : "--"}
                      </Td>
                      <Td textTransform={"capitalize"}>
                        <Badge
                          colorScheme={
                            el.status === "approved"
                              ? "green"
                              : el.status === "pending"
                              ? "yellow"
                              : "red"
                          }
                          px="3"
                          py="1"
                          rounded={"2xl"}
                          textTransform="capitalize"
                          display={"flex"}
                          alignItems="center"
                          justifyContent={"space-between"}
                          gap={"2"}
                          color={
                            el.status === "approved"
                              ? "green.500"
                              : el.status === "pending"
                              ? "yellow.500"
                              : "red.500"
                          }
                          border={"1px"}
                          fontWeight={"normal"}
                        >
                          <Text>{el.status}</Text>
                          <Icon
                            as={AiFillCaretDown}
                            w={3}
                            h={3}
                            color={
                              el.status === "approved"
                                ? "green.500"
                                : el.status === "pending"
                                ? "yellow.500"
                                : "red.500"
                            }
                          />
                        </Badge>
                      </Td>
                      <Td>
                        <Button
                          size={"sm"}
                          borderColor="gray.400"
                          colorScheme="gray"
                          _hover={{ background: "primary.100" }}
                          variant="outline"
                          px="6"
                          onClick={() => onOpenVerificationDetails(el.id)}
                        >
                          view
                        </Button>
                      </Td>
                    </Tr>
                  ))
                : null}
            </Tbody>
          </Table>
        </TableContainer>
        <PaginationWrapper
          handlePageChange={handlePageChange}
          totalDataCount={allVerification.pagination.total}
          currentPage={currentPage}
          isDisabled={isDisabled}
          pagesCount={pagesCount}
          offset={offset}
          pages={pages}
          size={pageSize}
        />
      </Box>
      <VerificationDetails
        isOpen={!!searchParams.get("verification-id")}
        onClose={onCloseVerificationDetails}
      />
      <DeclinedVerification
        isOpen={verificationAction === VerificationAction.DECLINE}
        onClose={onCloseVerificationAction}
      />
      <ApproveVerification
        isOpen={verificationAction === VerificationAction.APPROVE}
        onClose={onCloseVerificationAction}
      />
      {UserDetail}
    </>
  );
};

export default VerificationTable;
