import {
  Td,
  Th,
  Tr,
  Box,
  Table,
  Tbody,
  Thead,
  TableContainer
} from "@chakra-ui/react";
import React from "react";
import WithdrawalSkeleton from "./skeleton";
import NoData from "views/components/no-data";
import { usePagination } from "@ajna/pagination";
import getSymbolFromCurrency from "currency-symbol-map";
import { TransactionBadge } from "views/components/badge";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { getAllMerchantWithdrawalTransactions } from "app/store";
// import { ChangeraTransactionType } from "app/store/models/transactions";
import PaginationWrapper from "views/components/PaginationWrapper/PaginationWrapper";
import { formatFullDateToUsersLocale } from "data/utils/date-formatter-users-locale";

const head = [
  "Username",
  "Email",
  "Account Name",
  "Bank Details",
  "Withdrawal Mode",
  "Original Amount",
  "Conversion Rate",
  "Withdrawal Fee",
  "Destination Amount",
  "Date",
  "Status"
];

const Withdrawals = () => {
  const dispatch = useAppDispatch();
  const [data, loading, pagination] = useAppSelector((state) => [
    state.bitmamaMerchants.withdrawalTransactions.data,
    state.bitmamaMerchants.withdrawalTransactions.loading,
    state.bitmamaMerchants.withdrawalTransactions.pagination
  ]);

  const {
    pages,
    offset,
    pageSize,
    pagesCount,
    isDisabled,
    currentPage,
    setCurrentPage
  } = usePagination({
    total: pagination?.total,
    limits: { inner: 2, outer: 1 },
    initialState: { currentPage: 1, pageSize: 10 }
  });

  const handlePageChange = (nextPage: number) => {
    setCurrentPage(nextPage);
    setTimeout(() => {
      window.scrollTo({ top: 10, behavior: "smooth" });
    }, 500);
  };

  React.useEffect(() => {
    dispatch(
      getAllMerchantWithdrawalTransactions({
        perpage: 10,
        page: currentPage
      })
    );
  }, [currentPage]);

  return (
    <React.Fragment>
      <TableContainer>
        <Table variant="striped" colorScheme="gray">
          <Thead>
            <Tr>
              {head.map((data) => (
                <Th
                  key={data}
                  fontSize={16}
                  color="gray.800"
                  fontWeight={500}
                  lineHeight="19px"
                  letterSpacing="0px"
                  textTransform="unset"
                >
                  {data}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {loading ? <WithdrawalSkeleton /> : null}

            {!!data && !loading
              ? data?.map((d) => (
                  <Tr key={d.id}>
                    <Td>{d?.user?.username || "-"}</Td>

                    <Td>{d?.user?.email || "-"}</Td>

                    <Td>{d?.bankAccountName || "--"}</Td>

                    <Td>
                      <Box
                        mb={1}
                        fontSize={14}
                        color="gray.900"
                        fontWeight={500}
                        lineHeight="17px"
                        letterSpacing={0}
                      >
                        {d?.bankAccountName || ""}
                      </Box>
                      <Box
                        mb={1}
                        fontSize={14}
                        color="gray.900"
                        fontWeight={500}
                        lineHeight="17px"
                        letterSpacing={0}
                      >
                        {d?.bankAccountNumber || "--"}
                      </Box>
                      <Box color="gray.600" fontSize={13} lineHeight="14px">
                        {d?.bankName || ""}
                      </Box>
                    </Td>

                    <Td textTransform="capitalize">{d?.type}</Td>

                    <Td
                      fontSize={16}
                      color="error.500"
                      fontWeight={500}
                      letterSpacing={0}
                      lineHeight="19px"
                    >
                      {getSymbolFromCurrency(d?.sourceCurrency as any)}{" "}
                      {d && d?.originalAmount
                        ? d?.originalAmount?.toLocaleString()
                        : ""}
                    </Td>

                    <Td>{d?.conversionRate}</Td>

                    <Td color="error.500" lineHeight="18px" fontSize={14}>
                      {getSymbolFromCurrency(d?.sourceCurrency as any)}{" "}
                      {d?.transferFee?.toLocaleString()}
                    </Td>

                    <Td
                      fontSize={16}
                      color="gray.900"
                      fontWeight={500}
                      lineHeight="19px"
                      letterSpacing="0px"
                    >
                      {getSymbolFromCurrency(d?.destinationCurrency as any)}{" "}
                      {d && d?.destinationAmount
                        ? d?.destinationAmount?.toLocaleString()
                        : ""}
                    </Td>

                    <Td color="gray.500" lineHeight="18px" fontSize={14}>
                      {formatFullDateToUsersLocale(d.createdAt)}
                    </Td>

                    <Td>
                      <TransactionBadge
                        cursor="pointer"
                        children={d?.status}
                        variant={d?.status as any}
                      />
                    </Td>
                  </Tr>
                ))
              : null}

            {!data?.length && !loading ? (
              <Tr>
                <Td colSpan={7} background="transparent" h="xl">
                  <NoData text={`No Transactions Yet`} />
                </Td>
              </Tr>
            ) : null}
          </Tbody>
        </Table>
      </TableContainer>

      <PaginationWrapper
        pages={pages}
        size={pageSize}
        offset={offset}
        isDisabled={isDisabled}
        pagesCount={pagesCount}
        currentPage={currentPage}
        totalDataCount={pagination?.total}
        handlePageChange={handlePageChange}
      />
    </React.Fragment>
  );
};

export default Withdrawals;
