import {
  Box,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";

const Loading = (): JSX.Element => {
  return (
    <div className="flex flex-row items-center justify-center h-screen loadingIcon">
      <svg
        width="2260"
        height="2260"
        viewBox="0 0 2260 2260"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M881.021 1620.05L615.775 2135.5C430.154 2040.32 274.406 1895.8 165.629 1717.9C71.4799 1564.01 15.7525 1389.62 3.49457 1209.66C1.53742 1183.4 0.610348 1156.1 0.610348 1130.14C0.404331 968.417 35.1181 808.652 102.382 661.762C109.696 645.487 117.319 629.624 125.353 613.76C133.388 597.897 143.174 581.622 151.62 565.965C157.698 555.252 164.084 544.745 170.574 534.341L200.343 565.965L382.462 746.126L616.908 977.997C608.358 1008.69 602.384 1040.11 599.088 1071.84C597.336 1090.79 596.203 1110.05 596.203 1129.42C596.203 1229.03 622.676 1326.89 672.738 1413C722.903 1499.12 794.906 1570.4 881.639 1619.53L881.021 1620.05Z"
          fill="url(#paint0_linear_4_96)"
        />
        <path
          d="M1649.77 126.532L1396.68 618.189C1372.37 606.858 1347.24 597.279 1321.59 589.656C1243.2 566.273 1160.79 560.196 1079.83 571.939C998.864 583.579 921.505 612.73 852.901 657.332C874.121 516.932 893.796 390.953 893.796 390.953C893.796 390.953 923.565 248.184 764.829 272.803C674.182 282.074 479.394 310.607 329.929 333.269C499.48 162.996 718.888 51.2326 956.218 14.1497C1193.65 -22.9332 1436.65 16.6219 1650.08 127.047L1649.77 126.532Z"
          fill="#FFCB05"
        />
        <path
          d="M893.895 390.954C893.895 390.954 874.22 516.933 853.001 657.333C832.296 793.098 809.84 942.769 801.394 1004.68C783.573 1131.17 709.922 1070.7 709.922 1070.7L616.803 978.616L200.238 566.48L170.469 534.857L86.6201 445.652C51.8033 383.95 127.823 363.966 127.823 363.966C127.823 363.966 217.028 350.06 329.925 332.755C480.317 310.093 674.281 281.56 764.826 272.289C923.664 248.7 893.895 390.954 893.895 390.954Z"
          fill="#1B9948"
        />
        <path
          d="M2259.79 1130.04C2260 1310.4 2216.84 1488.3 2133.81 1648.48L1654.41 1401.57C1691.6 1333.79 1714.46 1259 1721.57 1182.06C1728.68 1105.01 1719.82 1027.44 1695.72 953.893C1671.51 880.448 1632.47 812.771 1581.07 754.984C1529.67 697.196 1466.83 650.637 1396.68 618.189L1649.88 126.531C1679.13 141.673 1707.67 158.155 1735.17 175.563C1896.07 277.438 2028.54 418.456 2120.32 585.329C2211.89 752.203 2259.89 939.574 2259.79 1130.04Z"
          fill="url(#paint1_linear_4_96)"
        />
        <path
          d="M2133.8 1648.48C2099.3 1715.12 2058.3 1778.16 2011.43 1836.67C1981.15 1873.96 1948.7 1909.4 1913.98 1942.67C1852.59 2002 1784.61 2054.23 1711.47 2098.21C1546.86 2196.89 1359.9 2252.1 1168.21 2258.7C976.404 2265.19 786.148 2222.85 615.257 2135.5L880.503 1620.15C946.738 1657.85 1019.98 1681.75 1095.69 1690.4C1171.4 1699.05 1248.14 1692.26 1321.17 1670.52C1354.03 1660.63 1386.07 1647.76 1416.66 1632.2C1473.01 1603.26 1524.1 1565.14 1567.77 1519.3C1588.27 1497.67 1607.12 1474.6 1624.12 1450.08C1634.83 1434.43 1644.82 1418.36 1653.89 1401.77L2133.8 1648.48Z"
          fill="url(#paint2_linear_4_96)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_4_96"
            x1="-258.776"
            y1="852.412"
            x2="817.16"
            y2="1666.45"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#1B9948" />
            <stop offset="0.57" stopColor="#1B9948" />
            <stop offset="0.9" stopColor="#4070B7" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_4_96"
            x1="2573.57"
            y1="1509.24"
            x2="1440.37"
            y2="572.454"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.65" stopColor="#DB2227" />
            <stop offset="0.68" stopColor="#DC2627" />
            <stop offset="0.72" stopColor="#DD3226" />
            <stop offset="0.76" stopColor="#E14726" />
            <stop offset="0.8" stopColor="#E56324" />
            <stop offset="0.84" stopColor="#EA8523" />
            <stop offset="0.86" stopColor="#EE931D" />
            <stop offset="0.92" stopColor="#F7B110" />
            <stop offset="0.97" stopColor="#FDC408" />
            <stop offset="1" stopColor="#FFCB05" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_4_96"
            x1="1036.45"
            y1="2304.32"
            x2="1652.82"
            y2="1439.79"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#4070B7" />
            <stop offset="0.46" stopColor="#4070B7" />
            <stop offset="0.56" stopColor="#436FB5" />
            <stop offset="0.63" stopColor="#4C6DAD" />
            <stop offset="0.69" stopColor="#5C68A0" />
            <stop offset="0.74" stopColor="#71628E" />
            <stop offset="0.8" stopColor="#8E5B77" />
            <stop offset="0.83" stopColor="#A55463" />
            <stop offset="0.93" stopColor="#C7343D" />
            <stop offset="0.99" stopColor="#DB2227" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export const LoadingText = () => (
  <Box mt={16} display="flex" alignItems="center" justifyContent="center">
    Loading...
  </Box>
);

export default Loading;

export const LoadingTable = ({
  pageSize,
  headers
}: {
  pageSize: number;
  headers: string[];
}) => {
  return (
    <TableContainer>
      <Table variant="simple">
        <Thead textColor={"gray.900"}>
          <Tr>
            {headers.map((el, idx) => (
              <Th
                key={idx}
                textTransform="capitalize"
                color="gray.900"
                fontSize={"md"}
                py="4"
                pl="0"
              >
                {el}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {Array.from(Array(pageSize).keys()).map((el) => (
            <Tr key={el}>
              {headers.map((el) => (
                <Td key={el} pl="0">
                  <Skeleton width="100%" height="30px" />
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
