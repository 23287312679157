import { createIcon } from "@chakra-ui/icon";

export const FilterIcon = createIcon({
  displayName: "FilterIcon",
  viewBox: "0 0 18 18",
  path: [
    <path
      d="M10.9094 16.8213L10.9027 16.8252L10.8962 16.8294L9.73404 17.5765C8.95879 18.0497 7.91661 17.4945 7.91661 16.5833V12.1667C7.91661 11.7896 7.81141 11.3907 7.67252 11.0435C7.53255 10.6935 7.3428 10.3573 7.14038 10.1043L7.12634 10.0868L7.11079 10.0706L6.72131 9.6645C6.61401 9.54899 6.60041 9.38445 6.67922 9.2615L6.67925 9.26152L6.68268 9.25602L10.9494 2.40602L10.9498 2.40535C11.0083 2.31098 11.1141 2.25 11.2333 2.25H15.4999C16.1405 2.25 16.6666 2.77614 16.6666 3.41667V5.25C16.6666 5.48899 16.589 5.79161 16.451 6.09518C16.3165 6.39102 16.144 6.6453 15.9897 6.80304L12.4239 9.9542C12.0989 10.2272 11.8515 10.6114 11.6855 11.0026C11.5176 11.3984 11.4166 11.8398 11.4166 12.25V15.8333C11.4166 15.9839 11.3626 16.1964 11.2543 16.4047C11.1443 16.6162 11.0117 16.7598 10.9094 16.8213Z"
      fill="currentColor"
      stroke="currentColor"
    />,
    <path
      d="M5.2425 7.502L5.2425 7.502L5.24141 7.50376C5.13202 7.68071 4.87145 7.71211 4.7201 7.55481C4.71991 7.55461 4.71972 7.55441 4.71953 7.55421L3.94606 6.73915L3.94618 6.73903L3.93693 6.72978C3.78153 6.57439 3.62539 6.33774 3.50838 6.07672C3.38944 5.81139 3.33337 5.57118 3.33337 5.41667V3.5C3.33337 2.76003 3.87536 2.25 4.50004 2.25H7.91671C8.17146 2.25 8.33778 2.53413 8.19945 2.76255L5.2425 7.502Z"
      fill="currentColor"
      stroke="currentColor"
    />
  ]
});
