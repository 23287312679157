import {
  Td,
  Th,
  Tr,
  Box,
  Table,
  Tbody,
  Thead,
  Avatar,
  Spinner,
  TableContainer
} from "@chakra-ui/react";
import { useEffect } from "react";
import { getBanHistory } from "app/store";
import NoData from "views/components/no-data";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { formatFullDateToUsersLocale } from "data/utils/date-formatter-users-locale";

const BanHistory = () => {
  const dispatch = useAppDispatch();
  let [searchParams] = useSearchParams();
  const [banHistory] = useAppSelector((state) => [state.user.banHistory]);

  const userId = +searchParams.get("user-id")!;

  useEffect(() => {
    if (!userId) return;
    dispatch(getBanHistory(userId));
  }, [userId]);

  if (banHistory.loading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" mt={12}>
        <Spinner />
      </Box>
    );
  }

  if (!banHistory?.data?.length) {
    return (
      <Box background="transparent">
        <NoData text="No bans yet" />
      </Box>
    );
  }

  return (
    <Box bg="#fff" borderRadius="16px" padding="1rem 0">
      <TableContainer bg="#fff">
        <Table
          size="md"
          fontSize="14px"
          variant="striped"
          lineHeight="18.2px"
          colorScheme="gray"
          color="brand.grayScale"
        >
          <Thead>
            <Tr>
              <Th
                color="gray.800"
                fontSize="16px"
                lineHeight="19px"
                fontWeight="medium"
                textTransform="capitalize"
              >
                Admin
              </Th>
              <Th
                color="gray.800"
                fontSize="16px"
                lineHeight="19px"
                fontWeight="medium"
                textTransform="capitalize"
              >
                Type
              </Th>
              <Th
                color="gray.800"
                fontSize="16px"
                lineHeight="19px"
                fontWeight="medium"
                textTransform="capitalize"
              >
                Reason
              </Th>
              <Th
                color="gray.800"
                fontSize="16px"
                lineHeight="19px"
                fontWeight="medium"
                textTransform="capitalize"
              >
                Date
              </Th>
            </Tr>
          </Thead>

          <Tbody>
            {banHistory?.data?.map((history) => {
              const admin = history?.bannedby || history?.unbannedby;
              const name = admin
                ? `${admin?.firstName} ${admin?.lastName}`
                : "Admin";
              return (
                <Tr className="capitalize">
                  <Td className="flex items-center gap-3">
                    <Avatar size="sm" />
                    <div>
                      <div className="font-semibold">{name?.toLowerCase()}</div>
                      <div className="lowercase text-gray-500">
                        {admin?.email || ""}
                      </div>
                    </div>
                  </Td>
                  <Td>{history?.bannedBy ? "Ban" : "Unban"}</Td>
                  <Td>{history?.reason || "-"}</Td>
                  <Td textTransform={"capitalize"} color="gray.500">
                    {history?.updatedAt
                      ? formatFullDateToUsersLocale(history?.updatedAt)
                      : "--"}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default BanHistory;
