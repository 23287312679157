import {
  Td,
  Th,
  Tr,
  Table,
  Tbody,
  Thead,
  TableContainer
} from "@chakra-ui/react";
import React from "react";
import SkeletonLoader from "./skeleton";
import { usePagination } from "@ajna/pagination";
import { useValentineWishList } from "app/hooks/use-wishlist";
import PaginationWrapper from "views/components/PaginationWrapper/PaginationWrapper";

const heading = ["Username", "Email", "Wishlist Item"];

const ValentineWishListSelectedWinnersTable = () => {
  const { valentineWishListSelectedWinners, dispatchValentineSelectedWinners } =
    useValentineWishList();

  const {
    pages,
    offset,
    pageSize,
    pagesCount,
    isDisabled,
    currentPage,
    setCurrentPage
  } = usePagination({
    limits: { inner: 2, outer: 1 },
    total: valentineWishListSelectedWinners?.pagination?.total,
    initialState: { currentPage: 1, pageSize: 10 }
  });

  const handlePageChange = (nextPage: number) => {
    setCurrentPage(nextPage);
    setTimeout(() => {
      window.scrollTo({ top: 10, behavior: "smooth" });
    }, 500);
  };

  React.useEffect(() => {
    if (valentineWishListSelectedWinners?.loading) return;
    dispatchValentineSelectedWinners({ page: 1, perpage: pageSize });
  }, [pageSize, currentPage]);

  return (
    <React.Fragment>
      <TableContainer bg="#fff" minH="calc(100vh - 10rem)">
        <Table fontSize="14px" lineHeight="18.2px">
          <Thead>
            <Tr>
              {heading.map((data) => (
                <Th
                  key={data}
                  fontSize="16px"
                  color="gray.800"
                  lineHeight="19px"
                  fontWeight="medium"
                  textTransform="capitalize"
                >
                  {data}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {valentineWishListSelectedWinners?.loading ? (
              <SkeletonLoader />
            ) : null}
            {valentineWishListSelectedWinners?.data?.map((el, idx) => (
              <Tr key={`${el.email}-${idx}`}>
                <Td>
                  {el?.firstName} {el?.lastName}
                </Td>

                <Td textTransform={"capitalize"}>{el?.email}</Td>

                <Td textTransform={"capitalize"}>
                  {el?.wishlist?.item || el?.wishlist?.items?.item || "-"}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      <PaginationWrapper
        pages={pages}
        offset={offset}
        size={pageSize}
        isDisabled={isDisabled}
        pagesCount={pagesCount}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        totalDataCount={valentineWishListSelectedWinners?.pagination?.total}
      />
    </React.Fragment>
  );
};

export default ValentineWishListSelectedWinnersTable;
