import {
  FiatWalletData,
  CryptoWalletData,
  PaginationState,
  AddLien,
  LienData
} from "app/store/models/wallets";
import { AxiosResponse } from "axios";
import instance from "data/axios-setup";

class WalletService {
  async getAllFiatWallets(params: {
    page: number;
    perpage: number;
    q?: string;
    userId?: number;
  }): Promise<
    AxiosResponse<{ data: FiatWalletData[]; pagination: PaginationState }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get(`/admin/fiat-wallets`, {
          params
        });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getAllCryptoWallets(params: {
    page: number;
    perpage: number;
    q?: string;
    userId?: number;
  }): Promise<
    AxiosResponse<{ data: CryptoWalletData[]; pagination: PaginationState }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get(`/admin/crypto-wallets`, {
          params
        });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getSingleFiatWallet(
    id: number
  ): Promise<AxiosResponse<{ data: FiatWalletData }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get(`/admin/fiat-wallets/${id}`);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getSingleCryptoWallet(
    id: number
  ): Promise<AxiosResponse<{ data: CryptoWalletData }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get(`/admin/crypto-wallets/${id}`);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async toggleFiatWallet(
    id: number,
    body: { reason: string },
    status: "activate" | "deactivate"
  ): Promise<AxiosResponse<{ data: any }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.put(
          `/admin/fiat-wallets/${id}/${status}`,
          body
        );
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async manualFundFiatWallet(
    id: number,
    body: {
      amount: number;
      reference: string;
      description: string;
    }
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.post(`/admin/fiat-wallets/${id}/fund`, body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async manualWithdraw(
    id: number,
    body: {
      amount: number;
      reference: string;
      description: string;
    }
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.post(
          `/admin/fiat-wallets/${id}/withdraw`,
          body
        );
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async addLien(body: AddLien): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.post(`/admin/lien`, body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getSingleLien(id: number): Promise<AxiosResponse<{ data: LienData }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get(`/admin/lien/${id}`);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getAllLien(params: {
    page: number;
    perpage: number;
  }): Promise<
    AxiosResponse<{ data: LienData[]; pagination: PaginationState }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get(`/admin/lien`, { params });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }
}

export default new WalletService();
