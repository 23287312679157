interface AppConfig {
  server: string;
  serverV4: string;
}

const config: AppConfig = {
  server: process.env.REACT_APP_BASE_URL
    ? `${process.env.REACT_APP_BASE_URL}/v3`
    : "https://api3-staging.changera.co/api/v3",

  serverV4: process.env.REACT_APP_BASE_URL
    ? `${process.env.REACT_APP_BASE_URL}/v4`
    : "https://api3-staging.changera.co/api/v4"
};
for (const [key, value] of Object.entries(config)) {
  if (!value) {
    console.log(
      `[-] Environmental varialbe ${key} is not set. Application might not funtion properly.`
    );
  }
}
export default config;
