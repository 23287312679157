import {
  Modal,
  Stack,
  Button,
  Select,
  FormLabel,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  ModalContent
} from "@chakra-ui/react";
import React from "react";

type BaseModalProps = {
  isOpen: boolean;
  setStatus: any;
  setCurrentPage: any;
  onClose: () => void;
};

const TransactionFilter = (props: BaseModalProps) => {
  const [value, setValue] = React.useState("");

  const handleApplyStatus = () => {
    props.setStatus(value);
    props.setCurrentPage(1);
    props.onClose();
  };

  return (
    <Modal
      isCentered
      isOpen={props.isOpen}
      onClose={props.onClose}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent py={6} px={[6]}>
        <ModalHeader
          p={0}
          mb={4}
          fontSize={16}
          color="#333843"
          fontWeight={600}
        >
          Transaction Filter
        </ModalHeader>
        <ModalBody
          p={1}
          mb={8}
          color="#444B59"
          overflow="unset"
          textAlign="center"
        >
          <Stack spacing={6}>
            <div>
              <FormLabel>Status</FormLabel>
              <Select value={value} onChange={(e) => setValue(e.target.value)}>
                <option value="pending">Pending</option>
                <option value="processing">In Progress</option>
                <option value="cancelled">Cancelled</option>
                <option value="successful">Successful</option>
              </Select>
            </div>
          </Stack>
        </ModalBody>

        <Button size="lg" colorScheme="primary" onClick={handleApplyStatus}>
          Apply
        </Button>
      </ModalContent>
    </Modal>
  );
};

export default TransactionFilter;
