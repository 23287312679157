import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FeatureData, FeatureState, PaginationState } from "../models/feature";

const initialState: FeatureState = {
  allFeatures: {
    data: [],
    loading: false,
    pagination: {
      hasPrevious: false,
      prevPage: 0,
      hasNext: true,
      next: 2,
      currentPage: 1,
      pageSize: 10,
      lastPage: 0,
      total: 0
    }
  }
};

export const FeatureReducer = createSlice({
  name: "feature",
  initialState,
  reducers: {
    getAllFeaturePending: (state) => {
      state.allFeatures.loading = true;
    },
    getAllFeatureSuccess: (
      state,
      action: PayloadAction<{
        data: FeatureData[];
        pagination: PaginationState;
      }>
    ) => {
      state.allFeatures.data = action.payload.data;
      state.allFeatures.pagination = action.payload.pagination;
      state.allFeatures.loading = false;
    },
    getAllFeatureFailed: (state) => {
      state.allFeatures.loading = false;
    }
  }
});

export const actions = FeatureReducer.actions;

export default FeatureReducer.reducer;
