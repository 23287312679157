import { useLocation } from "react-router";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useEffect } from "react";

import Loading from "views/components/loading";
import { useNavigate } from "react-router-dom";
import { getUserProfile } from "app/store/action-creators/dashboard.actions";

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, isLoading] = useAppSelector((state) => [
    state.dashboard.userProfile,
    state.dashboard.loading
  ]);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getUserProfile(navigate, location));
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div>{user && <>{children}</>}</div>
    </>
  );
};

export default PrivateRoute;
