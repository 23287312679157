import {
  Td,
  Th,
  Tr,
  Table,
  Tbody,
  Thead,
  Button,
  TableContainer
} from "@chakra-ui/react";
import React from "react";
import Skeleton from "./skeleton";
import { getAllMerchants } from "app/store";
import ViewWalletModal from "./modals/wallet";
import { usePagination } from "@ajna/pagination";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { formatFullDateToUsersLocale } from "data/utils/date-formatter-users-locale";
import PaginationWrapper from "views/components/PaginationWrapper/PaginationWrapper";

const heading = [
  "Full Name",
  "Username",
  "Email",
  "Country",
  "Date Registered",
  "Action"
];

const AllMerchantsTable = () => {
  const dispatch = useAppDispatch();
  let [params, setParams] = useSearchParams();

  const [allMerchants] = useAppSelector((state) => [
    state.bitmamaMerchants.allMerchants
  ]);

  const {
    pages,
    offset,
    pageSize,
    pagesCount,
    isDisabled,
    currentPage,
    setCurrentPage
  } = usePagination({
    limits: { inner: 2, outer: 1 },
    total: allMerchants?.pagination?.total,
    initialState: { currentPage: 1, pageSize: 10 }
  });

  const onViewWallet = (id: number) => {
    params.set("user-id", id.toString());
    params.set("view-wallet", "true");
    setParams(params);
  };

  const onCloseViewWalletModal = () => {
    params.delete("user-id");
    params.delete("view-wallet");
    setParams(params);
  };

  const handlePageChange = (nextPage: number) => {
    setCurrentPage(nextPage);
    setTimeout(() => {
      window.scrollTo({ top: 10, behavior: "smooth" });
    }, 500);
  };

  React.useEffect(() => {
    if (allMerchants?.loading) return;
    dispatch(getAllMerchants({ page: currentPage, perpage: 10 }));
  }, [currentPage]);

  return (
    <React.Fragment>
      <TableContainer bg="#fff" minH="calc(100vh - 10rem)">
        <Table
          color="brand.grayScale"
          fontSize="14px"
          lineHeight="18.2px"
          colorScheme="gray"
          variant="striped"
        >
          <Thead>
            <Tr>
              {heading.map((data) => (
                <Th
                  key={data}
                  fontSize="16px"
                  color="gray.800"
                  lineHeight="19px"
                  fontWeight="medium"
                  textTransform="capitalize"
                >
                  {data}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {allMerchants?.loading ? <Skeleton /> : null}
            {allMerchants?.data?.map((el, idx) => (
              <Tr key={idx}>
                <Td textTransform={"capitalize"}>{`${el?.firstName || "--"} ${
                  el?.lastName || ""
                }`}</Td>

                <Td textTransform={"capitalize"}>{el?.username || "--"}</Td>

                <Td>{el?.email || "--"}</Td>

                <Td textTransform={"capitalize"}>{el?.country || "--"}</Td>

                <Td textTransform={"capitalize"} color="gray.500">
                  {formatFullDateToUsersLocale(
                    el?.createdAt || new Date().toString()
                  )}
                </Td>
                <Td>
                  <Button
                    px="6"
                    size="sm"
                    variant="outline"
                    colorScheme="gray"
                    borderColor="gray.400"
                    _hover={{ background: "primary.100" }}
                    onClick={() => onViewWallet(el?.id)}
                  >
                    View Wallet
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      <PaginationWrapper
        pages={pages}
        offset={offset}
        size={pageSize}
        isDisabled={isDisabled}
        pagesCount={pagesCount}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        totalDataCount={allMerchants?.pagination?.total}
      />

      <ViewWalletModal
        onClose={onCloseViewWalletModal}
        isOpen={!!params.get("user-id") && !!params.get("view-wallet")}
      />
    </React.Fragment>
  );
};

export default AllMerchantsTable;
