import { PaginationState, ReportsResponse } from "app/store/models/reports";
import { AxiosResponse } from "axios";
import instance from "data/axios-setup";

class ReportsService {
  async getAllReports(params: {
    page: number;
    perpage: number;
    q?: string;
  }): Promise<
    AxiosResponse<{
      data: ReportsResponse[];
      pagination: PaginationState;
    }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get(`/admin/transactions`, { params });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }
}

export default new ReportsService();
