import {
  IGetWishListParams,
  IGetWishListItemParams,
  ICreateWishListItemsBody,
  IUpdateWishListItemsBody,
  IGetValentineWishListItemParams,
  IAddValentineWishListItemsBody,
  ISelectValentineWishListWinnerBody,
  IGetValentineWishListTopUsersParams
} from "app/store/models/wishlist";
import {
  getWishList,
  denyWishList,
  approveWishList,
  createWishListItems,
  getWishListItems,
  updateWishListItems,
  getValentineWishList,
  updateValentineWishListItems,
  getValentineWishListItems,
  addValentineWishListItems,
  selectValentineWishListWinner,
  getValentineWishListTopUsers,
  getValentineWishListSelectedWinners
} from "app/store/action-creators/wishlist.actions";
import { useAppDispatch, useAppSelector } from "./useReduxState";

export const useWishList = () => {
  const dispatch = useAppDispatch();

  const [
    wishList,
    wishListItems,
    singleWishList,
    createWishListItemsState,
    denyWishListState,
    approveWishListState,
    updateWishListItemsState
  ] = useAppSelector((state) => [
    state.wishList.getWishList,
    state.wishList.getWishListItems,
    state.wishList.getSingleWishList,
    state.wishList.createWishListItems,
    state.wishList.denyWishList,
    state.wishList.approveWishList,
    state.wishList.updateWishListItems
  ]);

  const dispatchWishList = async (params: IGetWishListParams) => {
    dispatch(getWishList(params));
  };

  const dispatchUpdateWishListItems = async (
    body: IUpdateWishListItemsBody
  ) => {
    dispatch(updateWishListItems(body));
  };

  const dispatchWistListItems = async (params: IGetWishListItemParams) => {
    dispatch(getWishListItems(params));
  };

  const dispatchCreateWishListItems = async (
    body: ICreateWishListItemsBody
  ) => {
    return await dispatch(createWishListItems(body));
  };

  const dispatchApproveWishList = async (id: number) => {
    return await dispatch(approveWishList(id));
  };

  const dispatchDenyWishList = async (id: number, reason?: string) => {
    return await dispatch(denyWishList(id, reason));
  };

  return {
    wishList,
    wishListItems,
    singleWishList,
    dispatchWishList,
    denyWishListState,
    dispatchDenyWishList,
    approveWishListState,
    dispatchWistListItems,
    dispatchApproveWishList,
    createWishListItemsState,
    updateWishListItemsState,
    dispatchUpdateWishListItems,
    dispatchCreateWishListItems
  };
};

export const useValentineWishList = () => {
  const dispatch = useAppDispatch();

  const [
    valentineWishList,
    valentineWishListItems,
    createValentineWishListItem,
    approveValentineWishListState,
    updateValentineWishListItemsState,
    valentineWishListTopUsers,
    valentineWishListSelectedWinners
  ] = useAppSelector((state) => [
    state.wishList.getValentineWishList,
    state.wishList.getValentineWishListItems,
    state.wishList.addValentineWishListItems,
    state.wishList.approveValentineWishListWinner,
    state.wishList.updateValentineWishListItem,
    state.wishList.getValentineWishListTopUsers,
    state.wishList.getValentineWishListWinners
  ]);

  const dispatchValentineWishList = async () => {
    dispatch(getValentineWishList());
  };

  const dispatchUpdateValentineWishListItem = async (
    id: number,
    body: { item: string }
  ) => {
    dispatch(updateValentineWishListItems(id, body));
  };

  const dispatchValentineWishListItems = async (
    params: IGetValentineWishListItemParams
  ) => {
    dispatch(getValentineWishListItems(params));
  };

  const dispatchCreateValentineWishListItems = async (
    body: IAddValentineWishListItemsBody
  ) => {
    return await dispatch(addValentineWishListItems(body));
  };

  const dispatchSelectValentineWishListWinner = async (
    body: ISelectValentineWishListWinnerBody
  ) => {
    return await dispatch(selectValentineWishListWinner(body));
  };

  const dispatchValentineWishTopUsers = async (
    body: IGetValentineWishListTopUsersParams
  ) => {
    return await dispatch(getValentineWishListTopUsers(body));
  };

  const dispatchValentineSelectedWinners = async (
    body: IGetValentineWishListTopUsersParams
  ) => {
    return await dispatch(getValentineWishListSelectedWinners(body));
  };

  return {
    valentineWishList,
    valentineWishListItems,
    valentineWishListTopUsers,
    dispatchValentineWishList,
    createValentineWishListItem,
    approveValentineWishListState,
    dispatchValentineWishTopUsers,
    dispatchValentineWishListItems,
    valentineWishListSelectedWinners,
    dispatchValentineSelectedWinners,
    updateValentineWishListItemsState,
    dispatchUpdateValentineWishListItem,
    dispatchCreateValentineWishListItems,
    dispatchSelectValentineWishListWinner
  };
};
