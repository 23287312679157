import {
  Title,
  Legend,
  Tooltip,
  BarElement,
  LinearScale,
  CategoryScale,
  Chart as ChartJS
} from "chart.js";
import { Bar } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";

ChartJS.register(
  Title,
  Legend,
  Tooltip,
  zoomPlugin,
  BarElement,
  LinearScale,
  CategoryScale
);

const TransactionChart = ({ data, labels }: any) => {
  return (
    <Bar
      style={{ maxWidth: "100%", width: "100%", height: "100%" }}
      options={{
        scales: {
          x: {
            grid: { display: false, drawBorder: false }
          },
          y: {
            display: true,
            ticks: { count: 3 },
            grid: {
              display: false,
              drawBorder: false
            }
          }
        },
        plugins: {
          title: { display: false },
          legend: { display: false },
          subtitle: { display: false },
          zoom: {
            pan: { enabled: true, mode: "x" },
            zoom: {
              mode: "x",
              pinch: { enabled: true },
              wheel: { enabled: true, speed: 0.001 }
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false
      }}
      data={{
        labels,
        datasets: [
          {
            data,
            borderRadius: 5,
            barPercentage: 0.75,
            borderSkipped: false,
            backgroundColor: "#F0F1F3",
            hoverBackgroundColor: "#141D3E"
          }
        ]
      }}
    />
  );
};

export default TransactionChart;
