import { DEFAULT_STATUS_TYPE } from "data/error-mapping";

export type TSummaryLoading = "getSummary";

export enum TransactionTime {
  daily = "dailyTransactions",
  weekly = "weeklyTransactions",
  monthly = "monthlyTransactions",
  yearly = "yearlyTransactions"
}

export interface ISummaryError {
  msg: string;
  for: TSummaryLoading;
}

export interface ISummaryState {
  data: null | ISummary;
  error: null | string;
  status: {
    getSummary: DEFAULT_STATUS_TYPE;
  };
}

interface ITransactionYearly {
  sum: number;
  year: number;
  total: number;
  average: number;
}

interface ISummaryRecentTransaction {
  id: number;
  rate: number;
  type: string;
  amount: number;
  userId: number;
  subType: string;
  currency: string;
  walletId: number;
  faucetId?: number;
  createdAt: string;
  updatedAt: string;
  reference: string;
  description: string;
  currencyType: string;
  balanceAfter: number;
  balanceBefore: number;
  signedAmount?: number;
  paymentGateway: string;
  virtualCardId?: number;
  cryptoWalletId?: number;
  earningWalletId?: number;
  groupTrxReference: string;
  virtualAccountId?: number;
  changeraTransactionType: string;
}

export interface ISummary {
  totalUsers: number;
  totalWallets: number;
  totalKycRequest: number;
  totalFiatWallets: number;
  dailyTransactions: any[];
  walletPieChartData: any[];
  weeklyTransactions: any[];
  monthlyTransactions: any[];
  totalCryptoWallets: number;
  yearlyTransactions: ITransactionYearly[];
  recentTransactions: ISummaryRecentTransaction[];
}
