import {
  Box,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Divider
} from "@chakra-ui/react";
import { useState } from "react";
import { useAppSelector } from "app/hooks";
import TransactionSkeletion from "views/containers/transactions/tables/skeleton";
import NoData from "views/components/no-data";
import { formatFullDateToUsersLocale } from "data/utils/date-formatter-users-locale";
import { TransactionBadge } from "views/components/badge";
import getSymbolFromCurrency from "currency-symbol-map";
import { TEuroGBPAccount } from "app/store/models/user";
import VirtualAccountDetails from "../virtual-account";

const UserVirtualCards = () => {
  const user = useAppSelector((state) => state.user.singleUser);
  const virtualAccounts = user.data?.euroGbpAccounts || [];

  const [selectedAccount, setSelectedAccount] =
    useState<TEuroGBPAccount | null>(null);

  return (
    <Box className="container mb-20">
      <div>
        <Box
          p={[4, 6]}
          display="flex"
          bgColor="#fff"
          borderRadius="10px"
          flexDirection="column"
        >
          <Box display="flex" flexDirection="column" className="mt-2">
            <TableContainer bg="#fff" minH="calc(100vh - 10rem)">
              <Table
                color="brand.grayScale"
                fontSize="14px"
                lineHeight="18.2px"
                colorScheme={"gray"}
                variant="striped"
              >
                <Thead>
                  <Tr>
                    <Th
                      textTransform={"capitalize"}
                      color="gray.800"
                      fontSize={"16px"}
                      lineHeight={"19px"}
                      fontWeight={"medium"}
                    >
                      Account Name
                    </Th>
                    <Th
                      textTransform={"capitalize"}
                      color="gray.800"
                      fontSize={"16px"}
                      lineHeight={"19px"}
                      fontWeight={"medium"}
                    >
                      Account Number
                    </Th>
                    <Th
                      textTransform={"capitalize"}
                      color="gray.800"
                      fontSize={"16px"}
                      lineHeight={"19px"}
                      fontWeight={"medium"}
                    >
                      Bank Details
                    </Th>

                    <Th
                      textTransform={"capitalize"}
                      color="gray.800"
                      fontSize={"16px"}
                      lineHeight={"19px"}
                      fontWeight={"medium"}
                    >
                      Balance
                    </Th>
                    <Th
                      textTransform={"capitalize"}
                      color="gray.800"
                      fontSize={"16px"}
                      lineHeight={"19px"}
                      fontWeight={"medium"}
                    >
                      Date Created
                    </Th>
                    <Th
                      textTransform={"capitalize"}
                      color="gray.800"
                      fontSize={"16px"}
                      lineHeight={"19px"}
                      fontWeight={"medium"}
                    >
                      Status
                    </Th>
                  </Tr>
                </Thead>
                <Divider />
                <Tbody>
                  {user?.loading ? <TransactionSkeletion /> : null}
                  {!!virtualAccounts?.length && !user?.loading
                    ? virtualAccounts?.map((el, idx) => (
                        <Tr key={idx}>
                          <Td textTransform={"capitalize"}>
                            {el.accountName || "--"}
                          </Td>

                          <Td textTransform={"capitalize"}>
                            <Stack>
                              <Text
                                fontSize={"14px"}
                                fontWeight="medium"
                                lineHeight={"1.2"}
                              >
                                {el.accountNumber || ""}
                              </Text>
                            </Stack>
                          </Td>
                          <Td textTransform={"capitalize"}>
                            <Stack>
                              <Text
                                fontSize={"14px"}
                                fontWeight="medium"
                                lineHeight={"1.2"}
                              >
                                {el.bankName}
                              </Text>
                              <Text
                                fontSize={"14px"}
                                lineHeight={"1.2"}
                                color="green.600"
                                marginTop={0}
                                cursor="pointer"
                                fontWeight="bold"
                                onClick={() => {
                                  setSelectedAccount(el);
                                }}
                              >
                                View More
                              </Text>
                            </Stack>
                          </Td>
                          <Td textTransform={"capitalize"}>
                            {getSymbolFromCurrency(el?.currency)}{" "}
                            {el?.balance?.toLocaleString() || "--"}
                          </Td>

                          <Td textTransform={"capitalize"} color="gray.500">
                            {el.createdAt
                              ? formatFullDateToUsersLocale(el.createdAt)
                              : "--"}
                          </Td>

                          <Td textTransform={"capitalize"}>
                            <TransactionBadge variant={el.status as any} />
                          </Td>
                        </Tr>
                      ))
                    : null}
                  {!virtualAccounts?.length && !user?.loading ? (
                    <Tr>
                      <Td colSpan={7} background="transparent" h="xl">
                        <NoData text={`No virtual accounts Yet`} />
                      </Td>
                    </Tr>
                  ) : null}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </div>

      <VirtualAccountDetails
        isOpen={!!selectedAccount}
        selectedAccount={selectedAccount!}
        onClose={() => setSelectedAccount(null)}
      />
    </Box>
  );
};

export default UserVirtualCards;
