import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Stack,
  useDisclosure
} from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useEffect, useState } from "react";
import { HiOutlineStar } from "react-icons/hi";
import { useSearchParams } from "react-router-dom";
//import TabLink from "views/components/links/tab-link";
import PushNotificationMultiple from "../modals/push-notifcations-multiple";
import ActiveUsersTable from "./active-users-table";
import BannedUsersTable from "./banned-users-table";
import UserFilterModal from "./filter";
import { Refresh } from "iconsax-react";
import { setUserFilter } from "app/store";

const Tables = () => {
  const dispatch = useAppDispatch();
  const [searchInput, setSearchInput] = useState("");
  const [isLoading, allUsersCount] = useAppSelector((state) => [
    state.user.allUsers.loading,
    state.user.allUsers.pagination.total
  ]);

  const [searchParams, setSearchParams] = useSearchParams();
  let blocked = searchParams.get("blocked");

  useEffect(() => {
    if (!blocked) {
      setSearchParams({ blocked: "false" });
    }
  }, []);

  const {
    isOpen: isPushOpen,
    onOpen: onPushOpen,
    onClose: onPushClose
  } = useDisclosure();

  const clearUserFilter = () => {
    setSearchInput("");
    dispatch(setUserFilter({ currentDate: new Date().getTime() }));
  };

  return (
    <Box bg="#fff" borderRadius="16px" padding={"1rem 1rem 24px"}>
      <Flex justifyContent={"space-between"} alignItems="center">
        <div className="flex gap-4 items-center">
          <Heading
            as={"h3"}
            fontSize="24px"
            fontWeight={"medium"}
            lineHeight="28px"
            py="4"
            px="2"
          >
            User Information
            <Box
              as="span"
              color="blue.500"
              fontSize={"12px"}
              lineHeight="1.2"
              fontWeight={"400"}
            >
              {isLoading ? ".........." : allUsersCount || ""}
            </Box>
          </Heading>
          <Stack
            direction={"row"}
            color="gray.600"
            position="relative"
            alignItems="center"
          >
            <Icon as={HiOutlineStar} w={"5"} h={"5"} cursor="not-allowed" />
            <UserFilterModal setSearchInput={setSearchInput} />
            <Icon
              as={Refresh}
              cursor="pointer"
              color="success.600"
              onClick={clearUserFilter}
              className={`${isLoading && "animate-spin"}`}
            />
          </Stack>
        </div>

        <Button
          colorScheme={"primary"}
          size={["sm", "md"]}
          onClick={onPushOpen}
        >
          Push Notification
        </Button>
      </Flex>
      {/* <Flex direction={"row"} justifyContent={"space-between"} px="2" py="2">
        <Stack spacing={"5"} direction="row">
          <TabLink
            urlParamKey={"blocked"}
            urlParamValue={false.toString()}
            count={allUsersCount}
          >
            Active Users
          </TabLink>
          <TabLink
            urlParamKey={"blocked"}
            urlParamValue={true.toString()}
            count={bannedCount}
          >
            Deactivated
          </TabLink>
        </Stack>
        <Stack direction={"row"} color="gray.600" position="relative">
          <Icon as={HiOutlineStar} w={"5"} h={"5"} cursor="not-allowed" />
          <UserFilterModal />
        </Stack>
      </Flex> */}

      <ActiveUsersTable
        searchInput={searchInput}
        setSearchInput={setSearchInput}
      />

      {false && <BannedUsersTable />}

      {isPushOpen ? (
        <PushNotificationMultiple isOpen={isPushOpen} onClose={onPushClose} />
      ) : null}
    </Box>
  );
};

export default Tables;
