import { createSlice } from "@reduxjs/toolkit";
import { DashboardState } from "../models/dashboard";

const initialState: DashboardState = {
  userProfile: null,
  loading: false,
  dashboardError: false
};

export const DashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    gettingUserProfile: (state: DashboardState) => {
      state.loading = true;
    },
    settingUser: (state: DashboardState, action: any) => {
      state.userProfile = action.payload;
      state.loading = false;
    },
    dashboardError: (state: DashboardState) => {
      state.loading = false;
      state.dashboardError = true;
    }
  }
});

export const actions = DashboardSlice.actions;

export default DashboardSlice.reducer;
