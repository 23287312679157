import {
  Td,
  Th,
  Tr,
  Box,
  Table,
  Tbody,
  Thead,
  Text,
  TableContainer,
  Stack,
  Select,
  Icon
} from "@chakra-ui/react";
import React from "react";
import RequestModal from "./details";
import { getAllWithdrawals } from "app/store";
import { usePagination } from "@ajna/pagination";
import { IWithdrawal, ProcessWithdrawalStatus } from "app/store/models/request";
import getSymbolFromCurrency from "currency-symbol-map";
import { TransactionBadge } from "views/components/badge";
import { useAppDispatch, useAppSelector, useCopyToClipboard } from "app/hooks";
import PaginationWrapper from "views/components/PaginationWrapper/PaginationWrapper";
import { actions } from "app/store/reducers/request.reducer";
import WithdrawalSkeleton from "./skeleton";
import { useUserDetails } from "app/hooks/use-user-details";
import { formatFullDateToUsersLocale } from "data/utils/date-formatter-users-locale";
import { IoMdCopy } from "react-icons/io";

const head = [
  "User Id",
  "Username",
  "FullName",
  "Type",
  "Reference",
  "Account Name",
  "Bank Details",
  "Mobile Money Name",
  "Mobile Money Details",
  "Source Amount",
  "Destination Amount",
  "Withdrawal Fee",
  "Changera Plan",
  "Date",
  "Status"
];

const Withdrawals = () => {
  const dispatch = useAppDispatch();
  const copyHandler = useCopyToClipboard();
  const [status, withdrawalStatus] = useAppSelector((state) => [
    state.request.status,
    state.request.withdrawal.status
  ]);
  const withdrawal = useAppSelector((state) => state.request.withdrawal);
  const [selected, setSelected] = React.useState<null | IWithdrawal>(null);

  const {
    pages,
    offset,
    pageSize,
    pagesCount,
    isDisabled,
    currentPage,
    setCurrentPage
  } = usePagination({
    limits: { inner: 2, outer: 1 },
    total: withdrawal?.pagination?.total,
    initialState: { currentPage: 1, pageSize: 10 }
  });

  const handleSetWithdrawal = (data: IWithdrawal) => {
    setSelected(data);
  };

  const handleCloseWithdrawal = () => {
    setSelected(null);
  };

  const handlePageChange = (nextPage: number) => {
    setCurrentPage(nextPage);
    setTimeout(() => {
      window.scrollTo({ top: 10, behavior: "smooth" });
    }, 500);
  };

  React.useEffect(() => {
    dispatch(
      getAllWithdrawals({
        page: currentPage,
        perpage: pageSize,
        status: withdrawalStatus
      })
    );
  }, [currentPage, withdrawalStatus]);

  // user details
  const { onOpenUserDetails, UserDetail } = useUserDetails();

  return (
    <React.Fragment>
      <RequestModal data={selected} onClose={handleCloseWithdrawal} />

      <TableContainer>
        <Stack mt="0.75rem" ml="2" spacing="4" direction={"row"}>
          <Box mb="12px">
            <Select
              width="120px"
              defaultValue={""}
              placeholder="status"
              onChange={(e) =>
                dispatch(
                  actions.setWithdrawalStatus(
                    e.target.value as ProcessWithdrawalStatus
                  )
                )
              }
            >
              <option value={ProcessWithdrawalStatus.approve}>Approved</option>
              <option value={ProcessWithdrawalStatus.deny}>Denied</option>
              <option value={ProcessWithdrawalStatus.pending}>Pending</option>
              <option value={ProcessWithdrawalStatus.processing}>
                Processing
              </option>
              <option value={ProcessWithdrawalStatus.cancelled}>
                Cancelled
              </option>
            </Select>
          </Box>
        </Stack>
        <Table variant="striped" colorScheme="gray">
          <Thead>
            <Tr>
              {head.map((data) => (
                <Th
                  key={data}
                  fontSize={16}
                  color="gray.800"
                  fontWeight={500}
                  lineHeight="19px"
                  letterSpacing="0px"
                  textTransform="unset"
                >
                  {data}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {status.getAllWithdrawals === ("loading" as any) ? (
              <WithdrawalSkeleton />
            ) : null}

            {!!withdrawal?.data &&
            status.getAllWithdrawals !== ("loading" as any)
              ? withdrawal?.data?.map((data) => (
                  <Tr key={data?.id}>
                    <Td
                      cursor="pointer"
                      _hover={{ color: "#B1B4BF" }}
                      onClick={() => onOpenUserDetails(data?.userId)}
                    >
                      {data?.userId || "-"}
                    </Td>
                    <Td>{data?.user?.username || "-"}</Td>
                    <Td>
                      {data?.user
                        ? `${data?.user?.firstName} ${data?.user?.lastName}`
                        : "-"}
                    </Td>
                    <Td>{data?.type || "-"}</Td>

                    <Td>
                      <Stack spacing="2" direction="row" alignItems="center">
                        <Text maxWidth="250px" className="truncate">
                          {data?.moneygranTransactionReference ||
                            data?.moneygramTransactionReference ||
                            "--"}
                        </Text>
                        {data?.moneygranTransactionReference ||
                          (data?.moneygramTransactionReference && (
                            <Icon
                              w={5}
                              h={5}
                              as={IoMdCopy}
                              color="success.300"
                              cursor={"pointer"}
                              onClick={() =>
                                copyHandler(
                                  data?.moneygranTransactionReference ||
                                    data?.moneygramTransactionReference ||
                                    "",
                                  "MoneyGram Transaction Reference"
                                )
                              }
                            />
                          ))}
                      </Stack>
                    </Td>

                    <Td>{data?.bankAccountName || "--"}</Td>
                    <Td>
                      <Box
                        mb={1}
                        fontSize={14}
                        color="gray.900"
                        fontWeight={500}
                        lineHeight="17px"
                        letterSpacing={0}
                      >
                        {data?.bankAccountNumber || "--"}
                      </Box>
                      <Box color="gray.600" fontSize={13} lineHeight="14px">
                        {data?.bankName || ""}
                      </Box>
                    </Td>
                    <Td>{data?.mobileMoneyName || "--"}</Td>
                    <Td>
                      <Box
                        mb={1}
                        fontSize={14}
                        color="gray.900"
                        fontWeight={500}
                        lineHeight="17px"
                        letterSpacing={0}
                      >
                        {data?.phone || "--"}
                      </Box>
                      <Box color="gray.600" fontSize={13} lineHeight="14px">
                        {data?.mobileNetwork || ""}
                      </Box>
                    </Td>
                    <Td
                      fontSize={16}
                      color="gray.900"
                      fontWeight={500}
                      letterSpacing={0}
                      lineHeight="19px"
                    >
                      {getSymbolFromCurrency(data?.sourceCurrency)}{" "}
                      {data && data?.originalAmount
                        ? data?.originalAmount?.toLocaleString()
                        : ""}
                    </Td>

                    <Td
                      fontSize={16}
                      color="gray.900"
                      fontWeight={500}
                      lineHeight="19px"
                      letterSpacing="0px"
                    >
                      {getSymbolFromCurrency(data?.destinationCurrency)}{" "}
                      {data && data?.destinationAmount
                        ? data?.destinationAmount?.toLocaleString()
                        : ""}
                    </Td>

                    <Td color="error.500" lineHeight="18px" fontSize={14}>
                      {getSymbolFromCurrency(data?.sourceCurrency)}{" "}
                      {data && data?.transferFee
                        ? data?.transferFee?.toLocaleString()
                        : ""}
                    </Td>

                    <Td>{data?.user?.plan}</Td>

                    <Td color="gray.500" lineHeight="18px" fontSize={14}>
                      {formatFullDateToUsersLocale(data?.createdAt)}
                    </Td>

                    <Td>
                      <TransactionBadge
                        cursor="pointer"
                        onClick={() => handleSetWithdrawal(data)}
                        children={data?.status}
                        variant={data?.status as any}
                      />
                    </Td>
                  </Tr>
                ))
              : null}
          </Tbody>
        </Table>
      </TableContainer>

      <PaginationWrapper
        pages={pages}
        size={pageSize}
        offset={offset}
        isDisabled={isDisabled}
        pagesCount={pagesCount}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        totalDataCount={withdrawal?.pagination?.total}
      />

      {UserDetail}
    </React.Fragment>
  );
};

export default Withdrawals;
