import React from "react";
import PaymentTable from "./payment";
import WithdrawalTable from "./withdrawal";
import { Tabs, TabList, Tab, TabPanel, TabPanels, Box } from "@chakra-ui/react";
import { useAppSelector } from "app/hooks";

type Props = { walletId: number };

const TransactionTable = ({ walletId }: Props) => {
  const [index, setTabIndex] = React.useState(0);
  const [isLoading, totalCount] = useAppSelector((state) => [
    state.transactions.walletTransactions.loading,
    state.transactions.walletTransactions.pagination.total
  ]);

  return (
    <Tabs colorScheme="primary.500" onChange={(index) => setTabIndex(index)}>
      <TabList>
        <Tab>
          <span>Withdrawal</span>
          {index === 0 && (
            <Box
              ml={2}
              fontSize={12}
              color="blue.500"
              display="inline"
              fontWeight={400}
              lineHeight="14px"
            >
              <span>{isLoading ? 0 : totalCount}</span>
            </Box>
          )}
        </Tab>

        <Tab>
          <span>Payment</span>
          {index === 1 && (
            <Box
              ml={2}
              fontSize={12}
              color="blue.500"
              display="inline"
              fontWeight={400}
              lineHeight="14px"
            >
              <span>{isLoading ? 0 : totalCount}</span>
            </Box>
          )}
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel>
          {index === 0 && <WithdrawalTable walletId={walletId} />}
        </TabPanel>

        <TabPanel>
          {index === 1 && <PaymentTable walletId={walletId} />}
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default TransactionTable;
