import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  PaginationState,
  SingleVerificationData,
  VerificationData,
  VerificationState
} from "../models/verification";

const initialState: VerificationState = {
  allVerification: {
    data: [],
    loading: false,
    pagination: {
      hasPrevious: false,
      prevPage: 0,
      hasNext: true,
      next: 2,
      currentPage: 1,
      pageSize: 10,
      lastPage: 0,
      total: 0
    }
  },
  singleVerification: {
    data: null,
    loading: false
  },
  deleteVerifiaction: { loading: false, error: "" }
};

export const VerificationSlice = createSlice({
  name: "verification",
  initialState,
  reducers: {
    getAllVerificationPending: (state) => {
      state.allVerification.loading = true;
    },
    getAllVerificationSuccess: (
      state,
      action: PayloadAction<{
        data: VerificationData[];
        pagination: PaginationState;
      }>
    ) => {
      state.allVerification.data = action.payload.data;
      state.allVerification.pagination = action.payload.pagination;
      state.allVerification.loading = false;
    },
    getAllVerificationFailed: (state) => {
      state.allVerification.loading = false;
    },

    deleteVerificationPending: (state) => {
      state.deleteVerifiaction.loading = true;
    },
    deleteVerificationSuccess: (state) => {
      state.deleteVerifiaction.error = "";
      state.deleteVerifiaction.loading = false;
    },
    deleteVerificationFailed: (state, action: PayloadAction<string>) => {
      state.deleteVerifiaction.loading = false;
      state.deleteVerifiaction.error = action.payload;
    },

    getSingleVerificationPending: (state) => {
      state.singleVerification.loading = true;
    },
    getSingleVerificationSuccess: (
      state,
      action: PayloadAction<{
        data: SingleVerificationData;
      }>
    ) => {
      state.singleVerification.data = action.payload.data;
      state.singleVerification.loading = false;
    },
    getSingleVerificationFailed: (state) => {
      state.singleVerification.loading = false;
    },
    resetSingleVerification: (state) => {
      state.singleVerification.data = null;
      state.singleVerification.loading = false;
    }
  }
});

export const actions = VerificationSlice.actions;

export default VerificationSlice.reducer;
