// import Search from "./components/search";
import {
  Box,
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Hide,
  Icon,
  Stack,
  useDisclosure
} from "@chakra-ui/react";
import BellIcon from "./components/bell-icon";
import HeaderAvatar from "./components/avatar";
import { IoMenuOutline } from "react-icons/io5";
import Content from "../sidebar/content";
import { useLayoutEffect } from "react";
import { useAppDispatch } from "app/hooks";
import { toggleSideBar } from "app/store/action-creators/ui.action";

const HeaderMobile = () => {
  const dispatch = useAppDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useLayoutEffect(() => {
    const closeMobileNavOnResize = () => {
      if (window.innerWidth > 992) {
        onClose();
      }
    };
    window.addEventListener("resize", closeMobileNavOnResize);

    return () => {
      window.removeEventListener("resize", closeMobileNavOnResize);
    };
  }, []);

  return (
    <Box width="100%">
      <Stack
        direction="row"
        spacing={[4, 6]}
        alignItems="center"
        justifyContent="space-between"
      >
        <Center>
          <Hide above="lg" key={"mobile-menu"}>
            <Icon
              as={IoMenuOutline}
              boxSize="8"
              cursor={"pointer"}
              onClick={onOpen}
            />
          </Hide>
          <Hide below="lg" key={"desktop-menu"}>
            <Icon
              as={IoMenuOutline}
              boxSize="8"
              cursor={"pointer"}
              onClick={() => dispatch(toggleSideBar())}
            />
          </Hide>
        </Center>

        <Stack direction="row" alignItems="center" spacing={[4, 6]}>
          <BellIcon />
          <HeaderAvatar />
        </Stack>
      </Stack>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent className="p-0">
          <DrawerBody padding="0">
            <Content onCloseSideBar={onClose} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default HeaderMobile;
