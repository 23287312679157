import { DEFAULT_STATUS_TYPE } from "data/error-mapping";

export enum ProcessWithdrawalStatus {
  deny = "denied",
  approve = "approved",
  processing = "processing",
  pending = "pending",
  cancelled = "cancelled"
}

export enum ProcessSendMoneyStatus {
  deny = "denied",
  approve = "approved"
}

export enum ToggleWalletStatus {
  activate = "activate",
  deactivate = "deactivate"
}

export type PaginationState = {
  currentPage: number;
  hasNext?: boolean;
  hasPrevious?: boolean;
  lastPage?: number;
  next?: number;
  pageSize: number;
  prevPage?: number;
  total?: number;
};

export type TRequestLoading =
  | "getAllFiatWallets"
  | "getAllCryptoWallets"
  | "getAllSendMoney"
  | "getAllWithdrawals"
  | "processWithdrawal"
  | "processSendMoney"
  | "toggleFiatWallet";

export interface IFiatWallet {
  id: number;
  active: true;
  abbr?: string;
  userId: number;
  balance: number;
  country?: string;
  currency: string;
  createdAt: string;
  updatedAt: string;
  currencyId: number;
  lastDeposit: number;
  lastWithdrawal: number;
  currencies: { id: number; name: string; code: string; symbol: string };
  user: {
    email: string;
    username: string;
    lastName: string;
    firstName: string;
    phone: string;
  };
}

export interface ICryptoWallet {
  id: number;
  memo?: string;
  userId: number;
  qrcode: string;
  address: string;
  balance: number;
  network: string;
  cointype: string;
  createdAt: string;
  updatedAt: string;
  tagNumber?: string;
  deletedAt?: string;
  user: {
    email: string;
    username: string;
    lastName: string;
    firstName: string;
    phone: string;
  };
}

export interface IRequestError {
  msg: string;
  for: TRequestLoading;
}

export interface IRequestSuccess {
  msg: string;
  for: TRequestLoading;
}

interface User {
  email: string;
  username: string;
  lastName: string;
  firstName: string;
  phone: string;
  plan: string;
}

export interface ISendMoney {
  id: number;
  userId: number;
  walletId: number;
  amount: number;
  currency: string;
  type: string;
  description: string;
  abbr?: string;
  rate: number;
  fee: number;
  recipientUserId: number;
  recipientWalletId: number;
  recipientAmount: number;
  recipientCurrency: string;
  recipientAbbr?: string;
  recipientBankName?: string;
  recipientBankAccountName?: string;
  recipientBankAccountNumber?: string;
  recipientPhoneNumber: string;
  mobileMoneyNetwork: string | null;
  recipientEmail: string;
  createdAt: string;
  updatedAt: string;
  recipientUsername: string;
  status: string;
  processedBy?: string;
  user: User;
}

export interface IWithdrawalState {
  data: IWithdrawal[];
  status: ProcessWithdrawalStatus | "";
  selected: IWithdrawal | {};
  pagination: PaginationState;
}

export interface ICryptoWalletState {
  data: ICryptoWallet[];
  pagination: PaginationState;
}

export interface IFiatWalletState {
  data: IFiatWallet[];
  pagination: PaginationState;
}

export interface ISendMoneyState {
  data: ISendMoney[];
  status: ProcessWithdrawalStatus | "";
  pagination: PaginationState;
}

export interface IWithdrawal {
  id: number;
  sourceAmount: number;
  destinationAmount: number;
  sourceCurrency: string;
  destinationCurrency: string;
  transferFee: number;
  conversionRate: number;
  conversionFee: number;
  bankAccountNumber: string;
  bankName: string;
  bankBranch?: string;
  routingNumber?: string;
  status: string;
  type: string;
  createdAt: string;
  updatedAt: string;
  bankAccountName: string;
  mobileNetwork: string;
  mobileMoneyName: string;
  phone: string;
  userId: number;
  sourceWalletId: number;
  processedBy?: string;
  originalAmount: number;
  moneygranTransactionReference?: string;
  moneygramTransactionReference?: string;
  sameDestination: false;
  user: {
    email: string;
    username: string;
    lastName: string;
    firstName: string;
    phone: string;
    plan: string;
  };
}

export interface IRequestState {
  error: null | string;
  sendMoney: ISendMoneyState;
  withdrawal: IWithdrawalState;
  fiatWallet: IFiatWalletState;
  crytoWallet: ICryptoWalletState;
  status: {
    getAllSendMoney: DEFAULT_STATUS_TYPE;
    processSendMoney: DEFAULT_STATUS_TYPE;
    toggleFiatWallet: DEFAULT_STATUS_TYPE;
    getAllWithdrawals: DEFAULT_STATUS_TYPE;
    getAllFiatWallets: DEFAULT_STATUS_TYPE;
    processWithdrawal: DEFAULT_STATUS_TYPE;
    getAllCryptoWallets: DEFAULT_STATUS_TYPE;
  };
}
