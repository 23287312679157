import { AxiosError } from "axios";
import toast from "react-hot-toast";
import { Dispatch } from "@reduxjs/toolkit";
import { actions } from "../reducers/team-management.reducer";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";
import teamManagementService from "data/services/team-management-service";
import instance from "data/axios-setup";

export const getAllTeams = (params: {
  page: number;
  perpage: number;
  q?: string;
}) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getAllTeamsBegin());
      const res = await teamManagementService.getAllTeams(params);
      if (res.status === 200 || 201 || 202) {
        dispatch(
          actions.getAllTeamsSuccess({
            data: res.data.data,
            pagination: res.data.pagination
          })
        );
      }
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.getAllTeamsFailed());
      toast.error(msg);
    }
  };
};

export const getAllRoles = () => {
  return async (dispatch: Dispatch) => {
    try {
      const res = await instance.get("/admin/roles");
      if (res.status == 201 || 200 || 202) {
        dispatch(
          actions.gottenRoles({
            data: res.data.data
          })
        );
      }
    } catch (err: any) {
      dispatch(actions.error());
    }
  };
};

export const unassignTeamMember = (data: {}, dis?: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loadingBegin());
      const res = await instance.put("/admin/roles", data);
      if (res.status == 201 || 200 || 202) {
        toast.success("You have successfully unassigned a team member.");
        dispatch(actions.teamMemberCreatedSuccesful());
        if (dis) {
          dis(getAllTeams({ page: 1, perpage: 10, q: "" }));
        }
      }
    } catch (err: any) {
      dispatch(actions.error());
      toast.error(err.response.data.message);
    }
  };
};

export const updateRole = (data: {}, dis: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.togglingUser());
      toast.loading("Updating user role.");
      const res = await instance.put(`/admin/roles`, data);
      if (res.status == 201 || 200 || 202) {
        toast.dismiss();
        toast.success("You have successfully updated this user role.");
        dis(getAllTeams({ page: 1, perpage: 10, q: "" }));
      }
    } catch (err: any) {
      toast.dismiss();
      dispatch(actions.error());
      toast.error(err.response.data.message);
    }
  };
};

export const deactivateTeamMember = (
  data: string,
  dis: any,
  callback?: () => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.togglingUser());
      toast.loading("Deactivating User.");
      const res = await instance.put(`/admin/users/${data}/deactivate`);
      if (res.status == 201 || 200 || 202) {
        toast.dismiss();
        toast.success("You have successfully deactivated this user.");
        if (callback) callback();
        dispatch(actions.toggledUser());
        dis(getAllTeams({ page: 1, perpage: 10, q: "" }));
      }
    } catch (err: any) {
      toast.dismiss();
      dispatch(actions.error());
      toast.error(err.response.data.message);
    }
  };
};

export const activateTeamMember = (data: string, dis: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.togglingUser());
      toast.loading("Activatiing User.");
      const res = await instance.put(`/admin/users/${data}/activate`);
      if (res.status == 201 || 200 || 202) {
        toast.dismiss();
        toast.success("You have successfully activated this user.");
        dispatch(actions.toggledUser());
        dis(getAllTeams({ page: 1, perpage: 10, q: "" }));
      }
    } catch (err: any) {
      toast.dismiss();
      dispatch(actions.error());
      toast.error(err.response.data.message);
    }
  };
};

export const resetCreateTeamSuccess = () => {
  return async (dispatch: Dispatch) => {
    dispatch(actions.resetCreateTeamSuccess());
  };
};
