import { UilAngleDown } from "@iconscout/react-unicons";
import { Box, Text, Stack, Avatar, Show } from "@chakra-ui/react";
import { useAppSelector } from "app/hooks";

const HeaderAvatar = () => {
  const auth = useAppSelector((state) => state.dashboard.userProfile);
  return (
    <Stack
      spacing={[0, 3]}
      direction="row"
      alignItems="center"
      cursor="pointer"
    >
      <Avatar
        width={10}
        height={10}
        borderRadius={16}
        src="https://bit.ly/kent-c-dodds"
      />
      <Show breakpoint="(min-width: 500px)">
        <Box display="flex" flexDirection="column">
          <Text
            fontSize={16}
            color="#04060C"
            fontWeight={500}
            lineHeight="19px"
            className="truncate"
          >
            {auth.firstName} {auth.lastName}
          </Text>
          <Text
            fontSize={14}
            color="gray.600"
            lineHeight="18px"
            className="truncate"
          >
            {auth.email}
          </Text>
        </Box>
      </Show>
      <UilAngleDown />
    </Stack>
  );
};

export default HeaderAvatar;
