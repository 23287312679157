import {
  Td,
  Th,
  Tr,
  Stack,
  Table,
  Tbody,
  Thead,
  Button,
  TableContainer
} from "@chakra-ui/react";
import React from "react";
import { getAllFiatWallets, toggleFiatWallets } from "app/store";
import { usePagination } from "@ajna/pagination";
import { IFiatWallet, ToggleWalletStatus } from "app/store/models/request";
import { TransactionBadge } from "views/components/badge";
import { useAppDispatch, useAppSelector } from "app/hooks";
import WalletRestrictionModalDetails from "./restrictions-modal";
import PaginationWrapper from "views/components/PaginationWrapper/PaginationWrapper";
import { useUserDetails } from "app/hooks/use-user-details";
import { formatFullDateToUsersLocale } from "data/utils/date-formatter-users-locale";

const head = ["User", "Wallet ID", "Date", "Status", "Action"];

const WalletRestriction = () => {
  const dispatch = useAppDispatch();
  const status = useAppSelector((state) => state.request.status);
  const fiatWallet = useAppSelector((state) => state.request.fiatWallet);
  const [selected, setSelected] = React.useState<null | IFiatWallet>(null);

  const {
    pages,
    offset,
    pageSize,
    pagesCount,
    isDisabled,
    currentPage,
    setCurrentPage
  } = usePagination({
    limits: { inner: 2, outer: 1 },
    total: fiatWallet.pagination.total,
    initialState: { currentPage: 1, pageSize: 10 }
  });

  const handleCloseDetails = () => {
    setSelected(null);
  };

  const handleSelect = (data: IFiatWallet) => {
    setSelected(data);
  };

  const handlePageChange = (nextPage: number) => {
    setCurrentPage(nextPage);
    setTimeout(() => {
      window.scrollTo({ top: 10, behavior: "smooth" });
    }, 500);
  };

  const handleToggleWallet = (id: number, status: boolean) => {
    const action = status
      ? ToggleWalletStatus.deactivate
      : ToggleWalletStatus.activate;

    dispatch(toggleFiatWallets(id, action, ""));
  };

  React.useEffect(() => {
    dispatch(
      getAllFiatWallets({
        active: false,
        page: currentPage,
        perpage: pageSize
      })
    );
  }, [currentPage]);

  // user details
  const { onOpenUserDetails, UserDetail } = useUserDetails();

  if (status.getAllFiatWallets === "loading") return <p>Loading...</p>;

  return (
    <React.Fragment>
      <WalletRestrictionModalDetails
        data={selected}
        onClose={handleCloseDetails}
      />
      <TableContainer>
        <Table variant="striped" colorScheme="gray">
          <Thead>
            <Tr>
              {head.map((data) => (
                <Th
                  key={data}
                  fontSize={16}
                  color="gray.800"
                  fontWeight={500}
                  lineHeight="19px"
                  letterSpacing="0px"
                  textTransform="unset"
                >
                  {data}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {fiatWallet?.data?.map((data) => (
              <Tr key={data.id}>
                <Td
                  cursor={data.userId ? "pointer" : "not-allowed"}
                  _hover={{ color: "#B1B4BF" }}
                  onClick={() => {
                    if (!data.id) return;
                    onOpenUserDetails(data.userId);
                  }}
                >
                  {data.user.username}
                </Td>
                <Td>{data.id}</Td>
                <Td>
                  {data.updatedAt
                    ? formatFullDateToUsersLocale(data.updatedAt)
                    : "--"}
                </Td>
                <Td>
                  <TransactionBadge variant="success" children="Success" />
                </Td>
                <Td>
                  <Stack spacing={2} direction="row">
                    <Button
                      size={"sm"}
                      borderColor="gray.400"
                      colorScheme="gray"
                      _hover={{ background: "primary.100" }}
                      variant="outline"
                      px="6"
                      onClick={() => handleSelect(data)}
                    >
                      view
                    </Button>

                    <Button
                      px="6"
                      size={"sm"}
                      colorScheme={data?.active ? "error" : "success"}
                      onClick={() => handleToggleWallet(data.id, data?.active)}
                    >
                      {data?.active ? "Disable" : "Enable"}
                    </Button>
                  </Stack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>

        <PaginationWrapper
          pages={pages}
          size={pageSize}
          offset={offset}
          isDisabled={isDisabled}
          pagesCount={pagesCount}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          totalDataCount={fiatWallet.pagination.total}
        />
      </TableContainer>

      {false && UserDetail}
    </React.Fragment>
  );
};

export default WalletRestriction;
