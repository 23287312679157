import { AxiosResponse } from "axios";
import instance from "data/axios-setup";
import {
  ISendNotificationMultipleUsers,
  ISendNotificationSingleUser,
  ISendNotificationsMass
} from "data/models/notification";

class NotificationService {
  async sendNotificationsSingleUser(
    body: ISendNotificationSingleUser
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.post("/admin/notification/user", body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async sendNotificationsMultipleUsers(
    body: ISendNotificationMultipleUsers
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.post(
          "/admin/notification/multiple-users",
          body
        );
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async sendNotificationsMass(
    body: ISendNotificationsMass
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.post("/admin/notification/mass", body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }
}

export default new NotificationService();
