import {
  Modal,
  Stack,
  Input,
  Select,
  Button,
  Spinner,
  Divider,
  ModalBody,
  FormLabel,
  ModalHeader,
  ModalFooter,
  FormControl,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  InputGroup,
  InputRightElement
} from "@chakra-ui/react";

import {
  getBanks,
  withdraw,
  resolveBankName,
  resetResolveBankName
} from "app/store";
import React from "react";
import NumberFormat from "react-number-format";
import getSymbolFromCurrency from "currency-symbol-map";
import { useAppDispatch, useAppSelector } from "app/hooks";

const initialState = {
  bank: "",
  amount: "",
  description: "",
  accountName: "",
  accountNumber: ""
};

const Withdraw = ({
  isOpen,
  onClose,
  currency,
  walletId,
  fetchWallet
}: any) => {
  const dispatch = useAppDispatch();

  const [
    banks,
    isLoadingBank,
    isWithdrawing,
    isResolvingBank,
    resolvedAccountName
  ] = useAppSelector((state) => [
    state.bitmamaMerchants.banks.data,
    state.bitmamaMerchants.banks.loading,
    state.bitmamaMerchants.withdraw.loading,
    state.bitmamaMerchants.resolvedBank.loading,
    state.bitmamaMerchants.resolvedBank?.data?.account_name
  ]);

  const [state, setState] = React.useState(initialState);

  const env: any = process.env.NODE_ENV;
  const bank = JSON.parse(state.bank || "{}");
  const shouldResolveAccount = env === "prod" && currency === "ngn";

  const handleChange = (e: React.ChangeEvent<any>) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleBankChange = (e: React.ChangeEvent<any>) => {
    handleChange(e);
    dispatch(resetResolveBankName());
  };

  const onWithdrawSuccess = () => {
    onClose();
    fetchWallet();
    setState(initialState);
    dispatch(resetResolveBankName);
  };

  const handleAccountNumberChange = (e: React.ChangeEvent<any>) => {
    dispatch(resetResolveBankName());

    const accountNumber = e.target.value;
    setState((prev) => ({ ...prev, accountNumber, resolvedAccountName: "" }));

    if (!bank?.code) return;
    if (currency !== "ngn") return;
    if (!shouldResolveAccount) return;
    if (accountNumber.length < 10) return;
    dispatch(resolveBankName({ bankCode: bank.code, accountNumber }));
  };

  const banksList = React.useMemo(() => {
    const newArr = Array.isArray(banks) ? banks?.slice() : [];
    if (!Array.isArray(newArr)) return [];

    return newArr?.sort((a, b) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
      return 0;
    });
  }, [banks]);

  const accountName = shouldResolveAccount
    ? resolvedAccountName
    : state.accountName;

  const isDisabled =
    !walletId ||
    !bank?.name ||
    !accountName ||
    !state.amount ||
    isWithdrawing ||
    isResolvingBank ||
    !state.description ||
    Number(state.amount) < 1 ||
    state.accountNumber.length < 10;

  const handleWithdraw = () => {
    if (isDisabled) return;

    dispatch(
      withdraw(
        walletId,
        {
          bankCode: bank?.code,
          bankName: bank?.name,
          amount: Number(state.amount),
          bankAccountName: accountName,
          description: state.description,
          bankAccountNumber: state.accountNumber
        },
        onWithdrawSuccess
      )
    );
  };

  React.useEffect(() => {
    if (isLoadingBank) return;
    dispatch(getBanks());
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textTransform={"capitalize"}>Withdraw Fund</ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody>
          <Stack my="5" spacing={"4"}>
            <FormControl>
              <FormLabel color={"gray.600"} fontWeight="400">
                Amount
              </FormLabel>

              <NumberFormat
                name="amount"
                decimalScale={2}
                placeholder="0.00"
                inputMode="decimal"
                displayType="input"
                value={state.amount}
                defaultValue={"0.00"}
                thousandSeparator={true}
                fixedDecimalScale={false}
                allowLeadingZeros={false}
                prefix={`${getSymbolFromCurrency(currency)}  `}
                onValueChange={({ value }) =>
                  setState((prevState) => ({ ...prevState, amount: value }))
                }
                className="border-gray-200 outline-1 outline-gray-200 rounded-md border-[2px] w-full text-gray-800 py-2 px-4 shadow-[#6584f6] focus-visible:border-[#6484F6] focus-visible:shadow-[0_0_0_1px_#6484f6]"
              />
            </FormControl>
            <FormControl>
              <FormLabel color={"gray.600"} fontWeight="400">
                Bank Name
              </FormLabel>
              <Select
                name="bank"
                value={state.bank}
                onChange={handleBankChange}
              >
                <option>Select Bank</option>
                {banksList?.map((bank) => (
                  <option value={JSON.stringify(bank)} key={bank?.name}>
                    {bank?.name}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel color={"gray.600"} fontWeight="400">
                Account Number
              </FormLabel>
              <Input
                min={10}
                type="number"
                name="accountNumber"
                disabled={isResolvingBank}
                value={state.accountNumber}
                onChange={handleAccountNumberChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel color={"gray.600"} fontWeight="400">
                Account Name
              </FormLabel>
              <InputGroup>
                <Input
                  name="accountName"
                  value={accountName}
                  onChange={handleChange}
                />
                {isResolvingBank && (
                  <InputRightElement children={<Spinner />} />
                )}
              </InputGroup>
            </FormControl>
            <FormControl>
              <FormLabel color={"gray.600"} fontWeight="400">
                Description
              </FormLabel>
              <Input
                name="description"
                onChange={handleChange}
                value={state.description}
              />
            </FormControl>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button
            w="full"
            disabled={isDisabled}
            colorScheme={"primary"}
            onClick={handleWithdraw}
          >
            {isWithdrawing ? <Spinner /> : "Withdraw Fund"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default Withdraw;
