import {
  Box,
  Icon,
  Text,
  Modal,
  Stack,
  Avatar,
  Button,
  Divider,
  ModalBody,
  Breadcrumb,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  BreadcrumbItem,
  BreadcrumbLink,
  ModalCloseButton
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import WithdrawalModal from "../withdrawal";
import { getAllFiatWallet } from "app/store";
import TransactionTable from "./transactions";
import { useSearchParams } from "react-router-dom";
import { BsArrowsAngleExpand } from "react-icons/bs";
import { FlashSolidIcon } from "views/components/icons";
import getSymbolFromCurrency from "currency-symbol-map";
import { BaseModalProps } from "views/containers/user-information/models";
import { useAppDispatch, useAppSelector, useBreadCrumbs } from "app/hooks";

const ViewWalletModal = ({ isOpen, onClose }: BaseModalProps) => {
  const dispatch = useAppDispatch();
  const allWallet = useAppSelector((state) => state.wallet.allFiatWallet);

  const {
    isOpen: isOpenWithdraw,
    onOpen: onOpenWithdraw,
    onClose: onCloseWithdraw
  } = useDisclosure();

  const wallet = allWallet.data[0];
  let [params] = useSearchParams();
  const userId = params.get("user-id");

  const fetchWallet = () => {
    dispatch(getAllFiatWallet({ userId: userId as any, page: 1, perpage: 1 }));
  };

  useEffect(() => {
    if (!userId) return;
    fetchWallet();
  }, [userId]);

  // bread crumbs
  const breadCrumbs = useBreadCrumbs();

  return (
    <React.Fragment>
      <Modal
        size="6xl"
        onClose={onClose}
        scrollBehavior="inside"
        isOpen={isOpen && !allWallet.loading}
      >
        <ModalOverlay />

        <ModalContent>
          <ModalHeader
            fontSize="14px"
            fontWeight="400"
            textTransform="capitalize"
          >
            <Stack spacing="4" direction="row" alignItems="center">
              <Icon as={BsArrowsAngleExpand} />
              <Breadcrumb mb="5">
                {breadCrumbs.map((el, idx) => (
                  <BreadcrumbItem key={idx}>
                    <BreadcrumbLink href={el.to}>{el.title}</BreadcrumbLink>
                  </BreadcrumbItem>
                ))}
                <BreadcrumbItem>
                  <BreadcrumbLink isCurrentPage={true}>
                    {!allWallet?.loading &&
                      `${wallet?.user?.firstName} ${wallet?.user?.lastName}`}
                  </BreadcrumbLink>
                </BreadcrumbItem>
              </Breadcrumb>
            </Stack>
          </ModalHeader>

          <ModalCloseButton />

          <Divider />

          <ModalBody>
            <Stack
              my={6}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack direction="row" spacing={4}>
                <Avatar
                  width="88px"
                  height="88px"
                  borderRadius="16px"
                  name={`${wallet?.user?.firstName} ${wallet?.user?.lastName}`}
                />
                <Box>
                  <Text
                    fontSize="24px"
                    fontWeight={500}
                    color="gray.900"
                    lineHeight="28px"
                  >
                    {wallet?.user?.firstName} {wallet?.user?.lastName}
                  </Text>
                  <Stack
                    px={3}
                    mt={2}
                    py={1.5}
                    spacing={4}
                    direction="row"
                    borderWidth={1}
                    borderRadius="16px"
                    borderStyle="solid"
                    alignItems="center"
                    display="inline-flex"
                    borderColor="gray.400"
                  >
                    <span>Merchant</span>
                    <Icon
                      w={5}
                      h={5}
                      mt="2px"
                      color="blue.500"
                      as={FlashSolidIcon}
                    />
                  </Stack>
                </Box>
              </Stack>

              <Button
                size={["sm", "md"]}
                colorScheme="primary"
                onClick={onOpenWithdraw}
              >
                Withdraw From Wallet
              </Button>
            </Stack>

            <Divider />

            <Box
              my={6}
              display="flex"
              alignItems="center"
              flexDirection="column"
              justifyContent="center"
            >
              <Text
                mb={3}
                fontSize="18px"
                fontWeight={700}
                color="gray.900"
                lineHeight="23px"
                fontFamily="Karla"
              >
                {wallet?.currency?.toUpperCase()} Wallet
              </Text>
              <Text
                fontSize="32px"
                fontWeight={700}
                color="gray.900"
                lineHeight="23px"
                fontFamily="Karla"
              >
                {getSymbolFromCurrency(wallet?.currency)}{" "}
                {wallet?.balance?.toLocaleString()}
              </Text>
            </Box>

            <Box>
              <TransactionTable walletId={wallet?.id} />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>

      <WithdrawalModal
        walletId={wallet?.id}
        isOpen={isOpenWithdraw}
        onClose={onCloseWithdraw}
        fetchWallet={fetchWallet}
        currency={wallet?.currency}
      />
    </React.Fragment>
  );
};

export default ViewWalletModal;
