import {
  AspectRatio,
  Box,
  Grid,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text
} from "@chakra-ui/react";
import { useAppSelector } from "app/hooks";
import { VerificationStatus } from "app/store/models/verification";
import { parseJSON } from "data/utils/string";
import { useState } from "react";

const Attachments = () => {
  const userDetails = useAppSelector(
    (state) => state.verification.singleVerification
  );

  // show image in full screen
  const [fullScreenMode, setFullScreenMode] = useState("");

  // const onOpenFullScreenMode = (url?: string) => {
  //   if (!url) return;
  //   setFullScreenMode(url);
  // };

  const onExitFullScreenMode = () => {
    setFullScreenMode("");
  };

  let bvnPhoto = parseJSON(userDetails?.data?.user?.bvnPhoto);
  bvnPhoto = parseJSON(bvnPhoto);

  const bvn =
    Array.isArray(bvnPhoto) &&
    bvnPhoto?.find((i: any) => i.image_type === "bvn_photo");

  const nationalId: any =
    Array.isArray(bvnPhoto) &&
    bvnPhoto?.find((i: any) => i.image_type === "ID");

  const NoData = ({ type }: any) => <Box boxSize="300px">No {type}</Box>;

  return (
    <>
      <Box py="2">
        <Grid
          h="full"
          rowGap={20}
          justifyContent={"space-between"}
          alignItems="center"
          gridGap={"4"}
          templateColumns={[
            "repeat(1, 1fr)",
            "repeat(1, 1fr)",
            "repeat(2, 1fr)"
          ]}
        >
          <Stack spacing={"4"} flexBasis="49%">
            <Heading
              fontSize={"xl"}
              fontFamily="Rubik"
              fontWeight={"medium"}
              as={"h3"}
            >
              Selfie
            </Heading>

            <Box position={"relative"} boxSize="300px">
              <Image
                boxSize="300px"
                src={userDetails.data?.selfieUrl || "--"}
                alt="user selfie"
                rounded={"md"}
                position="absolute"
                top={"0"}
                left="0"
                cursor={"pointer"}
                onClick={() =>
                  window.open(userDetails.data?.selfieUrl, "_blank")
                }
              />
              <Stack
                direction={"row"}
                h={"8"}
                w="full"
                bg="rgba(255, 255, 255, 0.3)"
                position="absolute"
                top="calc(100% - 2rem)"
                left="0"
                backdropFilter={"blur(16px)"}
                color="white"
                alignItems={"center"}
                justifyContent="space-between"
                px="2"
              >
                <Text
                  fontSize={"sm"}
                  textOverflow="ellipsis"
                  maxW="32ch"
                  whiteSpace={"nowrap"}
                  overflow="hidden"
                >
                  {userDetails.data?.selfieUrl || "--"}
                </Text>
              </Stack>
            </Box>
          </Stack>
          <Stack spacing={"4"} flexBasis="49%">
            <Heading
              fontSize={"xl"}
              fontFamily="Rubik"
              fontWeight={"medium"}
              as={"h3"}
            >
              Document
            </Heading>

            <Box position={"relative"} boxSize="300px">
              <Image
                boxSize="300px"
                src={userDetails.data?.documentUrl}
                alt="document"
                rounded={"md"}
                position="absolute"
                top={"0"}
                left="0"
                cursor={"pointer"}
                onClick={() =>
                  window.open(userDetails.data?.documentUrl, "_blank")
                }
              />
              <Stack
                direction={"row"}
                h={"8"}
                w="full"
                bg="rgba(255, 255, 255, 0.3)"
                position="absolute"
                top="calc(100% - 2rem)"
                left="0"
                backdropFilter={"blur(16px)"}
                color="white"
                alignItems={"center"}
                justifyContent="space-between"
                px="2"
              >
                <Text
                  fontSize={"sm"}
                  textOverflow="ellipsis"
                  maxW="32ch"
                  whiteSpace={"nowrap"}
                  overflow="hidden"
                >
                  {userDetails.data?.documentUrl || "--"}
                </Text>
              </Stack>
            </Box>
          </Stack>

          <Stack spacing={"4"} flexBasis="49%">
            <Heading
              fontSize={"xl"}
              fontFamily="Rubik"
              fontWeight={"medium"}
              as={"h3"}
            >
              BVN Photo
            </Heading>

            <Box position={"relative"} boxSize="300px">
              <Image
                boxSize="300px"
                src={bvn?.url}
                alt="bvn photo"
                rounded={"md"}
                position="absolute"
                top={"0"}
                left="0"
                cursor={"pointer"}
                fallback={<NoData type="BVN Photo" />}
                onClick={() => window.open(bvn?.url, "_blank")}
              />
              <Stack
                direction={"row"}
                h={"8"}
                w="full"
                bg="rgba(255, 255, 255, 0.3)"
                position="absolute"
                top="calc(100% - 2rem)"
                left="0"
                backdropFilter={"blur(16px)"}
                color="white"
                alignItems={"center"}
                justifyContent="space-between"
                px="2"
              >
                <Text
                  fontSize={"sm"}
                  textOverflow="ellipsis"
                  maxW="32ch"
                  whiteSpace={"nowrap"}
                  overflow="hidden"
                >
                  {bvn?.url || "--"}
                </Text>
              </Stack>
            </Box>
          </Stack>

          <Stack spacing={"4"} flexBasis="49%">
            <Heading
              fontSize={"xl"}
              fontFamily="Rubik"
              fontWeight={"medium"}
              as={"h3"}
            >
              National ID
            </Heading>

            <Box position={"relative"} boxSize="300px">
              <Image
                boxSize="300px"
                src={nationalId?.url}
                alt="national id"
                rounded={"md"}
                position="absolute"
                top={"0"}
                left="0"
                cursor={"pointer"}
                fallback={<NoData type="National Id" />}
                onClick={() => window.open(nationalId?.url, "_blank")}
              />
              <Stack
                direction={"row"}
                h={"8"}
                w="full"
                bg="rgba(255, 255, 255, 0.3)"
                position="absolute"
                top="calc(100% - 2rem)"
                left="0"
                backdropFilter={"blur(16px)"}
                color="white"
                alignItems={"center"}
                justifyContent="space-between"
                px="2"
              >
                <Text
                  fontSize={"sm"}
                  textOverflow="ellipsis"
                  maxW="32ch"
                  whiteSpace={"nowrap"}
                  overflow="hidden"
                >
                  {nationalId?.url || "--"}
                </Text>
              </Stack>
            </Box>
          </Stack>
        </Grid>
        <Stack direction={"row"} mt="5">
          <Text>Status:</Text>
          <Text
            fontStyle={"italic"}
            color={
              userDetails.data?.status === VerificationStatus.INCOMING
                ? "warning.500"
                : userDetails.data?.status === VerificationStatus.VERIFIED
                ? "success.500"
                : userDetails.data?.status === VerificationStatus.DECLINED
                ? "error.500"
                : ""
            }
          >
            {userDetails.data?.status === VerificationStatus.INCOMING
              ? "verifying"
              : userDetails.data?.status === VerificationStatus.VERIFIED
              ? "verified"
              : userDetails.data?.status === VerificationStatus.DECLINED
              ? "Declined"
              : "--"}
          </Text>
        </Stack>
      </Box>
      <Modal
        isOpen={!!fullScreenMode}
        onClose={onExitFullScreenMode}
        size="5xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <AspectRatio maxW="full" ratio={4 / 3}>
              <Image
                src={fullScreenMode}
                alt={"verification Document"}
                objectFit="contain"
              />
            </AspectRatio>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Attachments;
