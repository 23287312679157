import {
  FLUSH,
  PAUSE,
  PURGE,
  PERSIST,
  REGISTER,
  REHYDRATE,
  persistStore,
  persistReducer
} from "redux-persist";
import fx from "./reducers/fx.reducer";
import auth from "./reducers/auth.reducer";
import wallet from "./reducers/wallet.reducer";
import storage from "redux-persist/lib/storage";
import request from "./reducers/request.reducer";
import summary from "./reducers/summary.reducer";
import dashboard from "./reducers/dashboard.reducer";
import transactions from "./reducers/transactions.reducer";
import teamManagement from "./reducers/team-management.reducer";
import reports from "./reducers/reports.reducer";
import verification from "./reducers/verification.reducer";
import user from "./reducers/user.reducer";
import exchangeRate from "./reducers/exchange-rate.reducer";
import feature from "./reducers/feature.reducer";
import virtualCards from "./reducers/virtual-cards.reducer";
import UI from "./reducers/ui.reducer";
import flight from "./reducers/flight.reducer";
import campaigns from "./reducers/campaign.reducer";
import wishList from "./reducers/wishlist.reducer";
import virtualAccount from "./reducers/virtual-account.reducer";
import bitmamaMerchants from "./reducers/bitmama-merchants.reducer";
import { combineReducers, configureStore } from "@reduxjs/toolkit";

const rootReducer = combineReducers({
  fx,
  auth,
  wallet,
  summary,
  request,
  dashboard,
  transactions,
  teamManagement,
  reports,
  verification,
  user,
  exchangeRate,
  feature,
  virtualCards,
  UI,
  flight,
  bitmamaMerchants,
  campaigns,
  wishList,
  virtualAccount
});

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: []
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
});

export const persistor = persistStore(store);
