import { Box, Grid, Heading, Stack, Text } from "@chakra-ui/react";
import { useAppSelector } from "app/hooks";
import { getDate } from "data/utils/date-formatter-users-locale";
import { formatDateOnlyToUTC } from "data/utils/formatDateToUTC";
import { DetailProps } from "../models";

const PersonalInformation = () => {
  const userDetails = useAppSelector(
    (state) => state.verification.singleVerification
  );

  return (
    <Box py="2">
      <Grid
        h="full"
        justifyContent={"space-between"}
        alignItems="center"
        gridGap={"4"}
        templateColumns={["repeat(1, 1fr)", "repeat(2, 1fr)", "repeat(4, 1fr)"]}
      >
        <Detail title="User Id" text={`${userDetails.data?.userId || "--"}`} />
        <Detail
          title="Customer Name"
          text={`${userDetails.data?.user?.firstName || "--"} ${
            userDetails.data?.user?.lastName || ""
          }`}
        />
        <Detail
          title="email address"
          text={userDetails.data?.user?.email || "--"}
        />
        <Detail
          title="Date of Birth"
          text={
            userDetails.data
              ? formatDateOnlyToUTC(getDate(userDetails.data?.dateOfBirth))
              : "--"
          }
        />
        <Detail
          title="Document Number"
          text={userDetails.data ? userDetails.data?.documentNumber : "--"}
        />
        <Detail
          title="Document Type"
          text={userDetails.data ? userDetails.data?.documentType : "--"}
        />
        <Detail
          title="Document Issued Date"
          text={
            userDetails.data
              ? formatDateOnlyToUTC(getDate(userDetails.data?.issuedDate))
              : "--"
          }
        />
        <Detail
          title="Document Expiry Date"
          text={
            userDetails.data
              ? formatDateOnlyToUTC(getDate(userDetails.data?.expiryDate))
              : "--"
          }
        />
        <Detail title="Street" text={userDetails.data?.street || "--"} />
        <Detail title="City" text={userDetails.data?.city || "--"} />
        <Detail
          title="Country of Residence"
          text={userDetails.data?.country || "--"}
        />
        <Detail
          title="Occupation"
          text={userDetails.data?.occupation || "--"}
        />
        <Detail
          title="LGA of Origin"
          text={userDetails.data?.lgaOfOrigin || "--"}
        />
        <Detail
          title="LGA of Residence"
          text={userDetails.data?.lgaOfResidence || "--"}
        />
        <Detail
          title="State of Origin"
          text={userDetails.data?.stateOfOrigin || "--"}
        />{" "}
        <Detail
          title="State of Residence"
          text={userDetails.data?.stateOfResidence || "--"}
        />
        <Detail
          title="Dojah Reference ID"
          text={userDetails.data?.dojahReferenceId || "--"}
        />
      </Grid>
    </Box>
  );
};

const Detail = ({ title, text }: DetailProps) => (
  <Stack py="2">
    <Heading
      as={"h6"}
      fontSize="14px"
      fontWeight={"normal"}
      color="gray.600"
      textTransform={"capitalize"}
    >
      {title}
    </Heading>
    <Text
      fontSize={"18px"}
      fontWeight={"medium"}
      color="gray.900"
      textTransform={title === "email address" ? "lowercase" : "capitalize"}
    >
      {text}
    </Text>
  </Stack>
);

export default PersonalInformation;
