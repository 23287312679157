import React from "react";
import { ArrowLeft } from "iconsax-react";
import Input from "views/components/input";
import { useValentineWishList } from "app/hooks/use-wishlist";
import { IValentineWishlistItem } from "app/store/models/wishlist";
import { Box, Grid, Button, Heading, Text } from "@chakra-ui/react";

type Props = {
  showTableList: () => void;
};

const EditValentineWishList = ({ showTableList }: Props) => {
  const {
    valentineWishListItems,
    dispatchValentineWishListItems,
    updateValentineWishListItemsState,
    dispatchUpdateValentineWishListItem
  } = useValentineWishList();

  const [state, setState] = React.useState<IValentineWishlistItem[]>([]);

  const isSubmitDisabled = updateValentineWishListItemsState.loading;

  const handleInputChange = (item: IValentineWishlistItem) => {
    setState((prev) => {
      const newState = [...prev];
      const prevItemIndex = prev.findIndex((x) => x.id === item.id);

      newState[prevItemIndex] = item;
      return newState;
    });
  };

  const handleSubmit = async () => {
    if (!state.length) return;
    if (isSubmitDisabled) return;

    const update = state
      .filter((v, i) => v.item !== valentineWishListItems?.data?.[i].item)
      .map((i) => ({ id: i.id, item: i.item }));

    await Promise.all(
      update.map(
        async (i) =>
          await dispatchUpdateValentineWishListItem(i.id, { item: i.item })
      )
    );
  };

  React.useEffect(() => {
    dispatchValentineWishListItems({ page: 1, perpage: 1000 });
  }, []);

  React.useEffect(() => {
    if (
      !valentineWishListItems?.data?.length ||
      valentineWishListItems?.loading
    )
      return;
    setState(valentineWishListItems.data);
  }, [valentineWishListItems.loading]);

  return (
    <Box className="py-8 max-w-2xl mx-auto">
      <div
        onClick={showTableList}
        className="inline-flex items-center gap-3 cursor-pointer mb-12"
      >
        <ArrowLeft cursor="pointer" />
        <Heading
          py="4"
          px="2"
          as="h3"
          fontSize="18px"
          lineHeight="28px"
          fontWeight="medium"
        >
          Wishlist
        </Heading>
      </div>

      <Text as="h1" fontWeight="semibold" fontSize="3xl" textAlign="center">
        Edit Wishlist Items
      </Text>
      <p className="mt-3 mb-6 text-gray-600 text-center">
        Enter prize items for your wishlist or import from a .xlv file
      </p>

      <div className="flex flex-col gap-6">
        <div>
          <p className="text-gray-600 mb-2">
            Press “enter” to go to next item or add more items
          </p>

          <Grid templateColumns="repeat(2, 1fr)" gap={6}>
            {state?.map((item, i) => (
              <Input
                key={i}
                value={item.item}
                placeholder={`Item ${i + 1}`}
                backgroundColor="transparent"
                onChange={(e) =>
                  handleInputChange({
                    id: item.id,
                    active: true,
                    item: e.target.value,
                    createdAt: new Date().toString(),
                    updatedAt: new Date().toString()
                  })
                }
              />
            ))}
          </Grid>
        </div>

        <div className="flex items-center justify-end">
          <Button
            size="lg"
            ml="auto"
            fontSize="md"
            minWidth="200px"
            width="fit-content"
            fontWeight="medium"
            onClick={handleSubmit}
            colorScheme="primary"
            disabled={isSubmitDisabled}
            isDisabled={isSubmitDisabled}
            isLoading={updateValentineWishListItemsState.loading}
          >
            Update WishList
          </Button>
        </div>
      </div>
    </Box>
  );
};

export default EditValentineWishList;
