import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Stack,
  Text,
  Icon,
  Heading,
  Divider,
  Skeleton,
  Flex,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink
} from "@chakra-ui/react";
import { Link, useSearchParams } from "react-router-dom";
import { BaseModalProps } from "views/containers/user-information/models";
import {
  MessageOutlineIcon,
  ProfileCheckOutlineIcon
} from "views/components/icons";
import { IoCalendarOutline, IoCashOutline } from "react-icons/io5";
import { AiOutlineLink } from "react-icons/ai";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector, useBreadCrumbs } from "app/hooks";
import { getSingleCryptoWallet } from "app/store";
import { formatDateToUTC } from "data/utils/formatDateToUTC";
import { BsArrowsAngleExpand } from "react-icons/bs";

const ViewCryptoWalletModal = ({ isOpen, onClose }: BaseModalProps) => {
  const dispatch = useAppDispatch();
  const [cryptoWallet] = useAppSelector((state) => [
    state.wallet.singleCryptoWallet
  ]);

  // tab and url controllers
  let [searchParams, setSearchParams] = useSearchParams();
  const walletId = searchParams.get("wallet-id");
  let transactionType = searchParams.get("transaction-type");
  let viewWallet = searchParams.get("view-crypto-wallet");

  useEffect(() => {
    if (!!viewWallet && !transactionType) {
      searchParams.set("transaction-type", "withdrawal");
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  useEffect(() => {
    if (!walletId) return;
    dispatch(getSingleCryptoWallet(+walletId));
  }, [walletId]);

  // bread crumbs
  const breadCrumbs = useBreadCrumbs();

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="2xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            textTransform={"capitalize"}
            fontSize="14px"
            fontWeight={"400"}
          >
            <Stack spacing={"4"} direction="row" alignItems={"center"}>
              <Icon as={BsArrowsAngleExpand} />
              <Breadcrumb mb={"5"}>
                {breadCrumbs.map((el, idx) => (
                  <BreadcrumbItem key={idx}>
                    <BreadcrumbLink href={el.to}>{el.title}</BreadcrumbLink>
                  </BreadcrumbItem>
                ))}
                <BreadcrumbItem>
                  <BreadcrumbLink isCurrentPage={true}>
                    {walletId}
                  </BreadcrumbLink>
                </BreadcrumbItem>
              </Breadcrumb>
            </Stack>
          </ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Heading
                as={"h4"}
                fontSize={["18px", "24px"]}
                fontFamily={"karla"}
                my="5"
                fontWeight={"extrabold"}
              >
                Wallet Details
              </Heading>
            </Stack>
            {cryptoWallet.loading ? (
              <Flex
                p="5"
                bg="primary.500"
                color={"gray.300"}
                rounded="lg"
                direction="column"
                gap={"4"}
              >
                <Skeleton h="6" />
                <Skeleton h="6" />
                <Skeleton h="6" />
                <Skeleton h="6" />
              </Flex>
            ) : null}
            {!!cryptoWallet.data && !cryptoWallet.loading ? (
              <Stack
                p="5"
                bg="primary.500"
                color={"gray.300"}
                rounded="lg"
                spacing={"4"}
              >
                <Stack direction={"row"} justifyContent="space-between">
                  <Stack direction={"row"} alignItems="center">
                    <Icon as={ProfileCheckOutlineIcon} h={5} w={5} />
                    <Text>Changera Username</Text>
                  </Stack>
                  <Text fontWeight={"medium"} textTransform="capitalize">
                    {cryptoWallet.data?.user?.username || "--"}
                  </Text>
                </Stack>
                <Stack direction={"row"} justifyContent="space-between">
                  <Stack direction={"row"} alignItems="center">
                    <Icon as={IoCalendarOutline} h={5} w={5} />
                    <Text>Date Created</Text>
                  </Stack>
                  <Text fontWeight={"medium"}>
                    {cryptoWallet.data?.createdAt
                      ? formatDateToUTC(cryptoWallet.data.createdAt)
                      : "--"}
                  </Text>
                </Stack>
                <Stack direction={"row"} justifyContent="space-between">
                  <Stack direction={"row"} alignItems="center">
                    <Icon as={MessageOutlineIcon} h={5} w={5} />
                    <Text>Wallet Id / Type</Text>
                  </Stack>
                  <Text fontWeight={"medium"}>
                    {walletId} /{" "}
                    {cryptoWallet.data?.cointype.toUpperCase() || "--"}
                  </Text>
                </Stack>
                <Stack direction={"row"} justifyContent="space-between">
                  <Stack direction={"row"} alignItems="center">
                    <Icon as={AiOutlineLink} h={5} w={5} />
                    <Text>Address</Text>
                  </Stack>
                  <Text fontWeight={"medium"} textTransform="capitalize">
                    {cryptoWallet.data?.address || "--"}
                  </Text>
                </Stack>
                <Stack direction={"row"} justifyContent="space-between">
                  <Stack direction={"row"} alignItems="center">
                    <Icon as={IoCashOutline} h={5} w={5} />
                    <Text>Wallet Balance</Text>
                  </Stack>
                  <Text fontWeight={"medium"}>{`${
                    cryptoWallet.data?.balance.toLocaleString() || ""
                  } ${cryptoWallet.data?.cointype.toUpperCase() || ""}`}</Text>
                </Stack>
              </Stack>
            ) : null}
            <Divider mt="6" />
            <Link to={`transactions?wallet-id=${walletId}`}>
              <Button colorScheme={"primary"}>View Transactions</Button>
            </Link>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="red" w="full" size={"lg"} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ViewCryptoWalletModal;
