import { usePagination } from "@ajna/pagination";
import {
  Box,
  Button,
  Divider,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { getAllCryptoWallet } from "app/store";
import { useEffect, useMemo, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { useSearchParams } from "react-router-dom";
import NoData from "views/components/no-data";
import PaginationWrapper from "views/components/PaginationWrapper/PaginationWrapper";
import ViewWalletModal from "../action-modals/view-crypto-wallet-modal";
import WalletSkeleton from "./skeleton";
import { debounce } from "lodash";
import { formatFullDateToUsersLocale } from "data/utils/date-formatter-users-locale";

const CryptoWalletsTableContainer = () => {
  const allCryptoWallet = useAppSelector(
    (state) => state.wallet.allCryptoWallet
  );
  const dispatch = useAppDispatch();
  // tab and url controllers
  let [searchParams, setSearchParams] = useSearchParams();
  let userId = searchParams.get("user-id");

  // Wallet Modal controller

  const onViewWallet = (id: number) => {
    searchParams.set("wallet-id", id.toString());
    searchParams.set("view-crypto-wallet", "true");
    setSearchParams(searchParams);
  };

  const onCloseViewWalletModal = () => {
    searchParams.delete("wallet-id");
    searchParams.delete("view-crypto-wallet");
    searchParams.delete("transaction-type");
    setSearchParams(searchParams);
  };

  // pagination and search
  const [pageSize, setPageSize] = useState(10);
  const [searchString, setSearchString] = useState("");

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, offset } =
    usePagination({
      initialState: { currentPage: 1, pageSize },
      total: allCryptoWallet.pagination?.total,
      limits: {
        inner: 2,
        outer: 1
      }
    });

  const handlePageChange = (nextPage: number) => {
    setCurrentPage(nextPage);
    setTimeout(() => {
      window.scrollTo({ top: 10, behavior: "smooth" });
    }, 500);
  };

  const handleSize = (e: any) => {
    setPageSize(e.target.value);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;

    if (query.length >= 3) {
      setSearchString(query);
      setCurrentPage(1);
    } else {
      setSearchString("");
      setCurrentPage(1);
    }
  };

  const debouncedChangeHandler = useMemo(() => {
    return debounce(handleSearch, 1000);
  }, []);

  useEffect(() => {
    dispatch(
      getAllCryptoWallet({
        page: currentPage,
        perpage: pageSize,
        q: searchString,
        ...(!!userId && { userId: +userId })
      })
    );
  }, [currentPage, pageSize, searchString]);

  return (
    <>
      <TableContainer bg="#fff" minH="calc(100vh - 10rem)">
        <Stack mt="0.75rem" ml="2" spacing="4" direction={"row"}>
          <Box mb="16px">
            <InputGroup width="256px">
              <Input
                width="256px"
                paddingLeft="40px"
                minH="40px"
                placeholder={"Search"}
                border="1px solid #EDEBF9"
                onChange={debouncedChangeHandler}
                borderRadius="10px"
                fontSize="12px"
                _focus={{
                  border: "1px solid #EDEBF9"
                }}
                _hover={{
                  border: "1px solid #EDEBF9"
                }}
              />

              <InputLeftElement
                h="100%"
                mr="12px"
                children={<FiSearch color="#A0A3BD" />}
              />
            </InputGroup>
          </Box>
          <Box mb="12px">
            <Select
              width="120px"
              defaultValue={"Size"}
              placeholder="Size"
              onChange={handleSize}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
            </Select>
          </Box>
        </Stack>
        <Table
          color="brand.grayScale"
          fontSize="14px"
          lineHeight="18.2px"
          colorScheme={"gray"}
          variant="striped"
        >
          <Thead>
            {!userId ? (
              <Tr>
                <Th
                  textTransform={"capitalize"}
                  color="gray.800"
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"medium"}
                >
                  Full Name
                </Th>
                <Th
                  textTransform={"capitalize"}
                  color="gray.800"
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"medium"}
                >
                  address
                </Th>
                <Th
                  textTransform={"capitalize"}
                  color="gray.800"
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"medium"}
                >
                  wallet balance
                </Th>
                <Th
                  textTransform={"capitalize"}
                  color="gray.800"
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"medium"}
                >
                  coin
                </Th>
                <Th
                  textTransform={"capitalize"}
                  color="gray.800"
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"medium"}
                >
                  date created
                </Th>
                <Th
                  textTransform={"capitalize"}
                  color="gray.800"
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"medium"}
                >
                  action
                </Th>
              </Tr>
            ) : null}
            {!!userId ? (
              <Tr>
                <Th
                  textTransform={"capitalize"}
                  color="gray.800"
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"medium"}
                >
                  Coin
                </Th>
                <Th
                  textTransform={"capitalize"}
                  color="gray.800"
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"medium"}
                >
                  address
                </Th>
                <Th
                  textTransform={"capitalize"}
                  color="gray.800"
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"medium"}
                >
                  wallet balance
                </Th>
                <Th
                  textTransform={"capitalize"}
                  color="gray.800"
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"medium"}
                >
                  date created
                </Th>
              </Tr>
            ) : null}
          </Thead>
          <Divider />
          <Tbody>
            {allCryptoWallet.loading ? <WalletSkeleton /> : null}
            {!!allCryptoWallet.data.length &&
            !allCryptoWallet.loading &&
            !userId
              ? allCryptoWallet.data.map((el, idx) => (
                  <Tr key={idx}>
                    <Td textTransform={"capitalize"}>
                      {`${el.user?.firstName || "--"} ${
                        el.user?.lastName || ""
                      }`}
                    </Td>
                    <Td textTransform={"capitalize"}>{el.address || "--"}</Td>
                    <Td textTransform={"capitalize"} fontWeight={500}>
                      {el?.balance?.toLocaleString() || "--"}
                    </Td>
                    <Td textTransform={"capitalize"}>
                      {el.cointype.toUpperCase() || "--"}{" "}
                      {el?.network ? el?.network?.toUpperCase() : ""}
                    </Td>
                    <Td textTransform={"capitalize"} color="gray.500">
                      {el.createdAt
                        ? formatFullDateToUsersLocale(el.createdAt)
                        : "--"}
                    </Td>
                    <Td>
                      <Button
                        size={"sm"}
                        borderColor="gray.400"
                        colorScheme="gray"
                        _hover={{ background: "primary.100" }}
                        variant="outline"
                        px="6"
                        ml="1"
                        onClick={() => onViewWallet(el.id)}
                      >
                        view
                      </Button>
                    </Td>
                  </Tr>
                ))
              : null}
            {!!allCryptoWallet.data.length &&
            !allCryptoWallet.loading &&
            !!userId
              ? allCryptoWallet.data.map((el, idx) => (
                  <Tr key={idx}>
                    <Td textTransform={"capitalize"}>
                      {el.cointype.toUpperCase() || "--"}
                    </Td>
                    <Td textTransform={"capitalize"}>{el.address || "--"}</Td>
                    <Td textTransform={"capitalize"} fontWeight={500}>
                      {el.balance ? el.balance.toLocaleString() : "--"}
                    </Td>
                    <Td textTransform={"capitalize"} color="gray.500">
                      {el.createdAt
                        ? formatFullDateToUsersLocale(el.createdAt)
                        : "--"}
                    </Td>
                  </Tr>
                ))
              : null}
            {!allCryptoWallet.data.length && !allCryptoWallet.loading ? (
              <Tr>
                <Td colSpan={7} background="transparent" h="xl">
                  <NoData text={`No Fiat Wallets Yet`} />
                </Td>
              </Tr>
            ) : null}
          </Tbody>
        </Table>
      </TableContainer>
      <PaginationWrapper
        handlePageChange={handlePageChange}
        totalDataCount={allCryptoWallet.pagination?.total}
        currentPage={currentPage}
        isDisabled={isDisabled}
        pagesCount={pagesCount}
        offset={offset}
        pages={pages}
        size={pageSize}
      />
      <ViewWalletModal
        isOpen={
          !!searchParams.get("wallet-id") &&
          !!searchParams.get("view-crypto-wallet")
        }
        onClose={onCloseViewWalletModal}
      />
    </>
  );
};

export default CryptoWalletsTableContainer;
