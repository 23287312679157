import { Dispatch } from "@reduxjs/toolkit";
import instance from "data/axios-setup";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";
import toast from "react-hot-toast";
import { NavigateFunction } from "react-router-dom";
import { actions } from "../reducers/dashboard.reducer";

export const getUserProfile = (navigate: NavigateFunction, location: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.gettingUserProfile());
      const res = await instance.get("/auth/user");
      if (res.status == 201 || 200 || 202) {
        dispatch(actions.settingUser(res.data));
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || DEFAULT_ERROR_MESSAGE);
      dispatch(actions.dashboardError());
      navigate("/auth/login", { replace: true, state: { from: location } });
    }
  };
};
