import React from "react";
import Header from "./header";
import Funding from "./funding";
import SendMoney from "./send-money";
import Withdrawals from "./withdrawals";
import { Link } from "react-router-dom";
import { useAppSelector } from "app/hooks";
import WalletRestriction from "./wallet-restrictions";
import { Box, Tabs, TabList, Tab } from "@chakra-ui/react";
import { TabPanel, TabPanels, Tag } from "@chakra-ui/react";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";

const Request = () => {
  const [tabIndex, setTabIndex] = React.useState(0);
  const request = useAppSelector((state) => state.request);
  const transaction = useAppSelector(
    (state) => state.transactions.allTransactions
  );

  return (
    <Box>
      <Breadcrumb mb={4}>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/dashboard">
            Home
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink href="#">Requests</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Box
        p={[4, 6]}
        display="flex"
        bgColor="#fff"
        borderRadius="10px"
        flexDirection="column"
      >
        <Header tabIndex={tabIndex} />

        <Box>
          <Tabs
            colorScheme="primary.500"
            onChange={(index) => setTabIndex(index)}
          >
            <TabList>
              <Tab>
                <span>Withdrawal</span>
                <Tag
                  ml={1}
                  fontSize={12}
                  fontWeight={400}
                  lineHeight="14px"
                  colorScheme="error"
                  variant="solid"
                >
                  {request.withdrawal.pagination.total}
                </Tag>
              </Tab>
              <Tab>
                <span>Send Money</span>
                <Tag
                  ml={1}
                  fontSize={12}
                  fontWeight={400}
                  lineHeight="14px"
                  colorScheme="error"
                  variant="solid"
                >
                  {request.sendMoney.pagination.total}
                </Tag>
              </Tab>
              <Tab>
                <span>Funding</span>
                <Tag
                  ml={1}
                  fontSize={12}
                  fontWeight={400}
                  lineHeight="14px"
                  colorScheme="error"
                  variant="solid"
                >
                  {transaction.pagination?.total}
                </Tag>
              </Tab>
              <Tab>
                <span>Wallet Restriction</span>

                <Tag
                  ml={1}
                  fontSize={12}
                  fontWeight={400}
                  lineHeight="14px"
                  colorScheme="error"
                  variant="solid"
                >
                  {request.fiatWallet.pagination.total}
                </Tag>
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <Withdrawals />
              </TabPanel>
              <TabPanel>
                <SendMoney />
              </TabPanel>
              <TabPanel>
                <Funding />
              </TabPanel>
              <TabPanel>
                <WalletRestriction />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>

        <Box display="flex" flexDirection="column"></Box>
      </Box>
    </Box>
  );
};

export default Request;
