import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Divider,
  Flex,
  Stack,
  Heading,
  Icon,
  Text,
  Alert,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink
} from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import {
  BaseModalProps,
  VerificationAction,
  VerificationDetailParam
} from "../models";
import TabLink from "../../../components/links/tab-link";
import { ProfileCheckOutlineIcon } from "views/components/icons";
import { RiAttachment2 } from "react-icons/ri";
import { useEffect } from "react";
import PersonalInformation from "./personal-information";
import { useAppDispatch, useAppSelector, useBreadCrumbs } from "app/hooks";
import { getSingleVerification } from "app/store";
import Attachments from "./attachmenta";
import { formatDateOnlyToDateString } from "data/utils/formatDateToUTC";
import { VerificationStatus } from "app/store/models/verification";
import { BsArrowsAngleExpand } from "react-icons/bs";
import { resetSingleVerification } from "app/store";

const VerificationDetails = ({ isOpen, onClose }: BaseModalProps) => {
  const dispatch = useAppDispatch();
  const [userDetails] = useAppSelector((state) => [
    state.verification.singleVerification
  ]);

  // url params
  let [searchParams, setSearchParams] = useSearchParams();
  const verificationDetailParamValue = searchParams.get("verification-detail");
  const userId = searchParams.get("verification-id");

  const handleClose = () => {
    onClose();
    dispatch(resetSingleVerification());
  };

  useEffect(() => {
    if (!!userId && !verificationDetailParamValue) {
      searchParams.set(
        "verification-detail",
        VerificationDetailParam.ATTACHMENTS
      );
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  // fetch data
  useEffect(() => {
    if (!userId || userDetails?.loading) return;
    dispatch(getSingleVerification(+userId));
  }, [userId]);

  const showDeclineVerificationModalHandler = () => {
    if (!userId) return;
    searchParams.set("verification-action", VerificationAction.DECLINE);
    setSearchParams(searchParams);
  };

  const showApproveVerificationModalHandler = () => {
    if (!userId) return;
    searchParams.set("verification-action", VerificationAction.APPROVE);
    setSearchParams(searchParams);
  };

  // bread crumbs
  const breadCrumbs = useBreadCrumbs();

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      scrollBehavior="outside"
      size={"6xl"}
    >
      <ModalOverlay />
      <ModalContent minH={"calc(100vh - 8rem)"}>
        <ModalHeader fontSize={"14px"} lineHeight={"1.2"} fontWeight={"normal"}>
          <Stack spacing={"4"} direction="row" alignItems={"center"}>
            <Icon as={BsArrowsAngleExpand} />
            <Breadcrumb mb={"5"}>
              {breadCrumbs.map((el, idx) => (
                <BreadcrumbItem key={idx}>
                  <BreadcrumbLink href={el.to}>{el.title}</BreadcrumbLink>
                </BreadcrumbItem>
              ))}
              <BreadcrumbItem>
                <BreadcrumbLink isCurrentPage={true}>{userId}</BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
          </Stack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Divider />
          <Flex
            direction={"row"}
            justifyContent="space-between"
            alignItems={"center"}
            my={"4"}
          >
            <Heading
              as={"h6"}
              fontSize="24px"
              lineHeight={"1.1"}
              fontWeight="700"
              textTransform={"capitalize"}
              fontFamily="karla"
            >
              {`${userDetails.data?.user?.firstName || "--"} ${
                userDetails.data?.user?.lastName || ""
              }`}
            </Heading>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
              spacing="2"
            >
              {userDetails.data?.status === VerificationStatus.INCOMING ? (
                <>
                  <Button
                    disabled
                    size={"md"}
                    colorScheme={"red"}
                    onClick={showDeclineVerificationModalHandler}
                  >
                    Decline Verification
                  </Button>
                  <Button
                    disabled
                    colorScheme={"green"}
                    size={"md"}
                    onClick={showApproveVerificationModalHandler}
                    // disabled={
                    //   userDetails.data.country === "NGA" &&
                    //   !userDetails.data.user?.bvn
                    // }
                  >
                    Approve Verification
                  </Button>
                </>
              ) : null}
            </Stack>
          </Flex>
          {userDetails.data?.country === "NGA" &&
          !userDetails.data?.user?.bvn ? (
            <Alert status="error">
              <AlertIcon />
              BVN missing for this user
            </Alert>
          ) : null}
          <Divider />
          <Stack mt={"4"}>
            <Flex
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
              mb={"2"}
            >
              <Heading
                as={"h6"}
                fontSize="20px"
                lineHeight={"1.1"}
                fontWeight="700"
                textTransform={"capitalize"}
                fontFamily="karla"
              >
                Form
              </Heading>
              <Text color={"gray.600"} fontStyle="italic">
                Created:{" "}
                {userDetails.data
                  ? formatDateOnlyToDateString(userDetails.data.createdAt)
                  : "--"}
              </Text>
            </Flex>
            <Stack spacing={"5"} direction="row" pt={"3"}>
              <TabLink
                urlParamKey={"verification-detail"}
                urlParamValue={VerificationDetailParam.ATTACHMENTS}
              >
                <Stack direction={"row"}>
                  <Icon as={RiAttachment2} w={"5"} h={"5"} />
                  <Text>Attachments</Text>
                </Stack>
              </TabLink>
              <TabLink
                urlParamKey={"verification-detail"}
                urlParamValue={VerificationDetailParam.PERSONAL_DETAILS}
              >
                <Stack direction={"row"}>
                  <Icon as={ProfileCheckOutlineIcon} w={"5"} h={"5"} />
                  <Text>Personal Information</Text>
                </Stack>
              </TabLink>
            </Stack>
          </Stack>
          <Divider mb={"4"} />
          {searchParams.get("verification-detail") ===
          VerificationDetailParam.ATTACHMENTS ? (
            <Attachments />
          ) : searchParams.get("verification-detail") ===
            VerificationDetailParam.PERSONAL_DETAILS ? (
            <PersonalInformation />
          ) : null}
        </ModalBody>

        <ModalFooter>
          <Button
            size={"lg"}
            borderColor="gray.400"
            colorScheme="gray"
            _hover={{ background: "primary.100" }}
            variant="outline"
            px="6"
            w={"full"}
            onClick={onClose}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default VerificationDetails;
