import { Dispatch } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";
import featureService from "data/services/feature-service";
import toast from "react-hot-toast";
import { actions } from "../reducers/feature.reducer";

export const getAllFeatures = (params: { page: number; perpage: number }) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getAllFeaturePending());
      const res = await featureService.getAllFeatures(params);
      if (res.status === 200 || 201 || 202) {
        dispatch(
          actions.getAllFeatureSuccess({
            data: res.data.data,
            pagination: res.data.pagination
          })
        );
      }
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.getAllFeatureFailed());
      toast.error(msg);
    }
  };
};
