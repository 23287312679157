import React from "react";
import { ArrowLeft } from "iconsax-react";
import Input from "views/components/input";
import { useValentineWishList } from "app/hooks/use-wishlist";
import { Box, Grid, Button, Heading, Text } from "@chakra-ui/react";

type Props = {
  showTableList: () => void;
};

const PopulateWishList = ({ showTableList }: Props) => {
  const { valentineWishListItems, dispatchCreateValentineWishListItems } =
    useValentineWishList();

  const [state, setState] = React.useState(["", "", "", ""]);
  const isSubmitDisabled = valentineWishListItems.loading;

  const handleInputChange = (value: string, index: number) => {
    setState((prev) => {
      const newState = [...prev];
      newState[index] = value.trim();
      return newState;
    });
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();

      setState((prev) => {
        const newState = [...prev];
        newState.push("");
        return newState;
      });
    }
  };

  const handleSubmit = async () => {
    if (isSubmitDisabled) return;

    const items = state
      .filter((s) => !!s)
      .map((s) => ({
        item: s
      }));

    if (!items.length) return;
    const res = await dispatchCreateValentineWishListItems({
      items: items as any
    });

    if (res) {
      setState(["", "", "", ""]);
      showTableList();
    }
  };

  return (
    <Box className="py-8 max-w-2xl mx-auto">
      <div
        onClick={showTableList}
        className="flex items-center gap-3 cursor-pointer mb-12"
      >
        <ArrowLeft cursor="pointer" />
        <Heading
          py="4"
          px="2"
          as="h3"
          fontSize="18px"
          lineHeight="28px"
          fontWeight="medium"
        >
          Wishlist
        </Heading>
      </div>

      <Text as="h1" fontWeight="semibold" fontSize="3xl" textAlign="center">
        Create Wishlist Items
      </Text>
      <p className="mt-3 mb-6 text-gray-600 text-center">
        Enter prize items for your wishlist or import from a .xlv file
      </p>

      <div className="flex flex-col gap-6">
        <div>
          <p className="text-gray-600 mb-2">
            Press “enter” to go to next item or add more items
          </p>

          <Grid templateColumns="repeat(2, 1fr)" gap={6}>
            {state.map((_, i) => (
              <Input
                key={i}
                onKeyDown={handleKeyDown}
                placeholder={`Item ${i + 1}`}
                backgroundColor="transparent"
                onChange={(e) => handleInputChange(e.target.value, i)}
              />
            ))}
          </Grid>
        </div>

        <div className="flex items-center justify-end">
          <Button
            size="lg"
            ml="auto"
            fontSize="md"
            minWidth="200px"
            width="fit-content"
            fontWeight="medium"
            onClick={handleSubmit}
            colorScheme="primary"
            disabled={isSubmitDisabled}
            isDisabled={isSubmitDisabled}
            isLoading={valentineWishListItems.loading}
          >
            Add WishList Items
          </Button>
        </div>
      </div>
    </Box>
  );
};

export default PopulateWishList;
