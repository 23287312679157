import {
  Text,
  Icon,
  Modal,
  Stack,
  Button,
  Heading,
  Divider,
  Spinner,
  Textarea,
  ModalBody,
  FormLabel,
  ModalHeader,
  ModalFooter,
  FormControl,
  ModalOverlay,
  ModalContent,
  ModalCloseButton
} from "@chakra-ui/react";

import {
  MessageOutlineIcon,
  ProfileCheckOutlineIcon
} from "views/components/icons";
import React, { useState } from "react";
import { getSingleUser, recoverAccount } from "app/store";
import { IoCalendarOutline } from "react-icons/io5";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { BaseModalProps } from "views/containers/user-information/models";
import { formatDateToUTC } from "data/utils/formatDateToUTC";

type Props = BaseModalProps & { userId: number };

const RecoverUser = ({ userId, isOpen, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const [reason, setReason] = useState("");
  const controller = new AbortController();
  const [recover, requestor] = useAppSelector((state) => [
    state.user.recoverAccount,
    state.dashboard.userProfile
  ]);
  const isDisabled = !reason || recover.loading;

  const dispatchUser = () => {
    dispatch(getSingleUser(+userId, controller.signal));
  };

  const dispatchRecoverAccount = async () => {
    if (isDisabled) return;
    const success = await dispatch(recoverAccount(userId, reason));
    if (success) dispatchUser();
  };

  React.useEffect(() => {
    return () => controller.abort();
  }, []);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="outside">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textTransform={"capitalize"}>
            Recover Account
          </ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody>
            <Stack
              p="5"
              bg="primary.500"
              color={"gray.300"}
              rounded="lg"
              spacing={"4"}
            >
              <Stack direction={"row"} justifyContent="space-between">
                <Stack direction={"row"} alignItems="center">
                  <Icon as={ProfileCheckOutlineIcon} h={5} w={5} />
                  <Text>Requestor</Text>
                </Stack>
                <Text>
                  {requestor
                    ? `${requestor.firstName} ${requestor.lastName}`
                    : "--"}
                </Text>
              </Stack>

              <Stack direction={"row"} justifyContent="space-between">
                <Stack direction={"row"} alignItems="center">
                  <Icon as={MessageOutlineIcon} h={5} w={5} />
                  <Text>User Id</Text>
                </Stack>
                <Text>{userId}</Text>
              </Stack>

              <Stack direction={"row"} justifyContent="space-between">
                <Stack direction={"row"} alignItems="center">
                  <Icon as={IoCalendarOutline} h={5} w={5} />
                  <Text>Date</Text>
                </Stack>
                <Text>{formatDateToUTC(new Date())}</Text>
              </Stack>
            </Stack>

            <Heading
              my="5"
              as="h4"
              fontSize="24px"
              fontFamily="karla"
              fontWeight="extrabold"
            >
              Request Details
            </Heading>
            <Text color={"gray.600"}>Request</Text>
            <Text
              color="success.500"
              fontWeight="medium"
              textTransform="capitalize"
            >
              Recover Account
            </Text>

            <Stack my="5" spacing={"4"}>
              <FormControl>
                <FormLabel color={"gray.600"} fontWeight="400">
                  Reason
                </FormLabel>
                <Textarea
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                />
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              px="6"
              w="full"
              size="lg"
              colorScheme="green"
              disabled={isDisabled}
              isLoading={recover.loading}
              onClick={dispatchRecoverAccount}
            >
              {recover.loading ? <Spinner /> : "Recover Account"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default RecoverUser;
