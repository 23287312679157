import {
  Button,
  Divider,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import { useAppSelector } from "app/hooks";
import { ExchangeRateData } from "app/store/models/exchange-rate";
import { formatFullDateToUsersLocale } from "data/utils/date-formatter-users-locale";
import { useState } from "react";
import EditExchangeRate from "../modals/edit-exchange-rate";
import Skeleton from "./skeleton";

const ExchangeRateTable = () => {
  // user data from the store
  const [allExchangeRate] = useAppSelector((state) => [
    state.exchangeRate.allExchangeRate
  ]);

  const [isOpen, setIsOpen] = useState("");
  const [isData, setIsData] = useState<ExchangeRateData | null>(null);

  const onClose = () => {
    setIsOpen("");
    setIsData(null);
  };

  const onOpen = (id: number, data: ExchangeRateData) => {
    setIsOpen(id.toString());
    setIsData(data);
  };

  return (
    <>
      <TableContainer bg="#fff" minH="calc(100vh - 10rem)">
        <Table
          color="brand.grayScale"
          fontSize="14px"
          lineHeight="18.2px"
          colorScheme={"gray"}
          variant="striped"
        >
          <Thead>
            <Tr>
              <Th
                textTransform={"capitalize"}
                color="gray.800"
                fontSize={"16px"}
                lineHeight={"19px"}
                fontWeight={"medium"}
              >
                ID
              </Th>
              <Th
                textTransform={"capitalize"}
                color="gray.800"
                fontSize={"16px"}
                lineHeight={"19px"}
                fontWeight={"medium"}
              >
                buy rate
              </Th>
              <Th
                textTransform={"capitalize"}
                color="gray.800"
                fontSize={"16px"}
                lineHeight={"19px"}
                fontWeight={"medium"}
              >
                sell rate
              </Th>
              <Th
                textTransform={"capitalize"}
                color="gray.800"
                fontSize={"16px"}
                lineHeight={"19px"}
                fontWeight={"medium"}
              >
                currency code
              </Th>
              <Th
                textTransform={"capitalize"}
                color="gray.800"
                fontSize={"16px"}
                lineHeight={"19px"}
                fontWeight={"medium"}
              >
                date updated
              </Th>
              <Th
                textTransform={"capitalize"}
                color="gray.800"
                fontSize={"16px"}
                lineHeight={"19px"}
                fontWeight={"medium"}
              >
                action
              </Th>
            </Tr>
          </Thead>
          <Divider />
          <Tbody>
            {allExchangeRate.loading ? <Skeleton /> : null}
            {!!allExchangeRate.data.length && !allExchangeRate.loading
              ? allExchangeRate.data.map((el, idx) => (
                  <Tr key={idx}>
                    <Td textTransform={"capitalize"}>{el.id || "--"}</Td>
                    <Td textTransform={"capitalize"}>{el.buyRate || "--"}</Td>
                    <Td textTransform={"capitalize"}>{el.sellRate || "--"}</Td>
                    <Td textTransform={"capitalize"}>
                      {el.currencyCode.toUpperCase() || "--"}
                    </Td>
                    <Td textTransform={"capitalize"} color="gray.500">
                      {el.updatedAt
                        ? formatFullDateToUsersLocale(el.updatedAt)
                        : "--"}
                    </Td>
                    <Td>
                      <Button
                        size={"sm"}
                        borderColor="gray.400"
                        colorScheme="gray"
                        _hover={{ background: "primary.100" }}
                        variant="outline"
                        px="6"
                        onClick={() => onOpen(el.id, el)}
                      >
                        Edit
                      </Button>
                    </Td>
                  </Tr>
                ))
              : null}
          </Tbody>
        </Table>
      </TableContainer>
      <EditExchangeRate
        isOpen={!!isOpen && !!isData}
        onClose={onClose}
        id={isOpen}
        data={isData}
      />
    </>
  );
};

export default ExchangeRateTable;
