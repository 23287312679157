import { AxiosError } from "axios";
import toast from "react-hot-toast";
import { Dispatch } from "@reduxjs/toolkit";
import { actions } from "../reducers/verification.reducer";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";
import { VerificationStatus } from "../models/verification";
import verificationService from "data/services/verification-service";

export const getAllVerification = (params: {
  page: number;
  perpage: number;
  userId?: number;
  status?: VerificationStatus;
  q?: string;
}) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getAllVerificationPending());
      const res = await verificationService.getAllVerification(params);
      if (res.status === 200 || 201 || 202) {
        dispatch(
          actions.getAllVerificationSuccess({
            data: res.data.data,
            pagination: res.data.pagination
          })
        );
      }
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.getAllVerificationFailed());
      toast.error(msg);
    }
  };
};

export const deleteVerification = (id: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.deleteVerificationPending());
      const res = await verificationService.deleteVerification(id);
      if (res.status === 200 || 201 || 202) {
        dispatch(actions.deleteVerificationSuccess());
        toast.success(res.data?.message || "Success");
        return true;
      }
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.deleteVerificationFailed(msg));
      toast.error(msg);
    }
  };
};

// export const getSingleVerificationByUserId = (id: number) => {
//   return async (dispatch: Dispatch) => {
//     try {
//       const params: any = { page: 1, perpage: 1, userId: id };
//       dispatch(actions.getSingleVerificationPending());
//       const res = await verificationService.getAllVerification(params);
//       if (res.status === 200 || 201 || 202) {
//         dispatch(
//           actions.getSingleVerificationSuccess({
//             data: res?.data?.data?.[0] as any
//           })
//         );
//       }
//     } catch (err: any) {
//       const error = err as AxiosError<{ message: string }>;
//       const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
//       dispatch(actions.getSingleVerificationFailed());
//       toast.error(msg);
//     }
//   };
// };

export const getSingleVerification = (id: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getSingleVerificationPending());
      const res = await verificationService.getSingleVerification(id);
      if (res.status === 200 || 201 || 202) {
        dispatch(
          actions.getSingleVerificationSuccess({
            data: res.data.data
          })
        );
      }
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.getSingleVerificationFailed());
      toast.error(msg);
    }
  };
};

export const resetSingleVerification = () => {
  return async (dispatch: Dispatch) => {
    dispatch(actions.resetSingleVerification());
  };
};
