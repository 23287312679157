import TransactionItem from "./item";
import { Tbody } from "@chakra-ui/react";
import React from "react";

type Props = { data: any[] };

const TransactionList = ({ data }: Props) => {
  return (
    <Tbody>
      {data.map((d) => (
        <React.Fragment key={d.id}>
          <TransactionItem {...d} />
        </React.Fragment>
      ))}
    </Tbody>
  );
};

export default TransactionList;
