import { useAppSelector } from "app/hooks";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Stack, Heading, Button } from "@chakra-ui/react";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const Header = ({ tabIndex }: any) => {
  const withdrawal = useAppSelector((state) => state.request.withdrawal.data);
  const sendMoney = useAppSelector((state) => state.request.sendMoney.data);

  const withdrawalTable = {
    headerRows: 1,
    widths: ["*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*"],
    body: [
      [
        "Username",
        "Email",
        "Account Name",
        "Bank Details",
        "Withdrawal Mode",
        "Original Amouunut",
        "Conversion Rate",
        "Withdrawal Fee",
        "Destination Amount",
        "Changera Plan",
        "Date",
        "Status"
      ],
      ...withdrawal?.map((w) => [
        w?.user?.username || "-",
        w.user?.email || "-",
        w.bankAccountName,
        w.bankAccountNumber,
        w.type,
        w.originalAmount,
        w.conversionRate,
        w.transferFee,
        w.destinationAmount,
        w.user?.plan,
        w.createdAt,
        w.status
      ])
    ]
  };

  const sendMoneyTable = {
    headerRows: 1,
    widths: ["*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*"],
    body: [
      [
        "Username",
        "Email",
        "Account Name",
        "Bank Details",
        "Withdrawal Mode",
        "Original Amouunut",
        "Conversion Rate",
        "Withdrawal Fee",
        "Destination Amount",
        "Changera Plan",
        "Date",
        "Status"
      ],
      ...sendMoney?.map((s) => [
        s.user?.username || "-",
        s.user?.email || "-",
        s.recipientBankAccountName,
        s.recipientBankAccountNumber,
        s.type,
        s.amount,
        s.rate,
        s.fee,
        s.recipientAmount,
        s.user?.plan,
        s.createdAt,
        s.status
      ])
    ]
  };

  const docDefinition = {
    pageSize: "A2" as const,
    pageOrientation: "landscape" as const,
    content: [
      {
        defaultStyle: {
          lineHeight: 1.2,
          font: "Helvetica"
        },
        layout: "lightHorizontalLines",
        table: {} as any
      }
    ]
  };

  const handleDownload = () => {
    if (tabIndex === 0) docDefinition.content[0].table = withdrawalTable;
    if (tabIndex === 1) docDefinition.content[0].table = sendMoneyTable;
    pdfMake.createPdf(docDefinition).download();
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      mb={6}
    >
      <Heading
        color="gray.900"
        fontWeight={700}
        lineHeight="28px"
        fontFamily="Karla"
        fontSize={[20, 24]}
      >
        Requests
      </Heading>

      <Button
        color="#fff"
        fontSize={12}
        width="120px"
        maxWidth="100%"
        fontWeight={500}
        fontFamily="Rubik"
        bgColor="primary.500"
        onClick={handleDownload}
      >
        Download PDF
      </Button>
    </Stack>
  );
};

export default Header;
