import { Box, Heading, Text } from "@chakra-ui/react";
import { ReactComponent as Logo } from "app/assets/svg/logo.svg";

const WrongURL = () => {
  return (
    <div>
      <div className="h-screen bg-primary-600 relative">
        <Box
          className="flex items-center justify-center w-full mt-8 overflow-y-visible mdx:mt-12"
          position={"absolute"}
          top="50%"
          left="50%"
          transform={"translate(-50%, -50%)"}
        >
          <Box textAlign="center" py={10} px={6} color="white">
            <Heading display="inline-block" as="h2" size="2xl">
              Deprecated URL
            </Heading>
            <Text fontSize="18px" mt={3} mb={2}>
              The link you're trying to access has been disabled... Please
              contact Admin
            </Text>
          </Box>
        </Box>
        <div className="w-full px-2 py-6 overflow-y-auto mdx:py-16 mdx:px-16 bg-primary-600">
          <div className="relative">
            <div className="flex justify-center mdx:justify-start">
              <Logo />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WrongURL;
