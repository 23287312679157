import {
  ToggleWalletStatus,
  ProcessSendMoneyStatus,
  ProcessWithdrawalStatus,
  ISendMoneyState
} from "../models/request";
import { AxiosError } from "axios";
import toast from "react-hot-toast";
import { Dispatch } from "@reduxjs/toolkit";
import { actions } from "../reducers/request.reducer";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";
import requestService from "data/services/request-service";
import { store } from "../store";

export const getAllSendMoney = (params: {
  page: number;
  status: string;
  perpage: number;
}) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("getAllSendMoney"));
      const res = await requestService.getAllSendMoney(params);
      dispatch(
        actions.setSendMoney({
          data: res.data.data,
          pagination: res.data.pagination
        })
      );
      // toast.success(res.data.message);
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.error({ for: "getAllSendMoney", msg }));
    }
  };
};

export const getAllWithdrawals = (params: {
  page: number;
  perpage: number;
  status: ProcessWithdrawalStatus | "";
}) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("getAllWithdrawals"));
      const res = await requestService.getAllWithdrawals(params);
      dispatch(
        actions.setWithdrawals({
          data: res.data.data,
          pagination: res.data.pagination
        })
      );
      // toast.success(res.data.message);
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.error({ msg, for: "getAllWithdrawals" }));
    }
  };
};

export const processWithdrawals = (
  id: number,
  body: {
    reason: string;
    status: ProcessWithdrawalStatus;
  },
  callback: () => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("processWithdrawal"));
      const res = await requestService.processWithdrawal(id, body);

      if (res.data.state === "success") {
        dispatch(actions.setProcessedWithdrawal({ id, status: body.status }));
        toast.success(res.data.message);
        return callback();
      }

      const msg = DEFAULT_ERROR_MESSAGE;
      dispatch(actions.error({ msg, for: "processWithdrawal" }));
      toast.error(msg);
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.error({ msg, for: "processWithdrawal" }));
    }
  };
};

export const processingWithdrawals = (
  id: number,
  body: {
    status: ProcessWithdrawalStatus;
  },
  callback: () => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("processWithdrawal"));
      const res = await requestService.processingWithdrawal(id);
      dispatch(actions.setProcessedWithdrawal({ id, status: body.status }));

      callback();
      toast.success(res.data.message);
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.error({ msg, for: "processWithdrawal" }));
    }
  };
};

export const processSendMoney = (
  id: number,
  body: {
    reason: string;
    status: ProcessSendMoneyStatus;
  },
  sendMoneyData: ISendMoneyState,
  appDispatch: typeof store.dispatch
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("processSendMoney"));
      const res = await requestService.processSendMoney(id, body);
      dispatch(actions.setProcessedSendMoney());
      appDispatch(
        getAllSendMoney({
          page: sendMoneyData.pagination.currentPage,
          perpage: sendMoneyData.pagination.pageSize,
          status: sendMoneyData.status
        })
      );
      toast.success(res.data.message);
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.error({ msg, for: "processSendMoney" }));
    }
  };
};

export const getAllFiatWallets = (params: {
  page: number;
  perpage: number;
  active: boolean;
}) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("getAllFiatWallets"));
      const res = await requestService.getAllFiatWallets(params);
      dispatch(
        actions.setFiatWallets({
          data: res.data.data,
          pagination: res.data.pagination
        })
      );
      // toast.success(res.data.message);
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.error({ for: "getAllFiatWallets", msg }));
    }
  };
};

export const toggleFiatWallets = (
  id: number,
  status: ToggleWalletStatus,
  reason: string
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("toggleFiatWallet"));
      const res = await requestService.toggleFiatWallets(id, status, reason);
      dispatch(actions.setToggleFiatWallet({ id }));
      toast.success(res.data.message);
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.error({ for: "toggleFiatWallet", msg }));
    }
  };
};

export const getAllCryptoWallets = (params: {
  page: number;
  perpage: number;
  active: boolean;
}) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("getAllCryptoWallets"));
      const res = await requestService.getAllCryptoWallets(params);
      dispatch(
        actions.setCryptoWallets({
          data: res.data.data,
          pagination: res.data.pagination
        })
      );
      // toast.success(res.data.message);
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.error({ for: "getAllCryptoWallets", msg }));
    }
  };
};
