import {
  Box,
  Button,
  Divider,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import UserDetails from "../user-details";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { getAllUsers, setUserFilter } from "app/store";
import ActiveUsersSkeleton from "./active-users-skeleton";
import PaginationWrapper from "views/components/PaginationWrapper/PaginationWrapper";
import { usePagination } from "@ajna/pagination";
import { debounce } from "lodash";
import { FiSearch } from "react-icons/fi";
import { IoMdCopy } from "react-icons/io";
import { useCopyToClipboard } from "app/hooks";
import { formatFullDateToUsersLocale } from "data/utils/date-formatter-users-locale";

type Props = {
  searchInput: string;
  setSearchInput: React.Dispatch<React.SetStateAction<string>>;
};

const ActiveUsersTable: React.FC<Props> = ({ searchInput, setSearchInput }) => {
  // user data from the store
  const [filter, allUsers] = useAppSelector((state) => [
    state.user.filter,
    state.user.allUsers
  ]);
  const dispatch = useAppDispatch();

  let [searchParams, setSearchParams] = useSearchParams();

  // User details Modal controller
  const onOpenUserDetails = (id: number) => {
    searchParams.set("user-id", id.toString());
    setSearchParams(searchParams);
  };

  const onCloseUserDetails = () => {
    searchParams.delete("user-id");
    searchParams.delete("user-detail");
    searchParams.delete("wallet-category");
    searchParams.delete("transaction-type");
    searchParams.delete("verification-detail");
    setSearchParams(searchParams);
  };

  // pagination and search
  const [pageSize, setPageSize] = useState(10);

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, offset } =
    usePagination({
      initialState: { currentPage: 1, pageSize },
      total: allUsers.pagination.total,
      limits: {
        inner: 2,
        outer: 1
      }
    });

  const handlePageChange = (nextPage: number) => {
    setCurrentPage(nextPage);
    setTimeout(() => {
      window.scrollTo({ top: 10, behavior: "smooth" });
    }, 500);
  };

  const handleSize = (e: any) => {
    setPageSize(e.target.value);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;

    if (query.length >= 3) {
      dispatch(setUserFilter({ ...filter, q: query }));
      setCurrentPage(1);
    } else {
      dispatch(setUserFilter({ ...filter, q: "" }));
      setCurrentPage(1);
    }
  };

  const debouncedChangeHandler = useMemo(() => {
    return debounce(handleSearch, 1000);
  }, []);

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchInput(value);
    debouncedChangeHandler(e);
  };

  // fetch all actions
  useEffect(() => {
    const controller = new AbortController();
    dispatch(
      getAllUsers(
        {
          q: filter?.q,
          page: currentPage,
          perpage: pageSize,
          plan: filter?.plan,
          country: filter.country,
          endDate: filter?.endDate,
          startDate: filter?.startDate,
          blocked: filter?.status as any
        } as any,
        controller.signal
      )
    );

    return () => controller.abort();
  }, [
    filter?.q,
    filter?.status,
    filter?.plan,
    filter?.endDate,
    filter?.startDate,
    filter?.country,
    filter?.currentDate,
    currentPage,
    pageSize
  ]);

  //
  const copyHandler = useCopyToClipboard();

  return (
    <>
      <TableContainer bg="#fff">
        <Stack mt="0.75rem" ml="2" spacing="4" direction={"row"}>
          <Box mb="16px">
            <InputGroup width="256px">
              <Input
                width="256px"
                paddingLeft="40px"
                minH="40px"
                value={searchInput}
                placeholder={"Search"}
                border="1px solid #EDEBF9"
                onChange={handleSearchInputChange}
                borderRadius="10px"
                fontSize="12px"
                _focus={{
                  border: "1px solid #EDEBF9"
                }}
                _hover={{
                  border: "1px solid #EDEBF9"
                }}
              />

              <InputLeftElement
                h="100%"
                mr="12px"
                children={<FiSearch color="#A0A3BD" />}
              />
            </InputGroup>
          </Box>
          <Box mb="12px">
            <Select
              width="120px"
              defaultValue={"Size"}
              placeholder="Size"
              onChange={handleSize}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
            </Select>
          </Box>
        </Stack>
        <Table
          color="brand.grayScale"
          fontSize="14px"
          lineHeight="18.2px"
          colorScheme={"gray"}
          variant="striped"
        >
          <Thead>
            <Tr>
              <Th
                textTransform={"capitalize"}
                color="gray.800"
                fontSize={"16px"}
                lineHeight={"19px"}
                fontWeight={"medium"}
              >
                Full Name
              </Th>
              <Th
                textTransform={"capitalize"}
                color="gray.800"
                fontSize={"16px"}
                lineHeight={"19px"}
                fontWeight={"medium"}
              >
                username
              </Th>
              <Th
                textTransform={"capitalize"}
                color="gray.800"
                fontSize={"16px"}
                lineHeight={"19px"}
                fontWeight={"medium"}
              >
                user id
              </Th>
              <Th
                textTransform={"capitalize"}
                color="gray.800"
                fontSize={"16px"}
                lineHeight={"19px"}
                fontWeight={"medium"}
              >
                email
              </Th>
              <Th
                textTransform={"capitalize"}
                color="gray.800"
                fontSize={"16px"}
                lineHeight={"19px"}
                fontWeight={"medium"}
              >
                phone
              </Th>
              <Th
                textTransform={"capitalize"}
                color="gray.800"
                fontSize={"16px"}
                lineHeight={"19px"}
                fontWeight={"medium"}
              >
                country
              </Th>
              <Th
                textTransform={"capitalize"}
                color="gray.800"
                fontSize={"16px"}
                lineHeight={"19px"}
                fontWeight={"medium"}
              >
                changera plan
              </Th>
              <Th
                textTransform={"capitalize"}
                color="gray.800"
                fontSize={"16px"}
                lineHeight={"19px"}
                fontWeight={"medium"}
              >
                date registered
              </Th>
              <Th
                textTransform={"capitalize"}
                color="gray.800"
                fontSize={"16px"}
                lineHeight={"19px"}
                fontWeight={"medium"}
              >
                action
              </Th>
            </Tr>
          </Thead>
          <Divider />
          <Tbody>
            {allUsers.loading ? <ActiveUsersSkeleton /> : null}
            {!!allUsers.data.length && !allUsers.loading
              ? allUsers.data.map((el, idx) => (
                  <Tr key={idx}>
                    <Td textTransform={"capitalize"}>{`${
                      el.firstName || "--"
                    } ${el.lastName || ""}`}</Td>
                    <Td textTransform={"capitalize"}>{el.username || "--"}</Td>
                    <Td>
                      <Stack
                        direction={"row"}
                        spacing="2"
                        alignItems={"center"}
                      >
                        <Text>{el.id || "--"}</Text>
                        <Icon
                          as={IoMdCopy}
                          w={5}
                          h={5}
                          color="success.300"
                          cursor={"pointer"}
                          onClick={() =>
                            copyHandler(el.id.toString() || "", "User Id")
                          }
                        />
                      </Stack>
                    </Td>
                    <Td>
                      <Stack
                        direction={"row"}
                        spacing="2"
                        alignItems={"center"}
                      >
                        <Text>{el.email || "--"}</Text>
                        <Icon
                          as={IoMdCopy}
                          w={5}
                          h={5}
                          color="success.300"
                          cursor={"pointer"}
                          onClick={() => copyHandler(el.email || "", "Email")}
                        />
                      </Stack>
                    </Td>
                    <Td>
                      <Stack
                        direction={"row"}
                        spacing="2"
                        alignItems={"center"}
                      >
                        <Text>{el.phone || "--"}</Text>
                        <Icon
                          as={IoMdCopy}
                          w={5}
                          h={5}
                          color="success.300"
                          cursor={"pointer"}
                          onClick={() => copyHandler(el.phone || "", "Phone")}
                        />
                      </Stack>
                    </Td>
                    <Td textTransform={"capitalize"}>{el.country || "--"}</Td>
                    <Td textTransform={"capitalize"}>{el.plan || "--"}</Td>
                    <Td textTransform={"capitalize"} color="gray.500">
                      {el.createdAt
                        ? formatFullDateToUsersLocale(el.createdAt)
                        : "--"}
                    </Td>
                    <Td>
                      <Button
                        size={"sm"}
                        borderColor="gray.400"
                        colorScheme="gray"
                        _hover={{ background: "primary.100" }}
                        variant="outline"
                        px="6"
                        onClick={() => onOpenUserDetails(el.id)}
                      >
                        view
                      </Button>
                    </Td>
                  </Tr>
                ))
              : null}
          </Tbody>
        </Table>
      </TableContainer>
      <PaginationWrapper
        handlePageChange={handlePageChange}
        totalDataCount={allUsers.pagination.total}
        currentPage={currentPage}
        isDisabled={isDisabled}
        pagesCount={pagesCount}
        offset={offset}
        pages={pages}
        size={pageSize}
      />
      <UserDetails
        onClose={onCloseUserDetails}
        isOpen={!!searchParams.get("user-id")}
      />
    </>
  );
};

export default ActiveUsersTable;
