import Stats from "./stats";
import Graphs from "./graphs";
import { Box } from "@chakra-ui/react";
import Transactions from "./transactions";

const DashboardContainer = () => {
  return (
    <Box>
      <Stats />
      <Graphs />
      <Transactions />
    </Box>
  );
};

export default DashboardContainer;
