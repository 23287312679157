import Actions from "./actions";
import { useState } from "react";
import MerchantInformationTable from "./information";
import MerchantTransactionTable from "./transaction";
import { Box, Tabs, TabPanel, TabPanels } from "@chakra-ui/react";

const Merchant = () => {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <Box bg="#fff" borderRadius="16px" padding={"1rem 1rem 24px"}>
      <Tabs colorScheme="primary" onChange={setTabIndex}>
        <Actions />

        <TabPanels pt="3">
          <TabPanel p="0">
            {tabIndex === 0 ? <MerchantInformationTable /> : null}
          </TabPanel>
          <TabPanel p="0">
            {tabIndex === 1 ? <MerchantTransactionTable /> : null}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default Merchant;
