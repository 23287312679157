import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  BanHistory,
  BannedUserData,
  PaginationState,
  SingleUserData,
  SubscriptionData,
  UserData,
  UserFilter,
  UserState
} from "../models/user";

const initialState: UserState = {
  filter: {},
  recoverAccount: { error: "", loading: false },
  subscriptions: {
    cancel: { error: "", loading: false },
    subscribe: { error: "", loading: false },
    all: { data: [], error: "", loading: false }
  },
  allUsers: {
    data: [],
    loading: false,
    pagination: {
      hasPrevious: false,
      prevPage: 0,
      hasNext: true,
      next: 2,
      currentPage: 1,
      pageSize: 10,
      lastPage: 0,
      total: 0
    }
  },
  bannedUsers: {
    data: [],
    loading: false,
    pagination: {
      hasPrevious: false,
      prevPage: 0,
      hasNext: true,
      next: 2,
      currentPage: 1,
      pageSize: 10,
      lastPage: 0,
      total: 0
    }
  },
  singleUser: {
    data: null,
    loading: false
  },
  banHistory: {
    data: [],
    pagination: {
      hasPrevious: false,
      prevPage: 0,
      hasNext: true,
      next: 2,
      currentPage: 1,
      pageSize: 10,
      lastPage: 0,
      total: 0
    },
    loading: false
  }
};

export const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    getAllUsersPending: (state) => {
      state.allUsers.loading = true;
    },
    getAllUsersSuccess: (
      state,
      action: PayloadAction<{
        data: UserData[];
        pagination: PaginationState;
      }>
    ) => {
      state.allUsers.data = action.payload.data;
      state.allUsers.pagination = action.payload.pagination;
      state.allUsers.loading = false;
    },
    getAllUsersFailed: (state) => {
      state.allUsers.loading = false;
    },

    getSingleUserPending: (state) => {
      state.singleUser.loading = true;
    },
    getSingleUserSuccess: (
      state,
      action: PayloadAction<{
        data: SingleUserData;
      }>
    ) => {
      state.singleUser.data = action.payload.data;
      state.singleUser.loading = false;
    },
    getSingleUserFailed: (state) => {
      state.singleUser.loading = false;
    },
    getBannedUsersPending: (state) => {
      state.bannedUsers.loading = true;
    },
    getBannedUsersSuccess: (
      state,
      action: PayloadAction<{
        data: BannedUserData[];
        pagination: PaginationState;
      }>
    ) => {
      state.bannedUsers.data = action.payload.data;
      state.bannedUsers.pagination = action.payload.pagination;
      state.bannedUsers.loading = false;
    },
    getBannedUsersFailed: (state) => {
      state.bannedUsers.loading = false;
    },
    getBanHistoryPending: (state) => {
      state.banHistory.loading = true;
    },
    getBanHistorySuccess: (
      state,
      action: PayloadAction<{
        data: BanHistory[];
        pagination: PaginationState;
      }>
    ) => {
      state.banHistory.loading = false;
      state.banHistory.data = action.payload.data;
      state.banHistory.pagination = action.payload.pagination;
    },
    getBanHistoryFailed: (state) => {
      state.banHistory.loading = false;
    },
    setUserFilter: (state, action: PayloadAction<UserFilter>) => {
      state.filter = action.payload;
    },

    getSubscriptionsLoading: (state) => {
      state.subscriptions.all.loading = true;
    },
    getSubscriptionsSuccess: (
      state,
      action: PayloadAction<{
        data: SubscriptionData[];
        pagination: PaginationState;
      }>
    ) => {
      state.subscriptions.all.error = "";
      state.subscriptions.all.loading = false;
      state.subscriptions.all.data = action.payload.data;
    },
    getSubscriptionsFailed: (state, action: PayloadAction<string>) => {
      state.subscriptions.all.loading = false;
      state.subscriptions.all.error = action.payload;
    },
    cancelSubscriptionPending: (state) => {
      state.subscriptions.cancel.loading = true;
    },
    cancelSubscriptionSuccess: (state) => {
      state.subscriptions.cancel.error = "";
      state.subscriptions.cancel.loading = false;
    },
    cancelSubscriptionFailed: (state, action: PayloadAction<string>) => {
      state.subscriptions.cancel.loading = false;
      state.subscriptions.cancel.error = action.payload;
    },
    subscribeUserPending: (state) => {
      state.subscriptions.subscribe.loading = true;
    },
    subscribeUserSuccess: (state) => {
      state.subscriptions.subscribe.error = "";
      state.subscriptions.subscribe.loading = false;
    },
    subscribeUserFailed: (state, action: PayloadAction<string>) => {
      state.subscriptions.subscribe.loading = false;
      state.subscriptions.subscribe.error = action.payload;
    },
    recoverAccountPending: (state) => {
      state.recoverAccount.loading = true;
    },
    recoverAccountSuccess: (state) => {
      state.recoverAccount.error = "";
      state.recoverAccount.loading = false;
    },
    recoverAccountFailed: (state, action: PayloadAction<string>) => {
      state.recoverAccount.loading = false;
      state.recoverAccount.error = action.payload;
    }
  }
});

export const actions = UserSlice.actions;

export default UserSlice.reducer;
