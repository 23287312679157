import { usePagination } from "@ajna/pagination";
import {
  Box,
  Button,
  Divider,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { getAllFeatures } from "app/store";
import { FeatureData } from "app/store/models/feature";
import { useEffect, useState } from "react";
import PaginationWrapper from "views/components/PaginationWrapper/PaginationWrapper";
import ProcessFeature from "../modals/process-feature";
import Skeleton from "./skeleton";

const FeatureTable = () => {
  // user data from the store
  const [allFeatures] = useAppSelector((state) => [state.feature.allFeatures]);

  // pagination and search
  const [pageSize, setPageSize] = useState(10);

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, offset } =
    usePagination({
      initialState: { currentPage: 1, pageSize },
      total: allFeatures.pagination.total,
      limits: {
        inner: 2,
        outer: 1
      }
    });

  const handlePageChange = (nextPage: number) => {
    setCurrentPage(nextPage);
    setTimeout(() => {
      window.scrollTo({ top: 10, behavior: "smooth" });
    }, 500);
  };

  const handleSize = (e: any) => {
    setPageSize(e.target.value);
  };

  //
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      getAllFeatures({
        page: currentPage,
        perpage: pageSize
      })
    );
  }, [currentPage, pageSize]);

  //
  const [isProcessFeatureOpen, setIsOpen] = useState<{
    id: string;
    data: FeatureData | null;
  }>({
    id: "",
    data: null
  });

  const onProcessFeatureClose = () => {
    setIsOpen({
      id: "",
      data: null
    });
  };

  const onProcessFeatureOpen = (id: number, data: FeatureData) => {
    setIsOpen({
      id: id.toString(),
      data
    });
  };

  //

  return (
    <>
      <TableContainer bg="#fff" minH="calc(100vh - 10rem)">
        <Stack
          mt="0.75rem"
          ml="2"
          spacing="4"
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Box mb="12px">
            <Select
              width="120px"
              defaultValue={"Size"}
              placeholder="Size"
              onChange={handleSize}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
            </Select>
          </Box>
        </Stack>
        <Table
          color="brand.grayScale"
          fontSize="14px"
          lineHeight="18.2px"
          colorScheme={"gray"}
          variant="striped"
        >
          <Thead>
            <Tr>
              <Th
                textTransform={"capitalize"}
                color="gray.800"
                fontSize={"16px"}
                lineHeight={"19px"}
                fontWeight={"medium"}
              >
                ID
              </Th>
              <Th
                textTransform={"capitalize"}
                color="gray.800"
                fontSize={"16px"}
                lineHeight={"19px"}
                fontWeight={"medium"}
              >
                feature
              </Th>
              <Th
                textTransform={"capitalize"}
                color="gray.800"
                fontSize={"16px"}
                lineHeight={"19px"}
                fontWeight={"medium"}
              >
                status
              </Th>
              <Th
                textTransform={"capitalize"}
                color="gray.800"
                fontSize={"16px"}
                lineHeight={"19px"}
                fontWeight={"medium"}
              >
                action
              </Th>
            </Tr>
          </Thead>
          <Divider />
          <Tbody>
            {allFeatures.loading ? <Skeleton /> : null}
            {!!allFeatures.data.length && !allFeatures.loading
              ? allFeatures.data.map((el, idx) => (
                  <Tr key={idx}>
                    <Td textTransform={"capitalize"}>{el.id || "--"}</Td>
                    <Td textTransform={"capitalize"}>{el.feature || "--"}</Td>
                    <Td textTransform={"capitalize"}>
                      {el.active ? "active" : "inactive"}
                    </Td>
                    <Td>
                      <Button
                        size={"sm"}
                        colorScheme={el.active ? "red" : "green"}
                        px="6"
                        onClick={() => onProcessFeatureOpen(el.id, el)}
                      >
                        {el.active ? "Deactivate" : "Activate"}
                      </Button>
                    </Td>
                  </Tr>
                ))
              : null}
          </Tbody>
        </Table>
      </TableContainer>
      <PaginationWrapper
        handlePageChange={handlePageChange}
        totalDataCount={allFeatures.pagination.total}
        currentPage={currentPage}
        isDisabled={isDisabled}
        pagesCount={pagesCount}
        offset={offset}
        pages={pages}
        size={pageSize}
      />
      <ProcessFeature
        isOpen={!!isProcessFeatureOpen.id}
        onClose={onProcessFeatureClose}
        data={isProcessFeatureOpen.data}
      />
    </>
  );
};

export default FeatureTable;
