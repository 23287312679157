import { Box, Icon, Text } from "@chakra-ui/react";
import { useLogoutFunction } from "app/hooks";

type Props = { key: any; text: string; icon: any; isCollapsed: boolean };

const LogoutItem = ({ text, icon, isCollapsed }: Props) => {
  const logout = useLogoutFunction();
  return (
    <Box
      onClick={logout}
      p={5}
      gap={3}
      display="flex"
      color="#DB2438"
      cursor="pointer"
      borderRadius="xl"
      flexDirection="row"
      alignItems="center"
    >
      <Icon color="#DB2438" as={icon} width={6} height={6} />
      {!isCollapsed ? (
        <Text lineHeight="19px" fontSize={16} fontWeight={400}>
          {text}
        </Text>
      ) : null}
    </Box>
  );
};

export default LogoutItem;
