import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Textarea,
  Center,
  Text
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";
import notificationService from "data/services/notification.service";
import { useState } from "react";
import { toast } from "react-hot-toast";
import useAsyncSelect from "../hooks/use-async-select";
import { BaseModalProps } from "../models";

export default function PushNotificationMultiple({
  isOpen,
  onClose
}: BaseModalProps) {
  const { AsyncInput, data } = useAsyncSelect();

  const [mass, setMass] = useState(false);

  const [state, setState] = useState({
    title: "",
    message: ""
  });

  const onChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    setState((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const isDisabled = () =>
    !state.message || !state.title || (mass ? false : !data.length);

  //
  const [loading, setLoading] = useState(false);

  const submitHandler = async () => {
    const emails = data.map((el) => el.value);

    const body = {
      ...state,
      emails
    };

    setLoading(true);

    if (mass) {
      try {
        const res = await notificationService.sendNotificationsMass(state);
        toast.success(res.data.message || "Push Notification sent");
        onClose();
        setLoading(false);
      } catch (err: any) {
        const error = err as AxiosError<{ message: string }>;
        const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
        toast.error(msg);
        setLoading(false);
      }
    } else {
      try {
        const res = await notificationService.sendNotificationsMultipleUsers(
          body
        );
        toast.success(res.data.message || "Push Notification sent");
        onClose();
        setLoading(false);
      } catch (err: any) {
        const error = err as AxiosError<{ message: string }>;
        const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
        toast.error(msg);
        setLoading(false);
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Push Notification</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl pb="2">
            <FormLabel color={"gray.600"} fontSize={"sm"} fontWeight="normal">
              User Email Address
            </FormLabel>
            {AsyncInput}
          </FormControl>

          <Checkbox
            colorScheme={"primary"}
            checked={mass}
            onChange={() => setMass((prev) => !prev)}
          >
            Send to all Users
          </Checkbox>

          <FormControl py="5">
            <FormLabel color={"gray.600"} fontSize={"sm"} fontWeight="normal">
              Message Title
            </FormLabel>
            <Input
              type="text"
              placeholder="----"
              value={state.title}
              name="title"
              onChange={onChangeHandler}
            />
          </FormControl>

          <FormControl py="5">
            <FormLabel color={"gray.600"} fontSize={"sm"} fontWeight="normal">
              <Center justifyContent={"space-between"}>
                <Text>Message Title</Text>
                <Text>{state.message.length}/200</Text>
              </Center>
            </FormLabel>
            <Textarea
              placeholder="Type message here"
              value={state.message}
              name="message"
              onChange={onChangeHandler}
            />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme={"primary"}
            onClick={submitHandler}
            disabled={isDisabled()}
            isLoading={loading}
          >
            Send Message
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
