import React from "react";
import { Tr, Td, SkeletonCircle, Skeleton } from "@chakra-ui/react";

const WithdrawalSkeleton = () => {
  return (
    <React.Fragment>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((key) => (
        <Tr key={key}>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((keyy) => (
            <Td key={keyy}>
              <Skeleton width="100%" height="30px" />
            </Td>
          ))}
          <Td>
            <SkeletonCircle width="100%" height="30px" />
          </Td>
        </Tr>
      ))}
    </React.Fragment>
  );
};

export default WithdrawalSkeleton;
