import { createIcon } from "@chakra-ui/icon";

export const AppsSolidIcon = createIcon({
  viewBox: "0 0 24 24",
  displayName: "AppsSolidIcon",
  path: [
    <path
      d="M21 4.1V10.9C21 11.5934 20.8501 11.9494 20.6386 12.1483C20.4196 12.3544 20.0215 12.5 19.27 12.5H15.23C14.4785 12.5 14.0804 12.3544 13.8614 12.1483C13.6499 11.9494 13.5 11.5934 13.5 10.9V4.1C13.5 3.40657 13.6499 3.05057 13.8614 2.85167C14.0804 2.64556 14.4785 2.5 15.23 2.5H19.27C20.0215 2.5 20.4196 2.64556 20.6386 2.85167C20.8501 3.05057 21 3.40657 21 4.1Z"
      fill="white"
      stroke="white"
    />,
    <path
      d="M10.5 13.1V19.9C10.5 20.5934 10.3501 20.9494 10.1386 21.1483C9.91956 21.3544 9.52146 21.5 8.77 21.5H4.73C3.97854 21.5 3.58044 21.3544 3.36135 21.1483C3.14993 20.9494 3 20.5934 3 19.9V13.1C3 12.4066 3.14993 12.0506 3.36135 11.8517C3.58044 11.6456 3.97854 11.5 4.73 11.5H8.77C9.52146 11.5 9.91956 11.6456 10.1386 11.8517C10.3501 12.0506 10.5 12.4066 10.5 13.1Z"
      fill="white"
      stroke="white"
    />,
    <path
      d="M21 17.1V19.9C21 20.5934 20.8501 20.9494 20.6386 21.1483C20.4196 21.3544 20.0215 21.5 19.27 21.5H15.23C14.4785 21.5 14.0804 21.3544 13.8614 21.1483C13.6499 20.9494 13.5 20.5934 13.5 19.9V17.1C13.5 16.4066 13.6499 16.0506 13.8614 15.8517C14.0804 15.6456 14.4785 15.5 15.23 15.5H19.27C20.0215 15.5 20.4196 15.6456 20.6386 15.8517C20.8501 16.0506 21 16.4066 21 17.1Z"
      fill="white"
      stroke="white"
    />,
    <path
      d="M11 6.9V4.1C11 2.6 10.36 2 8.77 2H4.73C3.14 2 2.5 2.6 2.5 4.1V6.9C2.5 8.4 3.14 9 4.73 9H8.77C10.36 9 11 8.4 11 6.9Z"
      fill="white"
    />
  ]
});
