import { Link } from "react-router-dom";
import { Box, Icon, Text, Tooltip } from "@chakra-ui/react";

type Props = {
  key: any;
  icon: any;
  icon2?: any;
  url: string;
  text: string;
  active?: boolean;
  onClick?: () => void;
  isCollapsed: boolean;
};

const ListItem = ({
  url,
  text,
  icon,
  icon2,
  active,
  onClick,
  isCollapsed
}: Props) => (
  <Link to={url} onClick={onClick}>
    <Tooltip
      label={text}
      placement="auto"
      size={"lg"}
      isDisabled={!isCollapsed}
      hasArrow
      p="2"
      rounded={"lg"}
    >
      <Box
        p={5}
        gap={3}
        display="flex"
        cursor="pointer"
        borderRadius="xl"
        flexDirection="row"
        alignItems="center"
        color={active ? "#fff" : "gray.500"}
        background={active ? "primary.500" : "transparent"}
        _hover={{
          p: { color: "#fff" },
          bgColor: "primary.500",
          path: { color: "#fff" }
        }}
      >
        <Icon
          width={6}
          height={6}
          as={active ? icon2 : icon}
          color={active ? "#fff" : "gray.500"}
        />
        {!isCollapsed ? (
          <Text lineHeight="19px" fontSize={16} fontWeight={active ? 500 : 400}>
            {text}
          </Text>
        ) : null}
      </Box>
    </Tooltip>
  </Link>
);

export default ListItem;
