import {
  ISendMoney,
  IFiatWallet,
  IWithdrawal,
  ICryptoWallet,
  PaginationState,
  ProcessWithdrawalStatus,
  ProcessSendMoneyStatus,
  ToggleWalletStatus
} from "app/store/models/request";
import { AxiosResponse } from "axios";
import instance from "data/axios-setup";

class RequestService {
  async getAllSendMoney(params: {
    page: number;
    status: string;
    perpage: number;
  }): Promise<
    AxiosResponse<{
      message: string;
      data: ISendMoney[];
      pagination: PaginationState;
    }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get("admin/send-money", { params });
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getAllFiatWallets(params: {
    page: number;
    perpage: number;
    active: boolean;
  }): Promise<
    AxiosResponse<{
      message: string;
      data: IFiatWallet[];
      pagination: PaginationState;
    }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get("admin/fiat-wallets", { params });
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getBannedWallet(params: { walletId: number }): Promise<
    AxiosResponse<{
      data: IFiatWallet[];
    }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get("admin/banned-wallets", { params });
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }

  async toggleFiatWallets(
    id: number,
    status: ToggleWalletStatus,
    reason: string
  ): Promise<
    AxiosResponse<{
      message: string;
    }>
  > {
    return new Promise(async (resolve, reject) => {
      const url = `admin/fiat-wallets/${id}/${status}`;
      try {
        const response = await instance.put(url, { reason });
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getAllCryptoWallets(params: {
    page: number;
    perpage: number;
    active: boolean;
  }): Promise<
    AxiosResponse<{
      message: string;
      data: ICryptoWallet[];
      pagination: PaginationState;
    }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get("admin/crypto-wallets", { params });
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getAllWithdrawals(params: {
    page: number;
    perpage: number;
    status: ProcessWithdrawalStatus | "";
  }): Promise<
    AxiosResponse<{
      message: string;
      data: IWithdrawal[];
      pagination: PaginationState;
    }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get("admin/withdrawals", { params });
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }

  async processWithdrawal(
    id: number,
    body: { status: ProcessWithdrawalStatus; reason: string }
  ): Promise<
    AxiosResponse<{
      state: string;
      message: string;
    }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `admin/withdrawals/${id}`;
        const response = await instance.put(url, body);
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }

  async processingWithdrawal(id: number): Promise<
    AxiosResponse<{
      message: string;
    }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `admin/withdrawals/${id}`;
        const response = await instance.patch(url);
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }

  async processSendMoney(
    id: number,
    body: { status: ProcessSendMoneyStatus; reason: string }
  ): Promise<
    AxiosResponse<{
      message: string;
    }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `admin/send-money/${id}`;
        const response = await instance.put(url, body);
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }
}

export default new RequestService();
