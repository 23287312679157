import React from "react";
import { Tr, Td, Skeleton } from "@chakra-ui/react";

const TeamTableSkeletion = () => {
  return (
    <React.Fragment>
      {[1, 2, 3, 4, 5].map((key) => (
        <>
          <Tr key={key}>
            <Td fontWeight={600}>
              <Skeleton width="100%" height="30px" />
            </Td>
            <Td fontWeight={600}>
              <Skeleton width="100%" height="30px" />
            </Td>
            <Td fontSize="14px">
              <Skeleton width="100%" height="30px" />
            </Td>
            <Td fontSize="14px" color="##808899">
              <Skeleton width="100%" height="30px" />
            </Td>
            <Td fontSize="14px" color="##808899">
              <Skeleton width="100%" height="30px" />
            </Td>
          </Tr>
        </>
      ))}
    </React.Fragment>
  );
};

export default TeamTableSkeletion;
