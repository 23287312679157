import { useToast } from "@chakra-ui/react";

export const useCopyToClipboard = () => {
  const toast = useToast();

  const copyHandler = (data: string, copyTitle: string) => {
    navigator.clipboard.writeText(data);
    toast({
      title: `${copyTitle} Copied`,
      description: data,
      status: "info",
      variant: "subtle",
      position: "top-right",
      duration: 3000,
      isClosable: true
    });
  };

  return copyHandler;
};
