export type PaginationState = {
  currentPage: number;
  hasNext?: boolean;
  hasPrevious?: boolean;
  lastPage?: number;
  next?: number;
  pageSize?: number;
  prevPage?: number;
  total?: number;
};

export interface UserModel {
  email: string | null;
  username: string | null;
  lastName: string | null;
  firstName: string | null;
  phone: string | null;
}

export interface CurrenciesModel {
  id: number;
  name: string;
  code: string;
  symbol: string;
}

export interface FiatWalletData {
  id: number;
  userId: number;
  createdAt: string;
  updatedAt: string;
  balance: number;
  ledgerBalance: number;
  lockedBalance: number;
  active: boolean;
  lastDeposit: number;
  lastWithdrawal: number;
  currency: string;
  country: string | null;
  abbr: string | null;
  currencyId: number;
  user: UserModel | null;
  currencies: CurrenciesModel | null;
}

export interface AllFiatWalletState {
  pagination: PaginationState;
  data: FiatWalletData[];
  loading: boolean;
}

export interface SingleFiatWalletState {
  data: FiatWalletData | null;
  loading: boolean;
}

export interface CryptoWalletData {
  id: number;
  userId: string;
  address: string;
  cointype: string;
  tagNumber: number | null;
  memo: string | null;
  qrcode: string;
  deletedAt: string | null;
  createdAt: string;
  updatedAt: string;
  balance: number;
  network: string;
  user: UserModel;
}

export interface LienData {
  id: number;
  userId: number;
  status: string;
  balance: number;
  walletId: string;
  currency: string;
  createdAt: string;
  updatedAt: string;
  description: string;
  lastDeposit: number;
  lastWithdrawal: number;
}

export interface AllCryptoWalletState {
  pagination: PaginationState;
  data: CryptoWalletData[];
  loading: boolean;
}

export interface SingleCryptoWalletState {
  data: CryptoWalletData | null;
  loading: boolean;
}

export interface WalletsState {
  allFiatWallet: AllFiatWalletState;
  allCryptoWallet: AllCryptoWalletState;
  singleFiatWallet: SingleFiatWalletState;
  singleCryptoWallet: SingleCryptoWalletState;
  lien: {
    data: LienData[];
    loading: boolean;
    pagination: PaginationState;
  };
}

export interface AddLien {
  userId: number;
  amount: number;
  walletId: number;
  currency: string;
  description: string;
}

export enum WalletCategory {
  FIAT_WALLLET = "fiat",
  CRYPTO_WALLET = "crypto"
}
