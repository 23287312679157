import * as FileSaver from "file-saver";
import { useCallback } from "react";
import toast from "react-hot-toast";
import * as XLSX from "xlsx";

interface Props {
  apiData: any[];
  fileName: string;
}

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

export const useExportToCSV = ({ apiData, fileName }: Props) => {
  const exportToCSV = useCallback(async () => {
    if (!apiData.length) {
      toast.error("No Transactions to generate excel sheet for");
      return;
    }
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }, [apiData, fileName]);

  return exportToCSV;
};
