import {
  Td,
  Th,
  Tr,
  Box,
  Table,
  Tbody,
  Thead,
  TableContainer,
  Stack,
  Icon,
  Text
} from "@chakra-ui/react";
import React from "react";
import WithdrawalSkeleton from "./skeleton";
import NoData from "views/components/no-data";
import { usePagination } from "@ajna/pagination";
import getSymbolFromCurrency from "currency-symbol-map";
import { useAppDispatch, useAppSelector, useCopyToClipboard } from "app/hooks";
import { getAllMerchantPaymentTransactions } from "app/store";
import PaginationWrapper from "views/components/PaginationWrapper/PaginationWrapper";
import { formatFullDateToUsersLocale } from "data/utils/date-formatter-users-locale";
import { ChangeraTransactionType } from "app/store/models/transactions";
import { IoMdCopy } from "react-icons/io";

const head = [
  "Transaction Ref",
  "Received From",
  "Mode of Payment",
  "Receiving Wallet",
  "Amount",
  "Transaction Remark",
  "Date"
];

const Withdrawals = () => {
  const dispatch = useAppDispatch();
  const copyHandler = useCopyToClipboard();
  const [data, loading, pagination] = useAppSelector((state) => [
    state.bitmamaMerchants.paymentTransactions.data,
    state.bitmamaMerchants.paymentTransactions.loading,
    state.bitmamaMerchants.paymentTransactions.pagination
  ]);

  const {
    pages,
    offset,
    pageSize,
    pagesCount,
    isDisabled,
    currentPage,
    setCurrentPage
  } = usePagination({
    total: pagination?.total,
    limits: { inner: 2, outer: 1 },
    initialState: { currentPage: 1, pageSize: 10 }
  });

  const handlePageChange = (nextPage: number) => {
    setCurrentPage(nextPage);
    setTimeout(() => {
      window.scrollTo({ top: 10, behavior: "smooth" });
    }, 500);
  };

  React.useEffect(() => {
    dispatch(
      getAllMerchantPaymentTransactions({
        perpage: 10,
        page: currentPage,
        changeraTransactionType:
          ChangeraTransactionType.BITMAMA_MERCHANT_DEPOSIT
      })
    );
  }, [currentPage]);

  return (
    <React.Fragment>
      <TableContainer>
        <Table variant="striped" colorScheme="gray">
          <Thead>
            <Tr>
              {head.map((data) => (
                <Th
                  key={data}
                  fontSize={16}
                  color="gray.800"
                  fontWeight={500}
                  lineHeight="19px"
                  letterSpacing="0px"
                  textTransform="unset"
                >
                  {data}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {loading ? <WithdrawalSkeleton /> : null}

            {!!data && !loading
              ? data?.map((d) => (
                  <Tr key={d.id}>
                    <Td>
                      <Stack spacing="2" direction="row" alignItems="center">
                        <Text maxWidth="250px" className="truncate">
                          {d?.reference || "--"}
                        </Text>
                        {d?.reference && (
                          <Icon
                            w={5}
                            h={5}
                            as={IoMdCopy}
                            color="success.300"
                            cursor={"pointer"}
                            onClick={() =>
                              copyHandler(
                                d?.reference || "",
                                "Transaction Reference"
                              )
                            }
                          />
                        )}
                      </Stack>
                    </Td>

                    <Td>
                      <Box
                        mb={1}
                        fontSize={14}
                        color="gray.900"
                        fontWeight={500}
                        lineHeight="17px"
                        letterSpacing={0}
                      >
                        {d?.sourceAccountName || ""}
                      </Box>
                      <Box
                        mb={1}
                        fontSize={14}
                        color="gray.900"
                        fontWeight={500}
                        lineHeight="17px"
                        letterSpacing={0}
                      >
                        {d?.sourceAccountNumber || "--"}
                      </Box>
                      <Box color="gray.600" fontSize={13} lineHeight="14px">
                        {d?.sourceBankName || ""}
                      </Box>
                    </Td>

                    <Td textTransform="capitalize">{d?.paymentGateway}</Td>

                    <Td>{d?.currency?.toUpperCase()} Wallet</Td>

                    <Td
                      fontSize={16}
                      fontWeight={500}
                      lineHeight="19px"
                      letterSpacing="0px"
                      color={
                        d?.type === "credit"
                          ? "success.500"
                          : d?.type === "debit"
                          ? "error.500"
                          : "gray.900"
                      }
                    >
                      {getSymbolFromCurrency(d?.currency as any)}{" "}
                      {d?.amount?.toLocaleString()}
                    </Td>

                    <Td>
                      <Stack spacing="2" direction="row" alignItems="center">
                        <Text maxWidth="250px" className="truncate">
                          {d?.tranRemarks || "--"}
                        </Text>
                        {d?.tranRemarks && (
                          <Icon
                            w={5}
                            h={5}
                            as={IoMdCopy}
                            color="success.300"
                            cursor={"pointer"}
                            onClick={() =>
                              copyHandler(
                                d?.tranRemarks || "",
                                "Transaction Remarks"
                              )
                            }
                          />
                        )}
                      </Stack>
                    </Td>

                    <Td color="gray.500" lineHeight="18px" fontSize={14}>
                      {formatFullDateToUsersLocale(d?.createdAt)}
                    </Td>
                  </Tr>
                ))
              : null}

            {!data?.length && !loading ? (
              <Tr>
                <Td colSpan={7} background="transparent" h="xl">
                  <NoData text={`No Transactions Yet`} />
                </Td>
              </Tr>
            ) : null}
          </Tbody>
        </Table>
      </TableContainer>

      <PaginationWrapper
        pages={pages}
        size={pageSize}
        offset={offset}
        isDisabled={isDisabled}
        pagesCount={pagesCount}
        currentPage={currentPage}
        totalDataCount={pagination?.total}
        handlePageChange={handlePageChange}
      />
    </React.Fragment>
  );
};

export default Withdrawals;
