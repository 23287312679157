import { Box, Divider, Flex, Heading, Icon, Stack } from "@chakra-ui/react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import TabLink from "views/components/links/tab-link";
import { HiOutlineStar } from "react-icons/hi";
import { TbFilter } from "react-icons/tb";
import FiatWalletsTableContainer from "./fiat-wallet-table-container";
import { useAppSelector } from "app/hooks";
import { WalletCategory } from "app/store/models/wallets";
import CryptoWalletsTableContainer from "./crypto-wallet-table-container";

const WalletsTable = () => {
  const [fiatCount, cryptoCount] = useAppSelector((state) => [
    state.wallet.allFiatWallet.pagination.total,
    state.wallet.allCryptoWallet.pagination.total
  ]);
  // tab and url controllers
  let [searchParams, setSearchParams] = useSearchParams();
  let walletCategory = searchParams.get("wallet-category") as WalletCategory;

  useEffect(() => {
    if (!walletCategory) {
      setSearchParams({ "wallet-category": WalletCategory.FIAT_WALLLET });
    }
  }, [searchParams]);

  return (
    <>
      <Box bg="#fff" borderRadius="16px" padding={"1rem 1rem 24px"}>
        <Heading
          as={"h3"}
          fontSize="24px"
          fontWeight={"medium"}
          lineHeight="28px"
          py="4"
          px="2"
        >
          Wallets
        </Heading>
        <Flex direction={"row"} justifyContent={"space-between"} px="2" py="2">
          <Stack spacing={"5"} direction="row">
            <TabLink
              urlParamKey={"wallet-category"}
              urlParamValue={WalletCategory.FIAT_WALLLET}
              count={fiatCount}
            >
              Fiat Wallet
            </TabLink>
            <TabLink
              urlParamKey={"wallet-category"}
              urlParamValue={WalletCategory.CRYPTO_WALLET}
              count={cryptoCount}
            >
              Crypto Wallet
            </TabLink>
          </Stack>
          <Stack direction={"row"} color="gray.600">
            <Icon as={HiOutlineStar} w={"5"} h={"5"} cursor="pointer" />
            <Icon as={TbFilter} w={"5"} h={"5"} cursor="pointer" />
          </Stack>
        </Flex>
        <Divider />
        {walletCategory === WalletCategory.FIAT_WALLLET ? (
          <FiatWalletsTableContainer />
        ) : walletCategory === WalletCategory.CRYPTO_WALLET ? (
          <CryptoWalletsTableContainer />
        ) : null}
      </Box>
    </>
  );
};

export default WalletsTable;
