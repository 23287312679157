import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  PaginationState,
  TransactionsResponse,
  TransactionsState
} from "../models/transactions";

const pagination: PaginationState = {
  hasPrevious: false,
  prevPage: 0,
  hasNext: true,
  next: 2,
  currentPage: 1,
  pageSize: 10,
  lastPage: 0,
  total: 0
};

const initialState: TransactionsState = {
  allTransactions: {
    data: [],
    loading: false,
    pagination
  },
  userTransactions: {
    data: [],
    loading: false,
    pagination
  },
  walletTransactions: {
    data: [],
    loading: false,
    pagination
  },
  vCardTransactions: {
    data: [],
    loading: false,
    pagination
  }
};

export const TransactionSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    getAllTransactionsBegin: (state) => {
      state.allTransactions.loading = true;
    },
    getAllTransactionsSuccess: (
      state,
      actions: PayloadAction<{
        data: TransactionsResponse[];
        pagination: PaginationState;
      }>
    ) => {
      state.allTransactions.data = actions.payload.data;
      state.allTransactions.pagination = actions.payload.pagination;
      state.allTransactions.loading = false;
    },
    getAllTransactionsFailed: (state) => {
      state.allTransactions.loading = false;
    },
    getUserTransactionsBegin: (state) => {
      state.userTransactions.loading = true;
    },
    getUserTransactionsSuccess: (
      state,
      actions: PayloadAction<{
        data: TransactionsResponse[];
        pagination: PaginationState;
      }>
    ) => {
      state.userTransactions.data = actions.payload.data;
      state.userTransactions.pagination = actions.payload.pagination;
      state.userTransactions.loading = false;
    },
    getUserTransactionsFailed: (state) => {
      state.vCardTransactions.loading = false;
    },
    getVCardTransactionsBegin: (state) => {
      state.vCardTransactions.loading = true;
    },
    getVCardTransactionsSuccess: (
      state,
      actions: PayloadAction<{
        data: TransactionsResponse[];
        pagination: PaginationState;
      }>
    ) => {
      state.vCardTransactions.data = actions.payload.data;
      state.vCardTransactions.pagination = actions.payload.pagination;
      state.vCardTransactions.loading = false;
    },
    getVCardTransactionsFailed: (state) => {
      state.vCardTransactions.loading = false;
    },
    getWalletTransactionsBegin: (state) => {
      state.walletTransactions.loading = true;
    },
    getWalletTransactionsSuccess: (
      state,
      actions: PayloadAction<{
        data: TransactionsResponse[];
        pagination: PaginationState;
      }>
    ) => {
      state.walletTransactions.data = actions.payload.data;
      state.walletTransactions.pagination = actions.payload.pagination;
      state.walletTransactions.loading = false;
    },
    getWalletTransactionsFailed: (state) => {
      state.walletTransactions.loading = false;
    }
  }
});

export const actions = TransactionSlice.actions;

export default TransactionSlice.reducer;
