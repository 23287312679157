import { AxiosError } from "axios";
import toast from "react-hot-toast";
import { Dispatch } from "@reduxjs/toolkit";
import { actions } from "../reducers/transactions.reducer";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";
import transactionsService from "data/services/transactions-service";
import { ChangeraTransactionType } from "../models/transactions";

export const getAllTransactions = (params: {
  page: number;
  perpage: number;
  q?: string;
  changeraTransactionType: ChangeraTransactionType;
  walletId?: number;
  userId?: number;
  status?: string;
}) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getAllTransactionsBegin());
      const res = await transactionsService.getAllTransactions(params);
      if (res.status === 200 || 201 || 202) {
        dispatch(
          actions.getAllTransactionsSuccess({
            data: res.data.data,
            pagination: res.data.pagination
          })
        );
      }
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.getAllTransactionsFailed());
      toast.error(msg);
    }
  };
};

export const getUserTransactions = (params: {
  page: number;
  perpage: number;
  q?: string;
  changeraTransactionType?: ChangeraTransactionType;
  userId: number;
}) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getUserTransactionsBegin());
      const res = await transactionsService.getAllTransactions(params);
      if (res.status === 200 || 201 || 202) {
        dispatch(
          actions.getUserTransactionsSuccess({
            data: res.data.data,
            pagination: res.data.pagination
          })
        );
      }
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.getUserTransactionsFailed());
      toast.error(msg);
    }
  };
};

export const getWalletTransactions = (params: {
  page: number;
  perpage: number;
  q?: string;
  isBitmama?: boolean;
  changeraTransactionType: ChangeraTransactionType;
  walletId: number;
}) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getWalletTransactionsBegin());
      const res = await transactionsService.getAllTransactions(params);
      if (res.status === 200 || 201 || 202) {
        dispatch(
          actions.getWalletTransactionsSuccess({
            data: res.data.data,
            pagination: res.data.pagination
          })
        );
      }
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.getWalletTransactionsFailed());
      toast.error(msg);
    }
  };
};

export const getVCardTransactions = (
  params: {
    page: number;
    perpage: number;
    q?: string;
    changeraTransactionType: ChangeraTransactionType;
    virtualCardId: number;
  },
  signal?: AbortSignal
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getVCardTransactionsBegin());
      const res = await transactionsService.getAllTransactions(params, signal);
      if (res.status === 200 || 201 || 202) {
        dispatch(
          actions.getVCardTransactionsSuccess({
            data: res.data.data,
            pagination: res.data.pagination
          })
        );
      }
    } catch (err: any) {
      if (err?.code === "ERR_CANCELED") return;
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.getVCardTransactionsFailed());
      toast.error(msg);
    }
  };
};
