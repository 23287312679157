import { Dispatch } from "@reduxjs/toolkit";
import {
  error,
  loadingBegin,
  loginSuccess,
  logoutBegin,
  loadingStop,
  logoutSuccess,
  set2FA,
  settwoFAStep
} from "../reducers/auth.reducer";
import { removeToken, saveToken } from "data/utils/localStorage";
import toast from "react-hot-toast";
import { NavigateFunction } from "react-router-dom";
import QRCode from "qrcode";
import authService from "data/services/auth-service";

export const generate2FA = (email: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(loadingBegin());
      const res = await authService.generate2FA({ email });
      // convert to url to qrcode
      QRCode.toDataURL(res.data.data.url as string, function (err, url) {
        if (err) throw err;
        dispatch(set2FA({ url, secret: res.data.data.secret }));
      });
      dispatch(settwoFAStep(1));
      dispatch(loadingStop());
    } catch (err: any) {
      dispatch(error());
      toast.error(err.response.data.message);
    }
  };
};

export const login2FA = (
  navigate: NavigateFunction,
  data: {
    email: string;
    code: string;
  }
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(loadingBegin());

      const res = await authService.twoFALogin(data);

      saveToken(res.data.token);
      dispatch(loginSuccess(true));
      navigate("/", { replace: true });
    } catch (err: any) {
      dispatch(error());
      toast.error(err.response.data.message);
    }
  };
};

export const logOut = (navigate: NavigateFunction) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(logoutBegin());
      dispatch(logoutSuccess());
      removeToken();
      navigate("/auth/login", { replace: true });
      window.location.reload();
    } catch (err) {
      dispatch(error());
    }
  };
};
