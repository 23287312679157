import {
  Td,
  Th,
  Tr,
  Box,
  Table,
  Tbody,
  Thead,
  TableContainer,
  Stack,
  Select
} from "@chakra-ui/react";
import React from "react";
import { getAllSendMoney } from "app/store";
import SendMoneyDetailsModal from "./details";
import { usePagination } from "@ajna/pagination";
import getSymbolFromCurrency from "currency-symbol-map";
import { TransactionBadge } from "views/components/badge";
import { useAppDispatch, useAppSelector } from "app/hooks";
import PaginationWrapper from "views/components/PaginationWrapper/PaginationWrapper";
import { ISendMoney, ProcessWithdrawalStatus } from "app/store/models/request";
import { useUserDetails } from "app/hooks/use-user-details";
import { actions } from "app/store/reducers/request.reducer";
import { formatFullDateToUsersLocale } from "data/utils/date-formatter-users-locale";

const head = [
  "User Id",
  "Username",
  "Account Name",
  "Bank Details",
  "Mobile Money Details",
  "Recipient Username",
  "Recipient Details",
  "Withdrawal Mode",
  "Source Amount",
  "Destination Amount",
  "Withdrawal Fee",
  "Changera Plan",
  "Date",
  "Status"
];

const SendMoney = () => {
  const dispatch = useAppDispatch();
  const [status, sendMoney, sendMoneyStatus] = useAppSelector((state) => [
    state.request.status,
    state.request.sendMoney,
    state.request.sendMoney.status
  ]);
  const [selected, setSelected] = React.useState<null | ISendMoney>(null);

  const {
    pages,
    offset,
    pageSize,
    pagesCount,
    isDisabled,
    currentPage,
    setCurrentPage
  } = usePagination({
    limits: { inner: 2, outer: 1 },
    total: sendMoney.pagination.total,
    initialState: { currentPage: 1, pageSize: 10 }
  });

  const handleCloseDetails = () => {
    setSelected(null);
  };

  const handleSetSendMoney = (data: ISendMoney) => {
    setSelected(data);
  };

  const handlePageChange = (nextPage: number) => {
    setCurrentPage(nextPage);
    setTimeout(() => {
      window.scrollTo({ top: 10, behavior: "smooth" });
    }, 500);
  };

  React.useEffect(() => {
    dispatch(
      getAllSendMoney({
        page: currentPage,
        perpage: pageSize,
        status: sendMoneyStatus
      })
    );
  }, [currentPage, sendMoneyStatus]);

  React.useEffect(() => {
    handlePageChange(1);
  }, [sendMoneyStatus]);

  const { onOpenUserDetails, UserDetail } = useUserDetails();

  if (status.getAllSendMoney === "loading") return <p>Loading...</p>;

  return (
    <React.Fragment>
      <SendMoneyDetailsModal data={selected} onClose={handleCloseDetails} />
      <Stack mt="0.75rem" ml="2" spacing="4" direction={"row"}>
        <Box mb="12px">
          <Select
            width="120px"
            defaultValue={""}
            placeholder="status"
            value={sendMoneyStatus}
            onChange={(e) =>
              dispatch(
                actions.setSendMoneyStatus(
                  e.target.value as ProcessWithdrawalStatus
                )
              )
            }
          >
            <option value={ProcessWithdrawalStatus.approve}>Approved</option>
            <option value={ProcessWithdrawalStatus.deny}>Denied</option>
            <option value={ProcessWithdrawalStatus.pending}>Pending</option>
            <option value={ProcessWithdrawalStatus.processing}>
              Processing
            </option>
            <option value={ProcessWithdrawalStatus.cancelled}>Cancelled</option>
          </Select>
        </Box>
      </Stack>
      <TableContainer>
        <Table variant="striped" colorScheme="gray">
          <Thead>
            <Tr>
              {head.map((data) => (
                <Th
                  key={data}
                  fontSize={16}
                  color="gray.800"
                  fontWeight={500}
                  lineHeight="19px"
                  letterSpacing="0px"
                  textTransform="unset"
                >
                  {data}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {sendMoney?.data?.map((data) => (
              <Tr key={data.id}>
                <Td
                  cursor="pointer"
                  _hover={{ color: "#B1B4BF" }}
                  onClick={() => onOpenUserDetails(data.userId)}
                >
                  {data.userId || "-"}
                </Td>
                <Td>{data.user.username || "-"}</Td>
                <Td>{data.recipientBankAccountName || "-"}</Td>
                <Td>
                  <Box
                    mb={1}
                    fontSize={14}
                    color="gray.900"
                    fontWeight={500}
                    lineHeight="17px"
                    letterSpacing={0}
                  >
                    {data.recipientBankAccountNumber || "-"}
                  </Box>
                  <Box color="gray.600" fontSize={13} lineHeight="14px">
                    {data.recipientBankName}
                  </Box>
                </Td>
                <Td>
                  <Box
                    mb={1}
                    fontSize={14}
                    color="gray.900"
                    fontWeight={500}
                    lineHeight="17px"
                    letterSpacing={0}
                  >
                    {data.mobileMoneyNetwork ? data.recipientPhoneNumber : "--"}
                  </Box>
                  <Box color="gray.600" fontSize={13} lineHeight="14px">
                    {data.mobileMoneyNetwork || "--"}
                  </Box>
                </Td>
                <Td textTransform={"capitalize"}>
                  {data?.recipientUsername || "--"}
                </Td>
                <Td>
                  <Box
                    mb={1}
                    fontSize={14}
                    color="gray.900"
                    fontWeight={500}
                    lineHeight="17px"
                    letterSpacing={0}
                  >
                    {data.recipientEmail || "--"}
                  </Box>
                  <Box color="gray.600" fontSize={13} lineHeight="14px">
                    {data.recipientPhoneNumber || "--"}
                  </Box>
                </Td>
                <Td>{data.type}</Td>
                <Td
                  fontSize={16}
                  color="gray.900"
                  fontWeight={500}
                  letterSpacing={0}
                  lineHeight="19px"
                >
                  {getSymbolFromCurrency(data.currency)}{" "}
                  {data && data.amount ? data.amount.toLocaleString() : ""}
                </Td>
                <Td
                  fontSize={16}
                  color="gray.900"
                  fontWeight={500}
                  lineHeight="19px"
                  letterSpacing="0px"
                >
                  {getSymbolFromCurrency(data.recipientCurrency)}{" "}
                  {data && data.recipientAmount
                    ? data.recipientAmount.toLocaleString()
                    : ""}
                </Td>
                <Td color="error.500" lineHeight="18px" fontSize={14}>
                  {getSymbolFromCurrency(data.recipientCurrency)}{" "}
                  {data && data.fee ? data.fee.toLocaleString() : 0}
                </Td>
                <Td>{data.user.plan}</Td>

                <Td color="gray.500" lineHeight="18px" fontSize={14}>
                  {formatFullDateToUsersLocale(data.createdAt)}
                </Td>
                <Td>
                  <TransactionBadge
                    cursor="pointer"
                    onClick={() => handleSetSendMoney(data)}
                    children={data.status}
                    variant={data.status as any}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      <PaginationWrapper
        pages={pages}
        size={pageSize}
        offset={offset}
        isDisabled={isDisabled}
        pagesCount={pagesCount}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        totalDataCount={sendMoney.pagination?.total}
      />

      {false && UserDetail}
    </React.Fragment>
  );
};

export default SendMoney;
