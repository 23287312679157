import { useEffect } from "react";
import { Box, Heading } from "@chakra-ui/react";
import { usePagination } from "@ajna/pagination";
import { getAllExchangeRatesV4 } from "app/store";
import ExchangeRateTable from "./exchange-rate-table";
import { useAppDispatch, useAppSelector } from "app/hooks";
import PaginationWrapper from "views/components/PaginationWrapper/PaginationWrapper";

const ExchangeRateTableContainer = () => {
  const dispatch = useAppDispatch();

  const [allExchangeRate] = useAppSelector((state) => [
    state.exchangeRate.allExchangeRate
  ]);

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, offset } =
    usePagination({
      limits: { inner: 2, outer: 1 },
      total: allExchangeRate?.pagination?.total,
      initialState: { currentPage: 1, pageSize: 10 }
    });

  const handlePageChange = (nextPage: number) => {
    setCurrentPage(nextPage);
    setTimeout(() => {
      window.scrollTo({ top: 10, behavior: "smooth" });
    }, 500);
  };

  useEffect(() => {
    dispatch(getAllExchangeRatesV4({ page: currentPage }));
  }, [currentPage]);

  return (
    <Box bg="#fff" borderRadius="16px" padding={"1rem 1rem 24px"}>
      <Heading
        as={"h3"}
        fontSize="24px"
        fontWeight={"medium"}
        lineHeight="28px"
        py="4"
        px="2"
      >
        Exchange Rates
      </Heading>
      <ExchangeRateTable />

      <PaginationWrapper
        size={10}
        pages={pages}
        offset={offset}
        isDisabled={isDisabled}
        pagesCount={pagesCount}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        totalDataCount={allExchangeRate?.pagination?.total}
      />
    </Box>
  );
};

export default ExchangeRateTableContainer;
