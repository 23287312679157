import { Box, Divider, Stack } from "@chakra-ui/react";
import { useAppSelector } from "app/hooks";
import { WalletCategory } from "app/store/models/wallets";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import CryptoWalletsTableContainer from "views/containers/wallets/tables/crypto-wallet-table-container";
import FiatWalletsTableContainer from "views/containers/wallets/tables/fiat-wallet-table-container";
import TabLink from "../../../components/links/tab-link";

const UserWallets = () => {
  //
  const [fiatCount, cryptoCount] = useAppSelector((state) => [
    state.wallet.allFiatWallet.pagination.total,
    state.wallet.allCryptoWallet.pagination.total
  ]);

  //
  let [searchParams, setSearchParams] = useSearchParams();
  const userDetailParamValue = searchParams.get("user-detail");
  const userParamValue = searchParams.get("user-id");
  let walletCategory = searchParams.get("wallet-category") as WalletCategory;

  useEffect(() => {
    if (!!userParamValue && !!userDetailParamValue && !walletCategory) {
      searchParams.set("wallet-category", "fiat");
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  return (
    <Box py="2">
      <Stack spacing={"5"} direction="row" pb="1" pt={"3"}>
        <TabLink
          urlParamKey={"wallet-category"}
          urlParamValue={"fiat"}
          count={fiatCount}
        >
          Fiat Wallet
        </TabLink>
        <TabLink
          urlParamKey={"wallet-category"}
          urlParamValue={"crypto"}
          count={cryptoCount}
        >
          Crypto Wallet
        </TabLink>
      </Stack>
      <Divider />
      {walletCategory === WalletCategory.FIAT_WALLLET ? (
        <FiatWalletsTableContainer />
      ) : walletCategory === WalletCategory.CRYPTO_WALLET ? (
        <CryptoWalletsTableContainer />
      ) : null}
    </Box>
  );
};

export default UserWallets;
