import React from "react";
import { getSummary } from "app/store";
import { useAppDispatch } from "app/hooks";
import DashboardContainer from "views/containers/dashboard";

const Dashboard = () => {
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(getSummary());
  }, []);

  return <DashboardContainer />;
};

export default Dashboard;
