import {
  WithdrawBody,
  GetAllMerchantaParams,
  GetAllMerchantTransactionsParams
} from "../models/bitmama-merchant";
import { AxiosError } from "axios";
import toast from "react-hot-toast";
import { Dispatch } from "@reduxjs/toolkit";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";
import { actions } from "../reducers/bitmama-merchants.reducer";
import bitmamaMerchantService from "data/services/bitmama-merchant.service";

export const getAllMerchants = (params: GetAllMerchantaParams) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getAllMerchantsLoading());
      const res = await bitmamaMerchantService.getAllMerchants(params);
      dispatch(
        actions.getAllMerchantsSuccess({
          data: res.data.data,
          pagination: res.data.pagination
        })
      );
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.getAllMerchantsFailed());
    }
  };
};

export const getAllMerchantWithdrawalTransactions = (
  params: GetAllMerchantTransactionsParams
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getAllMerchantWithdrawalTransactionsLoading());
      const res =
        await bitmamaMerchantService.getAllMerchantWithdrawalTransactions(
          params
        );
      dispatch(
        actions.getAllMerchantWithdrawalTransactionsSuccess({
          data: res.data.data,
          pagination: res.data.pagination
        })
      );
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.getAllMerchantWithdrawalTransactionsFailed());
    }
  };
};

export const getAllMerchantPaymentTransactions = (
  params: GetAllMerchantTransactionsParams
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getAllMerchantPaymentTransactionsLoading());
      const res =
        await bitmamaMerchantService.getAllMerchantPaymentTransactions(params);
      dispatch(
        actions.getAllMerchantPaymentTransactionsSuccess({
          data: res.data.data,
          pagination: res.data.pagination
        })
      );
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.getAllMerchantPaymentTransctionsFailed());
    }
  };
};

export const getBanks = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getBanksLoading());
      const res = await bitmamaMerchantService.getBanks();
      dispatch(actions.getBankSuccess({ data: res.data.data }));
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.getBanksFailed());
    }
  };
};

export const resolveBankName = (body: {
  bankCode: string;
  accountNumber: string;
}) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.resolveBankNameLoading());
      const res = await bitmamaMerchantService.resolveBankName(body);
      dispatch(actions.resolveBankNameSuccess(res.data));
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.resolveBankNameFailed());
    }
  };
};

export const withdraw = (
  id: number,
  body: WithdrawBody,
  callback?: () => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.withdrawLoading());
      const res = await bitmamaMerchantService.withdraw(id, body);
      dispatch(actions.withdrawSuccess());
      toast.success(res.data.message);
      callback && callback();
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.withdrawFailed());
    }
  };
};

export const resetResolveBankName = () => {
  return (dispatch: Dispatch) => {
    dispatch(actions.resolveBankNameFailed());
  };
};
