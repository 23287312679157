import {
  CampaignData,
  CampaignState,
  PaginationState
} from "../models/campaign";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: CampaignState = {
  imageUrl: "",
  addCampaignLoading: false,
  uploadImageLoading: false,

  allCampaigns: {
    data: [],
    status: "",
    loading: false,
    pagination: {
      next: 2,
      total: 0,
      prevPage: 0,
      lastPage: 0,
      pageSize: 10,
      hasNext: true,
      currentPage: 1,
      hasPrevious: false
    }
  }
};

export const CampaignReducer = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    getAllCampaignPending: (state, action: PayloadAction<string>) => {
      state.allCampaigns.loading = true;
      state.allCampaigns.status = action.payload;
    },
    getAllCampaignSuccess: (
      state,
      action: PayloadAction<{
        data: CampaignData[];
        pagination: PaginationState;
      }>
    ) => {
      state.allCampaigns.loading = false;
      state.allCampaigns.data = action.payload.data;
      state.allCampaigns.pagination = action.payload.pagination;
    },
    getAllCampaignFailed: (state) => {
      state.allCampaigns.loading = false;
    },
    addCampaignPending: (state) => {
      state.addCampaignLoading = true;
    },
    addCampaignSuccess: (state) => {
      state.imageUrl = "";
      state.addCampaignLoading = false;
    },
    addCampaignFailed: (state) => {
      state.addCampaignLoading = false;
    },
    uploadImagePending: (state) => {
      state.uploadImageLoading = true;
    },
    uploadImageSuccess: (state, action: PayloadAction<string>) => {
      state.uploadImageLoading = false;
      state.imageUrl = action.payload;
    },
    uploadImageFailed: (state) => {
      state.imageUrl = "";
      state.uploadImageLoading = false;
    }
  }
});

export const actions = CampaignReducer.actions;

export default CampaignReducer.reducer;
