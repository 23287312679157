import {
  PaginationState,
  ExchangeRateData,
  IGetExchangeRatesV4Params
} from "app/store/models/exchange-rate";
import config from "data/config";
import { AxiosResponse } from "axios";
import instance from "data/axios-setup";

class ExchangeRateService {
  async getAllExchangeRates(): Promise<
    AxiosResponse<{ data: ExchangeRateData[] }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get(`/admin/exchange-rates`);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getAllExchangeRatesV4({
    page
  }: IGetExchangeRatesV4Params): Promise<
    AxiosResponse<{ data: ExchangeRateData[]; pagination: PaginationState }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get(`/admin/exchange-rates`, {
          params: { page },
          baseURL: config.serverV4
        });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async updateExchangeRate(
    id: number,
    body: {
      buyRate: number;
      sellRate: number;
    }
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.put(`/admin/exchange-rates/${id}`, body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }
}

export default new ExchangeRateService();
