import {
  BanHistory,
  BannedUserData,
  EditUserRequestBody,
  PaginationState,
  SingleUserData,
  SubscribeUserBody,
  SubscriptionData,
  UserData
} from "app/store/models/user";
import { AxiosResponse } from "axios";
import instance from "data/axios-setup";

class UserService {
  async getAllUsers(
    params: {
      page: number;
      perpage: number;
      q?: string;
      blocked?: boolean;
    },
    signal?: AbortSignal
  ): Promise<AxiosResponse<{ data: UserData[]; pagination: PaginationState }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get(`/admin/search-users`, {
          params,
          signal
        });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getBannedUsers(params: {
    page?: number;
    perpage?: number;
    q?: string;
    type?: string;
    userId?: number;
  }): Promise<
    AxiosResponse<{ data: BannedUserData[]; pagination: PaginationState }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get(`/admin/banned-users`, {
          params
        });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getSingleUser(
    id: number,
    signal?: AbortSignal
  ): Promise<AxiosResponse<{ data: SingleUserData }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get(`/admin/users/${id}`, { signal });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getBanHistory(
    userId: number
  ): Promise<
    AxiosResponse<{ data: BanHistory[]; pagination: PaginationState }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get(`/admin/users/ban-history/${userId}`);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async toggleUser(
    id: number,
    body: { reason: string },
    status: "activate" | "deactivate"
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.put(`/admin/users/${id}/${status}`, body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async editUser(
    id: number,
    body: EditUserRequestBody
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.patch(`/admin/users/${id}`, body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getSubscriptions(
    userId: number
  ): Promise<
    AxiosResponse<{ data: SubscriptionData[]; pagination: PaginationState }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get(`/admin/subscription/${userId}`);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async cancelSubscriptions(
    id: number
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.put(`/admin/subscription/ongoing/${id}`);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async subscribeUser(
    body: SubscribeUserBody
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.post("/admin/subscription/upgrade", body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async recoverAccount(
    userId: number,
    reason: string
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.patch(
          `/admin/users/recover-account/${userId}`,
          { reason }
        );
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }
}

export default new UserService();
