import { createIcon } from "@chakra-ui/icon";

export const WalletSolidIcon = createIcon({
  viewBox: "0 0 24 24",
  displayName: "WalletSolidIcon",
  path: [
    <path
      d="M3.5 8.26898C3.31982 8.4215 3.15252 8.589 3 8.76958V7.8398C3 6.85677 3.6022 5.98367 4.51672 5.63753C4.5168 5.6375 4.51687 5.63748 4.51695 5.63745L12.4536 2.63872C12.619 2.57894 12.7902 2.5498 12.95 2.5498C13.6894 2.5498 14.35 3.15122 14.35 3.9498V7.2498H13.85V3.9498C13.85 3.64947 13.7103 3.41371 13.5289 3.26346C13.3613 3.12466 13.1491 3.0498 12.95 3.0498C12.8337 3.0498 12.7227 3.07295 12.6198 3.1134L4.6936 6.10196L4.69347 6.102C3.96479 6.37698 3.5 7.0661 3.5 7.8398V8.26898Z"
      fill="white"
      stroke="white"
    />,
    <path
      d="M18.9985 15.0517L18.9986 15.0516L18.9977 15.0418C18.9831 14.8916 19.0411 14.746 19.1435 14.6436L19.1437 14.6437L19.153 14.6339C19.2304 14.5522 19.345 14.5 19.49 14.5H20.9876C20.9938 14.5006 20.9978 14.5017 21 14.5025V15.4993C20.9991 15.4995 20.9979 15.4998 20.9962 15.5H19.55C19.2416 15.5 19.0156 15.2734 18.9985 15.0517Z"
      fill="white"
      stroke="white"
    />,
    <path
      d="M4.2993 8.91053L4.29931 8.91055L4.30302 8.90772C4.84824 8.49231 5.52234 8.25 6.26 8.25H17.74C19.5339 8.25 21 9.71614 21 11.51V11.95C21 12.2239 20.7739 12.45 20.5 12.45H19.48C18.7881 12.45 18.1503 12.7222 17.6859 13.197C17.0708 13.8025 16.7564 14.7138 17.0176 15.6615C17.3373 16.8472 18.4868 17.55 19.63 17.55H20.5C20.7739 17.55 21 17.7761 21 18.05V18.24C21 20.0339 19.5339 21.5 17.74 21.5H6.26C4.46614 21.5 3 20.0339 3 18.24V11.51C3 10.4435 3.51087 9.49968 4.2993 8.91053ZM7 13.25H14C14.6861 13.25 15.25 12.6861 15.25 12C15.25 11.3139 14.6861 10.75 14 10.75H7C6.31386 10.75 5.75 11.3139 5.75 12C5.75 12.6861 6.31386 13.25 7 13.25Z"
      fill="white"
      stroke="white"
    />
  ]
});
