import {
  Text,
  Stack,
  Radio,
  Button,
  Badge,
  Popover,
  RadioGroup,
  PopoverBody,
  PopoverArrow,
  StackDivider,
  PopoverTrigger,
  PopoverContent,
  PopoverCloseButton,
  Icon
} from "@chakra-ui/react";
import React from "react";
import { getAllVirtualCards, updateVirtualCardStatus } from "app/store";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { ArrowDown2 } from "iconsax-react";

type Props = { id: number; isActive: boolean };

const UpdateCardStatus = ({ id, isActive }: Props) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(
    (state) => state.virtualCards.updateCard.loading
  );
  const [status, setStatus] = React.useState<any>();

  const callback = () => {
    dispatch(getAllVirtualCards({ page: 1, perpage: 10 }));
  };

  const handleSubmit = () => {
    const invalidStatus =
      (isActive && status === "active") || (!isActive && status === "inactive");

    if (invalidStatus) return;
    if (!id || loading || !status) return;

    dispatch(updateVirtualCardStatus(id, status, callback));
  };

  return (
    <Popover>
      <PopoverTrigger>
        <Badge
          px="3"
          py="1"
          gap="2"
          rounded="2xl"
          display="flex"
          cursor="pointer"
          alignItems="center"
          fontWeight="normal"
          textTransform="capitalize"
          justifyContent="space-between"
          colorScheme={isActive ? "green" : "red"}
          color={isActive ? "green.500" : "red.500"}
        >
          <Text>{isActive ? "Active" : "Inactive"}</Text>{" "}
          <Icon as={ArrowDown2} />
        </Badge>
      </PopoverTrigger>

      <PopoverContent width="300px" outlineColor={"none"} padding={2}>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <div className="my-1">
            <Text fontWeight="bold" color="gray.900" fontSize={"larger"}>
              Virtual Card Status
            </Text>
            <div className="mt-6">
              <RadioGroup>
                <Stack
                  divider={<StackDivider className="w-full" />}
                  mb={6}
                  spacing={3}
                >
                  <Radio
                    value="active"
                    disabled={isActive}
                    colorScheme="primary"
                    isDisabled={isActive}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <Text ml={4} fontSize={16} color="#333843">
                      Activate
                    </Text>
                  </Radio>

                  <Radio
                    value="inactive"
                    disabled={!isActive}
                    colorScheme="primary"
                    isDisabled={!isActive}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <Text ml={4} fontSize={16} color="#333843">
                      Deactivate
                    </Text>
                  </Radio>
                </Stack>
              </RadioGroup>
              <div className="w-full">
                <Button
                  w="full"
                  color="#fff"
                  fontSize={[12, 14]}
                  isLoading={loading}
                  h={["40px", "44px"]}
                  isDisabled={loading}
                  bgColor="primary.500"
                  onClick={handleSubmit}
                  _hover={{ bgColor: "primary.300" }}
                >
                  Apply
                </Button>
              </div>
            </div>
          </div>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default UpdateCardStatus;
