import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Stack,
  Text,
  Icon,
  Heading,
  FormControl,
  FormLabel,
  Textarea,
  Divider,
  Flex,
  Skeleton,
  Spinner,
  Select
} from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import { BaseModalProps } from "views/containers/user-information/models";
import { ProfileCheckOutlineIcon } from "views/components/icons";
import { IoCalendarOutline } from "react-icons/io5";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useEffect, useState } from "react";
import { formatDateToUTC } from "data/utils/formatDateToUTC";
import { VerificationDetailParam } from "../models";
import { getAllVerification, getSingleVerification } from "app/store";
import verificationService from "data/services/verification-service";
import { VerificationStatus } from "app/store/models/verification";
import toast from "react-hot-toast";
import { AxiosError } from "axios";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";

const messageTemplateList: { title: string; message: string }[] = [
  {
    title: "ID is NOT accepted",
    message: "ID is not part of the accepted IDs on Changera"
  },
  {
    title: "NO selfie",
    message: "Kindly hold your ID next to your face and upload a selfie"
  },
  {
    title: "Selfie is without ID",
    message: "Kindly hold your ID next to your face and upload a selfie"
  },
  {
    title: "Passport picture on ID is NOT clear",
    message: "Upload a valid ID document with your face showing clearly"
  },
  {
    title: "Expired ID",
    message: "Kindly upload a new ID that is valid for the next 6 months "
  },
  {
    title: "Altered document is NOT accepted",
    message:
      "Your ID document looks altered. Kindly provide a valid government issued document "
  },
  {
    title: "Document uploaded is NOT clear",
    message: "Kindly upload a clearer ID document as this one looks fuzzy."
  },
  {
    title: "Blank upload",
    message:
      "Kindly add a file to this verification when you submit it the next time"
  },
  {
    title: "Upload requires password",
    message: "Kindly upload a bank statement that is not password protected "
  },
  {
    title: "ID from this location are NOT accepted yet",
    message: "ID from this location are not accepted yet"
  },
  {
    title: "Utility bill is NOT recent",
    message: "Utility bill is not recent"
  },
  {
    title: "Address mismatch",
    message: "Address on Utility bill does not match address used to sign up"
  },
  {
    title: "NO Utility bill",
    message: "Utility bill not submitted for verification"
  }
];

const DeclinedVerification = ({ isOpen, onClose }: BaseModalProps) => {
  const dispatch = useAppDispatch();
  const [userDetails, requestor, currentPage, pageSize] = useAppSelector(
    (state) => [
      state.verification.singleVerification,
      state.dashboard.userProfile,
      state.verification.allVerification.pagination?.currentPage,
      state.verification.allVerification.pagination?.pageSize
    ]
  );

  // url params
  let [searchParams, setSearchParams] = useSearchParams();
  const verificationDetailParamValue = searchParams.get("verification-detail");
  const userId = searchParams.get("verification-id");
  const verificationStatus = searchParams.get(
    "verification-status"
  ) as VerificationStatus;

  useEffect(() => {
    if (!!userId && !verificationDetailParamValue) {
      searchParams.set(
        "verification-detail",
        VerificationDetailParam.ATTACHMENTS
      );
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  //   form state
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);

  const declineVerificationHandler = async () => {
    if (!userId || !currentPage || !pageSize || !verificationStatus) return;
    setLoading(true);
    try {
      const res = await verificationService.declineVerification(+userId, {
        reason: reason
      });
      if (res.status === 200 || 201 || 202) {
        dispatch(getSingleVerification(+userId));
        dispatch(
          getAllVerification({
            page: currentPage,
            perpage: pageSize,
            status: verificationStatus
          })
        );
        toast.success(`Verification Successfully Declined`);
        onClose();
      }
      setLoading(false);
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      setLoading(false);
    }
  };

  // fetch data
  useEffect(() => {
    if (!userId) return;
    dispatch(getSingleVerification(+userId));
  }, [userId]);

  //
  const [messageTemplate, setMessageTemplate] = useState("");

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textTransform={"capitalize"}>
            Decline Verification
          </ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody>
            {userDetails.loading ? (
              <Flex
                p="5"
                bg="primary.500"
                color={"gray.300"}
                rounded="lg"
                direction="column"
                gap={"4"}
              >
                <Skeleton h="6" />
                <Skeleton h="6" />
                <Skeleton h="6" />
              </Flex>
            ) : null}
            {!!userDetails.data && !userDetails.loading ? (
              <Stack
                p="5"
                bg="primary.500"
                color={"gray.300"}
                rounded="lg"
                spacing={"4"}
              >
                <Stack direction={"row"} justifyContent="space-between">
                  <Stack direction={"row"} alignItems="center">
                    <Icon as={ProfileCheckOutlineIcon} h={5} w={5} />
                    <Text>Declined By</Text>
                  </Stack>
                  <Text>
                    {requestor
                      ? `${requestor.firstName} ${requestor.lastName}`
                      : "--"}
                  </Text>
                </Stack>
                <Stack direction={"row"} justifyContent="space-between">
                  <Stack direction={"row"} alignItems="center">
                    <Icon as={IoCalendarOutline} h={5} w={5} />
                    <Text>Date Registered</Text>
                  </Stack>
                  <Text>
                    {userDetails.data?.createdAt
                      ? formatDateToUTC(userDetails.data.createdAt)
                      : "--"}
                  </Text>
                </Stack>
              </Stack>
            ) : null}
            <Heading
              as={"h4"}
              fontSize="24px"
              fontFamily={"karla"}
              my="5"
              fontWeight={"extrabold"}
            >
              Request Details
            </Heading>
            <Text color={"gray.600"}>Request</Text>
            <Text
              fontWeight={"medium"}
              color={"error.500"}
              textTransform="capitalize"
            >
              Decline Verification
            </Text>
            <Stack my="5" spacing={"4"}>
              <FormControl>
                <FormLabel color={"gray.600"} fontWeight="400">
                  Message Template
                </FormLabel>
                <Select
                  value={messageTemplate}
                  onChange={(e) => {
                    const { value } = e.target;
                    const val = !!value
                      ? (JSON.parse(e.target.value) as {
                          title: string;
                          message: string;
                        })
                      : null;
                    setMessageTemplate(e.target.value);
                    setReason(val?.message || "");
                  }}
                  placeholder="Select a template"
                >
                  {messageTemplateList.map((el, idx) => (
                    <option key={idx} value={JSON.stringify(el)}>
                      {el.title}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            <Stack my="5" spacing={"4"}>
              <FormControl>
                <FormLabel color={"gray.600"} fontWeight="400">
                  Reason
                </FormLabel>
                <Textarea
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                />
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              size={"lg"}
              colorScheme="red"
              px="6"
              w={"full"}
              onClick={declineVerificationHandler}
            >
              {loading ? <Spinner /> : "Decline Verification"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DeclinedVerification;
