import React from "react";
import PaymentTable from "./payment";
import WithdrawalTable from "./withdrawal";
import { useAppSelector } from "app/hooks";
import { Tabs, TabList, Tab, TabPanel, TabPanels, Tag } from "@chakra-ui/react";

const TransactionTable = () => {
  const [index, setTabIndex] = React.useState(0);
  const [paymentLength, withdrawalLength] = useAppSelector((state) => [
    state.bitmamaMerchants.paymentTransactions.pagination?.total,
    state.bitmamaMerchants.withdrawalTransactions.pagination?.total
  ]);

  return (
    <Tabs colorScheme="primary.500" onChange={(index) => setTabIndex(index)}>
      <TabList>
        <Tab>
          <span>Withdrawal</span>
          {index === 0 && (
            <Tag
              ml={1}
              fontSize={12}
              fontWeight={400}
              lineHeight="14px"
              colorScheme="error"
              variant="solid"
            >
              {withdrawalLength}
            </Tag>
          )}
        </Tab>

        <Tab>
          <span>Payment</span>
          {index === 1 && (
            <Tag
              ml={1}
              fontSize={12}
              variant="solid"
              fontWeight={400}
              lineHeight="14px"
              colorScheme="error"
            >
              {paymentLength}
            </Tag>
          )}
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel>{index === 0 && <WithdrawalTable />}</TabPanel>

        <TabPanel>{index === 1 && <PaymentTable />}</TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default TransactionTable;
