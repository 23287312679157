import { useAppSelector } from "app/hooks";
import { Box, TableContainer, Table } from "@chakra-ui/react";
import TransactionHead from "views/components/transaction/head";
import TransactionList from "views/components/transaction/list";
import TransactionToolbar from "views/components/transaction/toolbar";
import { useUserDetails } from "app/hooks/use-user-details";

const Transactions = () => {
  const transactions = useAppSelector(
    (state) => state.summary?.data?.recentTransactions
  );

  const { UserDetail } = useUserDetails();

  return (
    <>
      <Box bgColor="#fff" borderRadius="10px">
        <Box p={6}>
          <TransactionToolbar />
        </Box>
        <TableContainer>
          <Table>
            <TransactionHead
              data={[
                { name: "User" },
                { name: "Description" },
                { name: "Currency / Type" },
                { name: "Amount" },
                { name: "rate" },
                { name: "Transaction Type" },
                { name: "Wallet Id" },
                { name: "Date" },
                { name: "Status" }
              ]}
            />
            <TransactionList data={transactions || []} />
          </Table>
        </TableContainer>
      </Box>
      {UserDetail}
    </>
  );
};

export default Transactions;
