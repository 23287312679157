import { Link, Outlet } from "react-router-dom";
import { ReactComponent as Logo } from "app/assets/svg/logo.svg";

const AuthLayout = (): JSX.Element => {
  return (
    <div className="h-screen overflow-y-auto bg-primary-600">
      <div className="w-full px-4 py-8 mdx:py-16 mdx:px-16">
        <div>
          <div className="flex justify-center mdx:justify-start">
            <Link to={"/"}>
              <Logo />
            </Link>
          </div>
          <div className="flex items-center justify-center w-full mt-10 overflow-y-visible mdx:mt-12">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
