import { Box, Divider, Stack } from "@chakra-ui/react";
import { useAppSelector } from "app/hooks";
import { ChangeraTransactionType } from "app/store/models/transactions";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import UserTransactionsTableContainer from "views/containers/transactions/tables/user-transactions";
import TabLink from "../../../components/links/tab-link";

const UserTransactions = () => {
  //
  const count = useAppSelector(
    (state) => state.transactions.userTransactions.pagination.total
  );

  //
  let [searchParams, setSearchParams] = useSearchParams();
  const userDetailParamValue = searchParams.get("user-detail");
  const userParamValue = searchParams.get("user-id");
  const transactionTypeParamValue = searchParams.get("transaction-type");

  useEffect(() => {
    if (
      !!userParamValue &&
      !!userDetailParamValue &&
      !transactionTypeParamValue
    ) {
      searchParams.set("transaction-type", "all");
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  return (
    <Box py="2">
      <Stack spacing={"5"} direction="row" pb="1" pt={"3"} overflowX={"scroll"}>
        <TabLink
          count={count}
          urlParamValue="all"
          urlParamKey={"transaction-type"}
        >
          All
        </TabLink>
        <TabLink
          urlParamKey={"transaction-type"}
          urlParamValue={ChangeraTransactionType.WITHDRAWAL}
          count={count}
        >
          Withdrawal
        </TabLink>
        <TabLink
          urlParamKey={"transaction-type"}
          urlParamValue={ChangeraTransactionType.SEND_MONEY}
          count={count}
        >
          Sent
        </TabLink>
        <TabLink
          urlParamKey={"transaction-type"}
          urlParamValue={ChangeraTransactionType.FUNDING_WALLET}
          count={count}
        >
          Fiat Funding
        </TabLink>
        <TabLink
          urlParamKey={"transaction-type"}
          urlParamValue={ChangeraTransactionType.FUNDING_CRYPTO_WALLET}
          count={count}
        >
          Crypto Funding
        </TabLink>
        <TabLink
          urlParamKey={"transaction-type"}
          urlParamValue={ChangeraTransactionType.AIRTIME_VTU}
          count={count}
        >
          Airtime
        </TabLink>
        <TabLink
          urlParamKey={"transaction-type"}
          urlParamValue={ChangeraTransactionType.DATA_VTU}
          count={count}
        >
          Data
        </TabLink>
        <TabLink
          urlParamKey={"transaction-type"}
          urlParamValue={ChangeraTransactionType.CONVERSION}
          count={count}
        >
          Conversion
        </TabLink>
        <TabLink
          urlParamKey={"transaction-type"}
          urlParamValue={ChangeraTransactionType.FIAT_CONVERSION}
          count={count}
        >
          Fiat Conversion
        </TabLink>
        <TabLink
          urlParamKey={"transaction-type"}
          urlParamValue={ChangeraTransactionType.CRYPTO_CONVERSION}
          count={count}
        >
          Crypto Conversion
        </TabLink>
        <TabLink
          urlParamKey={"transaction-type"}
          urlParamValue={ChangeraTransactionType.REFERRAL}
          count={count}
        >
          Referral
        </TabLink>
        <TabLink
          urlParamKey={"transaction-type"}
          urlParamValue={ChangeraTransactionType.EARNING}
          count={count}
        >
          Earning
        </TabLink>
        <TabLink
          urlParamKey={"transaction-type"}
          urlParamValue={ChangeraTransactionType.FAUCET}
          count={count}
        >
          Faucet
        </TabLink>
        <TabLink
          urlParamKey={"transaction-type"}
          urlParamValue={ChangeraTransactionType.REWARD}
          count={count}
        >
          Reward
        </TabLink>
        <TabLink
          urlParamKey={"transaction-type"}
          urlParamValue={ChangeraTransactionType.INTER_TRANSFER}
          count={count}
        >
          Inter Transfer
        </TabLink>
        <TabLink
          urlParamKey={"transaction-type"}
          urlParamValue={ChangeraTransactionType.CARD}
          count={count}
        >
          Virtual Card
        </TabLink>
        <TabLink
          urlParamKey={"transaction-type"}
          urlParamValue={ChangeraTransactionType.PHYSICAL_CARD}
          count={count}
        >
          Physical Card
        </TabLink>
        <TabLink
          urlParamKey={"transaction-type"}
          urlParamValue={ChangeraTransactionType.SUBSCRIPTION}
          count={count}
        >
          Subscription
        </TabLink>
      </Stack>
      <Divider />
      <UserTransactionsTableContainer />
    </Box>
  );
};

export default UserTransactions;
