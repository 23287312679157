import {
  Box,
  Text,
  Modal,
  Stack,
  Select,
  Button,
  Divider,
  Textarea,
  ModalBody,
  FormLabel,
  ModalHeader,
  FormControl,
  ModalFooter,
  ModalOverlay,
  ModalContent,
  ModalCloseButton
} from "@chakra-ui/react";

const ProcessedWithdrawalModalDetails = ({ data, onClose }: any) => {
  const status = data?.status.charAt(0).toUpperCase() + data?.status.slice(1);

  return (
    <Modal isOpen={Boolean(data)} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          color="primary.500"
          lineHeight="28px"
          fontSize={24}
          fontWeight={700}
          fontFamily="Karla"
        >
          {status} Withdrawal
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box
            p={6}
            gap={3}
            color="#fff"
            display="flex"
            borderRadius={8}
            bgColor="primary.500"
            flexDirection="column"
          >
            <Stack direction="row" justifyContent="space-between">
              <Text color="gray.300">{status} By</Text>
              <Text>-</Text>
            </Stack>

            <Stack direction="row" justifyContent="space-between">
              <Text color="gray.300">Date {status}</Text>
              <Text>-</Text>
            </Stack>

            <Stack direction="row" justifyContent="space-between">
              <Text color="gray.300">Date Registered</Text>
              <Text>-</Text>
            </Stack>
          </Box>
          <Text
            mt={6}
            fontSize={24}
            color="gray.900"
            fontWeight={700}
            lineHeight="28px"
            fontFamily="Karla"
          >
            Details
          </Text>
          <Text color="gray.500" lineHeight="19px" mt={4}>
            Request
          </Text>
          <Text
            color={data?.status === "approved" ? "success.500" : "error.500"}
            lineHeight="19px"
            fontWeight={500}
            mt={2}
          >
            {status} Withdrawal
          </Text>

          <Box mt={6} display="flex" gap={4} flexDirection="column">
            <FormControl>
              <FormLabel
                mb={1}
                color="gray.600"
                lineHeight="24px"
                fontSize={14}
                fontWeight={400}
              >
                Status
              </FormLabel>
              <Select
                disabled
                height="44px"
                value={data?.status}
                colorScheme="primary.500"
                onChange={() => {}}
                focusBorderColor="primary.500"
              >
                <option value={data?.status}>{data?.status}</option>
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel
                mb={1}
                fontSize={14}
                color="gray.600"
                lineHeight="24px"
                fontWeight={400}
              >
                Please provide more information
              </FormLabel>
              <Textarea
                value=""
                disabled
                minHeight="120px"
                focusBorderColor="primary.500"
              />
            </FormControl>
          </Box>
        </ModalBody>

        <Divider mt={4} mb={1} />

        <ModalFooter>
          <Button
            mr={3}
            disabled
            width="full"
            height="56px"
            colorScheme={data?.status === "approved" ? "success" : "error"}
          >
            {data?.status === "approved" ? "Approve" : "Decline"} Withdrawal
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ProcessedWithdrawalModalDetails;
