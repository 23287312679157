import { AxiosResponse } from "axios";
import instance from "data/axios-setup";
import { IPagination } from "data/models/base";
import { IFXStatus, IGetAllFxData, IGetAllFxParams } from "app/store/models/fx";

class FxService {
  async getAllFx(
    params: IGetAllFxParams
  ): Promise<
    AxiosResponse<{ data: IGetAllFxData[]; pagination: IPagination }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get("/admin/fx/kyc", {
          params
        });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getSingleFx(
    id: number
  ): Promise<AxiosResponse<{ data: IGetAllFxData }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get(`/admin/fx/kyc/${id}`);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async updateFxStatus(
    id: number,
    status: IFXStatus
  ): Promise<AxiosResponse<any>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.put(`/admin/fx/kyc/${id}/status`, {
          status
        });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async deleteFX(id: number): Promise<AxiosResponse<any>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.put(`/admin/fx/kyc/${id}/remove`);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }
}

export default new FxService();
