import {
  PaginationState,
  VirtualAccountData
} from "app/store/models/virtual-accounts";
import { AxiosResponse } from "axios";
import instance from "data/axios-setup";

class VirtualAccountService {
  async getAllVirtualAccounts(params: {
    page: number;
    status: string;
    perpage: number;
    userId?: number;
    currency: string;
  }): Promise<
    AxiosResponse<{ data: VirtualAccountData[]; pagination: PaginationState }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get(`/admin/virtual-accounts`, {
          params
        });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }
}

export default new VirtualAccountService();
