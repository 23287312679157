import { AxiosError } from "axios";
import toast from "react-hot-toast";
import { Dispatch } from "@reduxjs/toolkit";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";
import { actions } from "../reducers/virtual-account.reducer";
import virtualAccountService from "data/services/virtual-account.service";

export const getAllVirtualAccounts = (params: {
  page: number;
  status: string;
  perpage: number;
  userId?: number;
  currency: string;
}) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getAllVirtualAccountsPending());
      const res = await virtualAccountService.getAllVirtualAccounts(params);
      if (res.status === 200 || 201 || 202) {
        dispatch(
          actions.getAllVirtualAccountsFulfilled({
            data: res.data.data,
            pagination: res.data.pagination
          })
        );
      }
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.getAllVirtualAccountsFailed());
      toast.error(msg);
    }
  };
};
