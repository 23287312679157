import routes from "app/routes";
import { Suspense } from "react";
import { useRoutes } from "react-router-dom";
import Loading from "views/components/loading";
import WrongURL from "views/components/wrong-url";

const App = (): JSX.Element => {
  var currentLocation = window.location;

  if (
    currentLocation.origin === "https://changera-admin-prod.web.app" ||
    currentLocation.hostname === "changera-admin-prod.web.app" ||
    currentLocation.host === "changera-admin-prod.web.app"
  ) {
    return <WrongURL />;
  }

  const routesHere = useRoutes(routes);

  return <Suspense fallback={<Loading />}>{routesHere}</Suspense>;
};

export default App;
