import {
  Td,
  Th,
  Tr,
  Box,
  Icon,
  Text,
  Badge,
  Table,
  Tbody,
  Thead,
  Button,
  TableContainer
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { deleteVerification, getAllVerification } from "app/store";
import VerificationSkeleton from "../skeleton";
import { AiFillCaretDown } from "react-icons/ai";
import { usePagination } from "@ajna/pagination";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useSearchParams, useNavigate } from "react-router-dom";
import PaginationWrapper from "views/components/PaginationWrapper/PaginationWrapper";
import { formatFullDateToUsersLocale } from "data/utils/date-formatter-users-locale";

const SingleUserVerificationTable = () => {
  const navigate = useNavigate();
  const [pageSize] = useState(10);
  const dispatch = useAppDispatch();
  const [id, setId] = React.useState(0);
  let [searchParams] = useSearchParams();

  const [deleting, allVerification] = useAppSelector((state) => [
    state.verification.deleteVerifiaction.loading,
    state.verification.allVerification
  ]);

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, offset } =
    usePagination({
      limits: { inner: 2, outer: 1 },
      total: allVerification.pagination.total,
      initialState: { currentPage: 1, pageSize }
    });

  const handlePageChange = (nextPage: number) => {
    setCurrentPage(nextPage);
    setTimeout(() => {
      window.scrollTo({ top: 10, behavior: "smooth" });
    }, 500);
  };

  const onOpenVerificationDetails = (id: number, status: string) => {
    navigate(
      `/verification?verification-status=${status}&verification-id=${id}&verification-detail=attachments`
    );
  };

  const handleDeleteKyc = async (id: number, status: string) => {
    setId(id);
    if (status === "approved" || deleting) return;
    const success = await dispatch(deleteVerification(id));

    if (success) {
      setId(0);
      dispatch(
        getAllVerification({ userId, page: currentPage, perpage: pageSize })
      );
    }
  };

  // fetch all verification
  const userId = +searchParams.get("user-id")!;

  useEffect(() => {
    if (!userId) return;

    dispatch(
      getAllVerification({
        userId,
        page: currentPage,
        perpage: pageSize
      })
    );
  }, [userId, pageSize, currentPage]);

  return (
    <>
      <Box bg="#fff" borderRadius="16px" padding="1rem 0">
        <TableContainer bg="#fff" minH="calc(100vh - 10rem)">
          <Table
            color="brand.grayScale"
            fontSize="14px"
            lineHeight="18.2px"
            colorScheme={"gray"}
            variant="striped"
          >
            <Thead>
              <Tr>
                <Th
                  textTransform={"capitalize"}
                  color="gray.800"
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"medium"}
                >
                  Name
                </Th>
                <Th
                  textTransform={"capitalize"}
                  color="gray.800"
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"medium"}
                >
                  Email
                </Th>
                <Th
                  textTransform={"capitalize"}
                  color="gray.800"
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"medium"}
                >
                  Dojah Reference
                </Th>
                <Th
                  textTransform={"capitalize"}
                  color="gray.800"
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"medium"}
                >
                  Time of Verification
                </Th>
                <Th
                  textTransform={"capitalize"}
                  color="gray.800"
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"medium"}
                >
                  status
                </Th>
                <Th
                  textTransform={"capitalize"}
                  color="gray.800"
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"medium"}
                >
                  action
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {allVerification.loading ? <VerificationSkeleton /> : null}
              {!!allVerification.data.length && !allVerification.loading
                ? allVerification.data.map((el, idx) => (
                    <Tr key={idx}>
                      <Td
                        textTransform={"capitalize"}
                        cursor="pointer"
                        _hover={{ color: "#B1B4BF" }}
                      >{`${el?.user?.firstName || el.firstName} ${
                        el?.user?.lastName || el.lastName
                      }`}</Td>
                      <Td>{el.user?.email}</Td>
                      <Td>{el?.dojahReferenceId || "--"}</Td>
                      <Td textTransform={"capitalize"} color="gray.500">
                        {el?.updatedAt
                          ? formatFullDateToUsersLocale(el?.updatedAt)
                          : "--"}
                      </Td>
                      <Td textTransform={"capitalize"}>
                        <Badge
                          colorScheme={
                            el.status === "approved"
                              ? "green"
                              : el.status === "pending"
                              ? "yellow"
                              : "red"
                          }
                          px="3"
                          py="1"
                          rounded={"2xl"}
                          textTransform="capitalize"
                          display={"flex"}
                          alignItems="center"
                          justifyContent={"space-between"}
                          gap={"2"}
                          color={
                            el.status === "approved"
                              ? "green.500"
                              : el.status === "pending"
                              ? "yellow.500"
                              : "red.500"
                          }
                          border={"1px"}
                          fontWeight={"normal"}
                        >
                          <Text>{el.status}</Text>
                          <Icon
                            as={AiFillCaretDown}
                            w={3}
                            h={3}
                            color={
                              el.status === "approved"
                                ? "green.500"
                                : el.status === "pending"
                                ? "yellow.500"
                                : "red.500"
                            }
                          />
                        </Badge>
                      </Td>
                      <Td className="flex gap-3">
                        <Button
                          size={"sm"}
                          borderColor="gray.400"
                          colorScheme="gray"
                          _hover={{ background: "primary.100" }}
                          variant="outline"
                          px="6"
                          onClick={() =>
                            onOpenVerificationDetails(el.id, el.status)
                          }
                        >
                          view
                        </Button>
                        <Button
                          size="sm"
                          colorScheme="red"
                          isLoading={deleting && id === el.id}
                          disabled={el.status === "approved" || deleting}
                          onClick={() => handleDeleteKyc(el.id, el.status)}
                        >
                          Delete
                        </Button>
                      </Td>
                    </Tr>
                  ))
                : null}
            </Tbody>
          </Table>
        </TableContainer>
        <PaginationWrapper
          handlePageChange={handlePageChange}
          totalDataCount={allVerification.pagination.total}
          currentPage={currentPage}
          isDisabled={isDisabled}
          pagesCount={pagesCount}
          offset={offset}
          pages={pages}
          size={pageSize}
        />
      </Box>
    </>
  );
};

export default SingleUserVerificationTable;
