import { createIcon } from "@chakra-ui/icon";

export const WalletCheckSolidIcon = createIcon({
  displayName: "WalletCheckSolidIcon",
  viewBox: "0 0 24 24",
  path: [
    <path
      d="M2.25972 20.8044L2.25978 20.8044L2.25559 20.7976C1.93368 20.2755 1.75 19.6563 1.75 19C1.75 17.0661 3.31614 15.5 5.25 15.5C7.18386 15.5 8.75 17.0661 8.75 19C8.75 19.6563 8.56632 20.2755 8.24441 20.7976L8.24436 20.7975L8.24028 20.8044C7.63707 21.8185 6.52725 22.5 5.25 22.5C3.97275 22.5 2.86293 21.8185 2.25972 20.8044ZM7.5595 19.0371L7.5595 19.0371L7.56116 19.0355C8.05994 18.57 8.09682 17.7738 7.62553 17.2688C7.16027 16.7704 6.36475 16.7332 5.85975 17.2036C5.85945 17.2039 5.85914 17.2042 5.85884 17.2045L4.61351 18.3564L4.47355 18.2164C3.98829 17.7312 3.19171 17.7312 2.70645 18.2164C2.22118 18.7017 2.22118 19.4983 2.70645 19.9836L3.69645 20.9736C3.94516 21.2223 4.26455 21.34 4.58 21.34C4.88173 21.34 5.19438 21.2253 5.42999 21.0066C5.43007 21.0065 5.43015 21.0065 5.43023 21.0064L7.5595 19.0371Z"
      fill="#7959F5"
      stroke="#7959F5"
    />,
    <path
      d="M19.25 13H21.75V16H19.25C18.4261 16 17.75 15.3239 17.75 14.5C17.75 13.6761 18.4261 13 19.25 13Z"
      fill="#7959F5"
      stroke="#7959F5"
    />,
    <path
      d="M16.3135 5.63992L8.13057 5.63003H8.12996C8.12078 5.63003 8.11579 5.62872 8.11373 5.62807C8.11153 5.62736 8.10989 5.62651 8.10832 5.6254C8.10475 5.62289 8.09883 5.61691 8.09455 5.60654C8.09026 5.59618 8.09018 5.58764 8.09097 5.58308C8.09133 5.58106 8.09193 5.57915 8.09307 5.57693C8.09416 5.57484 8.09688 5.57023 8.10352 5.56359L8.10352 5.56359L8.10452 5.56258L9.85338 3.80372C11.138 2.52894 13.2114 2.5288 14.4961 3.80331L16.3135 5.63992Z"
      fill="#7959F5"
      stroke="#7959F5"
    />,
    <path
      d="M22.12 18.66C21.51 20.72 19.75 22 17.35 22H10.85C10.46 22 10.21 21.57 10.37 21.21C10.67 20.51 10.86 19.72 10.86 19C10.86 15.97 8.38999 13.5 5.35999 13.5C4.59999 13.5 3.85999 13.66 3.17999 13.96C2.80999 14.12 2.35999 13.87 2.35999 13.47V12C2.35999 9.28 3.99999 7.38 6.54999 7.06C6.79999 7.02 7.06998 7 7.34999 7H17.35C17.61 7 17.86 7.01 18.1 7.05C20.12 7.28 21.58 8.51 22.12 10.34C22.22 10.67 21.98 11 21.64 11H19.35C17.18 11 15.46 12.98 15.93 15.23C16.26 16.87 17.78 18 19.45 18H21.64C21.99 18 22.22 18.34 22.12 18.66Z"
      fill="#7959F5"
    />
  ]
});
