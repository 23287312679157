import {
  PaginationState,
  VirtualAccountData,
  VirtualAccountsState
} from "../models/virtual-accounts";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialPagination: PaginationState = {
  next: 2,
  total: 0,
  prevPage: 0,
  lastPage: 0,
  pageSize: 10,
  hasNext: true,
  currentPage: 1,
  hasPrevious: false
};

const initialState: VirtualAccountsState = {
  allVirtualAccounts: {
    data: [],
    loading: false,
    pagination: initialPagination
  }
};

export const VirtualAccountSlice = createSlice({
  initialState,
  name: "virtual-account",
  reducers: {
    getAllVirtualAccountsPending: (state) => {
      state.allVirtualAccounts.loading = true;
    },
    getAllVirtualAccountsFulfilled: (
      state,
      action: PayloadAction<{
        data: VirtualAccountData[];
        pagination: PaginationState;
      }>
    ) => {
      state.allVirtualAccounts.loading = false;
      state.allVirtualAccounts.data = action.payload.data;
      state.allVirtualAccounts.pagination = action.payload.pagination;
    },
    getAllVirtualAccountsFailed: (state) => {
      state.allVirtualAccounts.loading = false;
    }
  }
});

export const actions = VirtualAccountSlice.actions;

export default VirtualAccountSlice.reducer;
