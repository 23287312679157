import React from "react";
import Sidebar from "./sidebar";
import { Box } from "@chakra-ui/react";
import DashboardContent from "./content";

const DashboardLayout = (): JSX.Element => (
  <Layout>
    <Sidebar />
    <DashboardContent />
  </Layout>
);

type LayoutProps = { children: React.ReactNode };

const Layout = (props: LayoutProps) => (
  <Box display="flex" height="100vh" overflow="hidden">
    {props.children}
  </Box>
);

export default DashboardLayout;
