import {
  Box,
  TabProps,
  BoxProps,
  TabListProps,
  TabPanelProps,
  Tab as ChakraTab,
  Link as ChakraLink,
  TabList as ChakraTabList,
  TabPanel as ChakraTabPanel
} from "@chakra-ui/react";
import { Link, useSearchParams } from "react-router-dom";

export const Tab = (props: TabProps) => (
  <ChakraTab
    _selected={{
      fontSize: 16,
      fontWeight: 600,
      borderBottomWidth: 2,
      borderStyle: "solid",
      borderColor: "primary.500",
      backgroundColor: "transparent !important"
    }}
    {...props}
  />
);

export const TabPanel = (props: TabPanelProps) => (
  <ChakraTabPanel p={0} {...props} />
);

type Props = { type: string; disabled?: boolean } & BoxProps;

export const TabLink = ({
  children,
  type,
  disabled,
  ...props
}: Props): JSX.Element => {
  let [searchParams] = useSearchParams();
  let isActive = searchParams.get("type") === type;

  return (
    <ChakraLink
      as={Link}
      userSelect="none"
      to={`?type=${type}`}
      w="fit-content"
      textDecor={"none"}
      textTransform="none"
      _hover={{
        textDecor: "none",
        textColor: (theme) => theme.colors.primary[600]
      }}
      onClick={disabled === true ? (e) => e.preventDefault() : undefined}
    >
      <Box
        width="100%"
        flexGrow={1}
        height="35px"
        flexBasis="50%"
        alignItems="center"
        display="inline-flex"
        justifyContent="center"
        color={isActive ? "primary.600" : "gray.600"}
        fontSize={16}
        fontWeight={600}
        borderBottomWidth={2}
        borderStyle="solid"
        borderColor="primary.500"
        backgroundColor="transparent !important"
        borderBottom={isActive ? "primary.600" : "transparent"}
        {...props}
      >
        {children}
      </Box>
    </ChakraLink>
  );
};

export const ButtonTab = (props: TabProps) => (
  <ChakraTab
    borderRadius="10px"
    backgroundColor={"gray.100"}
    _selected={{
      fontSize: 16,
      fontWeight: 600,
      backgroundColor: "primary.500",
      color: "white"
    }}
    whiteSpace="nowrap"
    {...props}
  />
);

export const ButtonTabList = (props: TabListProps) => (
  <ChakraTabList
    p={0}
    borderBottom="none"
    {...props}
    w="100%"
    rounded={"10px"}
    py="2"
  />
);
