import {
  Box,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Divider,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Badge
} from "@chakra-ui/react";
import { debounce } from "lodash";
import { usePagination } from "@ajna/pagination";
import { getAllVirtualCards } from "app/store";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import PaginationWrapper from "views/components/PaginationWrapper/PaginationWrapper";
import TransactionSkeletion from "views/containers/transactions/tables/skeleton";
import NoData from "views/components/no-data";
import { FiSearch } from "react-icons/fi";
import CardDetails from "views/containers/virtual-cards/card-details";
import { useSearchParams } from "react-router-dom";
import { VirtualCardResponse } from "app/store/models/virtual-cards";
import { formatFullDateToUsersLocale } from "data/utils/date-formatter-users-locale";

const UserVirtualCards = () => {
  const dispatch = useAppDispatch();

  const allVirtualCards = useAppSelector(
    (state) => state.virtualCards.allVirtualCards
  );
  const [pageSize, setPageSize] = useState(10);
  const [searchString, setSearchString] = useState("");

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, offset } =
    usePagination({
      initialState: { currentPage: 1, pageSize },
      total: allVirtualCards.pagination?.total,
      limits: {
        inner: 2,
        outer: 1
      }
    });

  const handleSize = (e: any) => {
    setPageSize(e.target.value);
  };
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;

    if (query.length >= 3) {
      setSearchString(query);
      setCurrentPage(1);
    } else {
      setSearchString("");
      setCurrentPage(1);
    }
  };

  const debouncedChangeHandler = useMemo(() => {
    return debounce(handleSearch, 1000);
  }, []);

  const handlePageChange = (nextPage: number) => {
    setCurrentPage(nextPage);
    setTimeout(() => {
      window.scrollTo({ top: 10, behavior: "smooth" });
    }, 500);
  };

  const [selectedCard, setSelectedCard] = useState<VirtualCardResponse | null>(
    null
  );

  let [searchParams] = useSearchParams();
  let userId = searchParams.get("user-id");

  useEffect(() => {
    if (!userId) return;
    const controller = new AbortController();
    dispatch(
      getAllVirtualCards(
        {
          page: currentPage,
          perpage: pageSize,
          q: searchString,
          userId: +userId
        },
        controller.signal
      )
    );

    return () => controller.abort();
  }, [currentPage, pageSize, searchString, userId]);

  return (
    <Box className="container mb-20">
      <div>
        <Box
          p={[4, 6]}
          display="flex"
          bgColor="#fff"
          borderRadius="10px"
          flexDirection="column"
        >
          <Box display="flex" flexDirection="column" className="mt-2">
            <TableContainer bg="#fff" minH="calc(100vh - 10rem)">
              <Stack mt="0.75rem" ml="2" spacing="4" direction={"row"}>
                <Box mb="16px">
                  <InputGroup width="256px">
                    <Input
                      width="256px"
                      paddingLeft="40px"
                      minH="40px"
                      placeholder={"Search by card service id"}
                      border="1px solid #EDEBF9"
                      onChange={debouncedChangeHandler}
                      borderRadius="10px"
                      fontSize="12px"
                      _focus={{
                        border: "1px solid #EDEBF9"
                      }}
                      _hover={{
                        border: "1px solid #EDEBF9"
                      }}
                    />

                    <InputLeftElement
                      h="100%"
                      mr="12px"
                      children={<FiSearch color="#A0A3BD" />}
                    />
                  </InputGroup>
                </Box>
                <Box mb="12px">
                  <Select
                    width="120px"
                    defaultValue={"Size"}
                    placeholder="Size"
                    onChange={handleSize}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                  </Select>
                </Box>
              </Stack>
              <Table
                color="brand.grayScale"
                fontSize="14px"
                lineHeight="18.2px"
                colorScheme={"gray"}
                variant="striped"
              >
                <Thead>
                  <Tr>
                    <Th
                      textTransform={"capitalize"}
                      color="gray.800"
                      fontSize={"16px"}
                      lineHeight={"19px"}
                      fontWeight={"medium"}
                    >
                      Card Name
                    </Th>
                    <Th
                      textTransform={"capitalize"}
                      color="gray.800"
                      fontSize={"16px"}
                      lineHeight={"19px"}
                      fontWeight={"medium"}
                    >
                      Card Details
                    </Th>
                    <Th
                      textTransform={"capitalize"}
                      color="gray.800"
                      fontSize={"16px"}
                      lineHeight={"19px"}
                      fontWeight={"medium"}
                    >
                      Currency
                    </Th>

                    <Th
                      textTransform={"capitalize"}
                      color="gray.800"
                      fontSize={"16px"}
                      lineHeight={"19px"}
                      fontWeight={"medium"}
                    >
                      Total Transactions
                    </Th>
                    <Th
                      textTransform={"capitalize"}
                      color="gray.800"
                      fontSize={"16px"}
                      lineHeight={"19px"}
                      fontWeight={"medium"}
                    >
                      User
                    </Th>

                    <Th
                      textTransform={"capitalize"}
                      color="gray.800"
                      fontSize={"16px"}
                      lineHeight={"19px"}
                      fontWeight={"medium"}
                    >
                      Status
                    </Th>
                    <Th
                      textTransform={"capitalize"}
                      color="gray.800"
                      fontSize={"16px"}
                      lineHeight={"19px"}
                      fontWeight={"medium"}
                    >
                      Date Created
                    </Th>
                  </Tr>
                </Thead>
                <Divider />
                <Tbody>
                  {allVirtualCards.loading ? <TransactionSkeletion /> : null}
                  {!!allVirtualCards.data.length && !allVirtualCards.loading
                    ? allVirtualCards.data.map((el, idx) => (
                        <Tr key={idx}>
                          <Td textTransform={"capitalize"}>
                            {el.cardName || "--"}
                          </Td>

                          <Td textTransform={"capitalize"}>
                            <Stack>
                              <Text
                                fontSize={"14px"}
                                fontWeight="medium"
                                lineHeight={"1.2"}
                              >
                                {` Last 4: ${el.last4 || ""}`}
                              </Text>
                              <Text
                                fontSize={"14px"}
                                lineHeight={"1.2"}
                                color="green.600"
                                marginTop={0}
                                cursor="pointer"
                                fontWeight="bold"
                                onClick={() => {
                                  setSelectedCard(el);
                                }}
                              >
                                View More
                              </Text>
                            </Stack>
                          </Td>
                          <Td textTransform={"uppercase"}>
                            {el.currency || "--"}
                          </Td>
                          <Td textTransform={"capitalize"}>
                            {el.totalTransactions || "--"}
                          </Td>
                          <Td textTransform={"capitalize"}>
                            <Stack>
                              <Text
                                fontSize={"14px"}
                                fontWeight="medium"
                                lineHeight={"1.2"}
                              >
                                {`${el.user?.firstName || "--"} ${
                                  el.user?.lastName || ""
                                }`}
                              </Text>
                            </Stack>
                          </Td>

                          <Td textTransform={"capitalize"}>
                            <Badge
                              colorScheme={
                                !el.isActive || el.status === "frozen"
                                  ? "red"
                                  : "green"
                              }
                              px="3"
                              py="1"
                              rounded={"2xl"}
                              textTransform="capitalize"
                              display={"flex"}
                              alignItems="center"
                              justifyContent={"center"}
                              gap={"2"}
                              color={
                                !el.isActive || el.status === "frozen"
                                  ? "red.500"
                                  : "green.500"
                              }
                              fontWeight={"normal"}
                            >
                              <Text className="text-center">
                                {!el.isActive || el.status === "frozen"
                                  ? "Frozen"
                                  : "Unfrozen"}
                              </Text>
                            </Badge>
                          </Td>
                          <Td textTransform={"capitalize"} color="gray.500">
                            {el.createdAt
                              ? formatFullDateToUsersLocale(el.createdAt)
                              : "--"}
                          </Td>
                        </Tr>
                      ))
                    : null}
                  {!allVirtualCards.data.length && !allVirtualCards.loading ? (
                    <Tr>
                      <Td colSpan={7} background="transparent" h="xl">
                        <NoData text={`No virtual cards Yet`} />
                      </Td>
                    </Tr>
                  ) : null}
                </Tbody>
              </Table>
            </TableContainer>

            <PaginationWrapper
              handlePageChange={handlePageChange}
              totalDataCount={allVirtualCards.pagination?.total}
              currentPage={currentPage}
              isDisabled={isDisabled}
              pagesCount={pagesCount}
              offset={offset}
              pages={pages}
              size={pageSize}
            />
          </Box>
        </Box>
      </div>

      <CardDetails
        selectedCard={selectedCard}
        isOpen={!!selectedCard}
        onClose={() => setSelectedCard(null)}
      />
    </Box>
  );
};

export default UserVirtualCards;
