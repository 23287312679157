import { usePagination } from "@ajna/pagination";
import {
  Box,
  Divider,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { getUserTransactions } from "app/store/action-creators/transactions.actions";
import { ChangeraTransactionType } from "app/store/models/transactions";
import { useEffect, useMemo, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { useSearchParams } from "react-router-dom";
import NoData from "views/components/no-data";
import PaginationWrapper from "views/components/PaginationWrapper/PaginationWrapper";
import TransactionSkeletion from "./skeleton";
import { debounce } from "lodash";
import getSymbolFromCurrency from "currency-symbol-map";
import { formatFullDateToUsersLocale } from "data/utils/date-formatter-users-locale";
import { TransactionBadge } from "views/components/badge";
import VirtualCardTransactionsModal from "views/containers/virtual-cards/vcard-tx-modal";

const UserTransactionsTableContainer = () => {
  const dispatch = useAppDispatch();

  const allTransactions = useAppSelector(
    (state) => state.transactions.userTransactions
  );

  let [searchParams] = useSearchParams();
  let changeraTransactionType = searchParams.get(
    "transaction-type"
  ) as ChangeraTransactionType;
  let userId = searchParams.get("user-id");

  // pagination and search
  const [pageSize, setPageSize] = useState(10);
  const [searchString, setSearchString] = useState("");

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, offset } =
    usePagination({
      initialState: { currentPage: 1, pageSize },
      total: allTransactions.pagination.total,
      limits: {
        inner: 2,
        outer: 1
      }
    });

  const handlePageChange = (nextPage: number) => {
    setCurrentPage(nextPage);
    setTimeout(() => {
      window.scrollTo({ top: 10, behavior: "smooth" });
    }, 500);
  };

  const handleSize = (e: any) => {
    setPageSize(e.target.value);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;

    if (query.length >= 3) {
      setSearchString(query);
      setCurrentPage(1);
    } else {
      setSearchString("");
      setCurrentPage(1);
    }
  };

  const debouncedChangeHandler = useMemo(() => {
    return debounce(handleSearch, 1000);
  }, []);

  useEffect(() => {
    if (!changeraTransactionType || !userId) return;
    dispatch(
      getUserTransactions({
        page: currentPage,
        perpage: pageSize,
        q: searchString,
        userId: +userId,
        changeraTransactionType:
          (changeraTransactionType as any) === "all"
            ? undefined
            : changeraTransactionType
      })
    );
  }, [changeraTransactionType, currentPage, pageSize, searchString, userId]);

  useEffect(() => {
    setCurrentPage(1);
  }, [changeraTransactionType]);

  const [virtualCardId, setVirtualCardId] = useState("");
  const isVirtualCard = changeraTransactionType === "card";

  return (
    <>
      <TableContainer bg="#fff" minH="calc(100vh - 10rem)">
        <Stack mt="0.75rem" ml="2" spacing="4" direction={"row"}>
          <Box mb="16px">
            <InputGroup width="256px">
              <Input
                width="256px"
                paddingLeft="40px"
                minH="40px"
                placeholder={
                  "Search by reference or groupt transaction reference"
                }
                border="1px solid #EDEBF9"
                onChange={debouncedChangeHandler}
                borderRadius="10px"
                fontSize="12px"
                _focus={{
                  border: "1px solid #EDEBF9"
                }}
                _hover={{
                  border: "1px solid #EDEBF9"
                }}
              />

              <InputLeftElement
                h="100%"
                mr="12px"
                children={<FiSearch color="#A0A3BD" />}
              />
            </InputGroup>
          </Box>
          <Box mb="12px">
            <Select
              width="120px"
              defaultValue={"Size"}
              placeholder="Size"
              onChange={handleSize}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
            </Select>
          </Box>
        </Stack>
        <Table
          color="brand.grayScale"
          fontSize="14px"
          lineHeight="18.2px"
          colorScheme={"gray"}
          variant="striped"
        >
          <Thead>
            <Tr>
              {isVirtualCard && (
                <Th
                  textTransform={"capitalize"}
                  color="gray.800"
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"medium"}
                >
                  Card ID
                </Th>
              )}
              <Th
                textTransform={"capitalize"}
                color="gray.800"
                fontSize={"16px"}
                lineHeight={"19px"}
                fontWeight={"medium"}
              >
                description
              </Th>
              <Th
                textTransform={"capitalize"}
                color="gray.800"
                fontSize={"16px"}
                lineHeight={"19px"}
                fontWeight={"medium"}
              >
                transaction ref
              </Th>
              <Th
                textTransform={"capitalize"}
                color="gray.800"
                fontSize={"16px"}
                lineHeight={"19px"}
                fontWeight={"medium"}
              >
                recipient
              </Th>
              <Th
                textTransform={"capitalize"}
                color="gray.800"
                fontSize={"16px"}
                lineHeight={"19px"}
                fontWeight={"medium"}
              >
                currency / type
              </Th>
              <Th
                textTransform={"capitalize"}
                color="gray.800"
                fontSize={"16px"}
                lineHeight={"19px"}
                fontWeight={"medium"}
              >
                amount
              </Th>
              <Th
                textTransform={"capitalize"}
                color="gray.800"
                fontSize={"16px"}
                lineHeight={"19px"}
                fontWeight={"medium"}
              >
                rate
              </Th>
              <Th
                textTransform={"capitalize"}
                color="gray.800"
                fontSize={"16px"}
                lineHeight={"19px"}
                fontWeight={"medium"}
              >
                balance before
              </Th>
              <Th
                textTransform={"capitalize"}
                color="gray.800"
                fontSize={"16px"}
                lineHeight={"19px"}
                fontWeight={"medium"}
              >
                balance after
              </Th>
              <Th
                textTransform={"capitalize"}
                color="gray.800"
                fontSize={"16px"}
                lineHeight={"19px"}
                fontWeight={"medium"}
              >
                date created
              </Th>
              <Th
                textTransform={"capitalize"}
                color="gray.800"
                fontSize={"16px"}
                lineHeight={"19px"}
                fontWeight={"medium"}
              >
                status
              </Th>
            </Tr>
          </Thead>
          <Divider />
          <Tbody>
            {allTransactions.loading ? <TransactionSkeletion /> : null}
            {!!allTransactions.data.length && !allTransactions.loading
              ? allTransactions.data.map((el, idx) => (
                  <Tr key={idx}>
                    {isVirtualCard && (
                      <Td
                        className="text-blue-500 font-medium cursor-pointer"
                        textTransform={"capitalize"}
                        onClick={() => {
                          setVirtualCardId(
                            (el?.virtualCardId || "").toString()
                          );
                        }}
                      >
                        {el?.virtualCardId || "--"}
                      </Td>
                    )}
                    <Td textTransform={"capitalize"}>
                      {el.description || "--"}
                    </Td>
                    <Td textTransform={"capitalize"}>{el.reference}</Td>
                    <Td textTransform={"capitalize"}>{"N/A"}</Td>
                    <Td textTransform={"capitalize"} fontWeight={500}>
                      {`${el.currency.toUpperCase()} / ${el.currencyType}`}
                    </Td>
                    <Td
                      textTransform={"capitalize"}
                      fontWeight={500}
                      color={el.type === "credit" ? "success.500" : "error.500"}
                    >
                      {el.type === "credit" ? "+" : "-"}{" "}
                      {getSymbolFromCurrency(el.currency)}{" "}
                      {el.amount ? el.amount.toLocaleString() : "0"}
                    </Td>
                    <Td textTransform={"capitalize"}>{el.rate || "--"}</Td>
                    <Td textTransform={"capitalize"} fontWeight={500}>
                      {el.balanceBefore
                        ? el.balanceBefore.toLocaleString()
                        : "0"}
                    </Td>
                    <Td textTransform={"capitalize"} fontWeight={500}>
                      {el.balanceAfter ? el.balanceAfter.toLocaleString() : "0"}
                    </Td>
                    <Td textTransform={"capitalize"} color="gray.500">
                      {el.createdAt
                        ? formatFullDateToUsersLocale(el.createdAt)
                        : "--"}
                    </Td>
                    <Td textTransform={"capitalize"}>
                      <TransactionBadge variant={el.status as any} />
                    </Td>
                  </Tr>
                ))
              : null}
            {!allTransactions.data.length && !allTransactions.loading ? (
              <Tr>
                <Td colSpan={7} background="transparent" h="xl">
                  <NoData
                    text={`No ${changeraTransactionType} Transactions Yet`}
                  />
                </Td>
              </Tr>
            ) : null}
          </Tbody>
        </Table>
      </TableContainer>

      <VirtualCardTransactionsModal
        id={+virtualCardId}
        onClose={() => setVirtualCardId("")}
        isOpen={isVirtualCard && !!virtualCardId}
      />

      <PaginationWrapper
        handlePageChange={handlePageChange}
        totalDataCount={allTransactions.pagination?.total}
        currentPage={currentPage}
        isDisabled={isDisabled}
        pagesCount={pagesCount}
        offset={offset}
        pages={pages}
        size={pageSize}
      />
    </>
  );
};

export default UserTransactionsTableContainer;
