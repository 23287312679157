import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  PaginationState,
  ReportsResponse,
  ReportsState
} from "../models/reports";

const initialState: ReportsState = {
  allReports: {
    data: [],
    loading: false,
    pagination: {
      hasPrevious: false,
      prevPage: 0,
      hasNext: true,
      next: 2,
      currentPage: 1,
      pageSize: 10,
      lastPage: 0,
      total: 62
    }
  }
};

export const ReportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    getAllReportsBegin: (state: ReportsState) => {
      state.allReports.loading = true;
    },
    getAllReportsSuccess: (
      state: ReportsState,
      actions: PayloadAction<{
        data: ReportsResponse[];
        pagination: PaginationState;
      }>
    ) => {
      state.allReports.data = actions.payload.data;
      state.allReports.loading = false;
      state.allReports.pagination = actions.payload.pagination;
    },
    error: (state: ReportsState) => {
      state.allReports.loading = false;
    }
  }
});

export const actions = ReportsSlice.actions;

export default ReportsSlice.reducer;
