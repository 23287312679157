import { Box, Text } from "@chakra-ui/react";

type Props = {
  name: string;
  value?: string | number;
  icon: React.ComponentType<{ width: any; height: any }>;
};

const StatsCard = ({ name, value, icon: Icon }: Props) => (
  <Box
    p={4}
    px={4}
    py={5}
    data-group
    gap="14px"
    flexGrow={1}
    width="100%"
    bgColor="#fff"
    display="flex"
    borderRadius="xl"
    cursor="pointer"
    flexDirection="row"
    alignItems="center"
    _hover={{
      boxShadow: "2xl",
      transition: "all ease 0.25s",
      backgroundColor: "purple.500"
    }}
  >
    <Box
      gap={2}
      width="44px"
      height="44px"
      padding="10px"
      borderRadius="8px"
      bgColor="purple.100"
      _groupHover={{ bgColor: "#fff" }}
    >
      <Icon width="24px" height="24px" />
    </Box>
    <div>
      <Text
        mb={2}
        fontSize={24}
        color="gray.900"
        lineHeight="28px"
        fontWeight={500}
        fontFamily="Karla"
        _groupHover={{ color: "#fff" }}
      >
        {value}
      </Text>
      <Text
        color="gray.600"
        lineHeight="18px"
        fontSize={14}
        _groupHover={{ color: "#fff" }}
      >
        {name}
      </Text>
    </div>
  </Box>
);

export default StatsCard;
