import {
  Td,
  Th,
  Tr,
  Table,
  Tbody,
  Thead,
  Stack,
  Button,
  TableContainer
} from "@chakra-ui/react";
import React from "react";
import SkeletonLoader from "./skeleton";
import { usePagination } from "@ajna/pagination";
import { useValentineWishList } from "app/hooks/use-wishlist";
import { IValentineWishListUsers } from "app/store/models/wishlist";
import PaginationWrapper from "views/components/PaginationWrapper/PaginationWrapper";

const heading = ["Username", "Email", "Transaction Volume", "Action"];

const ValentineWishListTopUsersTable = () => {
  const [id, setId] = React.useState(0);

  const {
    valentineWishListTopUsers,
    dispatchValentineWishTopUsers,
    approveValentineWishListState,
    dispatchSelectValentineWishListWinner
  } = useValentineWishList();

  const {
    pages,
    offset,
    pageSize,
    pagesCount,
    isDisabled,
    currentPage,
    setCurrentPage
  } = usePagination({
    limits: { inner: 2, outer: 1 },
    initialState: { currentPage: 1, pageSize: 10 },
    total: valentineWishListTopUsers?.pagination?.total
  });

  const handlePageChange = (nextPage: number) => {
    setCurrentPage(nextPage);
    setTimeout(() => {
      window.scrollTo({ top: 10, behavior: "smooth" });
    }, 500);
  };

  const handleApprove = async (user: IValentineWishListUsers) => {
    setId(user.userId);
    const res = await dispatchSelectValentineWishListWinner(user);
    if (res) dispatchValentineWishTopUsers({ page: 1, perpage: 100 });
  };

  React.useEffect(() => {
    if (valentineWishListTopUsers?.loading) return;
    dispatchValentineWishTopUsers({ page: 1, perpage: 100 });
  }, [pageSize, currentPage]);

  return (
    <React.Fragment>
      <TableContainer bg="#fff" minH="calc(100vh - 10rem)">
        <Table fontSize="14px" lineHeight="18.2px">
          <Thead>
            <Tr>
              {heading.map((data) => (
                <Th
                  key={data}
                  fontSize="16px"
                  color="gray.800"
                  lineHeight="19px"
                  fontWeight="medium"
                  textTransform="capitalize"
                >
                  {data}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {valentineWishListTopUsers?.loading ? <SkeletonLoader /> : null}
            {valentineWishListTopUsers?.data?.map((el, idx) => (
              <Tr key={`${el.email}-${idx}`}>
                <Td>
                  {el?.firstName} {el.lastName}
                </Td>
                <Td textTransform={"capitalize"}>{el?.email}</Td>
                <Td textTransform={"capitalize"}>
                  ${el?.amount?.toLocaleString() || 0}
                </Td>

                <Td>
                  <Stack gap={2} direction="row">
                    <Button
                      size="md"
                      colorScheme="green"
                      fontWeight="medium"
                      onClick={() => handleApprove(el)}
                      disabled={
                        approveValentineWishListState.loading &&
                        id === +el.userId
                      }
                      isDisabled={
                        approveValentineWishListState.loading &&
                        id === +el.userId
                      }
                      isLoading={
                        approveValentineWishListState.loading &&
                        id === el.userId
                      }
                    >
                      Select
                    </Button>
                  </Stack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      <PaginationWrapper
        pages={pages}
        offset={offset}
        size={pageSize}
        isDisabled={isDisabled}
        pagesCount={pagesCount}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        totalDataCount={valentineWishListTopUsers?.pagination?.total}
      />
    </React.Fragment>
  );
};

export default ValentineWishListTopUsersTable;
