import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink
} from "@chakra-ui/react";
import { useBreadCrumbs } from "app/hooks";
import VerificationTable from "./verification-table";

const VerificationContainer = () => {
  const breadCrumbs = useBreadCrumbs();
  return (
    <Box className="mb-20">
      <Breadcrumb mb={"5"}>
        {breadCrumbs.map((el, idx) => (
          <BreadcrumbItem
            key={idx}
            isCurrentPage={idx === breadCrumbs.length - 1}
          >
            <BreadcrumbLink href={el.to}>{el.title}</BreadcrumbLink>
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
      <VerificationTable />
    </Box>
  );
};

export default VerificationContainer;
