import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthState, twoFactorState } from "../models/auth";

const twoFactorInit = {
  step: 0,
  state: {
    url: "",
    secret: ""
  },
  email: ""
};

const initialState: AuthState = {
  isLoggedIn: false,
  isRegistered: false,
  loading: false,
  errorMsg: "",
  error: false,
  twoFactor: twoFactorInit
};

export const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loadingBegin: (state: AuthState) => {
      state.loading = true;
    },
    loadingStop: (state: AuthState) => {
      state.loading = false;
    },
    error: (state: AuthState) => {
      state.loading = false;
      state.error = true;
    },
    loginSuccess: (state: AuthState, action: PayloadAction<boolean>) => {
      state.loading = false;
      state.isLoggedIn = action.payload;
      state.error = false;
      state.twoFactor = twoFactorInit;
    },
    logoutBegin: (state: AuthState) => {
      state.loading = true;
    },
    logoutSuccess: (state: AuthState) => {
      state.loading = false;
      state.isLoggedIn = false;
      state.error = false;
    },
    set2FA: (state, action: PayloadAction<twoFactorState>) => {
      state.twoFactor.state.url = action.payload.url;
      state.twoFactor.state.secret = action.payload.secret;
    },
    settwoFAStep: (state, action: PayloadAction<number>) => {
      state.twoFactor.step = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.twoFactor.email = action.payload;
    }
  }
});

export const {
  loadingBegin,
  error,
  loginSuccess,
  logoutBegin,
  logoutSuccess,
  loadingStop,
  set2FA,
  settwoFAStep,
  setEmail
} = AuthSlice.actions;

export default AuthSlice.reducer;
