import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  PaginationState,
  SingleCardResponse,
  VirtualCardResponse,
  VirtualCardState
} from "../models/virtual-cards";

const initialState: VirtualCardState = {
  updateCard: { loading: false, error: "" },
  cardDetails: {
    data: null,
    loading: false
  },
  allVirtualCards: {
    data: [],
    loading: false,
    pagination: {
      hasPrevious: false,
      prevPage: 0,
      hasNext: true,
      next: 2,
      currentPage: 1,
      pageSize: 10,
      lastPage: 0,
      total: 62
    }
  }
};

export const VirtualCardSlice = createSlice({
  name: "virtualCards",
  initialState,
  reducers: {
    getAllVirtualCardBegin: (state: VirtualCardState) => {
      state.allVirtualCards.loading = true;
    },
    getAllVirtualCardSuccess: (
      state: VirtualCardState,
      actions: PayloadAction<{
        data: VirtualCardResponse[];
        pagination: PaginationState;
      }>
    ) => {
      state.allVirtualCards.data = actions.payload.data;
      state.allVirtualCards.loading = false;
      state.allVirtualCards.pagination = actions.payload.pagination;
    },
    gettingCardDetails: (state: VirtualCardState) => {
      state.cardDetails.data = null;
      state.cardDetails.loading = true;
    },
    gottenCardDetails: (
      state: VirtualCardState,
      actions: PayloadAction<{
        data: SingleCardResponse;
      }>
    ) => {
      state.cardDetails.data = actions.payload.data;
      state.cardDetails.loading = false;
    },
    error: (state: VirtualCardState) => {
      state.allVirtualCards.loading = false;
      state.cardDetails.loading = false;
    },
    updateVirtualCardStatusBegin: (state: VirtualCardState) => {
      state.updateCard.loading = true;
    },
    updateVirtualCardStatusSuccess: (state: VirtualCardState) => {
      state.updateCard.loading = false;
    },
    updateVirtualCardStatusFailed: (
      state: VirtualCardState,
      action: PayloadAction<string>
    ) => {
      state.updateCard.loading = false;
      state.updateCard.error = action.payload;
    }
  }
});

export const actions = VirtualCardSlice.actions;

export default VirtualCardSlice.reducer;
