import { AxiosResponse } from "axios";
import instance from "data/axios-setup";
import { twoFactorState } from "app/store/models/auth";

class AuthService {
  async generate2FA(body: {
    email: string;
  }): Promise<AxiosResponse<{ data: twoFactorState }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.post("/admin/two-fa/generate", body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async loginAdminUser(body: { email: string; password: string }): Promise<
    AxiosResponse<{
      message: string;
    }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.post("/admin/auth/login", body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async twoFALogin(body: { email: string; code: string }): Promise<
    AxiosResponse<{
      token: string;
      message: string;
    }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.post("/admin/auth/two-fa", body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async logout(): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post(`/auth/logout`);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }
}

export default new AuthService();
