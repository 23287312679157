import {
  Button,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  PinInput,
  PinInputField,
  Stack
} from "@chakra-ui/react";
import { UilEnvelope } from "@iconscout/react-unicons";
import { UilPadlock } from "@iconscout/react-unicons";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as RoundIcon } from "app/assets/svg/roundIcon.svg";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import authService from "data/services/auth-service";
import toast from "react-hot-toast";
import { login2FA } from "app/store";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";

export const LoginContainer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => state.auth.loading);
  const [values, setValues] = useState({ email: "", password: "" });

  //
  const { email, password } = values;
  const handleChange =
    (name: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [name]: e.target.value });
    };

  //
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    try {
      const res = await authService.loginAdminUser(values);
      toast.success(res.data.message);
      setStep(1);
    } catch (error: any) {
      toast.error(error?.response?.data?.message || DEFAULT_ERROR_MESSAGE);
    }
  };

  //
  const [pin, setPin] = useState("");

  const submitPinHandler = useCallback(
    async (e: { preventDefault: () => void }) => {
      e.preventDefault();
      if (pin.length < 6) return;

      dispatch(login2FA(navigate, { email, code: pin }));
    },
    [pin]
  );

  useEffect(() => {
    if (pin.length < 6) return;
    dispatch(login2FA(navigate, { email, code: pin }));
  }, [pin]);

  //
  const [step, setStep] = useState(0);

  const steps = [
    <>
      <p className="text-gray-900 text-heading100 mdx:text-heading300">
        Welcome Back!
      </p>
      <p className="mt-1 text-gray-700">
        Enter your details below to sign into your Changera admin account
      </p>
      <div className="mt-6 mdx:mt-10 authForm">
        <form onSubmit={handleSubmit}>
          <div className="my-4">
            <InputGroup className="items-center">
              <InputLeftElement
                top="auto"
                pointerEvents="none"
                children={<UilEnvelope color="#B1B4BF" />}
              />
              <Input
                focusBorderColor="none"
                type="email"
                placeholder="Business Email"
                size="lg"
                color="#444B59"
                value={email}
                onChange={handleChange("email")}
                name="email"
                isRequired
                _placeholder={{ opacity: 1, color: "#667085" }}
              />
            </InputGroup>
          </div>
          <div className="my-4">
            <InputGroup className="items-center">
              <InputLeftElement
                top="auto"
                pointerEvents="none"
                children={<UilPadlock color="#B1B4BF" />}
              />
              <Input
                focusBorderColor="none"
                type={show ? "text" : "password"}
                placeholder="Password"
                size="lg"
                name="password"
                color="#667085"
                isRequired
                value={password}
                onChange={handleChange("password")}
                _placeholder={{ opacity: 1, color: "#667085" }}
              />
              <InputRightElement width="4.5rem" top="auto">
                <Button size="sm" onClick={handleClick}>
                  {show ? "Hide" : "Show"}
                </Button>
              </InputRightElement>
            </InputGroup>
          </div>
          <div>
            <Link
              to={"/auth/2fa"}
              className="text-green-500 hover:text-green-400 text-center w-full block"
            >
              Generate 2FA
            </Link>
          </div>
          <div className="mt-8 mdx:mt-12">
            <Button
              isDisabled={!values.email || !values.password}
              isLoading={isLoading}
              className="mdx:p-6 bg-primary-600"
              textColor="white"
              type="submit"
              bgColor="#111834"
              w="full"
              _hover={{ bgColor: "#3B435E" }}
            >
              <div className="flex items-center">
                <p>Sign In</p>
                <RoundIcon className="ml-2" />
              </div>
            </Button>
          </div>
        </form>
      </div>
    </>,
    <Stack alignItems={"center"}>
      <p className="text-gray-900 text-heading100 mdx:text-heading300">
        Welcome Back!
      </p>
      <p className="mt-1 text-gray-700">Enter your 6 didgit code below</p>
      <HStack py="10">
        <PinInput value={pin} onChange={(value) => setPin(value)}>
          <PinInputField borderColor={"primary.500"} />
          <PinInputField borderColor={"primary.500"} />
          <PinInputField borderColor={"primary.500"} />
          <PinInputField borderColor={"primary.500"} />
          <PinInputField borderColor={"primary.500"} />
          <PinInputField borderColor={"primary.500"} />
        </PinInput>
      </HStack>
      <div className="mt-8 mdx:mt-12 w-full">
        <Button
          isDisabled={pin.length < 6}
          isLoading={isLoading}
          className="mdx:p-6 bg-primary-600"
          textColor="white"
          type="submit"
          bgColor="#111834"
          w="full"
          _hover={{ bgColor: "#3B435E" }}
          onClick={submitPinHandler}
        >
          <div className="flex items-center">
            <p>Submit</p>
            <RoundIcon className="ml-2" />
          </div>
        </Button>
      </div>
    </Stack>
  ];

  return (
    <div className="w-11/12 p-6 bg-gray-100 mdx:w-5/12 sm:w-9/12">
      {steps[step]}
    </div>
  );
};
