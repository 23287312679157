import {
  PaginationState,
  VerificationData,
  VerificationStatus,
  SingleVerificationData
} from "app/store/models/verification";
import { AxiosResponse } from "axios";
import instance from "data/axios-setup";

class VerificationService {
  async getAllVerification(params: {
    q?: string;
    page: number;
    perpage: number;
    userId?: number;
    status?: VerificationStatus;
  }): Promise<
    AxiosResponse<{ data: VerificationData[]; pagination: PaginationState }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get(`/admin/kyc`, { params });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async deleteVerification(
    id: number
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.delete(`/admin/kyc/${id}`);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getSingleVerification(
    id: number
  ): Promise<AxiosResponse<{ data: SingleVerificationData }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get(`/admin/kyc/${id}`);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async declineVerification(
    id: number,
    body: { reason: string }
  ): Promise<AxiosResponse<{ data: any }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.put(`/admin/kyc/${id}`, body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async approveVerification(id: number): Promise<AxiosResponse<{ data: any }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.patch(`/admin/kyc/${id}`);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }
}

export default new VerificationService();
